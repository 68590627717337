import '../../assets/scss/_header.scss'
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";
import { useEncryptStorage } from "../../hooks/useEncryptStorage";
import UseIdleTimer from "../../hooks/useIdleTimer"
import { fetchNewAccessToken, fetchUserProfile, postUpdatedTheme } from "../../service/userService"
import { useThemeSwitcher } from '../../hooks/useThemeSwitcher';
import { acknowledgePushNotification, getAllNotifications, getAllPushNotifications, mailAcknowledgement } from '../../service/notificationService';
import { IPushNotification, IThresholdLimitObject } from '../../interface/INotification';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setProfileImage, setRole } from '../../redux/userProfile/action';


export default function Header() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    let thresholdLimitObject = {
        notificationId: 0,
        alertName: "",
        limit: 0,
        mail_group_id: ""
    }

    const [theme, setTheme] = useState<any>(useEncryptStorage.getItem("theme"));
    const [viewLink, setViewLink] = useState<string>(window.location.pathname)
    const [userprofileImage, setUserProfile] = useState<any>("")
    const [refreshToken, setRefreshToken] = useState<boolean>(false)
    const [showLogOutPop, setShowLogoutPopup] = useState<boolean>(false)
    const themeSwitcher = useThemeSwitcher(viewLink)
    let index: number;
    let pushNotification: IPushNotification[];
    const [notificationDetails, setNoticatioNDetails] = useState<IPushNotification>({
        notificationId: 0,
        message: "",
        createdDate: "",
    })
    const [ackMessage, setAckMessage] = useState<string>("")
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [thresHoldLimitNotification, setThresHoldLimitNotification] = useState<IThresholdLimitObject>(thresholdLimitObject)
    const [showThresHoldLimitPopup, setThresHoldLimitPopup] = useState<boolean>(false)
    let notificationInfo: any[] = [];
    const [notificationInfoState, setNotificationInfoState] = useState<any[]>([])
    const [thresHoldAckMessage, setThresHoldAckMessage] = useState<string>("")
    const [notificationId, setNotificationId] = useState<number>(0)
    const [mailGroupId, setMailGroupId] = useState<number>(0)
    const [alertName, setAlertName] = useState<string>("")
    const [limit, setLimit] = useState<number>(0)
    const profileimageStore = useSelector((state: any) => state.profileImage.profileImage)
    const role = useSelector((state: any) => state.role.role)
    const manageUrl = ['/manageproviders', '/manageuser', '/manageevents', '/managesecrets', '/manageconnectors']
    const thresHoldUrl = ['/threshold']
    const InventoryUrl = ['/inventory']
    const environmentUrl = ['/costAnomaly', '/recommendation', '/resourceavailability', '/securityrules','/criticalEvents', '/invoice']
    const organizationUrl = ['/manageorganization']
    const dashboardUrl = ['/dashboard']

    useEffect(() => {
        if(!(useEncryptStorage.getItem("userName"))) {
            navigate('/login')
        }
    },[])
    
    useEffect(() => {
        setUserDetails()
        themeSwitcher()

        resourceAvailabilityCheck()
        thresholdLimitCheck()
        setViewLink(window.location.pathname)
        getAccessToken()
        setUserProfile(useEncryptStorage.getItem('profileUrl'))

        //IdleTimer which checks inactivity
        const timer = new UseIdleTimer({
            timeout: 1800,
            onTimeout: () => {
                useEncryptStorage.clear();
                setRefreshToken(true);
                document.body.classList.remove('dark-header-theme');
                document.body.classList.remove('dark-theme');
                document.body.classList.remove('modal-open');
                document.body.classList.remove('modal-backdrop')
                document.body.classList.remove('fade')
                document.documentElement.removeAttribute('style');
                navigate('/login')
            },
            onExpired: () => { },
        });
        return () => {
            timer.cleanUp();
        };
    }, [viewLink])

    const getAccessToken = () => {
        if (refreshToken === false) {
            setInterval(async () => {
                const refreshTokenResponse = await fetchNewAccessToken();

                if (refreshTokenResponse.statusCode === 200) {
                    useEncryptStorage.setItem("accessToken", refreshTokenResponse.data.accessToken);
                    useEncryptStorage.setItem("refreshToken", refreshTokenResponse.data.refreshToken);
                    useEncryptStorage.setItem("expiryTime", refreshTokenResponse.data.expiryTime);
                    useEncryptStorage.setItem("imageUrl", refreshTokenResponse.data.imageUrl);
                }
            }, 3300000);
        }
    }

    const setUserDetails = async() => {
        
        const userProfileResponse = await fetchUserProfile();
    
        if (userProfileResponse.statusCode === 200) {
            dispatch(setRole(userProfileResponse.data.organizations[0].roleName))
            dispatch(setProfileImage(userProfileResponse.data.profileUrl))
        }
    }

    //logout is handled
    const handleLogout = () => {
        setRefreshToken(true)
        useEncryptStorage.clear()
        document.body.classList.remove('dark-header-theme');
        document.body.classList.remove('dark-theme');
        document.body.classList.remove('modal-open');
        document.body.classList.remove('modal-backdrop')
        document.body.classList.remove('fade')
        document.documentElement.removeAttribute('style');
        navigate('/login')  
    }

    const handleThemeSwitch = () => {
        if (theme === "dark") {
            useEncryptStorage.setItem("theme", "light");
            setTheme(useEncryptStorage.getItem("theme"))
            postUpdatedTheme("light")
            themeSwitcher()
        }
        else if (theme === "light") {
            useEncryptStorage.setItem("theme", "dark");
            setTheme(useEncryptStorage.getItem("theme"))
            postUpdatedTheme("dark")
            themeSwitcher()
        }
    }

    const resourceAvailabilityCheck = () => {
        setInterval(async () => {
            const checkResponse = await getAllPushNotifications();
            
            if (checkResponse.statusCode === 200) {
                pushNotification = checkResponse.data
                if (checkResponse.data.length) {
                    handlePushNotification(0)
                }
            }
        }, 120000)

    }

    const handlePushNotification = (indexValue: number) => {
        index = indexValue
        if (indexValue >= pushNotification.length) {
            return
        }

        setNoticatioNDetails(pushNotification[indexValue])
        setShowNotification(true)
    }

    const handleAcknowledgement = async() => {

        const acknowledgePushNotificationResponse = await acknowledgePushNotification(notificationDetails?.notificationId, ackMessage)
        setShowNotification(false)

        if (acknowledgePushNotificationResponse.statusCode === 200) {
            if(index === pushNotification.length-1) {
                handlePushNotification(index + 1)
            }
        }
    }

    const thresholdLimitCheck = async() => {
        const thresHoldLimitResponse = await getAllNotifications()

        if (thresHoldLimitResponse.statusCode === 200) {
            setThresHoldLimitNotification(thresHoldLimitResponse.data)

            notificationInfo = thresHoldLimitResponse.data
            setNotificationInfoState(thresHoldLimitResponse.data)
            
            if(!("message" in thresHoldLimitResponse.data)) {
                notificationInfo?.filter((eachNotification: any) => eachNotification.isActive).some((eachNotification: any) => {
                    let currentUserMailGroup = eachNotification.MailResponse.find((eachMailGrp: any) => eachMailGrp.EmailId === useEncryptStorage.getItem("userMailId") && eachMailGrp.Status == 1);
                    if (currentUserMailGroup !== undefined && currentUserMailGroup !== null) {
                        setThresHoldLimitPopup(true)
                        setMailGroupId(currentUserMailGroup.MailGroupId)
                        setNotificationId(eachNotification.NotificationId)
                        setAlertName(eachNotification.alertName)
                        setLimit(eachNotification.limit)
                        return true;
                    }
                })
            }
            
        }
        else {
            return
        }
    }

    const handleThresholdAck = async() => {
        let organizationId = notificationInfoState.find((each: any) => each.NotificationId === notificationId)?.OrganizationId
        const mailAcknowledgementResonse = await mailAcknowledgement(mailGroupId, thresHoldAckMessage, organizationId)

        if(mailAcknowledgementResonse) {
            setThresHoldLimitPopup(false)
            thresholdLimitCheck()
            setThresHoldAckMessage("")
        }
    }

    const handleAckValueChange = (value: string) => {
        setThresHoldAckMessage(value.trim())
    }

    const handleCommentChange = (value: string) => {
        setAckMessage(value)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <nav className="navbar top-navbar navbar-expand-lg bg-light top-nav-sty">
                        <div className="container-fluid">
                            <div className="w-100 d-flex d-block d-lg-none justify-content-between" onClick={() => window.location.href = '/dashboard'}>
                                <div className="d-flex">
                                    <button
                                        aria-controls="navbarTogglerDemo03"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        className="navbar-toggler px-2"
                                        data-bs-target="#navbarTogglerDemo03"
                                        data-bs-toggle="collapse"
                                        type="button"
                                        onClick={() => window.location.href = '/dashboard'}
                                    >
                                        <span className="navbar-toggler-icon" />
                                    </button>
                                    <a
                                        href={'/dashboard'}
                                        className="navbar-brand d-block d-lg-none ms-3"
                                    >
                                        <img className="logo-sty" src="images/trinetra-logo.svg" alt="trinetra-l" />
                                    </a>
                                </div>
                            </div>
                            <a
                                href={'/dashboard'}
                                className="navbar-brand d-lg-block d-none"
                                role="button"
                            >
                                <span className="logo-sty logo-img d-flex align-items-center" />
                            </a>
                            <div
                                className="collapse navbar-collapse"
                                id="navbarTogglerDemo03"
                            >
                                <ul className="navbar-nav top-navbar-margin me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a
                                            aria-current="page"
                                            className={`${(dashboardUrl.includes(viewLink)) && "active"} nav-link top-navbar-link px-3 mx-1"`}
                                            role="button"
                                            href='/dashboard'
                                        >
                                            Dashboard
                                        </a>
                                    </li>
                                    {role === "Owner" &&
                                        <li
                                            className="nav-item"
                                        >
                                            <a
                                                className={`${(organizationUrl.includes(viewLink)) && "active"} nav-link top-navbar-link px-3 mx-1`}
                                                role="button"
                                                onClick={() => navigate('/manageorganization')}
                                            >
                                                Organization
                                            </a>
                                        </li>
                                    }
                                    <li className="nav-item dropdown">
                                        <a
                                            aria-expanded="false"
                                            className={`${(environmentUrl.includes(viewLink)) && "active"} nav-link topnav-icon-swap top-navbar-link px-3 mx-1`}
                                            data-bs-toggle="dropdown"
                                            role="button"
                                        >
                                            Environment
                                            <span className="top nav-icon-sty nav-dropdw-active-img ms-2"></span>
                                            <span className="top nav-icon-sty nav-dropdw-img ms-2"></span>
                                        </a>
                                        <ul className="dropdown-menu top-nav-dropdown-sty border-0 p-2">
                                            <li className="custom-dropdown-brd py-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/costAnomaly')}
                                                >
                                                    Cost Anomaly Detection
                                                </a>
                                            </li>
                                            <li className="custom-dropdown-brd py-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/recommendation')}
                                                >
                                                    Recommendations
                                                </a>
                                            </li >
                                            <li className="custom-dropdown-brd pb-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/resourceavailability')}
                                                >
                                                    Resource Availability
                                                </a>
                                            </li >
                                            <li className="custom-dropdown-brd py-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/securityrules')}
                                                >
                                                    Security Rules
                                                </a>
                                            </li >
                                            <li className="custom-dropdown-brd py-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/criticalEvents')}
                                                >
                                                    Critical Events
                                                </a>
                                            </li >
                                            <li className="pt-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/invoice')}
                                                >
                                                    Invoices
                                                </a>
                                            </li >
                                        </ul >
                                    </li >
                                    <li className="nav-item">
                                        <a
                                            className={`${(InventoryUrl.includes(viewLink)) && "active"} nav-link top-navbar-link px-3 mx-1`}
                                            role="button"
                                            onClick={() => navigate('/inventory')}
                                        >
                                            Inventory
                                        </a>
                                    </li >
                                    <li className="nav-item">
                                        <a
                                            className={`${(thresHoldUrl.includes(viewLink)) && "active"} nav-link top-navbar-link px-3 mx-1 `}
                                            role="button"
                                            onClick={() => navigate('/threshold')}
                                        >
                                            Threshold
                                        </a>
                                    </li >
                                    <li className="nav-item dropdown">
                                        <a
                                            aria-expanded="false"
                                            className={`${(manageUrl.includes(viewLink)) && "active"} nav-link top-navbar-link topnav-icon-swap px-3 mx-1 `}
                                            data-bs-toggle="dropdown"
                                            role="button"
                                        >
                                            Manage
                                            <span className="top nav-icon-sty nav-dropdw-active-img ms-2"></span>
                                            <span className="top nav-icon-sty nav-dropdw-img ms-2"></span>
                                        </a>
                                        <ul className="dropdown-menu top-nav-dropdown-sty border-0 p-2">
                                            <li className="custom-dropdown-brd pb-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/manageproviders')}
                                                >
                                                    Providers
                                                </a>
                                            </li>
                                            <li
                                                className="custom-dropdown-brd py-1"
                                            >
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/manageuser')}
                                                >
                                                    Users
                                                </a >
                                            </li >
                                            <li className="custom-dropdown-brd py-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/manageevents')}
                                                >
                                                    Events
                                                </a>
                                            </li >
                                            <li className="custom-dropdown-brd py-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/managesecrets')}
                                                >
                                                    Secrets
                                                </a>
                                            </li >
                                            <li className="pt-1">
                                                <a
                                                    className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    role="button"
                                                    onClick={() => navigate('/manageconnectors')}
                                                >
                                                    Connectors
                                                </a>
                                            </li>
                                        </ul >
                                    </li >
                                </ul >
                                <button
                                    className="btn icon-swap p-0 d-lg-block d-none border-0 active"
                                    type="button"
                                    onClick={() => handleThemeSwitch()}
                                >
                                    {theme === "light" &&
                                        <img
                                            alt="dark-theme-icon"
                                            className="theme-toggle-btn"
                                            src="images/dark-theme-icon.svg"
                                        />
                                    }
                                    {theme === "dark" &&
                                        <img
                                            alt="light-theme-icon"
                                            className="theme-toggle-btn"
                                            src="images/light-theme-icon.svg"
                                        />
                                    }
                                </button>
                                <a
                                    className="nav-link mx-4 d-lg-block d-none"
                                    role="button"
                                    onClick={() => {navigate('/notifications')}}
                                >
                                    <img
                                        alt="notification-icon"
                                        src="images/notification_icon.svg"
                                    />
                                </a >
                                <div className="profile-nav-brd d-lg-block d-none">
                                    <a
                                        aria-expanded="false"
                                        className="nav-link ms-4 me-2"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        id="profile"
                                        role="button"
                                    >
                                        <img
                                            src={profileimageStore}
                                            className="user-profile-icon me-1"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src="images/User-Profile.svg";
                                            }}
                                        />
                                        <span className="top nav-icon-sty nav-dropdw-img" />
                                    </a>
                                    <ul
                                        aria-labelledby="profile"
                                        className="dropdown-menu custom-dropdown-sty border-0 p-2"
                                    >
                                        <li className="custom-dropdown-brd pb-1">
                                            <a
                                                className="dropdown-item custom-dropdown-li py-2 px-2"
                                                onClick={() => navigate('/userProfile')}
                                            >
                                                User profile
                                            </a>
                                        </li>
                                        <li className="pt-1">
                                            <a
                                                className="dropdown-item py-2 px-2 custom-dropdown-li"
                                                onClick={() => setShowLogoutPopup(true)}
                                            >
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div >
                            </div >
                        </div >
                    </nav >
                </div >
            </div >
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showLogOutPop ? 'show-popup' : ''}`}>
                <div className="modal-dialog custom-width-providerpopup">
                    <div className="modal-content popup-sty border-0 custom-border-radius">
                        <div className="modal-header border-0">
                            <button
                                aria-label="Close"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                type="button"
                                onClick={() => setShowLogoutPopup(false)}
                            />
                        </div>
                        <div className="modal-body pt-1">
                            <div className="text-center mb-1">
                                <img
                                    alt="alter"
                                    className="mt-1 logout-img-sty"
                                    src="images/logout.gif"
                                />
                                <label className="form-label custom-pop-label mt-4 mb-1 w-100">
                                    Do you really want to log out?
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button
                                className="btn secondary-btn me-3"
                                type="button"
                                onClick={() => handleLogout()}
                            >
                                Yes
                            </button>
                            <button
                                className="btn color-warning-btn"
                                data-bs-dismiss="modal"
                                type="button"
                                onClick={() => setShowLogoutPopup(false)}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showNotification ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-5">
                            <div className="text-center mb-4">
                                <img className="mt-4 mb-2" src="images/Alert-Icon.svg" alt="alter" />
                                <label className="form-label alert-label mt-4 mb-1 w-100">Alert! </label>
                                <label className="form-label alert-label mb-1 w-100">
                                    <span className="color-warning-alert">{notificationDetails?.message}</span> is not available
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="Comments" className="form-label custom-label">Comments <span className="color-warning">*</span></label>
                                    <textarea className="form-control custom-input resize-none" rows={3} placeholder="Enter Your Comments" id="Comments"
                                    onChange={(e) => {handleCommentChange(e.target.value)}}>
                                    </textarea>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn primary-btn" data-bs-dismiss="modal"
                                onClick={() => {handleAcknowledgement()}}
                                >Acknowledge</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showThresHoldLimitPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body px-5">
                            <div className="text-center mb-4">
                                <img className="mt-4 mb-2" src="images/Alert-Icon.svg" alt="alter" />
                                <label className="form-label alert-label mt-4 mb-1 w-100">Alert! </label>
                                <label className="form-label alert-label mb-1 w-100"><span className="color-warning-alert">{alertName}{" "}</span>
                                    has reached <span>{limit}%</span> of threshold limit.
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="Comments" className="form-label custom-label">Comments <span className="color-warning">*</span></label>
                                    <textarea className="form-control custom-input resize-none" rows={3} placeholder="Enter Your Comments" id="Comments"
                                    onChange={(e) => {handleAckValueChange(e.target.value)}}>
                                    </textarea>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => {handleThresholdAck()}}>Acknowledge</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};