import React, { useEffect, useState } from 'react'
import {
  IRegistrationCreds,
  IRegistrationErrorCreds
} from '../../interface/IRegistrationData'
import {
  checkOrganization,
  updateUserProfileImage,
  userSignup
} from '../../service/userRegistrationService'
import { checkUser } from '../../service/authService'
import { useNavigate } from 'react-router-dom'
import {
  getLogoUrl,
  getRandomNumber,
  setFileName
} from '../../service/organizationService'

export default function UserRegistration () {
  const navigate = useNavigate()

  const [userName, setUserName] = useState<string>('')
  const [organizationName, setOrganizationName] = useState<string>('')
  const [emailId, setEmailId] = useState<string>('')
  const [createPassword, setCreatePassword] = useState<string>('')
  const [resetPassword, setResetPassword] = useState<string>('')
  const [uploadedImageData, setUploadedImageData] = useState()

  const [nameRequiredError, setNameRequiredError] = useState<boolean>(false)
  const [validNameError, setValidNameError] = useState<boolean>(false)
  const [nameLengthError, setNameLengthError] = useState<boolean>(false)
  const [orgRequiredError, setOrgRequiredError] = useState<boolean>(false)
  const [validOrgError, setValidOrgError] = useState<boolean>(false)
  const [emailRequiredError, setEmailRequiredError] = useState<boolean>(false)
  const [validEmailError, setValidEmailError] = useState<boolean>(false)
  const [userExistError, setUserExistError] = useState<boolean>(false)
  const [passwordRequiredError, setPasswordRequiredError] =
    useState<boolean>(false)
  const [validPasswordError, setValidPasswordError] = useState<boolean>(false)
  const [passwordCharCountError, setPasswordCharCountError] =
    useState<boolean>(false)
  const [resetPasswordRequiredError, setResetPasswordRequiredError] =
    useState<boolean>(false)
  const [restPasswordMatchError, setResetPasswordMacthError] =
    useState<boolean>(false)
  const [fileTypeError, setFileTypeError] = useState<boolean>(false)
  const [fileSizeError, setFileSizeError] = useState<boolean>(false)
  const [organizationlengthError, setOrganizationlengthError] =
    useState<boolean>(false)
  const [updloadedImageName, setUpdloadedImageName] = useState<string>('')

  const [loginButtonEnabled, setLoginButtonEnabled] = useState<boolean>(true)
  const [loginLoader, setLoginLoader] = useState<boolean>(false)

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl: Element) => {
      return new (window as any).bootstrap.Tooltip(tooltipTriggerEl)
    })

    return () => {
      tooltipList.forEach((tooltip: any) => tooltip.dispose())
    }
  }, [])

  useEffect(() => {
    const isNameValid = userName.trim() !== "" && !nameRequiredError && !validNameError && !nameLengthError;
    const isOrgValid = organizationName.trim() !== "" && !orgRequiredError && !validOrgError && !organizationlengthError;
    const isEmailValid = emailId.trim() !== "" && !emailRequiredError && !validEmailError && !userExistError;
    const isPasswordValid = createPassword.trim() !== "" && !passwordRequiredError && !validPasswordError && !passwordCharCountError;
    const isResetPasswordValid = resetPassword.trim() !== "" && !resetPasswordRequiredError && !restPasswordMatchError;
    const isPasswordsMatching = createPassword === resetPassword;
    const isImageValid = !!uploadedImageData;
 
    if (isNameValid && isOrgValid && isEmailValid && isPasswordValid && isResetPasswordValid && isPasswordsMatching && isImageValid) {
        setLoginButtonEnabled(false);
    } else {
        setLoginButtonEnabled(true);
    }
}, [userName, organizationName, emailId, createPassword, resetPassword, nameRequiredError, validNameError, nameLengthError, orgRequiredError, validOrgError, organizationlengthError, emailRequiredError, validEmailError, userExistError, passwordRequiredError, validPasswordError, passwordCharCountError, resetPasswordRequiredError, restPasswordMatchError, uploadedImageData]);

  const validateName = (e: any) => {
    setUserName(e.target.value)
    const updatedUserName = e.target.value
    let userNameRegex = /[a-zA-Z]/

    if (updatedUserName === '') {
      setNameRequiredError(true)
      setValidNameError(false)
      setNameLengthError(false)
    } else if (updatedUserName !== '') {
      setNameRequiredError(false)

      if (updatedUserName.length > 32) {
        setNameLengthError(true)
        setNameRequiredError(false)
      } else if (updatedUserName.length <= 32) {
        setNameLengthError(false)

        setValidNameError(!userNameRegex.test(updatedUserName))
        setNameLengthError(false)
        setNameRequiredError(false)
      }
    }
  }

  const validateOrganization = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(e.target.value)
    const updateOrgName = e.target.value
    if (updateOrgName === '') {
      setOrgRequiredError(true)
      setValidOrgError(false)
      setOrganizationlengthError(false)
    } else if (updateOrgName !== '') {
      setOrgRequiredError(false)
      setValidOrgError(false)

      if (updateOrgName.length > 180) {
        setOrganizationlengthError(true)
        setOrgRequiredError(false)
        setValidOrgError(false)
      } else if (updateOrgName.length <= 180) {
        setOrganizationlengthError(false)
      }
    }
  }

  const checkOrganizationExists = async (organizationName: string) => {
    if (organizationName) {
      let result = await checkOrganization(organizationName)

      if (result?.data?.message === 'Already Exists') {
        setValidOrgError(true)
        setOrgRequiredError(false)
        setOrganizationlengthError(false)
      } else {
        setValidOrgError(false)
      }
    } else {
      setOrgRequiredError(true)
    }
  }

  const validateEmail = (e: any) => {
    setEmailId(e.target.value)
    const updateEmailId = e.target.value
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    if (updateEmailId === '') {
      setEmailRequiredError(true)
      setValidEmailError(false)
      setUserExistError(false)
    } else if (updateEmailId !== '') {
      setEmailRequiredError(false)
      setValidEmailError(false)

      if (!emailRegex.test(updateEmailId)) {
        setValidEmailError(true)
        setEmailRequiredError(false)
        setUserExistError(false)
        setEmailRequiredError(false)
      } else if (emailRegex.test(updateEmailId)) {
        setEmailRequiredError(false)
      }
    }
  }

  const checkUserExists = async (emailId: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    if (emailId !== '' && emailRegex.test(emailId)) {
      let result = await checkUser(emailId)

      if (result?.data?.message === 'Already Exists') {
        setUserExistError(true)
      } else {
        setUserExistError(false)
      }
    } else {
      setEmailRequiredError(emailId === '')
    }
  }

  const handleImageUpload = async (e: any) => {
    const allowedExtensions = ['.png', '.jpeg', '.jpg']
    const fileData = e.target.files[0]
    const fileType = fileData?.name
      .substring(fileData.name.lastIndexOf('.'))
      .toLowerCase()
    const fileSize = fileData?.size
    const fileName = `${fileData?.name.substring(
      0,
      fileData?.name.lastIndexOf('.')
    )}_${userName}_${Math.floor(Math.random() * 90000) + 10000}_${fileType}`

    if (!allowedExtensions.includes(fileType)) {
      setFileTypeError(true)
    } else if (allowedExtensions.includes(fileType)) {
      setFileTypeError(false)
    }

    if (fileSize >= 250000) {
      setFileSizeError(true)
    } else if (fileSize <= 250000) {
      setFileSizeError(false)
    }

    if (allowedExtensions.includes(fileType) && fileSize <= 250000) {
      setUpdloadedImageName(fileName)
      setUploadedImageData(fileData)
    }
  }

  const validateCreatePassword = (e: any) => {
    setCreatePassword(e.target.value)
    const lengthRegex = /^.{8,20}$/
    let validPassword =
      /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    const updatePassword = e.target.value

    if (updatePassword === '') {
      setPasswordRequiredError(true)
      setValidPasswordError(false)
      setPasswordCharCountError(false)
    } else if (!lengthRegex.test(updatePassword)) {
      setPasswordCharCountError(true)
      setPasswordRequiredError(false)
      setValidPasswordError(false)
    } else if (!validPassword.test(updatePassword)) {
      setValidPasswordError(true)
      setPasswordRequiredError(false)
      setPasswordCharCountError(false)
    } else {
      setValidPasswordError(false)
      setPasswordRequiredError(false)
      setPasswordCharCountError(false)
    }
  }

  const validateResetPassword = (e: any) => {
    setResetPassword(e.target.value)
    const resetPassword = e.target.value
    if (resetPassword === '') {
      setResetPasswordRequiredError(true)
      setResetPasswordMacthError(false)
    } else if (resetPassword !== createPassword) {
      setResetPasswordMacthError(true)
      setResetPasswordRequiredError(false)
    } else {
      setResetPasswordMacthError(false)
      setResetPasswordRequiredError(false)
    }
  }

  const handleSignup = async () => {
    let fileName = setFileName(
      updloadedImageName,
      organizationName,
      getRandomNumber()
    )
    const updateProfileImageResponse = await updateUserProfileImage(
      uploadedImageData,
      updloadedImageName
    )

    if (updateProfileImageResponse) {
      let registrationPayload = {
        name: userName,
        organizationName: organizationName,
        logoUrl: getLogoUrl(fileName),
        email: emailId,
        password: createPassword
      }

      setLoginLoader(true)
      const loginResponse = await userSignup(registrationPayload)

      if (loginResponse.statusCode === 200) {
        setLoginLoader(false)
        navigate('/login')
      } else if (loginResponse.statusCode !== 200) {
        setLoginLoader(false)
      }
    }
  }

  return (
    <div className='container-fluid'>
      <div className='row '>
        <div className='col-lg-6 d-lg-block d-none p-0'>
          <div className='bg-gradient-primary bg-height text-center position-relative my-0 rounded-0'>
            <div className='mx-auto'>
              <img
                src='images/login-image.svg'
                alt='login-image'
                className=' login-image-sty mt-5 mb-5'
              />
            </div>
          </div>
        </div>

        <div className='col-lg-6 col-md-12 login-mbl-margin align-self-center'>
          <div className='form-signup '>
            <h5 className='page-header mb-3'>Sign Up</h5>
            <div className='row'>
              <div className='col-md-12'></div>
              <div className='col-md-12 mb-3'>
                <label htmlFor='Name' className='form-label custom-label'>
                  Name
                </label>
                <input
                  type='text'
                  className='form-control custom-input py-2'
                  id='Name'
                  placeholder='Enter the Name'
                  value={userName}
                  onChange={e => validateName(e)}
                  onBlur={e =>
                    setNameRequiredError(e.target.value === '' ? true : false)
                  }
                />
                {nameRequiredError && (
                  <span className='signup-errors d-block error-span'>
                    Name is required
                  </span>
                )}
                {validNameError && (
                  <span className='signup-errors d-block error-span'>
                    Enter a valid Name
                  </span>
                )}
                {nameLengthError && (
                  <span className='signup-errors d-block error-span'>
                    Name should not be more than 32 characters
                  </span>
                )}
              </div>
              <div className='col-md-6 mb-3'>
                <label htmlFor='org-Name' className='form-label custom-label'>
                  Organization Name
                </label>
                <input
                  type='text'
                  className='form-control custom-input py-2'
                  id='org-Name'
                  placeholder='Enter the Organization name'
                  value={organizationName}
                  onChange={e => validateOrganization(e)}
                  onBlur={e => checkOrganizationExists(e.target.value)}
                />
                {orgRequiredError && (
                  <span className='signup-errors d-block error-span'>
                    Organization name is required
                  </span>
                )}
                {validOrgError && (
                  <span className='signup-errors d-block error-span'>
                    Organization Name Already Exists
                  </span>
                )}
                {organizationlengthError && (
                  <span className='signup-errors d-block error-span'>
                    Organization Name should not be greater than 180 characters
                  </span>
                )}
              </div>
              <div className='col-md-6 mb-3'>
                <label
                  htmlFor='upload-logo'
                  className='form-label custom-label'
                >
                  Upload Organization Logo
                </label>
                <div className='input-group custom-input-grp custom-hig-40 d-flex'>
                  <div className='fileUpload btn btn-custom-dollar pe-3'>
                    <span className='color-white '>
                      <img src='images/upload-icon.svg' />
                    </span>
                    <input
                      id='uploadBtn'
                      type='file'
                      className='upload'
                      onChange={e => handleImageUpload(e)}
                    />
                  </div>
                  <input
                    id='uploadFile'
                    placeholder='No file chosen'
                    disabled
                    value={updloadedImageName}
                  />
                </div>
                {fileSizeError && (
                  <span className='text-danger d-block error-span'>
                    The uploaded file should be less than 250 KB.
                  </span>
                )}
                {fileTypeError && (
                  <span className='text-danger d-block error-span'>
                    The uploaded file should be in JPG, JPEG or PNG format.
                  </span>
                )}
              </div>
              <div className='col-md-12 mb-3'>
                <label htmlFor='email' className='form-label custom-label'>
                  Email ID{' '}
                </label>
                <input
                  type='text'
                  className='form-control custom-input py-2'
                  id='email'
                  placeholder='name@company.com'
                  value={emailId}
                  onChange={e => validateEmail(e)}
                  onBlur={e => checkUserExists(e.target.value)}
                />
                {emailRequiredError && (
                  <span className='signup-errors d-block error-span'>
                    Email ID is required
                  </span>
                )}
                {validEmailError && (
                  <span className='signup-errors d-block error-span'>
                    Please enter a valid Buisness Email ID
                  </span>
                )}
                {userExistError && (
                  <span className='signup-errors d-block error-span'>
                    User already exist
                  </span>
                )}
              </div>
              <div className='col-md-12 mb-3'>
                <label
                  htmlFor='create-password'
                  className='form-label custom-label'
                >
                  Create Password
                  <span
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title=''
                    data-bs-original-title='Password must contain minimum of 8 characters that includes 
                                at least 1 Lowercase, Uppercase, Number and Special character'
                  >
                    <img
                      className='ms-1'
                      src='images/information-icon.svg'
                      alt='info'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                    />
                  </span>
                </label>
                <input
                  type='password'
                  className='form-control custom-input py-2'
                  id='create-password'
                  placeholder='Enter the password'
                  value={createPassword}
                  autoComplete='new-password'
                  onChange={e => validateCreatePassword(e)}
                  onBlur={e =>
                    setPasswordRequiredError(
                      e.target.value === '' ? true : false
                    )
                  }
                />
                {passwordRequiredError && (
                  <span className='signup-errors d-block error-span'>
                    Password is required
                  </span>
                )}
                {passwordCharCountError && (
                  <span className='signup-errors d-block error-span'>
                    Password field accepts only 8-20 characters.
                  </span>
                )}
                {validPasswordError && (
                  <span className='signup-errors d-block error-span'>
                    Enter a valid password
                  </span>
                )}
              </div>
              <div className='col-md-12 mb-3'>
                <label
                  htmlFor='create-password'
                  className='form-label custom-label'
                >
                  Re-Enter Password
                </label>
                <input
                  type='password'
                  className='form-control custom-input py-2'
                  id='create-password'
                  placeholder='Enter the password'
                  value={resetPassword}
                  onChange={e => validateResetPassword(e)}
                  autoComplete='new-password'
                  onBlur={e =>
                    setResetPasswordRequiredError(
                      e.target.value === '' ? true : false
                    )
                  }
                />
                {resetPasswordRequiredError && (
                  <span className='signup-errors d-block error-span'>
                    Password is required
                  </span>
                )}
                {restPasswordMatchError && (
                  <span className='signup-errors d-block error-span'>
                    Password and Re-Entered Password doesn't match
                  </span>
                )}
              </div>
              <div className='col-md-12 my-3'>
                <button
                  className='btn primary-btn w-100 btn-disabled-sty'
                  disabled={loginButtonEnabled}
                  onClick={() => handleSignup()}
                  type='button'
                >
                  {loginLoader && (
                    <span className='spinner-border spinner-border-sm mr-1'></span>
                  )}
                  Sign Up
                </button>
              </div>
              <p className='login-link-text'>
                Already have an account?
                <span>
                  <a
                    className='text-decoration-none link-sty ms-2'
                    onClick={() => {
                      navigate('/login')
                    }}
                  >
                    {' '}
                    Sign in
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className='row d-block d-lg-none'></div>
        </div>
      </div>
    </div>
  )
}