import { client } from "../client/Client";
import { IRequestPayload } from "../interface/treshold";
export const getAzureRecommendations = async ( request:IRequestPayload) => {
    let result = await client(`/infra/recommendations`, request, "POST");
    return result;
}
export const getAwsAdvisorChecks = async (request:any) => {

    let result = await client(`/infra/advisor/checks`, request, "POST");

    return result;
}
export const getAwsAdvisorCheckById = async (request: IRequestPayload, checkId: string) => {

    let result = await client(`/infra/advisor/check/${checkId}`, request, "POST");

    return result;
}
export const getAwsAdvisorCheckSummaries = async (request: any) => {

    let result = await client(`/infra/advisor/checks/summaries`, request, "POST");

    return result;
}
export const getProjectKeys = async (organizationId:any) => {
    let request={
            "userName" : "",
            "instanceUrl" : ""
    }
    let result = await client(`/manage/organization/${organizationId}/projectKey`,request, "POST");

    return result;
}
export const getmailId = async (organizationId:any,connectorType:any,request={}) => {
 
    let result = await client(`/manage/organization/${organizationId}/ticket?connectorType=${connectorType}`,request, "Get");

     return result;
}
export const createTicket = async (organizationId:any,connectorType:any,request:any) => {

    let result = await client(`/infra/organization/${organizationId}/recommendations/ticket?connectorType=${connectorType}`,request, "Post");

    return result;
}
