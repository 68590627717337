
export const formatDateWithTime = (date: Date) => {

    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export const formatDate = (date: Date) => {

    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);

}

export const formatShortDate = (dateString: Date) => {
    const date = new Date(dateString);
    const options : Intl.DateTimeFormatOptions ={ month: 'short', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }


export const formatResourceAvailabilityDateWithTime = (date: any, providerType: string) => {
    if (providerType === "AWS") {
        return formatDateAWS(date);
    } else if (providerType === "Azure") {
        return formatDateAzure(date);
    }
};

const formatDateAWS = (date: any) => {
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

const formatDateAzure = (azureDate: string) => {
    // Create a Date object from the Azure date string
    const date = new Date(azureDate);

    // Check if the date object is valid
    if (isNaN(date.getTime())) {
        throw new Error('Invalid Azure date string');
    }

    // Format options for the desired output
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
};
