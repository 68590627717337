import { key } from "../hooks/useEncryptStorage";

export const setFileName = (fileName: string, organizationName: string, randomNumber: number) => {

    let fileNameWithoutExt = fileName.substring(0, fileName.lastIndexOf('.'));
    let ext = fileName.substring(fileName.lastIndexOf('.'));
    return fileNameWithoutExt + "_" + organizationName + "_" + randomNumber + ext;

}

export const getRandomNumber = () => {
    return Math.floor(Math.random() * 90000) + 10000;

}

export const getLogoUrl = (fileName: string) => {
    return `https://${key.ACCOUNT_NAME}.blob.core.windows.net/${key.CONTAINER_NAME}/` + fileName;
}

