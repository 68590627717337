import '../../assets/scss/login.css'
import '../../assets/scss/_login.scss'
import { useEffect, useState } from 'react'
import { ILoginCreds } from '../../interface/login'
import { userLogin } from "../../service/authService"
import { useNavigate } from 'react-router-dom'
import { useEncryptStorage } from "../../hooks/useEncryptStorage"
import { fetchUserProfile } from '../../service/userService'
import { useDispatch } from 'react-redux'
import { setRole } from '../../redux/userProfile/action'

export default function Login() {

    let loginDetailsObj = {
        email: "",
        password: ""
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loginDetails, setLoginDetails] = useState<ILoginCreds>(loginDetailsObj)

    const [emailRequiredError, setEmailRequiredError] = useState<boolean>(false)
    const [validEmailIdError, setValidEmailIdError] = useState<boolean>(false)

    const [passwordRequiredError, setPasswordRequiredError] = useState<boolean>(false)
    const [wrongCredError, setWrongCredError] = useState<boolean>(false)
    const [loginLoader, setLoginLoader] = useState<boolean>(false)
    const [loginButtonEnabled, setLoginButtonEnabled] = useState<boolean>(true)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const handleUserDetail = (e: any) => {

        const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        setLoginDetails ((prevLoginDetails) => {
            const loginUpdatedLoginData = { ...prevLoginDetails, [e.target.id]: e.target.value }

            if (e.target.id === "email" && loginUpdatedLoginData.email === "") {
                setEmailRequiredError(true)
                setValidEmailIdError(false)
            }         
            else if (e.target.id === "email" && !regex.test(loginUpdatedLoginData.email)) {
                setValidEmailIdError(true)
                setEmailRequiredError(false)
            }
            else if (e.target.id === "email" && regex.test(loginUpdatedLoginData.email)) {
                setValidEmailIdError(false)
            }
            else if (e.target.id === "email" && loginUpdatedLoginData.email !== "") {
                setEmailRequiredError(false)
            }

            if (e.target.id === "password" && loginUpdatedLoginData.password === "") {
                setPasswordRequiredError(true)
            }
            if (e.target.id === "password" && loginUpdatedLoginData.password !== "") {
                setPasswordRequiredError(false)
            }

            if (loginUpdatedLoginData.email !== "" && loginUpdatedLoginData.password !== "" && regex.test(loginUpdatedLoginData.email)) {
                setLoginButtonEnabled(false)
            }
            else {
                setLoginButtonEnabled(true)
            }

            return loginUpdatedLoginData
        })
    }
    const onEnterClick =(e:any)=>{
        if(emailRequiredError==false && passwordRequiredError==false && validEmailIdError==false && e.key == 'Enter'){
            handleLogin()
            setLoginButtonEnabled(false)
        }else{
            setLoginButtonEnabled(true)
        }
        
    }

    const handleLogin = async () => {
        setWrongCredError(false)
        setLoginLoader(true)
        const loginResponse = await userLogin(loginDetails)

        if (loginResponse.statusCode === 200) {
            userData(loginResponse.data);
            
            setLoginLoader(false)
            navigate('/check')
        }
        else if (loginResponse.statusCode !== 200) {
            setWrongCredError(true)
            setLoginLoader(false)
        }
    }

    const userData = async (userData: any) => {
        useEncryptStorage.setItem("currency", userData.currency);
        useEncryptStorage.setItem("expiresIn", userData.expiresIn);
        useEncryptStorage.setItem("loginId", userData.loginId);
        useEncryptStorage.setItem("profileUrl", userData.profileUrl);
        useEncryptStorage.setItem("theme", userData.theme);
        useEncryptStorage.setItem("token", userData.token);
        useEncryptStorage.setItem("userMailId", userData.userMailId);
        useEncryptStorage.setItem("userName", userData.userName);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-lg-6 d-lg-block d-none p-0">
                        <div className="bg-gradient-primary bg-height text-center position-relative my-0 rounded-0">
                            <div className="mx-auto">
                                <img
                                    alt="login-image"
                                    className=" login-image-sty mt-5 mb-5"
                                    src="images/login-image.svg"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 login-mbl-margin align-self-center">
                        <div className="form-signup ">
                            <h5 className="page-header mb-3">
                                Sign In to get started
                            </h5>
                            <form id="loginForm">
                                <div className="row">
                                    {wrongCredError &&
                                        <div className="col-md-12 py-2">
                                            <div
                                                className="alert alert-warning py-2"
                                                role="alert"
                                            >
                                                <strong>
                                                    Wrong user credentials
                                                </strong>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-12 mb-3">
                                        <label
                                            className="form-label custom-label"
                                            htmlFor="email"
                                        >
                                            Email ID
                                        </label>
                                        <input
                                            className="form-control custom-input py-2"
                                            id="email"
                                            placeholder="name@company.com"
                                            type="email"
                                            value = {loginDetails.email}
                                            onChange = {(e) => handleUserDetail(e)}
                                            onKeyDown={(e)=> onEnterClick(e)}
                                            onBlur = {() => setEmailRequiredError(loginDetails.email === "" ? true : false)}
                                        />
                                        {emailRequiredError &&
                                            <span
                                                className="signup-errors d-block error-span"
                                            >
                                                Email ID is required
                                            </span>
                                        }
                                        {validEmailIdError &&
                                            <span
                                                className="signup-errors d-block error-span"
                                            >
                                                Please enter a valid business Email ID
                                            </span>
                                        }
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label
                                            className="form-label custom-label"
                                            htmlFor="password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            type={showPassword === true ? "text" : "password"}
                                            className="form-control custom-input py-2"
                                            id="password"
                                            placeholder="Enter the Password"
                                            value = {loginDetails.password}
                                            onChange = {(e) => handleUserDetail(e)}
                                            onKeyDown={(e)=> onEnterClick(e)}
                                            onBlur = {() => setPasswordRequiredError(loginDetails.password === "" ? true : false)}
                                        />
                                        {passwordRequiredError &&
                                            <span
                                                className="signup-errors d-block error-span"
                                            >
                                                Password is required
                                            </span>
                                        }
                                        {loginDetails.password != "" && (loginDetails.password.length< 8 || loginDetails.password.length > 20)  &&
                                            <span
                                                className="signup-errors d-block error-span"
                                            >
                                               Password field accepts 8-20 characters
                                            </span>
                                        }
                                    </div>
                                    <div className="col-md-12 mb-3 d-flex justify-content-between">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input custom-checked box-shadow-none"
                                                id="showPassword"
                                                type="checkbox"
                                                onChange = {(e) => setShowPassword(e.target.checked)}
                                            />
                                            <label
                                                className="form-check-label custom-label"
                                                htmlFor="showPassword"
                                            >
                                                Show Password
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <a
                                                    className="text-decoration-none login-link-text link-sty"
                                                    onClick={() => navigate('/forgetPassword')}
                                                >
                                                    Forgot Password ?
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 my-3">
                                        <button
                                            className="btn primary-btn w-100"
                                            disabled = {loginButtonEnabled}
                                            onClick = {() => handleLogin()}
                                            type='button'
                                        >
                                            {loginLoader &&
                                                <span
                                                    className="spinner-border spinner-border-sm mr-1"
                                                >
                                                </span>
                                            }
                                            Login
                                        </button>
                                    </div>
                                    <p className="login-link-text">
                                        Don't have an account ?{' '}
                                        <span>
                                            <a
                                                className="text-decoration-none link-sty ms-2"
                                                onClick={() => navigate('/signup')}
                                            >
                                                {' '}Sign up.
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}