import React, { useState, useEffect } from 'react';
import { getOrganization, checkOrganizationExists, addOrganizationDetails,  } from '../../service/managementService';
import { IOrganizationData, defaultOrganization } from "../../interface/organization";
import { useNavigate } from 'react-router-dom';
import { updateOrganizationProfileImage } from "../../service/userService"
import Header from "../Header/Header";
import '../../assets/scss/_manageorganization.scss'
import { OrganizationPostData } from './OrganizationPostData';
import { useSelector } from 'react-redux';

// PS_ORG_1.0
const ManageOrganization: React.FC = () => {
  let userDataObj: IOrganizationData = {
		organizationId: 0,
    parentOrganizationId: 0,
    logoUrl: "",
    organizationName: "",
    providerType: [],
    roleId: 0,
    roleName: "",
	}

  // PS_ORG_1.1
  const [organizationArr, setOrganizationArr] = useState<IOrganizationData[]>([]);
  const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization)
  const [organizationName, setOrganizationName] = useState('')
  const [showNotAddedErrorPopup, setShowNotAddedErrorPopup] = useState<boolean>(false)
  const [nameRequieredError, setNameRequieredError] = useState(false)
  const [organizationCheckError, setOrganizationCheckError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false)
	const [fileTypeError, setFileTypeError] = useState<boolean>(false)
  const [tempProfileUrl, setTempProfileUrl] = useState<string>("")
  const [uploadImage, setUploadImage] = useState("");
  const [showAddOrganization, setShowAddOrganization] = useState<boolean>(false);
  const [showAddButton, setshowAddButton] = useState<boolean>(false);
  const [showAddLoader, setShowAddLoader] = useState<boolean>(false)
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false)
	const [userOrganization, setUserOrganization] = useState<IOrganizationData>(userDataObj)
  const [pageLoader, setPageLoader] = useState(true)
  const [organizationlengthError, setOrganizationlengthError] = useState<boolean>(false)
  const role = useSelector((state: any) => state.role.role)

  // PS_ORG_1.2
  const navigate = useNavigate();

  // PS_ORG_1.3
  useEffect (() => {
    if(role !== "BillingReader") {
      getAllOrganization();
    }
  },[])

  useEffect(() => {
  
    // PS_ORG_1.3
    enableAddButton(uploadImage, organizationName);
  
    // PS_ORG_1.3
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl: Element) => {
      return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
    });
  
    // Cleanup function
    return () => {
      // Dispose tooltips on component unmount
      tooltipList.forEach((tooltip: any) => tooltip.dispose());
    };
  }, [uploadImage, organizationName, nameRequieredError, organizationCheckError, fileSizeError, fileTypeError]);

  const getAllOrganization = async() => {
    setPageLoader(true)
    // PS_ORG_1.4
    const userOrganization = await getOrganization();
    // PS_ORG_1.6
    if ( userOrganization && userOrganization.statusCode === 200) {
      setPageLoader(false)
      setOrganization((userOrganization.data))
      setUserOrganization(userOrganization.data)
      setOrganizationArr(userOrganization.data)
    } else {
      // PS_ORG_1.7
      setErrorPopUp(true);
      setPageLoader(false)
    }
    setPageLoader(false)
}
  
  // PS_ORG_1.8
  const getProviderTypeLogo = (providerType: string) => {
    // PS_ORG_1.8
    switch (providerType) {
      // PS_ORG_1.8
      case 'AWS': {
        return 'aws-img';
      }
      // PS_ORG_1.8
      case 'Azure': {
        return 'azure-img';
      }
      case 'gcp': {
        return 'assets/images/gcp-small.svg';
      }
      default: {
        return '';
      }
    }
  };

  // PS_ORG_1.9
  const resetAddOrganizationForm = () => {
    setOrganizationName('');
    setNameRequieredError(false);
    setOrganizationCheckError(false);
    setFileSizeError(false);
    setFileTypeError(false);
    setUploadImage('');
    setshowAddButton(false);
    // Reset other state variables as needed
  };
  
  // PS_ORG_1.10
  const handleOrganizationClick = (organizationId : number | null) => {
    // Navigate to the desired route with organizationId as a query parameter
    navigate(`/manageuser`);
  };


  // PS_ORG_1.11
  const handleInputChange = (e: any) => {
    // PS_ORG_1.11
    const updatedName = e.target.value;

    // PS_ORG_1.12
    if (updatedName === '') {
      setNameRequieredError(true);
      setOrganizationCheckError(false);
      setOrganizationlengthError(false)
    } else if(updatedName !== ""){
      setNameRequieredError(false)
      setOrganizationCheckError(false);
      setOrganizationlengthError(false)
      if(updatedName.length > 180) {
          setOrganizationlengthError(true)
          setNameRequieredError(false)
          setOrganizationCheckError(false);
        }
        else if(updatedName.length <= 180) {
          setOrganizationlengthError(false)
        }
   }
    // PS_ORG_1.12
    setOrganizationName(updatedName);
    // PS_ORG_1.13
    enableAddButton(uploadImage, organizationName);
  };

  // PS_ORG_1.14
  const organizationCheck = async (e: any) => {
    if (e.target.value !== "") {
      // PS_ORG_1.14
      const organizationExistsResponse = await checkOrganizationExists(organizationName);

      // PS_ORG_1.16
      if (organizationExistsResponse.statusCode === 200 && organizationExistsResponse.data.message === "Already Exists") {
        setOrganizationCheckError(true);
        setNameRequieredError(false);
        setOrganizationlengthError(false)
      } else if (organizationExistsResponse.statusCode === 200 && organizationExistsResponse.data.message === "Does Not Exists") {
        // PS_ORG_1.16
        setNameRequieredError(false);
        setOrganizationCheckError(false);
        setOrganizationlengthError(false)
      }
    }
  };

  const handleImageUpload = async (e: any) => {
    // PS_ORG_1.18
    const allowedExtensions = [".png", ".jpeg", ".jpg"];
    const fileData = e.target.files[0];
    const fileType = fileData?.name.substring(fileData.name.lastIndexOf('.')).toLowerCase();
    const fileSize = fileData?.size;
    const fileName = `${fileData?.name.substring(0, fileData?.name.lastIndexOf('.'))}_${organizationName}_${Math.floor(Math.random() * 90000) + 10000}_${fileType}`;
  
    setUploadImage(fileName);
  
    // PS_ORG_1.19
    const isValidFileType = allowedExtensions.includes(fileType);
    const isValidFileSize = fileSize <= 250000;
  
    setFileTypeError(!isValidFileType);
    setFileSizeError(!isValidFileSize);
  
    if (isValidFileType && isValidFileSize) {
      // PS_ORG_1.20
      const updateProfileImageResponse = await updateOrganizationProfileImage(fileName, fileData);
      setTempProfileUrl(updateProfileImageResponse);
      // enableAddButton(fileName, organizationName);
      const shouldEnable = fileName !== "" && organizationName !== "" && !nameRequieredError && !organizationCheckError && isValidFileSize && isValidFileType;

      setshowAddButton(shouldEnable);
    } else {
      setshowAddButton(false);
    }
  };
  
  const enableAddButton = (fileName: any, organizationName: any) => {
    const shouldEnable = fileName !== "" && organizationName !== "" && !nameRequieredError && !organizationCheckError && !fileSizeError && !fileTypeError && !organizationlengthError;
  
    // PS_ORG_1.13
    setshowAddButton(shouldEnable);
  };

  // PS_ORG_1.22
  const updateOrganization = async () => {
    setShowAddLoader(true)
    // PS_ORG_1.22
    let organizationPostData = new OrganizationPostData(organizationName, tempProfileUrl);
    organizationPostData.parentOrganizationId = organizationArr[0].parentOrganizationId!;
    // PS_ORG_1.22
		const updateOrganizationResponse = await addOrganizationDetails(organizationPostData)

    // PS_ORG_1.24
		if (updateOrganizationResponse.statusCode === 200) {
			getAllOrganization()
      resetAddOrganizationForm()
      setShowAddOrganization(false)
      setShowAddLoader(false)
		}
		else if (updateOrganizationResponse.statusCode !== 200) {
      setErrorPopUp(true);
			setShowAddLoader(false)
		}
	}

  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="row justify-content-center">
          <div className="col-md-10 pb-5">
            <div className="d-flex justify-content-between align-items-center my-4">
              <h5 className="page-header">Manage Organization</h5>
              <button
                type="button"
                className="btn primary-btn d-lg-block d-none"
                onClick={() => {setShowAddOrganization(true)}}
              >
                <span>
                  <img src="images/static-lable-add.svg" alt="add-icon" className="me-2" />
                </span>
                Add Organization
              </button>
              <button type="button" className="btn primary-btn d-block d-lg-none">
                <span>
                  <img src="images/static-lable-add.svg" alt="add-icon" className="me-2" />
                </span>
                Add
              </button>
            </div>
            <div className="row g-4">
              {!pageLoader && organizationArr && organizationArr?.length === 0 && (
                <div className="noRecordsFound">No records found!</div>
              )}
              {organizationArr.map((eachOrganization) => (
                <div key={eachOrganization.organizationId} className="col-lg-4 col-md-6 col-sm-12">
                  <div
                    onClick={() => handleOrganizationClick(eachOrganization.organizationId)}
                    className="card h-100 card-sty"
                  >
                    <div className="card-body manage-card-brd-sty p-0">
                      <div className="d-xs-inline-block d-sm-flex">
                        <div className="col-md-8 col-sm-6 text-center mt-3 mb-2">
                          <span>
                            <img src={eachOrganization.logoUrl} alt="card-logo" className="manage-card-logo my-2" />
                          </span>
                          <p className="page-header mt-3">{eachOrganization.organizationName}</p>
                        </div>
                        <div
                          className={`col-md-4 col-sm-6 text-center manage-card-right-bg py-3 ${
                            !eachOrganization.providerType?.length ? 'manage-card-no-pro' : ''
                          }`}
                        >
                          {eachOrganization.providerType && eachOrganization.providerType.length > 0 ? (
                            <>
                              <h4 className="manage-provider-text">Providers</h4>
                              <ul className="list-unstyled mt-0">
                                {Array.isArray(eachOrganization.providerType) &&
                                  eachOrganization.providerType.map((eachProvider) => (
                                    <li key={eachProvider} className="text-center my-3">
                                      <span
                                        className={`provider-icon ${getProviderTypeLogo(eachProvider)}`}
                                        data-bs-toggle="tooltip" data-bs-placement="top" title={eachProvider}
                                      ></span>
                                    </li>
                                  ))}
                              </ul>
                            </>
                          ) : (
                            <p className="manage-card-nopro-txt para-text text-center">No providers added</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
    
        {/* AddOrganization Popup */}
        <div className={`modal fade show ${showAddOrganization ? "show-popup" : ""}`} id="AddOrganization-popup" tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content popup-sty border-0 px-3 py-2 custom-border-radius">
              <div className="modal-header border-0">
                <h5 className="modal-title custom-header-style">Add an Organization</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setShowAddOrganization(false); resetAddOrganizationForm();}}></button>
              </div>
              <div className="modal-body pt-2 pb-0">
                <div className="row mb-4">
                  <div className="col-md-12 mb-3">
                    {/* Organization Name input */}
                    <label className="w-100 form-label custom-label" htmlFor="organization">Organization Name</label>
                    <input
                      className="w-100 custom-input form-control py-2 px-2"
                      type="text"
                      placeholder="Enter the Organization Name"
                      id="organization"
                      value={organizationName}
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => { organizationCheck(e); setNameRequieredError(organizationName === "") }}
                    />
                    {nameRequieredError && <span className="signup-errors d-block">Organization Name is required</span>}
                    {organizationCheckError && <span className="signup-errors d-block">Organization Name Already Exists</span>}
                    {organizationlengthError &&
                                    <span
                                        className="signup-errors d-block error-span"
                                    >
                                        Organization Name should not be greater than 180 characters
                                    </span>
                                }
                  </div>
                  <div className="col-md-12">
                    {/* Attach a Logo input */}
                    <label className="w-100 form-label custom-label">Attach a Logo</label>
                    <div className="upload-btn-wrapper">
                      <button type="button" className="upload-btn-style mb-2 px-4">
                        <span className="me-2 upload-img"></span>Upload Image
                      </button>
                      <input className="custom-cursor" name="myfile" type="file" id="imageId" onChange={(e) => handleImageUpload(e)}/>
                      <input id="uploadFile" disabled={true} className="font-14 font-regular text-grey formcontrol-browse ps-4" placeholder={uploadImage !== "" ? uploadImage : "No file chosen"}></input>
                      {fileSizeError && <span className="text-danger d-block error-span">The uploaded file should be less than 250 KB.</span>}
                      {fileTypeError && <span className="text-danger d-block error-span">The uploaded file should be in JPG, JPEG, or PNG format.</span>}
                    </div>
                  </div>
                </div>
              </div>
              <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showNotAddedErrorPopup ? 'show-popup' : ''}`}>
                  <div role="document" className="custom-modal-theme modal-dialog">
                      <div className="modal-content">
                          <div className="modal-header border-0">
                              <button type="button"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  className="btn-close"
                                  onClick={() => { setErrorPopUp(false)}}></button>
                          </div>
                          <div className="modal-body">
                              <div className="text-center mb-1">
                                  <img alt="Error" src="images/Error.svg" />
                              </div>
                          </div>
                          <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                              <button type="button" className="btn primary-btn" onClick={() => { setErrorPopUp(false)}}>
                                  Got It
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-2">
                  <button type="button" className="btn secondary-btn" data-bs-dismiss="modal"
                      onClick={() => {setShowAddOrganization(false); resetAddOrganizationForm();}}>Cancel</button>
                  <button type="button" className="btn primary-btn ms-3" disabled={!showAddButton} onClick={() => updateOrganization()}>Add
                  {/* <button type="button" className="btn primary-btn ms-3" disabled = {showAddButton}>Add */}
                    {showAddLoader && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pageLoader &&
              <div className="container-fluid" >
                  <div className="modal-backdrop fade show" tabIndex={-1}>
                      <div className="position-absolute top-50 start-50 translate-middle">
                          <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                      </div>
                  </div>
              </div >
                }
    </>
  );
} 

export default ManageOrganization;