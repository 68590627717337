import { client } from '../client/Client'
import { IAwsProviderPayload, IAzureProviderDetails, IProviderPayload } from '../interface/manage';

export async function fetchProviders(payload: IProviderPayload, organizationId: number) {
    let response = await client(`/manage/providers/organization/${organizationId}`,payload, "POST")

    return response
}

export async function fetchTestConnection(payload: IAwsProviderPayload | IAzureProviderDetails) {
    let response = await client('/manage/provider/testconnection',payload, "POST")

    return response
}

export async function updateProviderDetails(payload: IAwsProviderPayload | IAzureProviderDetails, editIndex: number) {
    let response = await client(`/manage/organization/${payload.organizationId}/provider/${editIndex}`,payload, "POST")

    return response
}

export async function deleteProviderDetail(organizationId: number, providerId: number) {
    let response = await client(`/manage/provider/${providerId}/organization/${organizationId}`,"", "DELETE")

    return response
}

export async function getLinkedAccounts(secretName: string) {
    let response = await client(`/manage/provider/linkedAccounts`,JSON.stringify({ secretName }), "POST")

    return response
}
