import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  IOrganizationData,
  ProviderDetail,
  defaultOrganization
} from '../../interface/organization'
import { ICostAnomalyData
} from '../../interface/costAnomalyDetection'
import {
  getAllThresholds,
  deleteThresholds
} from '../../service/thresholdService'
import getSymbolFromCurrency from 'currency-symbol-map'
import Header from '../Header/Header'
import { formatDate } from '../../service/dateFormatterService'
import {
  ProviderSelect,
  ProviderSelectProps
} from '../ResuableComponents/ProviderSelect'
import { ScopeSelect, ScopeSelectProp } from '../ResuableComponents/ScopeSelect'
import { OrganizationSelect } from '../ResuableComponents/OrganizationSelect'
import { compare } from '../../utils/comparator'
import {
  getThresholdChartOptions
} from '../../service/chartService'
import { LineChart } from '../ResuableComponents/Chart/LineChart'
import { useEncryptStorage } from '../../hooks/useEncryptStorage'
import { IExchangeRate, exchangeRateObj } from '../../interface/dashboard'
import {convertCostData, getExchangeRate} from '../../service/currencyService'
import { roundToDecimalPlaces } from '../../service/numberFormat'
export const ManageTreshold = () => {
  const nav = useNavigate()
  // data
  const [tresholdData, setthresholdData] = useState<ICostAnomalyData[]>([])
  const [filteredthresholdData, setFilteredthresholdData] = useState<any>([])
  const [deleteId, setDeleteId] = useState<any>()
  const [successpopup, setSuccesspopup] = useState<boolean>(false)
  const [deletepopup, setdeletepopup] = useState<boolean>(false)
  const [organization, setOrganization] =
    useState<IOrganizationData>(defaultOrganization)
  const [providerType, setProviderType] = useState<any>()
  // reusable dropdowns
  const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
      organizationId: 0,
      providerTypes: [],
      onProviderTypeChange: (organizationId, providerType) => {}
    })

  const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: '',
    onScopeChange: scope => {}
  })
  // pagination
  const pageLimit = 7
  const [currentLength, setCurrentLength] = useState(pageLimit)
  const [SaveLoader, SetSaveLoader] = useState<any>(false)
  // loader
  const [pageLoader, setPageLoader] = useState(true)
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false)

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl: Element) => {
      return new (window as any).bootstrap.Tooltip(tooltipTriggerEl)
    })

    return () => {
      tooltipList.forEach((tooltip: any) => tooltip.dispose())
    }
  }, [])

  const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
  ) => {
    setPageLoader(true)
    setOrganization(selectedOrganization)
    setProviderTypeSelectProps({
      organizationId: selectedOrganization.organizationId,
      onProviderTypeChange: onProviderTypeChange,
      providerTypes: selectedOrganization.providerType
    })
  }

  const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
  ) => {
    setPageLoader(true)
    setProviderType(selectedProviderType)
    setScopeSelectProps({
      organizationId: organizationId,
      providerType: selectedProviderType,
      onScopeChange: onScopeChange
    })
  }
  const [organizationId, setOrganizationId] = useState<any>()
  const [accountId, setaccountId] = useState<any>()
  const [selectedScope, SetselectedScope] = useState<ProviderDetail>()
  const [secretName, SetsecretName] = useState<any>()
  const [connectorName, SetconnectorName] = useState<any>()
  const [exchangeRate,setExchangeRate] = useState<IExchangeRate>(exchangeRateObj)
  useEffect(() => {
    async function fetchExchangeRate() {
        const response = await getExchangeRate();
        const rate = response.data; 
        setExchangeRate(rate);
    }
    fetchExchangeRate();
}, []);
const convertCost = (cost: number, sourceCurrency: string) =>{
  if (useEncryptStorage.getItem("currency") === sourceCurrency ) {
      return cost
  } else {
      const convertedCost = convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRate)
      const convertedExchangeCost = roundToDecimalPlaces(convertedCost.cost,2)
      return convertedExchangeCost
  }
}
  const onScopeChange = async (
    selectedScope: ProviderDetail | ProviderDetail[]
  ) => {
    setPageLoader(true)
    selectedScope = selectedScope as ProviderDetail
    SetselectedScope(selectedScope)
    SetsecretName(selectedScope.secretName)
    setaccountId(selectedScope.accountId)
    SetconnectorName(selectedScope.connectorName)
    setOrganizationId(selectedScope.organizationId)
    let providerType = selectedScope.providerType
    let Payload
    if (providerType === 'AWS') {
      Payload = {
        organizationId : selectedScope.organizationId,
        accountId: selectedScope.accountId,
        secretName: selectedScope.secretName,
        subscriptionId: '',
        tenantId: ''
      }
    } else if (providerType === 'Azure') {
      Payload = {
        organizationId : selectedScope.organizationId,
        accountId: '',
        secretName: selectedScope.secretName,
        subscriptionId: selectedScope.subscriptionId,
        tenantId: selectedScope.tenantId
      }
    }
    await getAllthresholdValues(Payload, providerType)
  }
  // PS_MT_24
  const getAllthresholdValues = async (Payload: any, providerType: any) => {
    setTimeout(async () => {
    const result = await getAllThresholds(Payload, providerType)
    setPageLoader(false)
    if (result.statusCode === 200) {
      let thresholdResponse = result.data
      setthresholdData(thresholdResponse)
      setFilteredthresholdData(thresholdResponse)
    } else {
      setErrorPopUp(true)
    }
  },2000)
  }
  // organization, provider, scope components
  const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
    []
    )
    const providerSelectComponent = useMemo(
    () => <ProviderSelect {...providerTypeSelectProps} />,
    [providerTypeSelectProps]
  )
  const scopeSelectComponent = useMemo(
    () => <ScopeSelect {...scopeSelectProps} />,
    [scopeSelectProps]
  )
  // PS_MT_76
  const sort = (sortKey: any, order: string): void => {
    let sortedList = filteredthresholdData.sort((a: any, b: any) =>
      compare(a[sortKey], b[sortKey], order)
    )
    setFilteredthresholdData([...sortedList])
  }
  // PS_MT_41
  const updateThreshold = (name: string) => {
    nav(
      `/editthreshold?name=${name}&action=edit&secretName=${secretName}&orgId=${organizationId}&connectorName=${connectorName}&providerType=${providerType}&accountId=${accountId}`
    )
  }
  // Create a variable id and assign it with the event e.target.id
  const deleteThreshold = async (name: any) => {
    const payload = {
      secretName: selectedScope?.secretName,
      tenantId: selectedScope?.tenantId,
      subscriptionId: selectedScope?.subscriptionId,
      accountId: selectedScope?.accountId
    }
    SetSaveLoader(true)
    const result = await deleteThresholds(
      name,
      payload,
      providerType,
      organizationId
    )
    if (result.statusCode === 200) {
      setdeletepopup(false)
      setSuccesspopup(true)
      SetSaveLoader(false)
      await  getAllthresholdValues(payload, providerType)


    } else if (result.statusCode === 400) {
      setErrorPopUp(true)
    }
  }
  const role = useSelector((state: any) => state.role.role)
  const thresholdChart = () => {
    const data = filteredthresholdData?.map((eachData: any) => {
      return {
        name: eachData.name,
        value: convertCost(parseFloat(roundToDecimalPlaces(eachData.amount,2)),eachData.currencyType),
        amount: convertCost(parseFloat(roundToDecimalPlaces(eachData.amount,2)),eachData.currencyType),
        currencyType: useEncryptStorage.getItem("currency"),
        currentSpend:convertCost(parseFloat(roundToDecimalPlaces(eachData.currentSpend,2)),eachData.currencyType)
      }
    })
     const chartOptions = getThresholdChartOptions(data)
    return (
      <>
        <LineChart options={chartOptions}></LineChart>
      </>
    )
  }

  return (
    <>
      <div>
        <Header />
        <div className='container-fluid'>
          {/* <!-- cost anomly starts here --> */}
          <div className='row justify-content-center'>
            <div className='col-md-10'>
              <div className='d-lg-flex d-md-block justify-content-between align-items-center my-4'>
                <h5 className='page-header mb-lg-0 mb-3'>Manage Thresholds</h5>
                <div className='d-md-flex d-sm-block'>
                  <div className=' dropdown me-md-4 res-margin-20'>
                    {organziationSelectComponent}
                  </div>
                  {/* // <!--organization ends--> */}
                  {/* <!--Provider starts --> */}
                  {/* <div className='dropdown me-md-4 res-margin-20'> */}
                    {providerSelectComponent}
                    {scopeSelectComponent}
                  {/* </div> */}
                  {/* <!--Provider ends --> */}
                  {/* <!--Scope starts--> */}
                  <button
                    type='button'
                    className='btn primary-btn d-lg-block d-none ms-2'
                    onClick={() => {
                      nav(
                        `/addthreshold?action=new&providerType=${providerType}&orgId=${organizationId}`
                      )
                    }}
                    disabled={role=='BillingReader'?true:false}
                  >
                    <span>
                      <img src='images/static-lable-add.svg' className='me-2' />
                    </span>
                    New Threshold
                  </button>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3" style={{ height: "250px" }}>
                                    {(!tresholdData || tresholdData.length === 0) ? (
                                        <div style={{ textAlign: "center" }}>
                                            <img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-2" />
                                            <p className="para-text">No data found</p>
                                        </div>
                                    ) : (
                                      thresholdChart()
                                    )}
                                </div>
              <div className='row mb-5'>
                <h3 className='sub-header mb-3'>Created Thresholds</h3>
                <div className='col-md-12'>
                  {/* <!--table starts  --> */}
                  <div className='table-responsive'>
                    <table className='table table-borderless custom-grid '>
                      <thead className='custom-table-head'>
                        <tr>
                          <th className='custom-grid-font-weight position-relative'>
                            NAME
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('amount', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('amount', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            RESET PERIOD
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('timeGrain', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('timeGrain', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            CREATION DATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('starttime', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('starttime', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            EXPIRATION DATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('endtime', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('endtime', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='text-end custom-grid-font-weight position-relative'>
                            THRESHOLD
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('amount', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('amount', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='text-end custom-grid-font-weight position-relative'>
                            CURRENT SPENDS
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('currentSpend', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('currentSpend', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th
                            className='text-center custom-grid-font-weight'
                            // *ngIf="organization?.roleId != roleEnum.BILLING_READER"
                          >
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      {filteredthresholdData.length > 0 ?
                      <tbody>
                        
                          {filteredthresholdData?.map((each: any) => {
                              return (
                                <tr>
                                  <td className='grid-primary-text'>
                                    {each.name}
                                  </td>
                                  <td className='align-items-center'>
                                    {each.timeGrain}
                                  </td>
                                  <td>
                                    {formatDate(new Date(each.starttime))}
                                  </td>
                                  <td>{formatDate(new Date(each.endtime))}</td>
                                  <td className='grid-primary-text text-end'>
                                  {useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseFloat(roundToDecimalPlaces(each.amount,2)),each.currencyType)}
                                    {/* {getSymbolFromCurrency(each.currencyType)}
                                    {each.amount.toFixed(2)} */}
                                  </td>
                                  <td className='grid-primary-text text-end'>
                                  {useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseFloat(roundToDecimalPlaces(each.currentSpend,2)),each.currencyType)}
                                    {/* {getSymbolFromCurrency(each.currencyType)}
                                    {each.currentSpend.toFixed(2)} */}
                                  </td>
                                  <td className='custom-grid-font-weight text-center text-nowrap'>
                                    <a
                                      onClick={() => updateThreshold(each.name)}
                                      className={`${role=="BillingReader" && "disabled"}`}
                                    >
                                      <span className='me-3 edit-img' data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title='edit'
                                      data-bs-original-title='edit'></span>
                                    </a>
                                    <a
                                      // data-bs-toggle='modal'
                                      // data-bs-target='#delete'
                                      // href='#delete'
                                      className={`${role=="BillingReader" && "disabled"}`}
                                      onClick={() => {
                                        setDeleteId(each.name)
                                        setdeletepopup(true)
                                      }}
                                    >
                                      <span className='delete-img' 
                                          data-bs-toggle='tooltip'
                                          data-bs-placement='top'
                                          title='Delete'
                                          id='deleteTooltip'></span>
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                      </tbody>:<tbody>
                      <tr className="custom-alternative-line">
                                                    <td colSpan={7} className="text-center">
                                                        <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                            className="no-data-found-sty mb-4" />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr>
                        </tbody>}
                    </table>
                  </div>
                  {/* < !--table ends-- > */}
                </div>
              </div>
            </div>
          </div>
          {/* < !--cost anomly ends here-- > */}
        </div>
      </div>

      <div
        className={`modal show fade ${successpopup ? 'show-popup' : ''}`}
        tabIndex={-1}
        role='dialog'
      >
        <div
          className='modal-dialog custom-width-providerpopup custom-modal-theme'
          role='document'
        >
          <div className='modal-content custom-popup-height'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => setSuccesspopup(false)}
              />
            </div>
            <div className='modal-body'>
              <div className='text-center mb-1'>
                <img className='mt-1' src='images/Success.svg' alt='Success' />
                <label className='form-label custom-pop-label mt-4 mb-0 w-100'>
                  Successfully Deleted Threshold!
                </label>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-center pb-4'>
              <button
                type='button'
                className='btn primary-btn'
                data-bs-dismiss='modal'
                onClick={() => setSuccesspopup(false)}
              >
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
         className={`modal show fade ${deletepopup ? 'show-popup' : ''}`}
         tabIndex={-1}
         role='dialog'
      >
        <div className='modal-dialog custom-width-providerpopup'>
          <div className='modal-content popup-sty border-0 custom-border-radius'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='btn-close'
                aria-label='Close'
                onClick={()=>{setdeletepopup(false)}}
              ></button>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-1'>
                <img
                  className='mt-1'
                  src='images/Delete-Confirmation.svg'
                  alt='delete'
                />
                <label className='form-label custom-bold my-4 w-100'>
                  Delete Confirmation
                </label>
                <label className='form-label custom-pop-label mb-1'>
                  Are you sure you want to delete the threshold?
                </label>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-center pb-4'>
              <button
                type='button'
                className='btn secondary-btn me-3'
                onClick={() => deleteThreshold(deleteId)}
              >
                Yes
                {SaveLoader && 
                        <span className='spinner-border spinner-border-sm mr-1'></span>}
              </button>
              <button type='button' className='btn color-warning-btn' onClick={()=>{setdeletepopup(false)}}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      {pageLoader && (
        <div className='container-fluid'>
          <div
            className='modal-backdrop fade show'
            id='AddOrganization-popup'
            data-bs-keyboard='false'
            tabIndex={-1}
            aria-hidden='true'
          >
            <div className='position-absolute top-50 start-50 translate-middle'>
              <img
                src='images/cloud-load-gif.gif'
                alt='white-loader'
                className='page-loader'
              />
            </div>
          </div>
        </div>
      )}
      {/* start of error popup */}
				<div className={`modal show fade ${errorPopUp ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setErrorPopUp(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Error.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Error Occured
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setErrorPopUp(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of error popup */}
    </>
  )
}
