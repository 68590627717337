import { useEffect, useMemo, useState } from "react";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { IOrganizationData, ProviderDetail, defaultOrganization } from "../../interface/organization";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { deleteDashhbord, getAllDashboards, setDefaultDashboard } from "../../service/dashboardService";
import { IDashboard } from "../../interface/dashboard";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsDashboardListToggled } from "../../redux/dashboardList/action";

interface DashBoardListProps {
    organizationId: number;
    showDashboardList: boolean;
    component: string
    providerType: string
    onDashBoardListClose: () => void
}

const DashBoardList:React.FC<DashBoardListProps>  = (dashboardListProp)=> {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    let dashboardListObj: IDashboard= {
        organizationId: 0,
        name: "",
        isPrivate: false,
        providerType: "",
        previewUrl: new ArrayBuffer(0)
    }

    const [showDashBoardList, setshowDashBoardList] = useState<boolean>(false)
    const [dashboardList, setDashboardList] = useState<IDashboard[]>([dashboardListObj])
    const [dashboardListFiltered, setDashboardListFiltered] = useState<IDashboard[]>(dashboardList)
    const [listLoader, setListLoader] = useState<boolean>(true)
    const [listType, setListType] = useState<boolean>(false)
    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState<boolean>(false)
    const [deleteListData, setDeleteListData] = useState<any>({})
    const [organizationId, setOrganizationId] = useState<number>(0)
    const [sefaultList, setDefaultList] = useState<any>({})
    const role = useSelector((state: any) => state.role.role)

    const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
        organizationId: 0,
        providerTypes: [],
        onProviderTypeChange: (organizationId, providerType) => { },
    });

    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        showAll: true,
        
        onScopeChange: (scope) => { },
    });

    const onOrganizationChange = async (
        selectedOrganization: IOrganizationData
    ) => {
        setListLoader(true)
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        });
        setOrganizationId(selectedOrganization.organizationId)
        setListLoader(false)
    };

    const onProviderTypeChange = async (
        organizationId: number,
        selectedProviderType: string
    ) => {
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            showAll: true,
            onScopeChange: onScopeChange,
        });
        getDashboardList(dashboardListProp.component === "Template", organizationId, selectedProviderType)

    };

    const onScopeChange = async (
        selectedScope: ProviderDetail | ProviderDetail[]
    ) => {
        
    };

    const organziationSelectComponent = useMemo(
        () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
        []
    );
    const providerSelectComponent = useMemo(
        () => <ProviderSelect {...providerTypeSelectProps} />,
        [providerTypeSelectProps]
    );

    useEffect(() => {
        if(dashboardListProp.showDashboardList === true) {
            setDashboardList([dashboardListObj])
            setDashboardListFiltered([dashboardListObj])
            setshowDashBoardList(true)
            getDashboardList(dashboardListProp.component !== "Templates", dashboardListProp.organizationId, dashboardListProp.providerType)
        }else {
            setshowDashBoardList(false)
        }
    },[dashboardListProp.showDashboardList])

    function createImageUrl(blob: any): any {
        try {
            return `data:image/png;base64,${blob}`;
        } catch (e) {
            console.error(e);
        }
    }

    const getDashboardList = async (isTemplate: boolean, organizationId: number, selectedProviderType: string) => {
        setListLoader(true)
        const dashboardListResponse = await getAllDashboards(organizationId, isTemplate, selectedProviderType)

        if(dashboardListResponse.statusCode === 200) {
            setDashboardList(dashboardListResponse.data)
            setDashboardListFiltered(dashboardListResponse.data)
            setListLoader(false)
        }
        setListLoader(false)
    }

    const handleSearch = (searchValue: string) => {
        if(searchValue !== "") {
            let filteredDashboards = dashboardList.filter(eachDashboard => eachDashboard.name.startsWith(searchValue));
    
            setDashboardListFiltered(filteredDashboards)
        }
        else {
            setDashboardListFiltered(dashboardList)
        }
    }

    const handleDelete = (dashboardData: IDashboard) => {
        setDeleteConfirmationPopup(true)
        setDeleteListData(dashboardData)
    }

    const deleteDashboardListData = async () => {
        const deleteDashBoardResponse = await deleteDashhbord(organizationId, deleteListData.dashboardId)
    
        if (deleteDashBoardResponse.statusCode === 200) {
            const updatedDashboardList = dashboardList.filter(each => each.dashboardId !== deleteListData.dashboardId);
            const updatedDashboardListFiltered = dashboardListFiltered.filter(each => each.dashboardId !== deleteListData.dashboardId);
    
            setDashboardList(updatedDashboardList);
            setDashboardListFiltered(updatedDashboardListFiltered);
        }
    
        setDeleteConfirmationPopup(false);
    }

    const handleSetAsDefault = async(eachList: any) => {
        await setDefaultDashboard(organizationId, eachList?.dashboardId)
    }

    return (
    <>
        <div id="addChart" className={`modal show fade ${showDashBoardList ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
            <div className='custom-modal-style modal-dialog modal-xl templates-modal' role='document'>
                <div className='modal-content'>
                    <div className="d-lg-flex d-md-block justify-content-between custom-border-bottom pb-3">
                        <div>
                            <h5 className="modal-title custom-header-style responsive-margin-bottom">Saved {dashboardListProp.component}
                                <button type="button" className="btn-close custom-width float-end d-lg-none"
                                >
                                </button>
                            </h5>
                        </div>
                        <div className="d-lg-flex d-md-block align-items-center">
                            <div className="dropdown me-md-4 res-margin-20 app-organization-select">
                                {organziationSelectComponent}
                            </div>
                            {providerSelectComponent}
                            <div className="input-group me-5">
                                <input type="text" className="form-control text-field border-radius font-14 font-medium pe-5 custom-input" id="search" placeholder="Search" onKeyUp={(e) => handleSearch((e.target as HTMLInputElement).value)}/>
                                <span className="inputfield-icon-sty"><a href="#"><img src="images/search.svg" alt="Search icon"
                                    className="close-icon" /></a></span>
                            </div>
                            <button type="button" className="btn-close custom-width responsive-display-none d-md-none d-lg-block" data-bs-dismiss="modal" aria-label="Close" onClick = {() => {dashboardListProp.onDashBoardListClose(); dispatch(setIsDashboardListToggled(false))}}
                            ></button>
                        </div>
                    </div>
                    <div className="modal-body px-0 pt-4 pb-0">
                        <div className="row popup-scrl-sty">
                            {dashboardListFiltered[0]?.name !== "" && (
                                dashboardListFiltered?.map((eachList: IDashboard, index: number) => (
                                    <div className="col-lg-4 col-md-12 mb-4" key={index+1}>
                                        <div className="card h-100 template-card border-0 ">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <h5 className="template-head-style">{eachList?.name}</h5>
                                                    <button className="btn border-0 p-0" type="button" id="dropdownMenuButton2"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/3Dot-Dropdown-Button.svg" alt="button-icon" />
                                                    </button>
                                                    <ul className="dropdown-menu custom-dropdown-sty border-0 py-1 px-2" key={index+1}>
                                                        {role !== "BillingReader" &&
                                                            <li className="border-grey custom-dropdown-brd py-1">
                                                                <a className="dropdown-item function-dropdown custom-dropdown-li py-2 px-2"
                                                                href = {`/dashboard?action=edit&organizationId=${organizationId}&dashboardId=${eachList.dashboardId}`}>
                                                                    <img src="images/Edit-Dropdown.svg" alt="edit-icon" className="me-2" />Edit</a>
                                                            </li>
                                                        }
                                                        <li className="pt-1 custom-dropdown-brd py-1">
                                                            <a className="dropdown-item py-2 px-2 function-dropdown custom-dropdown-li" onClick={() => {handleDelete(eachList)}}
                                                            >
                                                                <img src="images/Delete-Dropdown.svg" alt="delete-icon" className="me-2" />Delete</a>
                                                        </li>
                                                        <li className="pt-1 custom-dropdown-brd py-1">
                                                            <a className="dropdown-item py-2 px-2 function-dropdown custom-dropdown-li"
                                                            href = {`/dashboard?action=view&organizationId=${organizationId}&dashboardId=${eachList.dashboardId}&type=${dashboardListProp.component}`}
                                                            >
                                                                <img src="images/Eye-Icon.svg" alt="eye-icon" className="me-2" />View</a>
                                                        </li>
                                                        <li className="pt-1">
                                                            <a className="dropdown-item py-2 px-2 function-dropdown custom-dropdown-li" onClick={() => {handleSetAsDefault(eachList)}}
                                                            >
                                                                <img src="images/Set-default-icon.svg" alt="default-icon" className="me-2" />Set as default</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="w-100">
                                                    <p className="created-date-style">
                                                        Created On: <span>{moment(eachList.createdOn).format('MM/DD/YY')}</span>
                                                        <span> / </span>
                                                        Updated On:<span>{moment(eachList.modifiedOn).format('MM/DD/YY')}</span>
                                                    </p>
                                                    <img className="w-100 temp-preview-img-sty" alt="ss" src={createImageUrl(eachList.previewUrl)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                            }
                            {dashboardList.length === 0 &&
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 mb-3" style={{ height: `250px` }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-2" />
                                        <p className="para-text">No data found</p>
                                    </div>
                                </div>
                            }
                            {listLoader &&
                                <div id="loader" className="col-lg-12 col-md-12 col-sm-12 mb-3 spinner chart-spinner">
                                    <div className="custom-loader"></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div id="addChart" className={`modal show fade ${deleteConfirmationPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className='modal-dialog custom-width-providerpopup custom-modal-style' role='document'>
                    <div className='modal-content'>
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setDeleteListData({}); setDeleteConfirmationPopup(false) }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img className="mt-1" src="images/Delete-Confirmation.svg" alt="delete" />
                                <label className="form-label custom-bold my-4 w-100">Delete Confirmation</label>
                                <label className="form-label custom-pop-label mb-1">You are about to delete the following template: <span
                                    className="color-warning">{deleteListData.name}</span></label>
                                <label className="form-label custom-pop-label mb-1">Do you want to proceed?</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn secondary-btn me-3" data-bs-dismiss="modal" onClick={() => {deleteDashboardListData()}}
                            >Yes</button>
                            <button type="button" className="btn color-warning-btn" onClick={() => { setDeleteListData({}); setDeleteConfirmationPopup(false) }}
                            >No</button>
                        </div>
                    </div>
                </div>
            </div>
    </>
    )
};

export default DashBoardList
