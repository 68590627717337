import { client } from "../client/Client";
import { IWebHookConnectionDetails } from "../interface/manage";

export async function webHookSaveDetails(webHookUrlPayloadObj: IWebHookConnectionDetails, organizationId : number) {
    // PS_CA_010
    let response = await client(`/infra/organization/${organizationId}/anomalies/integration`, webHookUrlPayloadObj, "POST");
    return response;
}

export async function fetchExistingWebHookUrl(organizationId : number) {
    // PS_CA_025
    let response = await client(`/infra/organization/${organizationId}/anomalies/integration`,"", "GET");
    return response;
}
