import Header from "../Header/Header";
import '../../assets/scss/_connectors.scss'
import { useEffect, useMemo, useState } from "react";
import { IOrganizationData, ProviderDetail, defaultOrganization } from "../../interface/organization";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { deleteConnection, fetchConnectionDetails, fetchProjectKeys, fetchTicketingConnectionDetails, saveJiraConnection, saveServiceInstance, setSecret } from "../../service/managementService";
import { TicketType } from '../../enum/connectorTypeEnum'
import { IJiraConnectionDetails, IServiceNowConnectionDetails, ITicketingToolConnectionStatus } from "../../interface/manage";
import Select from 'react-select';
import { useSelector } from "react-redux";

export default function Connectors() {

    const [providerType, setProviderType] = useState("");
    const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
    const [definedFilterValue, setDefinedFilterValue] = useState<any>([])
    const [selectedValue, setSelectedValue] = useState<any>([])
    const role = useSelector((state: any) =>  state.role.role)

    let jiraConnectionDetailsObj: IJiraConnectionDetails = {
        organizationId: 0,
        url: "",
        token: "",
        userName: "",
        projectKey: []
    }

    let serviceNowConnectionDetailsObj: IServiceNowConnectionDetails = {
        instanceEmailId: ""
    }
    
    let jiraConnectionPayloadObj: IJiraConnectionDetails = {
        organizationId: 0,
        url: "",
        token: "",
        userName: "",
        projectKey: []
    };

    let serviceNowConnectionPayloadObj: IServiceNowConnectionDetails = {
        instanceEmailId: ""
    };

    let ticketingToolConnectionStatusObj: ITicketingToolConnectionStatus = {
        jiraUrl: "",
        serviceNowInstanceUrl: ""
    }

    const [showJiraConnectedStatus, setShowJiraConnectedStatus] = useState<boolean>(false)
    const [showJiraDisConnectedStatus, setShowJiraDisConnectedStatus] = useState<boolean>(false)
    const [ticketingToolConnectionStatus, setTicketingToolConnectionStatus] = useState<ITicketingToolConnectionStatus>(ticketingToolConnectionStatusObj)

    const [showServiceConnectedStatus, setShowServiceConnectedStatus] = useState<boolean>(false)
    const [showServiceDisconnectedStatus, setShowServiceDisconnectedStatus] = useState<boolean>(false)

    const [showJiraTicketPopup, setShowJiraTicketPopup] = useState<boolean>(false)

    const [jiraConnectionDetails, setJiraConnectionDetails] = useState<IJiraConnectionDetails>(jiraConnectionDetailsObj)
    const [jiraUrlRequired, setJiraUrlRequired] = useState<boolean>(false)
    const [jiraTokenRequired, setJiraTokenRequired] = useState<boolean>(false)
    const [jiraUserNameRequired, setJiraUserNameRequired] = useState<boolean>(false)
    const [jiraProjectKeyRequired, setJiraProjectKeyRequired] = useState<boolean>(false) 
    const [isJiraSaveDisabled, setJiraSaveDisabled] = useState<boolean>(true)

    const [showServiceTicketPopup, setShowServiceTicketPopup] = useState<boolean>(false)

    const [serviceNowConnectionDetails, setServiceNowConnectionDetails] = useState<IServiceNowConnectionDetails>(serviceNowConnectionDetailsObj)
    const [serviceInstanceEmailRequried, setServiceInstanceEmailRequried] = useState<boolean>(false)
    const [isServiceSaveDisabled, setServiceSaveDisabled] = useState<boolean>(true)

    const [jiraConnectionPayload, setJiraConnectionPayloadDetails] = useState<IJiraConnectionDetails>(jiraConnectionPayloadObj)
    const [serviceNowConnectionPayload, setServiceNowConnectionPayloadDetails] = useState<IServiceNowConnectionDetails>(serviceNowConnectionPayloadObj)
    const [showLoader, setShowLoader] = useState<boolean>(true)
    const [selectedTool, setSelectedTool] = useState<string>("")

    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState<boolean>(false)
    const [isJiraSaveLoaderEnabled, setIsJiraSaveLoaderEnabled] = useState<boolean>(false)
    const [isServiceSaveLoaderEnabled, setIsServiceSaveLoaderEnabled] =useState<boolean>(false)

    const [projectKeys, setProjectKeys] = useState<any>([])

    const [providerTypeSelectProps, setProviderTypeSelectProps] =
        useState<ProviderSelectProps>({
            organizationId: 0,
            providerTypes: [],
            onProviderTypeChange: (organizationId, providerType) => { },
        });

    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        onScopeChange: (scope) => { },
    });

    const onOrganizationChange = async (
        selectedOrganization: IOrganizationData
    ) => {
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        });
        setOrganization(selectedOrganization);
        getConnectionDetails(selectedOrganization.organizationId)
    };

    const onProviderTypeChange = async (
        organizationId: number,
        selectedProviderType: string
        ) => {
            setProviderType(selectedProviderType);
            setScopeSelectProps({
                organizationId: organizationId,
                providerType: selectedProviderType,
                onScopeChange: onScopeChange,
            });
        setProviderType(selectedProviderType);
    };

    const onScopeChange = async (
        selectedScope: ProviderDetail | ProviderDetail[]
    ) => {
        selectedScope = selectedScope as ProviderDetail;
    };

    const organziationSelectComponent = useMemo(
        () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
        []
    );

    const getConnectionDetails = async (organizationId: number) => {
        setShowLoader(true)
        const connectionDetailResponse = await fetchTicketingConnectionDetails(organizationId)
        setTicketingToolConnectionStatus(connectionDetailResponse.data)

        if (connectionDetailResponse.statusCode === 200) {
            if (connectionDetailResponse.data.jiraUrl === "Connected") {
                setShowJiraConnectedStatus(true)
                setShowJiraDisConnectedStatus(false)
                const JiraConnectionDetailsResponse = await fetchConnectionDetails(organizationId, TicketType.JIRA)

                if (JiraConnectionDetailsResponse.statusCode === 200) {
                    setJiraConnectionDetails(JiraConnectionDetailsResponse.data)

                    setSelectedValue((prevFilterValues: any) => {
                        const newFilterValues: any = [];
                        JiraConnectionDetailsResponse.data?.projectKey.forEach((obj: any) => {
                            newFilterValues.push({
                                label: obj,
                                value: obj,
                            });
                        });
                        return newFilterValues;
                    });
                }
            }
            else if (connectionDetailResponse.data.jiraUrl === "DisConnected") {
                setShowJiraDisConnectedStatus(true)
                setShowJiraConnectedStatus(false)
            }

            if (connectionDetailResponse.data.serviceNowInstanceUrl === "Connected") {
                setShowServiceConnectedStatus(true)
                setShowServiceDisconnectedStatus(false)
                const serviceConnectionDetailsResponse = await fetchConnectionDetails(organizationId, TicketType.SERVICE_NOW)

                if (serviceConnectionDetailsResponse.statusCode === 200) {
                    setServiceNowConnectionDetails(serviceConnectionDetailsResponse.data)
                }
            }
            else if (connectionDetailResponse.data.serviceNowInstanceUrl === "DisConnected") {
                setShowServiceDisconnectedStatus(true)
                setShowServiceConnectedStatus(false)
            }
        }
        setShowLoader(false)
    }

    const handleClosePopup = () => {
        setJiraConnectionPayloadDetails((prevData: any) => {
            const clearJiraConnectionPayload = {
                ...prevData,
                url: "",
                token: "",
                userName: "",
                projectKey: []
            }

            return clearJiraConnectionPayload
        })

        setServiceNowConnectionPayloadDetails((prevData: any) => {
            const clearServiceNowConnectionPayload = {
                ...prevData,
                instanceEmailId: "",
            }

            return clearServiceNowConnectionPayload
        })

        setJiraSaveDisabled(true)
        setServiceSaveDisabled(true)
        setShowJiraTicketPopup(false)
        setShowServiceTicketPopup(false)
        setJiraUrlRequired(false)
        setJiraTokenRequired(false)
        setJiraUserNameRequired(false)
        setServiceInstanceEmailRequried(false)
        setJiraProjectKeyRequired(false)
        setSelectedValue([])
    }

    const handleJiraToolClick = () => { 
        if(role === "Owner") {
            if (ticketingToolConnectionStatus.jiraUrl === "Connected") {
                setShowJiraTicketPopup(true)
                setJiraConnectionPayloadDetails((prevData) => {
                    const populateToolDetails = {
                        ...prevData,
                        url: jiraConnectionDetails.url,
                        token: jiraConnectionDetails.token,
                        userName: jiraConnectionDetails.userName,
                        projectKey: jiraConnectionDetails.projectKey,
                    }
    
                    return populateToolDetails
                })
            }
            else if (ticketingToolConnectionStatus.jiraUrl === "DisConnected") {
                setShowJiraTicketPopup(true)
            }
        }
    }

    const handleServiceToolClick = () => {
        if(role === "Owner") {
            if (ticketingToolConnectionStatus.serviceNowInstanceUrl === "Connected") {
                setShowServiceTicketPopup(true)
                setServiceNowConnectionPayloadDetails((prevData) => {
                    const populateToolDetails = {
                        ...prevData,
                        instanceEmailId: serviceNowConnectionDetails.instanceEmailId,
                    }
    
                    return populateToolDetails
                })
            }
            else if (ticketingToolConnectionStatus.serviceNowInstanceUrl === "DisConnected") {
                setShowServiceTicketPopup(true)
            }
        }
    }

    const handleJiraConnectionDataChange = (id: string, value: any) => {
        setJiraConnectionPayloadDetails((prevData: any) => {
            const newConnectionDetail = { ...prevData, [id]: value }

            if (id === "url") {
                if (value === "") {
                    setJiraUrlRequired(true)
                }
                else if (value !== "") {
                    setJiraUrlRequired(false)
                }
            }

            if (id === "token") {
                if (value === "") {
                    setJiraTokenRequired(true)
                }
                else if (value !== "") {
                    setJiraTokenRequired(false)
                    setSecret(newConnectionDetail.token, organization.organizationId)
                }
            }

            if (id === "uername") {
                if (value === "") {
                    setJiraUserNameRequired(true)
                }
                else if (value !== "") {
                    setJiraUserNameRequired(false)
                }
            }

            if (
                newConnectionDetail.url !== "" &&
                newConnectionDetail.token !== "" &&
                newConnectionDetail.username !== "") {
                setJiraSaveDisabled(false)
            }

            if(newConnectionDetail.url !== "" && newConnectionDetail.token !== ""&& newConnectionDetail.token !== undefined && newConnectionDetail.userName !== "") {
                getProjectKeys(newConnectionDetail)
            }

            return newConnectionDetail
        })
    }

    const handleDropDownChange = (selectedOption: any) => {
        setJiraProjectKeyRequired(false)
        setDefinedFilterValue((prevDefinedFilterValues: any) => {
            const newDefinedFilterValue: any = [];
            selectedOption.forEach((obj: any, index: number) => {
                newDefinedFilterValue.push(obj.label);
            });
            return newDefinedFilterValue;
        });

        setSelectedValue(selectedOption)
    }

    const handleServiceConnectionDataChange = (id: string, value: any) => {
        setServiceNowConnectionPayloadDetails((prevData: any) => {
            const newConnectionDetail = { ...prevData, [id]: value }

            if (id === "instanceEmailId") {
                if (value === "") {
                    setServiceInstanceEmailRequried(true)
                }
                else if (value !== "") {
                    setServiceInstanceEmailRequried(false)
                }
            }

            if (newConnectionDetail.url !== "") {
                setServiceSaveDisabled(false)
            }

            return newConnectionDetail
        })
    }

    const handleServiceSave = async() => {
        setIsServiceSaveLoaderEnabled(true)
        setShowLoader(true)
        const serviceInstanceSaveResponse = await saveServiceInstance(serviceNowConnectionPayload, ticketingToolConnectionStatus.serviceNowInstanceUrl, organization.organizationId)

        if(serviceInstanceSaveResponse.statusCode === 200) {
            setShowServiceTicketPopup(false)
            getConnectionDetails(organization.organizationId)
        }
        setShowLoader(false)
        setIsServiceSaveLoaderEnabled(false)
    }

    const handleJiraSave = async() => {
        setIsJiraSaveLoaderEnabled(true)
        setShowLoader(true)
        jiraConnectionPayload.projectKey = definedFilterValue
        const jiraInstanceSaveResponse = await saveJiraConnection(jiraConnectionPayload, ticketingToolConnectionStatus.jiraUrl, organization.organizationId)

        if(jiraInstanceSaveResponse.statusCode === 200) {
            setShowJiraTicketPopup(false)
            getConnectionDetails(organization.organizationId)
        }

        setShowLoader(false)
        setIsJiraSaveLoaderEnabled(false)
    }

    const handleDelete = async() => {
        if(role === "Owner") {
            setDeleteConfirmationPopup(false)
            setShowJiraTicketPopup(false)
            setShowServiceTicketPopup(false)
            setShowLoader(true)

            const deleteResponse = await deleteConnection(selectedTool, organization.organizationId)
    
            if(deleteResponse.statusCode === 200) {
                getConnectionDetails(organization.organizationId)
                
                handleClosePopup()
            }
    
            setDeleteConfirmationPopup(false)
            setShowLoader(false)
        }
    }

    const getProjectKeys = async(connectionDetail: IJiraConnectionDetails) => {

        jiraConnectionPayload.organizationId = organization.organizationId
        jiraConnectionPayload.token = connectionDetail.token
        jiraConnectionPayload.userName = connectionDetail.userName

        const projectKeysResponse = await fetchProjectKeys(jiraConnectionPayload, organization.organizationId)

        if (projectKeysResponse.statusCode === 200) {
            setProjectKeys((prevFilterValues: any) => {
                const newFilterValues: any = [];
                projectKeysResponse.data?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
    }

    return (
        <div>
            <Header />
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-10 pb-5">
                        <div className="d-flex justify-content-between align-items-center my-4">
                            <h5 className="page-header">Manage Connectors</h5>
                            <div className="d-md-flex d-sm-block">
                                <div className="dropdown me-md-4 res-margin-20 app-organization-select">
                                    {organziationSelectComponent}
                                </div>
                            </div>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="card card-sty connectors-card-sty">
                                    <div className="card-body py-0">
                                        <div className="d-xs-inli   -block d-sm-flex">
                                            <div className="col-md-7 col-sm-6 mt-3 mb-5" onClick={() => handleJiraToolClick() }>
                                                <span><img src="images/Jira_Logo.svg" alt="card-logo"
                                                    className="manage-jira-card-logo ms-10" /></span>
                                                <p className="page-header mt-3">{""}</p>
                                            </div>
                                            <div className="col-md-5 col-sm-6 text-center py-3">
                                                <div
                                                    className="mt-0">
                                                    {showJiraConnectedStatus &&
                                                        <div className="d-flex justify-content-end pt-4">
                                                            <span className="connectors-card-logo ms-10 connectors-provider-text d-flex">
                                                                <img src="images/Connected.svg" className="connector-legend mt-1"/>
                                                                <span>{" "}
                                                                    Connected
                                                                </span>
                                                            </span>
                                                        </div>
                                                    }
                                                    {showJiraDisConnectedStatus &&
                                                        <div className="d-flex justify-content-end pt-4">
                                                            <span className="connectors-card-logo ms-10 connectors-provider-text d-flex">
                                                                <img src="images/Disconnected.svg" className="connector-legend mt-1" />
                                                                <span>{" "}
                                                                    Disconnected
                                                                </span>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                {showJiraConnectedStatus &&
                                                        <div className="d-flex justify-content-end mt-1">
                                                            {role == 'Owner' &&<span className="connectors-card-logo ms-10 connectors-provider-text text-end" onClick={() => {setDeleteConfirmationPopup(true); setSelectedTool("jira")}}>
                                                               <img src='images/Delete-Icon.svg'/>
                                                            </span>}
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="card card-sty connectors-card-sty">
                                    <div className="card-body py-0">
                                        <div className="d-xs-inline-block d-sm-flex">
                                            <div className="col-md-7 col-sm-6 mt-3 mb-5" onClick={() => { handleServiceToolClick() }}>
                                                <span><img src="images/servicenow.svg" alt="card-logo"
                                                    className="manage-service-card-logo ms-10" /></span>
                                                <p className="page-header mt-3">{""}</p>
                                            </div>
                                            <div className="col-md-5 col-sm-6 py-3">
                                                <div
                                                    className="mt-0">
                                                    {showServiceConnectedStatus &&
                                                        <div className="d-flex justify-content-end pt-4">
                                                            <span className="connectors-card-logo ms-10 connectors-provider-text d-flex justify-content-end">
                                                            <img src="images/Connected.svg" className="connector-legend mt-1"/>
                                                                <span>{" "}
                                                                    Connected
                                                                </span>
                                                            </span>
                                                        </div>
                                                    }
                                                    {showServiceDisconnectedStatus &&
                                                        <div className="d-flex justify-content-end pt-4">
                                                            <span className="connectors-card-logo ms-10 connectors-provider-text d-flex">
                                                                <img src="images/Disconnected.svg" className="connector-legend mt-1"/>
                                                                <span>{" "}
                                                                    Disconnected
                                                                </span>
                                                            </span>
                                                        </div>
                                                    }
                                                    {showServiceConnectedStatus &&
                                                        <div className="d-flex justify-content-end mt-1">
                                                            {role == 'Owner' &&<span className="connectors-card-logo ms-10 connectors-provider-text text-end" onClick={() => {setDeleteConfirmationPopup(true); setSelectedTool("serviceNow")}}>
                                                             <img src='images/Delete-Icon.svg'/>
                                                            </span>}
                                                        </div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of jira ticket popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showJiraTicketPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center">
                                <img src="images/Jira_Logo-symbal.svg" className="me-3 provider-icon shadow-sm" />
                                Jira Configuration
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { handleClosePopup() }}>
                            </button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="url">URL
                                        <span className="color-warning">*</span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter URL"
                                        id="url"
                                        value={jiraConnectionPayload.url}
                                        onChange={(e) => { handleJiraConnectionDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setJiraUrlRequired(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${jiraUrlRequired ? "" : "invisible"}`}>
                                        URL is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="token">Token
                                        <span className="color-warning">*</span>
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter token"
                                        id="token"
                                        value={jiraConnectionPayload.token}
                                        onChange={(e) => { handleJiraConnectionDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setJiraTokenRequired(e.target.value === "") }}
                                        disabled = {showJiraConnectedStatus === true} />
                                    <span className={`text-danger d-block error-span ${jiraTokenRequired ? "" : "invisible"}`}>
                                        Token is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="username">Username
                                        <span className="color-warning">*</span>
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter username"
                                        id="userName"
                                        value={jiraConnectionPayload.userName}
                                        onChange={(e) => { handleJiraConnectionDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setJiraUserNameRequired(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${jiraUserNameRequired ? "" : "invisible"}`}>
                                        Username is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="url">Project Key
                                        <span className="color-warning">*</span>
                                    </label>
                                    <Select
                                        id="dropdown"
                                        isMulti
                                        options={projectKeys}
                                        className="custom-input-react-select"
                                        onChange={handleDropDownChange}
                                        value={selectedValue}
                                        isClearable={true}
                                        onBlur={(e) => { setJiraProjectKeyRequired(e.target.value==='') }}
                                    />
                                    <span className={`text-danger d-block error-span ${jiraProjectKeyRequired ? "" : "invisible"}`}>
                                    Select atleast one project key
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                            <button type="button" className="btn secondary-btn"
                                onClick={() => { handleClosePopup() }}
                            >Cancel</button>
                            <button type="button"
                                className="btn primary-btn ms-3"
                                disabled={isJiraSaveDisabled}
                                onClick={() => {handleJiraSave()}}
                            >
                                {isJiraSaveLoaderEnabled &&
                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                }
                                {showJiraConnectedStatus ? "Update": "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of service ticket popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showServiceTicketPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center">
                                <img src="images/serviceNowCard.svg" className="me-3 provider-icon shadow-sm" />
                                ServiceNow Configuration
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { handleClosePopup() }}>
                            </button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="instanceEmailId">Instance Email ID
                                        <span className="color-warning">*</span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter Instance Email ID"
                                        id="instanceEmailId"
                                        value={serviceNowConnectionPayload.instanceEmailId}
                                        onChange={(e) => { handleServiceConnectionDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setServiceInstanceEmailRequried(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${serviceInstanceEmailRequried ? "" : "invisible"}`}>
                                        Instance Email ID is required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                            <button type="button" className="btn secondary-btn"
                                onClick={() => { handleClosePopup() }}
                            >Cancel</button>
                            <button type="button"
                                className="btn primary-btn ms-3"
                                disabled={isServiceSaveDisabled}
                                onClick={() => {handleServiceSave()}}
                            >
                                {isServiceSaveLoaderEnabled &&
                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                }
                                {showServiceConnectedStatus ? "Update": "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* loader */}
            {showLoader &&
                <div className="container-fluid">
                    <div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                        </div>
                    </div>
                </div>
            }

            {/* start of delete provider confirmation popup */}
            <div className={`modal show fade ${deleteConfirmationPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog custom-width-providerpopup">
                    <div className="modal-content popup-sty border-0 custom-border-radius">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setDeleteConfirmationPopup(false)}}></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                            <img className="mt-1" src="images/Delete-Confirmation.svg" alt="delete"/>
                            <label className="form-label custom-bold my-4 w-100">Delete Confirmation</label>
                            <label className="form-label custom-pop-label mb-1"> Are you sure to delete the Connectors?</label>
                            <label className="form-label custom-pop-label mb-1"> Do you want to proceed?</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn secondary-btn me-3" data-bs-dismiss="modal" onClick={() => {handleDelete()}}>Yes</button>
                            <button type="button" className="btn color-warning-btn"  data-bs-dismiss="modal" onClick={() => {setDeleteConfirmationPopup(false)}}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};