import { BlobServiceClient } from '@azure/storage-blob';
import { client } from '../client/Client'
import { key } from '../hooks/useEncryptStorage';

export async function checkOrganization(organizationName:any) {
    let result = await client(`/authorize/organization/exists/${organizationName}` , null, 'GET');

    return result;
}


export async function updateUserProfileImage(file: any, fileName: any) {
    let blobClient = new BlobServiceClient(`https://${key.ACCOUNT_NAME}.blob.core.windows.net?${key.SAS_TOKEN}`).getContainerClient(`${key.CONTAINER_NAME}`);
    let bClient = blobClient.getBlockBlobClient(fileName);
    bClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file?.type } });
    
    return `https://${key.ACCOUNT_NAME}.blob.core.windows.net/${key.CONTAINER_NAME}/` + fileName;
}

export async function userSignup(userData:any) {
    let userSignUpResponse = await client("/authorize/user/signup", userData, "POST")

    return userSignUpResponse
}