import "../../assets/scss/_notifications.scss";
import React, { useState, useEffect, useMemo } from "react";
import { INotification, IOrganizationData, IProviderTypeDetails, ProviderDetail, defaultOrganization,IExchangeRate } from "../../interface/organization";
import { async } from "q";
import { getNotification } from "../../service/notificationService";
import { formatDateWithTime } from "../../service/dateFormatterService";
import { IFilter, INotificationResponse, MailGrp, NotificationModel, NotificationSortKeys } from "../../interface/notifications";
import { exchangeRateObj } from '../../interface/dashboard';
import Header from "../Header/Header";
import { padWithALeadingZero } from "../../utils/numberUtils";
import { compare } from "../../utils/comparator";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { roundToDecimalPlaces } from "../../service/numberFormat";
import { debounce } from 'lodash'
import {convertCostData, getExchangeRate} from '../../service/currencyService'
import { useEncryptStorage } from "../../hooks/useEncryptStorage";

export default function Notification() {

    useEffect(() => {
        setPageLoader(true)
        async function fetchExchangeRate() {
            const response = await getExchangeRate();
            if (response.statusCode === 200) {
                // Assuming the response contains the exchange rate as a string
                const rate = response.data; // Extract exchange rate from response data
                setExchangeRate(rate);
            }
        }
        fetchExchangeRate();
      }, []);

    // PS_NT_01
    const [totalCount, setTotalCount] = useState(0);
    const [notificationData, setNotificationData] = useState<NotificationModel[]>([]);
    const [notificationStatusPopUp, setNotificationStatusPopUp] = useState(false)
    const [notificationStatus, setNotificationStatus] = useState<MailGrp[]>([]);
    
    const [selectedProviderDetail, setSelectedProviderDetail] = useState<ProviderDetail>()
    const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
    const [providerType, setProviderType] = useState("");
    const [pageLoader, setPageLoader] = useState<boolean>(false)
    const [exchangeRate,setExchangeRate] = useState<IExchangeRate>(exchangeRateObj)
    const [shouldFetchNotification, setShouldFetchNotification] = useState(false);

    // PS_NT_02
    let notificationPayload: IFilter = {
        limit: 10,
        searchText: null,
        skip: 0
    }

    // PS_NT_03
    const [providerTypeSelectProps, setProviderTypeSelectProps] = useState<ProviderSelectProps>({
        organizationId: 0,
        providerTypes: [],
        onProviderTypeChange: (organizationId, providerType) => { },
    });

    // PS_NT_03
    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        onScopeChange: (scope) => { },
    });

    // PS_NT_04
    const pageLimit = 5
    const [currentLength, setCurrentLength] = useState(pageLimit)

    // PS_NT_05
    const onOrganizationChange = async (selectedOrganization: IOrganizationData) => {
        setPageLoader(true)
        setOrganization(selectedOrganization);
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        })
    }

    // PS_NT_06
    const onProviderTypeChange = async (organizationId: number, selectedProviderType: string) => {
        console.log(organization)
        setProviderType(selectedProviderType);
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            onScopeChange: onScopeChange,
        })
    }

    // PS_NT_07
    const onScopeChange = async (selectedScope: ProviderDetail | ProviderDetail[]) => {
        selectedScope = selectedScope as ProviderDetail

        setSelectedProviderDetail(selectedScope)

        let notificationPayload: any = {
            limit: 10,
            searchText: null,
            skip: 0
            
        }

        if (selectedScope.providerType === "AWS") {
            getAllNotification(selectedScope.accountId, selectedScope.organizationId, notificationPayload)
        }
        else if (selectedScope.providerType === "Azure") {
            getAllNotification(selectedScope.subscriptionId, selectedScope.organizationId, notificationPayload)
        }
        setShouldFetchNotification(true);
    }


    async function getAllNotification(id: any, organizationId: any, notificationPayload: any) {
        setPageLoader(true)
        // PS_NT_08
        setTimeout(async () => {
            const result = await getNotification(id, organizationId, notificationPayload);
            // PS_NT_10
            if (result.statusCode === 200) {
                // PS_NT_10
                if(result.data.message) {
                    setTotalCount(0)
                    setCurrentLength(0)
                    setNotificationData(result.data.notifications);
                    setPageLoader(false);
                }
                // PS_NT_10
                else {
                    setNotificationData(result.data.notifications);
                    setTotalCount(result.data.count)
                    setCurrentLength(result.data.notifications.length)
                    setPageLoader(false);
                }
            }
        }, 3000)
    }

    // PS_NT_11
    function handleLoadMore() {
        setCurrentLength(currentLength => currentLength + pageLimit);
    }

    // PS_NT_12
    function actionClick(mailResponse: MailGrp[]) {
        setNotificationStatusPopUp(true)
        setNotificationStatus(mailResponse);
    }

    // PS_NT_13
    function closePopUpAction() {
        setNotificationStatusPopUp(false);
    }

    // PS_NT_14
    const sort = (sortKey: NotificationSortKeys, order: string): void => {
        // PS_NT_14
        notificationPayload.sortBy = sortKey
        notificationPayload.orderBy = order

        if(selectedProviderDetail?.providerType === "AWS"){
            // PS_NT_14
            getAllNotification(selectedProviderDetail?.accountId, selectedProviderDetail.organizationId, notificationPayload)
        }
        else if(selectedProviderDetail?.providerType === "Azure") {
            // PS_NT_14
            getAllNotification(selectedProviderDetail?.subscriptionId, selectedProviderDetail.organizationId, notificationPayload)
        }
    }

    // PS_NT_15
    const handleSearch = async (e : any) => {

        // PS_NT_15
        let notificationDatas = notificationData
        if (e.target.value) {
            notificationPayload.searchText = e.target.value

            if(selectedProviderDetail?.providerType === "AWS"){
                // PS_NT_15
                getAllNotification(selectedProviderDetail?.accountId, selectedProviderDetail.organizationId, notificationPayload)
            }
            else if(selectedProviderDetail?.providerType === "Azure") {
               //  PS_NT_15
               getAllNotification(selectedProviderDetail?.subscriptionId, selectedProviderDetail.organizationId, notificationPayload)
            }
        }
        else {
            notificationPayload.searchText = null
            if(selectedProviderDetail?.providerType === "AWS"){
                getAllNotification(selectedProviderDetail?.accountId, selectedProviderDetail.organizationId, notificationPayload)
            }
            else if(selectedProviderDetail?.providerType === "Azure") {
                getAllNotification(selectedProviderDetail?.subscriptionId, selectedProviderDetail.organizationId, notificationPayload)
            }
        }
        setNotificationData(notificationDatas);
    }

    // PS_NT_15
    const debouncedSearch = debounce(handleSearch, 1000);

    const convertCost = (cost: number, sourceCurrency: string) =>{
        if (useEncryptStorage.getItem("currency") === sourceCurrency ) {
            return cost
        } else {
            const convertedCost = convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRate)
            console.log(convertedCost);
            const convertedExchangeCost = roundToDecimalPlaces(convertedCost.cost,2)
            return convertedExchangeCost
        }
    }

    // PS_NT_16
    const renderMailResponseTable = (notificationStatus : any) => {
        return (
            <div className="table-responsive">
                <table className="table table-borderless custom-grid mt-4 mb-4">
                    <thead className="custom-table-head">
                        <tr>
                            <th className="text-nowrap custom-grid-font-weight">RECIPIENT</th>
                            <th className="text-nowrap custom-grid-font-weight">ACKNOWLEDGED TIME</th>
                            <th className="text-nowrap custom-grid-font-weight">STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificationStatus.length > 0 &&
                            notificationStatus.map((each : any, index : any) => (
                                <tr key={index}>
                                    <td className="text-nowrap grid-primary-text">{each.EmailId}</td>
                                    <td className="text-nowrap">
                                        {each.acknowledgedTime
                                            ? formatDateWithTime(new Date(each.acknowledgedTime))
                                            : '-'}
                                    </td>
                                    <td className={`status-td${each.Status === 2 ? 'Acknowledged' : 'NotAcknowledged'}-style text-nowrap`}>
                                        {each.Status === 2 ? 'Acknowledged' : 'Not Acknowledged'}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    };
    
    // PS_NT_17
    const organziationSelectComponent = useMemo(() => <OrganizationSelect onOrganizationChange={onOrganizationChange} />, []);
    const providerSelectComponent = useMemo(() => <ProviderSelect {...providerTypeSelectProps} />, [providerTypeSelectProps]);
    const scopeSelectComponent = useMemo(() => <ScopeSelect {...scopeSelectProps} />, [scopeSelectProps])

    return (
        <>
            <div>
                <Header />
                <div className="container-fluid">
                    {/* Notifications grid starts here */}
                    <div className="row justify-content-center">
                        <div className="col-md-10">
    
                            <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                                <h5 className="page-header mb-md-3 mb-lg-0 d-lg-block d-none">Notifications</h5>
                                <div className="d-md-flex d-sm-block">
                                    <div className=" dropdown me-md-4 res-margin-20 app-organization-select">
                                        {organziationSelectComponent}
                                    </div>
                                    {providerSelectComponent}
                                    <div className="db-provider">
                                        {scopeSelectComponent}
                                    </div>
    
                                    <div className="input-group ms-3">
                                        <input
                                            type="text" className="form-control text-field border-radius font-14 font-medium pe-5 custom-input ng-untouched ng-pristine ng-valid"
                                            id="search" placeholder="Search"
                                            onChange={(e) => debouncedSearch(e)} 
                                        />
                                        <span className="inputfield-icon-sty">
                                            <a >
                                                <img src="images/search.svg" alt="Search icon" className="close-icon" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* table starts */}
                                    <div className="table-responsive">
                                        <table className="table table-borderless custom-grid ">
                                            <thead className="custom-table-head">
                                                <tr>
                                                    <th className="custom-grid-font-weight position-relative">NAME
                                                        <span className="sorting">
                                                            <a className="sortup" onClick={() => sort('alert_name', 'Asc')}><span
                                                                className="sort-icon sortup-img"></span></a>
                                                            <a className="sortdown" onClick={() => sort('alert_name', 'Desc')}><span
                                                                className="sort-icon sortdown-img"></span></a>
                                                        </span>
                                                    </th>
                                                    <th className="custom-grid-font-weight">THRESHOLD FOR</th>
                                                    <th className="custom-grid-font-weight text-end position-relative">% REACHED
                                                        <span className="sorting">
                                                            <a className="sortup" onClick={() => sort('limit', 'Asc')}><span
                                                                className="sort-icon sortup-img"></span></a>
                                                            <a className="sortdown" onClick={() => sort('limit', 'Desc')}><span
                                                                className="sort-icon sortdown-img"></span></a>
                                                        </span>
                                                    </th>
                                                    <th className="custom-grid-font-weight text-end position-relative">THRESHOLD
                                                        <span className="sorting">
                                                            <a className="sortup" onClick={() => sort('threshold_amount', 'Asc')}><span
                                                                className="sort-icon sortup-img"></span></a>
                                                            <a className="sortdown" onClick={() => sort('threshold_amount', 'Desc')}><span
                                                                className="sort-icon sortdown-img"></span></a>
                                                        </span>
                                                    </th >
                                                    <th className="custom-grid-font-weight position-relative">REACHED TIME
                                                        <span className="sorting">
                                                            <a className="sortup" onClick={() => sort('reached_time', 'Asc')}><span
                                                                className="sort-icon sortup-img"></span></a>
                                                            <a className="sortdown" onClick={() => sort('reached_time', 'Desc')}><span
                                                                className="sort-icon sortdown-img"></span></a>
                                                        </span>
                                                    </th >
                                                    <th className="custom-grid-font-weight text-end position-relative"># RECIPIENTS
                                                        <span className="sorting">
                            <a className="sortup" ><span className="sort-icon sortup-img" onClick={() => sort('number_of_recipients','Asc')}></span></a>
                            <a className="sortdown" ><span className="sort-icon sortdown-img" onClick={() => sort('number_of_recipients','Desc')}></span></a >
                        </span >
                                                    </th >
                                                    <th className="text-center custom-grid-font-weight">ACTION</th>
                                                </tr >
                                            </thead >
                                            <tbody>
                                                {totalCount > 0 &&
                                                    notificationData.slice(0, currentLength).map((eachNotification: any, index) => (
                                                            <tr key={index}>
                                                                {providerType === 'AWS' ? (
                                                                    <>
                                                                        <td className="grid-primary-text">{eachNotification.alertName || '-'}</td>
                                                                        <td>{eachNotification.alertFor || '-'}</td>
                                                                        <td className="text-end">{eachNotification.limit} %</td>
                                                                        <td className="text-end">{useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseInt(roundToDecimalPlaces(parseFloat(eachNotification.thresholdAmount), 2)),eachNotification.currencyType)}</td>
                                                                        <td className="grid-primary-text">{eachNotification.reachedTime && formatDateWithTime(new Date(eachNotification.reachedTime))}</td>
                                                                        <td className="grid-primary-text text-end">{eachNotification.numberofRecipients}</td>
                                                                        <td className="grid-primary-text text-center">
                                                                            <img src="images/Eye-Icon.svg" alt="" onClick={() => actionClick(eachNotification.MailResponse)}></img>
                                                                        </td>
                                                                    </>
                                                                ) : null}
                                                                {providerType === 'Azure' ? (
                                                                    <>
                                                                        <td className="grid-primary-text">{eachNotification.alertName || '-'}</td>
                                                                        <td>{eachNotification.alertFor || '-'}</td>
                                                                        <td className="text-end">{eachNotification.limit} %</td>
                                                                        <td className="text-end">{useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseInt(roundToDecimalPlaces(parseFloat(eachNotification.thresholdAmount), 2)),eachNotification.currencyType)}</td>
                                                                        <td className="grid-primary-text">{eachNotification.reachedTime ? formatDateWithTime(new Date(eachNotification.reachedTime)) : '-'}</td>
                                                                        <td className="grid-primary-text text-end">{eachNotification.numberofRecipients}</td>
                                                                        <td className="grid-primary-text text-center">
                                                                            <img src="images/Eye-Icon.svg" alt="" onClick={() => actionClick(eachNotification.MailResponse)}></img>
                                                                        </td>
                                                                    </>
                                                                ) : null}
                                                            </tr>
                                                    ))}
                                            </tbody >
                                        </table >
    
                                        <p className="mb-3 mt-2 para-text"># of Records:
                                            <span
                                                className="primary-color">{padWithALeadingZero(totalCount < currentLength ? totalCount : currentLength)} out of {padWithALeadingZero(totalCount)}
                                            </span>
                                        </p>
                                        {totalCount > currentLength &&
                                            <div className="row justify-content-center">
                                                <button type="button" className="btn secondary-btn mt-2 mb-5"
                                                    onClick={handleLoadMore}>
                                                    Load More
                                                </button>
                                            </div>
                                        }
                                        {/* 
                                {/* < !--table ends-- > */}
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                    {/* /* < !--Notifications grid ends here-- >  */}
                </div>
    
                {/* < !--Page Loader starts here-- > */}
                {pageLoader &&
                    <div className="container-fluid" >
                        <div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                            <div className="position-absolute top-50 start-50 translate-middle">
                                <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                            </div>
                        </div>
                    </div >
                }
    
                {/* // < !--Status Pop - up Begins-- > */}
                {/* <div className="modal fade" id="Status-popup" data-bs-backdrop="static" tabIndex = {-1} aria-hidden="true"> */}
                {notificationStatusPopUp && 
                <div className={`modal show fade show-popup`} tabIndex={-1} role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content popup-sty px-3 border-0 custom-border-radius">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style">Status</h5>
                                <button type="button" className="btn-close" onClick={closePopUpAction}></button>
                            </div>
                            <div className="modal-body pt-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        {providerType === 'AWS' && renderMailResponseTable(notificationStatus)}
                                        {providerType === 'Azure' && renderMailResponseTable(notificationStatus)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </>
    );
}