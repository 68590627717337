import { SET_IS__DASHBOARD_LIST_TOGGLED,  } from "./action"

const isEnabled: string =  ""

export const isEnabledReducer = (state = isEnabled, action: any) => {
    switch (action?.type) {
        case SET_IS__DASHBOARD_LIST_TOGGLED:
            return {
                isEnabled: action.payload
            }    
        default:
            return state;
    } 
}