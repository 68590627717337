import { useState, useEffect, useMemo } from 'react'
import { TagsInput } from 'react-tag-input-component'
import { MultiSelect } from 'react-multi-select-component'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import '../../assets/scss/createThresholds.scss'

import Header from '../Header/Header'
import {
  ProviderSelect,
  ProviderSelectProps
} from '../ResuableComponents/ProviderSelect'
import { ScopeSelect, ScopeSelectProp } from '../ResuableComponents/ScopeSelect'
import { OrganizationSelect } from '../ResuableComponents/OrganizationSelect'
import {
  IOrganizationData,
  ProviderDetail,
  defaultOrganization
} from '../../interface/organization'
import { getProviderDetails } from '../../service/managementService'
import { saveThreshold, thresholdByName } from '../../service/thresholdService'
import {
  getCostAnalysisChartData,
  getCostAnalysisChartOptionsForThreshold
} from '../../service/chartService'
import {
  getCostAnalysis,
  getCostByDimensions
} from '../../service/dashboardService'
import { LineChart } from '../ResuableComponents/Chart/LineChart'
import { EChartsOption } from 'echarts-for-react'
import { useEncryptStorage } from '../../hooks/useEncryptStorage'
import { roundToDecimalPlaces } from '../../service/numberFormat'
import { convertCostData, getExchangeRate } from '../../service/currencyService'
import { IExchangeRate, exchangeRateObj } from '../../interface/dashboard'
import { ChartdataProps } from '../../interface/chart/chartData'
//PS_CT_07
export const AddTreshold = () => {
  const nav = useNavigate()
  const id = useLocation()
  const NumRegex = /^[0-9]+$/
  const Emailregex: any = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
  //PS_CT_08
  const [thresholdDetails, setThresholdDetails] = useState<any>({
    Budget_Name: '',
    TimeGrain: 'Monthly',
    Amount: '',
    Startdate: '',
    EndDate: '',
    level1: [],
    level2: [],
    level3: [],
    webHookUrl: '',
    finalAmount: '',
    resourceGroups: [],
    numberOfRecipients: ''
  })
  // PS_CT_09
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl: Element) => {
      return new (window as any).bootstrap.Tooltip(tooltipTriggerEl)
    })

    return () => {
      tooltipList.forEach((tooltip: any) => tooltip.dispose())
    }
  }, [])
  // PS_CT_09
  useEffect(() => {
    SetType(id.pathname)
    if (OrgId && ProviderType) {
      getAllProviders()
    }
    if (id.pathname == '/addthreshold') {
      SetisEdit(false)
    } else {
      SetisEdit(true)
    }
    if (ProviderType == 'AWS') {
      SetisAWS(true)
    } else {
      SetisAWS(false)
    }
  }, [])
  // PS_CT_10
  const [typeCheck, SetType] = useState<any>()
  const [isEdit, SetisEdit] = useState<boolean>()
  const [isAWS, SetisAWS] = useState<boolean>(false)
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const [providerType, setProviderType] = useState<any>()
  const [selectScope, SetselectScope] = useState<ProviderDetail>()
  const [selectedScope, setSelectedScope] = useState<string>('')
  const [organization, setOrganization] =
    useState<IOrganizationData>(defaultOrganization)
  const [scopeList, setScopeList] = useState<ProviderDetail[]>([])
  const [dropDownValue, SetdropDownValue] = useState<any>({ Scope: 'All' })
  const [markLineOption, setMarkLineOption] = useState<EChartsOption>()
  const [isfillOutPrevAlert, setIsfillOutPrevAlert] = useState<boolean>(false)
  const [isPerctEmpty, setIsPerctEmpty] = useState(false)
  const [amtList, setAmtList] = useState<any>([50, 75, 90])
  const [isDisabled, setIsDisabled] = useState(false)
  const [deletedIndex, setDeletedIndex] = useState<any>(null)
  const [SaveLoader, SetSaveLoader] = useState<any>(false)
  const [SuccesspopUp, setSuccesspopUp] = useState<boolean>(false)
  const [editData, SetEditData] = useState<any>()
  const [emailData, SetEmailData] = useState<any>([])
  const [formErrors, setformErrors] = useState<string>('')
  const [emailLvl1Required, setEmailLvl1Required] = useState<boolean>(false)
  const [emailLvl2Required, setEmailLvl2Required] = useState<boolean>(false)
  const [emailLvl3Required, setEmailLvl3Required] = useState<boolean>(false)
  const [validemailLvl1, setvalidEmailLvl1] = useState<boolean>(false)
  const [validemailLvl2, setvalidEmailLvl2] = useState<boolean>(false)
  const [validemailLvl3, setvalidEmailLvl3] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [BudgetRequiredError, setBudgetRequiredError] = useState<boolean>(false)
  const [validBudgetError, setValidBudgetError] = useState<boolean>(false)
  const [TimeGrainRequiredError, setTimeGrainRequiredError] =
    useState<boolean>(false)
  // PS_CT_11
  const [AmountRequiredError, setAmountRequiredError] = useState<boolean>(false)
  const [validStartdateError, setValidStartdateError] = useState<boolean>(false)
  const [StartdateRequiredError, setStartdateRequiredError] =
    useState<boolean>(false)
  const [enddateRequiredError, setenddateRequiredError] =
    useState<boolean>(false)
  const [ResourseGroups, setResourseGroups] = useState<any>()
  console.log(ResourseGroups)
  const [costAnalysisData, setCostAnalysisData] = useState<any>()
  const [costAnalysisChartLoader, setCostAnalysisChartLoader] = useState(false)
  const [costAnalysisChartRetry, setCostAnalysisChartRetry] = useState(false)
  const [currencyType, setCurrencyType] = useState('USD')
  const [showNotAddedErrorPopup, setShowNotAddedErrorPopup] = useState<boolean>(false)
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [chartData, SetchartData] = useState<ChartdataProps[]>([])
  const [ButtonEnabled, setButtonEnabled] = useState<boolean>(true)
  const [selectedOptions, setSelectedOptions] = useState<any>([])
  const [thAmount, setThAmount] = useState<any>() 
  const [email1tags, setemail1Tags] = useState<any>([])
  const [email2tags, setemail2Tags] = useState<any>([])
  const [email3tags, setemail3Tags] = useState<any>([])
  const [pageLoader, setPageLoader] = useState(false)
  const [noOfRecipients, setNoOfRecipients] = useState<number>(0)
  const [NotificationId, setNotificationId] = useState<any>()
  const OrgId: any = params.get('orgId')
  const ProviderType: any = params.get('providerType')
  const thresholdName: any = params.get('name')
  const [exchangeRate,setExchangeRate] = useState<IExchangeRate>(exchangeRateObj)
  useEffect(() => {
    async function fetchExchangeRate() {
        const response = await getExchangeRate();
        const rate = response.data; 
        setExchangeRate(rate);
    }
    fetchExchangeRate();
}, []);
const convertCost = (cost: number, sourceCurrency: string) =>{
  if (useEncryptStorage.getItem("currency") === sourceCurrency ) {
      return cost
  } else {
      const convertedCost = convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRate)
      const convertedExchangeCost = roundToDecimalPlaces(convertedCost.cost,2)
      return convertedExchangeCost
  }
}
  const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: '',
    onScopeChange: scope => { }
  })
  const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
      organizationId: 0,
      providerTypes: [],
      onProviderTypeChange: (organizationId, providerType) => { }
    })
  // PS_CT_12
  const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
  ) => {
    setOrganization(selectedOrganization)
    setProviderTypeSelectProps({
      organizationId: selectedOrganization.organizationId,
      onProviderTypeChange: onProviderTypeChange,
      providerTypes: selectedOrganization.providerType
    })
  }

  const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
  ) => {
    setProviderType(selectedProviderType)
    setScopeSelectProps({
      organizationId: organizationId,
      providerType: selectedProviderType,
      onScopeChange: onScopeChange
    })

    setProviderType(selectedProviderType)
  }
  // PS_CT_13
  const onScopeChange = async (
    selectedScope: ProviderDetail | ProviderDetail[]
  ) => {
    selectedScope = selectedScope as ProviderDetail
    let providerType = selectedScope.providerType
    SetselectScope(selectedScope)
    // SetdropDownValue(selectedScope.connectorName)
    let Payload
    if (providerType === 'AWS') {
      Payload = {
        accountId: selectedScope.accountId,
        secretName: selectedScope.secretName,
        subscriptionId: '',
        tenantId: ''
      }
    } else if (providerType === 'Azure') {
      Payload = {
        accountId: '',
        secretName: selectedScope.secretName,
        subscriptionId: selectedScope.subscriptionId,
        tenantId: selectedScope.tenantId
      }
    }
    // PS_CT_18
    await getEditedThresholdData(Payload)
    await getCostAnalysisDetails(Payload)
    await getResourceGrps(Payload, selectedScope.organizationId)
  }
  // PS_CT_14
  const getAllProviders = async () => {
    const providerPayload = {
      page: 1000,
      searchText: '',
      sortBy: '',
      orderBy: '',
      providerType: ProviderType
    }

    const providerResoponse = await getProviderDetails(OrgId, providerPayload)

    // TODO: check status code
    setScopeList(providerResoponse.data.providerdetails)
    setSelectedScope(providerResoponse.data.providerdetails[0].providerId)
    onScopeChange(providerResoponse.data.providerdetails[0])
  }
  // PS_CT_14
  const handleScopeChange = (selectedScope: string) => {
    if (selectedScope === 'All') {
      onScopeChange(scopeList)
      return
    }
    let scopeFound = scopeList.find(each => each.providerId === +selectedScope)
    if (scopeFound) {
      onScopeChange(scopeFound)
    }
  }
  function getIndexNumber(index: any) {
    setDeletedIndex(index)
    setIsfillOutPrevAlert(false)
    // openModal();
  }
  function deleteAlert() {
    setIsDisabled(false)
    const updatedAmtList = [...amtList]
    updatedAmtList.splice(deletedIndex, 1)
    setAmtList(updatedAmtList)
  }
  function addAlertConditions(index: any) {
    setIsfillOutPrevAlert(false)
    // setIsPerctEmpty(false)
    if (amtList[0] === 0) {
      setIsfillOutPrevAlert(true)
      return
    }else if(checkDuplicateAmt(amtList[0], index)){
      setIsDuplicate(true)
      return
    }else if(amtList[0] === '' || NaN){
      setIsPerctEmpty(true)
      return
    }

    if (amtList.length < 5 && isPerctEmpty === false && isDuplicate === false ) {
      const updatedAmtList = [0, ...amtList.slice(0, 4)]
      setAmtList(updatedAmtList)
    } else {
      setIsDisabled(true)
    }
  }
  // PS_CT_19
  const getEditedThresholdData = async (Payload: any) => {
    if (id.pathname == '/editthreshold') {
      setPageLoader(true)
      // PS_CT_21
      const providerResoponse = await thresholdByName(
        thresholdName,
        Payload,
        ProviderType,
        OrgId
      )
      // PS_CT_30
      if (providerResoponse.statusCode == 200) {
        SetEditData(providerResoponse?.data)
        SetEmailData(providerResoponse?.data?.alertRecipients)
        // setAmtList(providerResoponse.data.alertConditions)
        setThresholdDetails({
          ...thresholdDetails,
          Budget_Name: providerResoponse?.data?.Budget_Name
            ? providerResoponse?.data?.Budget_Name
            : '',
          TimeGrain: providerResoponse?.data?.TimeGrain
            ? providerResoponse?.data?.TimeGrain
            : '',
          Amount: providerResoponse?.data?.Amount
            ? providerResoponse?.data?.Amount
            : '',
          Startdate: providerResoponse?.data?.Startdate
            ? providerResoponse?.data?.Startdate
            : '',
          EndDate: providerResoponse?.data?.EndDate
            ? providerResoponse?.data?.EndDate
            : '',
          level1: providerResoponse?.data?.alertRecipients[0]?.mailGroup
            ? providerResoponse?.data?.alertRecipients[0]?.mailGroup
            : '',
          level2: providerResoponse?.data?.alertRecipients[1]?.mailGroup
            ? providerResoponse?.data?.alertRecipients[1]?.mailGroup
            : '',
          level3: providerResoponse?.data?.alertRecipients[2]?.mailGroup
            ? providerResoponse?.data?.alertRecipients[2]?.mailGroup
            : '',
          webHookUrl: providerResoponse?.data?.webHookUrl
        })
        setNotificationId(providerResoponse?.data?.NotificationId)
        setemail1Tags(
          providerResoponse?.data?.alertRecipients[0]?.mailGroup || []
        )
        setemail2Tags(
          providerResoponse?.data?.alertRecipients[1]?.mailGroup || []
        )
        setemail3Tags(
          providerResoponse?.data?.alertRecipients[2]?.mailGroup || []
        )
        setAmtList(providerResoponse?.data?.alertConditions || [50, 75, 90])
        // PS_CT_31
        setThAmount(providerResoponse?.data?.Amount)
        setNotificationId(providerResoponse?.data?.NotificationId)
        setvalidEmailLvl1(false)
        setEmailLvl1Required(false)
        setPageLoader(false)
      } else {
        setPageLoader(false)
        setShowNotAddedErrorPopup(true)
      }
    }
  }
  const [isAmtEmpty, setIsAmtEmpty] = useState<boolean>(false)
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false)
  const [tempIndex, setTempIndex] = useState<any>(null)
  const [tempPerctIndex, setTempPerctIndex] = useState<any>(null)
  const [tempAmtIndex, setTempAmtIndex] = useState<any>(null)
  const getAmount = (percentage: number, index: any) => {
    setIsPerctEmpty(false)
    setIsfillOutPrevAlert(false)
    if(!isNaN(percentage)){
    if (percentage === 0 ||  percentage === null  ) {
      setIsDuplicate(false)
      setTempPerctIndex(index)
      setIsPerctEmpty(true)
    } else if (!checkDuplicateAmt(percentage, index)) {
      setIsAmtEmpty(false)
      setIsPerctEmpty(false)
    }
  }else{
      setIsDuplicate(false)
      setTempPerctIndex(index)
      setIsPerctEmpty(true)
    }
      const updatedAmtList = [...amtList]
      updatedAmtList[index] = isNaN(percentage) ? '':percentage
      setAmtList(updatedAmtList)
  }
  const checkDuplicateAmt = (value: number, index: any) => {
    if (amtList.includes(value) && amtList[index] !== value) {
      setTempIndex(index)
      setIsDuplicate(true)
      return true
    }
    // else {
    //   setIsDuplicate(false)
    //   return false
    // }
  }
  const getPercent = (amount: any, index: any) => {
    var intnum = parseInt(amount)
    setIsAmtEmpty(false)
    if (intnum !== 0) {
      const calculatedPerct:number = (intnum / parseInt(thAmount)) * 100 
      const updatedAmtList = [...amtList]
      updatedAmtList[index] = isNaN(calculatedPerct) ? '':calculatedPerct
      setAmtList(updatedAmtList)
    } else {
      setIsAmtEmpty(true)
      setTempAmtIndex(index)
    }
  }

  // PS_CT_39
  const handleThresholddetails = (name: string, value: any) => {
    const onlyNameRegex =/^[a-zA-Z-,' ']+$/;
    const date = new Date(value)
    const dayOfMonth = date.getDate()
    setThresholdDetails((Details: any) => {
      const UpdatedthresholdData = { ...Details, [name]: value }
      // PS_CT_40
      if (name === 'Budget_Name' && UpdatedthresholdData.Budget_Name === '') {
        setBudgetRequiredError(true)
        setValidBudgetError(false)
      }else if (!onlyNameRegex.test(UpdatedthresholdData.Budget_Name) && UpdatedthresholdData.Budget_Name !== '') {
        setBudgetRequiredError(false)
        setValidBudgetError(true)
      }else if (onlyNameRegex.test(UpdatedthresholdData.Budget_Name) && UpdatedthresholdData.Budget_Name === '') {
        setBudgetRequiredError(true)
        setValidBudgetError(false)
      }  else {
        setBudgetRequiredError(false)
        setValidBudgetError(false)
      }
      // PS_CT_41
      // PS_CT_42
      if (name === 'Amount' && UpdatedthresholdData.Amount === '') {
        setAmountRequiredError(true)
      } else if (name === 'Amount' && UpdatedthresholdData.Amount !== '') {
        setAmountRequiredError(false)
      }
      // PS_CT_40
      if (name === 'Startdate' && UpdatedthresholdData.Startdate === '') {
        setStartdateRequiredError(true)
        setValidStartdateError(false)
      } else if (name === 'Startdate' && UpdatedthresholdData.Startdate != '') {
        if (dayOfMonth !== 1) {
          setStartdateRequiredError(false)
          setValidStartdateError(true)
        } else {
          setStartdateRequiredError(false)
          setValidStartdateError(false)
        }
      } else {
        setStartdateRequiredError(false)
        setValidStartdateError(false)
      }
      if (name === 'EndDate' && UpdatedthresholdData.EndDate === '') {
        setenddateRequiredError(true)
      } else {
        setenddateRequiredError(false)
      }
      if (name === 'level1' && UpdatedthresholdData.level1.length === 0) {
        setEmailLvl1Required(true)
        setvalidEmailLvl1(false)
      } else if (!Emailregex.test(UpdatedthresholdData.level1.slice(-1)) && UpdatedthresholdData.level1.length != 0) {
        setvalidEmailLvl1(true)
        setEmailLvl1Required(false)
      } else if (Emailregex.test(UpdatedthresholdData.level1.slice(-1)) && UpdatedthresholdData.level1.length == 0) {
        setEmailLvl1Required(true)
        setvalidEmailLvl1(false)
      } else if (
        !Emailregex.test(UpdatedthresholdData.level1.slice(-1)) &&
        UpdatedthresholdData.level1.length != 0
      ) {
        setEmailLvl1Required(false)
        setvalidEmailLvl1(true)
      } else {
        setEmailLvl1Required(false)
        setvalidEmailLvl1(false)
      }
      if (name === 'level2' && UpdatedthresholdData.level2.length === 0) {
        setEmailLvl2Required(true)
        setvalidEmailLvl2(false)
      } else if (!Emailregex.test(UpdatedthresholdData.level2.slice(-1)) && UpdatedthresholdData.level2.length !== 0) {
        setvalidEmailLvl2(true)
        setEmailLvl2Required(false)
      } else if (UpdatedthresholdData.level2.length == 0 && Emailregex.test(UpdatedthresholdData.level2.slice(-1))) {
        setEmailLvl2Required(true)
        setvalidEmailLvl2(false)
      } else {
        setEmailLvl2Required(false)
        setvalidEmailLvl2(false)
      }
      if (name === 'level3' && UpdatedthresholdData.level3.length === 0) {
        setEmailLvl3Required(true)
        setvalidEmailLvl3(false)
      } else if (!Emailregex.test(UpdatedthresholdData.level3.slice(-1)) && UpdatedthresholdData.level3.length !== 0) {
        setvalidEmailLvl3(true)
        setEmailLvl3Required(false)
      } else if (UpdatedthresholdData.level3.length == 0 && Emailregex.test(UpdatedthresholdData.level3.slice(-1))) {
        setEmailLvl3Required(true)
        setvalidEmailLvl3(false)
      } else {
        setEmailLvl3Required(false)
        setvalidEmailLvl3(false)
      }
      if (
        UpdatedthresholdData.Budget_Name != '' && onlyNameRegex.test(UpdatedthresholdData.Budget_Name) &&
        UpdatedthresholdData.Amount != '' &&
        UpdatedthresholdData.Startdate != '' && 
        UpdatedthresholdData.EndDate != '' &&
        UpdatedthresholdData.level1.length != 0 &&
        UpdatedthresholdData.level3.length != 0 &&
        UpdatedthresholdData.level3.length != 0
      ) {
        setButtonEnabled(false)
      } else {
        setButtonEnabled(true)
      }
      return UpdatedthresholdData
    })
  }
  const CreateThreshold = async () => {
    SetSaveLoader(true)
    const newThresholdForm: any = {
      tenant_id: selectScope?.tenantId,
      alertRecipients: [
        {
          level_id: 1,
          mailGroup: thresholdDetails.level1
        },
        {
          level_id: 2,
          mailGroup: thresholdDetails.level1
        },
        {
          level_id: 3,
          mailGroup: thresholdDetails.level1
        }
      ],
      sub_id: selectScope?.subscriptionId,
      secretName: selectScope?.secretName,
      OrganizationId: parseInt(OrgId),
      accountId: selectScope?.accountId,
      Budget_Name: thresholdDetails.Budget_Name,
      TimeGrain: thresholdDetails.TimeGrain,
      Startdate: thresholdDetails.Startdate,
      EndDate: thresholdDetails.EndDate,
      Amount: parseInt(thresholdDetails.Amount),
      currencyType: 'USD',
      alertConditions: amtList,
      webHookUrl: thresholdDetails.webHookUrl
    }
    if (ProviderType === 'Azure' ) {
      const groups = selected?.map((resource: any) => resource.value)
      setResourseGroups(groups)
      newThresholdForm.Resourcegroup = groups
    } else {
      newThresholdForm.Resourcegroup = []
    }
    if ( id.pathname == '/editthreshold' ) {
      newThresholdForm.NotificationId = NotificationId
    } else {
      newThresholdForm.NotificationId = 0
    }
    let tempCount = 0
    newThresholdForm.alertRecipients.forEach((eachLevel: any) => {
      tempCount += eachLevel.mailGroup.length
    })
    setNoOfRecipients(tempCount)
    newThresholdForm.numberOfRecipients = tempCount

    const Resoponse = await saveThreshold(newThresholdForm, ProviderType)
    if (Resoponse?.statusCode == 200) {
      SetSaveLoader(false)
      setSuccesspopUp(true)
    }
    else {
      SetSaveLoader(false)
      setShowNotAddedErrorPopup(true)
    }
  }
  const bindProviders = () => {
    return scopeList?.map((eachScope: any, index: number) => (
      <option
        key={index}
        value={eachScope.connectorName}
        onClick={() => handleScopeChange(eachScope.connectorName)}
      >
        {eachScope.connectorName}
      </option>
    ))
  }

  const getCostAnalysisDetails = async (Payload: any) => {
    setCostAnalysisChartLoader(true)
    setCostAnalysisChartRetry(false)
    let dateRange = 'monthly'
    const costRequest: any = {
      tenantId: Payload.tenantId,
      subscriptionId: Payload.subscriptionId,
      secretName: Payload?.secretName,
      timeframe: 'MonthToDate',
      accountId: [Payload?.accountId]
    }
    const providerResoponse = await getCostAnalysis(
      [costRequest],
      dateRange,
      ProviderType,
      OrgId
    )

    setCostAnalysisChartRetry(false)

    const currencyType = providerResoponse?.data?.map((eachData: any) => eachData.currency).find((eachData: any) => eachData !== undefined)
    setCurrencyType(currencyType)
    setCurrencySymbol(currencyType === 'USD' ? '$' : '₹')
    const data = getCostAnalysisChartData(
      providerResoponse?.data,
      dateRange,
      ProviderType
    )
    SetchartData(data)
    setCostAnalysisChartLoader(false)
  }
  const getResourceGrps = async (Payload: any, organzationId: number) => {
    const dimensionCostRequest: any = {
      secretName: Payload.secretName,
      subscriptionId: Payload.subscriptionId,
      tenantId: Payload.tenantId,
      timeframe: '',
      groupBy: [
        {
          type: 'Dimension',
          name: 'ResourceGroup'
        }
      ]
    }
    const projectRes = await getCostByDimensions(
      [dimensionCostRequest],
      ProviderType,
    )
    if (projectRes?.statusCode == 200) {
      projectRes?.data?.forEach((obj: any, i: any) => {
        selectedOptions.push({
          label: obj.ResourceGroup,
          value: obj.ResourceGroup
        })
      })
      setSelectedOptions(selectedOptions)
    }
  }
  // Handle option selection
  const handleSelect = (selected: any) => {
    setSelected(selected)
  }
  const handlelvl1Delete = (tagName: string) => {
    const newTags = [...thresholdDetails.level1]
    const index = newTags.findIndex(eachTag => eachTag === tagName)
    newTags.splice(index, 1)
    setThresholdDetails({
      ...thresholdDetails,
      level1: newTags
    })
  }
  const beforeValidatelvl1 = (tag: string, existingTags: string[]) => {
    if (existingTags.length < 3) {
      return true
    } else {
      return false
    }
  }
  const beforeValidatelvl2 = (tag: string, existingTags: string[]) => {
    if (existingTags.length < 3) {
      return true
    } else {
      return false
    }
  }
  const beforeValidatelvl3 = (tag: string, existingTags: string[]) => {
    if (existingTags.length < 3) {
      return true
    } else {
      return false
    }
  }


  const handlelvl2Delete = (tagName: string) => {
    const tags = [...thresholdDetails.level2]
    const index = tags.findIndex(eachTag => eachTag === tagName)
    tags.splice(index, 1)
    setThresholdDetails({
      ...thresholdDetails,
      level2: tags
    })
  }
  const handlelvl3Delete = (tagName: string) => {
    const newTags = [...thresholdDetails.level3]
    const index = newTags.findIndex(eachTag => eachTag === tagName)
    newTags.splice(index, 1)
    setThresholdDetails({
      ...thresholdDetails,
      level3: newTags
    })
  }
  function createthresholdChart() {
   
    const Cdata = chartData?.map((eachData: any) => {
      return {
        currency: useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹",
        value: convertCost(parseFloat(roundToDecimalPlaces(eachData.value,2)),eachData.currency === "$" ? "USD" : "INR"),
        xAxis: eachData.xAxis,
      }
    })
    const data = getCostAnalysisChartOptionsForThreshold(Cdata, 'bar')
    return (
      <div id='CostAnalysis-chart'>
        <LineChart
          options={data}
          dynamicChartOptions={markLineOption}
        ></LineChart>
      </div>
    )
  }
  const setThresholdMarkLine = (value: any) => {
    if (!value || isNaN(value)) value = 0
    setMarkLineOption({
      series: [
        {
          markLine: {
            silent: true,
            symbol: [],
            lineStyle: {
              color: 'rgba(240, 6, 6, 1)',
              type: 'dashed'
            },
            data: [
              {
                yAxis: value
              }
            ],
            label: {
              formatter: 'Threshold: {c}',
              position: 'insideEndTop'
            }
          }
        }
      ]
    })
  }
  const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
    []
  )
  const providerSelectComponent = useMemo(
    () => <ProviderSelect {...providerTypeSelectProps} />,
    [providerTypeSelectProps]
  )
  const scopeSelectComponent = useMemo(
    () => <ScopeSelect {...scopeSelectProps} />,
    [scopeSelectProps]
  )
  return (
    <>
      <div>
        <Header />
        <div className='row justify-content-center'>
          <div className='col-md-10'>
            <div className='row justify-content-center pt-3'>
              <div className='d-lg-flex d-md-block justify-content-between align-items-center my-4'>
                <h5 className='page-header mb-sm-3 responsive-margin-bottom'>
                  <a
                    className='me-4'
                    onClick={() => {
                      nav(-1)
                    }}
                  >
                    <img src='images/back-btn.svg' alt='back-btn' />
                  </a>
                  <span className='responsive-display-none'>
                    {isEdit == true ? 'Edit' : 'Create New'}{' '}
                  </span>
                  Threshold
                  <span className='d-lg-none float-end me-3 provider-icon shadow-sm'></span>
                </h5>
                <div className='d-md-flex d-sm-block'>
                  <div className='d-md-flex align-items-center res-margin'>
                    <label className='form-label mb-0 custom-label me-md-2'>
                      {isAWS ? 'Account ID' : 'Scope'}
                    </label>
                    <div className='col-md-auto col-sm-12 me-md-4 responsive-margin-bottom'>
                      <div>
                        <select
                          id='Scope'
                          className='custom-input scope-dropdown cust-scope-dropdown py-2 w-100 px-3 form-select ng-valid ng-touched ng-dirty account-id-width'
                          // value={dropDownValue}
                          onChange={e =>
                            SetdropDownValue({
                              ...dropDownValue,
                              [e.target.id]: e.target.value
                            })
                          }
                          disabled={isEdit}
                        >
                          <option value='All'>
                            {isEdit
                              ? selectScope?.connectorName
                              : isAWS
                                ? 'Select account'
                                : 'Select scope'}
                          </option>
                          {bindProviders()}
                        </select>
                        {/* <span className="text-danger error-span" >Scope is required</span> */}
                      </div>
                    </div>
                  </div>
                  {!isAWS ? (
                    <div className='w-100 '>
                      <MultiSelect
                        className='custom-input-react me-4 scope-dropdown cust-scope-dropdown p-0 w-100 form-select ng-valid ng-touched ng-dirty account-id-width'
                        options={selectedOptions}
                        labelledBy='Select resource group'
                        value={selected}
                        onChange={handleSelect}
                        hasSelectAll={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className='responsive-display-none'>
                    <span
                      className={`d-md-none d-lg-block float-end me-3 provider-icon shadow-sm ${ProviderType === 'AWS' ? 'aws-img' : 'azure-img'
                        }`}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-3 px-2'>
                <div className='monthly-expense-card px-3 py-3'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h5 className='table-inner-header'>
                        Monthly Expense Overview{' '}
                      </h5>
                    </div>
                    <div>
                      <label className=' legend-text me-2'>
                        <span className='legend-sty legend-red me-2'></span>
                        Threshold
                      </label>
                      <label className=' legend-text'>
                        <span className='legend-sty legend-blue me-2'></span>
                        Actual Cost
                      </label>
                    </div>
                  </div>
                  <div className='cust-chart-hw'>
                    {/* {costAnalysisChartLoader && (
                      <div className='spinnig-center d-flex justify-content-center pt-5'>
                        <div className='spinner-border' role='status'>
                          <span
                            className='loader'
                            id='costAnalyisLoader'
                          ></span>
                        </div>
                      </div>
                    )} */}

                    {chartData && (dropDownValue.Scope != 'All' || isEdit) ? (
                      createthresholdChart()
                    ) : (
                      <div className=' d-flex justify-content-center padding-top'>
                      <span className='color-scope'>Select the scope!</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-12 p-0'>
                  <div className='d-flex mb-3 align-items-center'>
                  <h3 className='sub-header mb-0 px-2 float-start'>
                    Threshold Details & Amount
                  </h3>
                  <label className="alert alert-warning font-semibold ms-3 border-0 p-1 mb-0 px-lg-2 warning-msg">
                    User will be able to edit only Level 2 & Level 3 Email ID
                    and Threshold amount
                  </label>
                  </div>
                </div>
                <div className='col-md-4 mb-3'>
                  <label className='w-100 form-label custom-label'>
                    Alert Name <span className='color-warning-alert'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    value={thresholdDetails?.Budget_Name}
                    type='text'
                    onChange={e =>
                      handleThresholddetails(e.target.name, e.target.value)
                    }
                    placeholder='Enter Alert Name'
                    name='Budget_Name'
                    maxLength={33}
                    disabled={isEdit}
                    onBlur={() =>
                      setBudgetRequiredError(
                        thresholdDetails.Budget_Name === '' ? true : false
                      )
                    }
                  />
                  {BudgetRequiredError && (
                    <span className='text-danger error-span'>
                      Alert Name is required
                    </span>
                  )}
                  {validBudgetError && (
                    <span className='text-danger error-span'>
                      Enter Valid Alert Name
                    </span>
                  )}
                  {thresholdDetails?.Budget_Name?.length > 32 ?
                    <span className='text-danger error-span'>
                      Alert name should not be more than 32 characters
                    </span> : <></>}
                </div>
                <div className='col-md-4 mb-3'>
                  <label className='w-100 form-label custom-label'>
                    Reset Period <span className='color-warning-alert'>*</span>
                  </label>
                  <select
                    className='custom-input py-2 px-3 form-select py-2 w-100 px-3'
                    name='TimeGrain'
                    value={thresholdDetails?.TimeGrain}
                    disabled={isEdit}
                    onChange={e =>
                      handleThresholddetails(e.target.name, e.target.value)
                    }
                  >
                    <option value='Annually'>Annually</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Quarterly'>Quarterly</option>
                  </select>
                  {TimeGrainRequiredError && (
                    <span className='text-danger error-span'>
                      Timegrain is required
                    </span>
                  )}
                </div>
                <div className='col-md-4 mb-3'>
                  <label className='w-100 form-label custom-label'>
                    Threshold Amount{' '}
                    <span className='color-warning-alert'>*</span>
                  </label>
                  <div className='input-group'>
                    <span className='input-group-text btn-custom-dollar'>
                      $
                    </span>
                    <input
                      type='text'
                      className='custom-input form-control px-2 py-1'
                      aria-label='Dollar amount (with dot and two decimal places)'
                      name='Amount'
                      placeholder={'Enter Threshold Amount'}
                      value={thresholdDetails?.Amount}
                      onChange={(e: any) => {
                        if (!NumRegex.test(e.target.value)) {
                          e.target.value = ''
                        }
                        setThresholdDetails({
                          ...thresholdDetails,
                          Amount: e.target.value
                        })
                        setThAmount(e.target.value)
                        setThresholdMarkLine(e.target.value)
                      }}
                      onBlur={() =>
                        setAmountRequiredError(
                          thresholdDetails.Amount === '' ? true : false
                        )
                      }
                    />
                    {AmountRequiredError && (
                      <span className='text-danger d-block w-100 error-span'>
                        Threshold amount is required
                      </span>
                    )}
                  </div>
                </div>
                <div className='col-md-4 mb-3 position-relative'>
                  <label className='w-100 form-label custom-label'>
                    Start Date <span className='color-warning-alert'>*</span>
                  </label>

                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='date'
                    name='Startdate'
                    value={thresholdDetails?.Startdate}
                    max={new Date().toISOString().split('T')[0]}
                    min={(new Date(new Date().setMonth(new Date().getMonth() - 12))).toISOString().split('T')[0]}
                    onBlur={() =>
                      setStartdateRequiredError(
                        thresholdDetails.Startdate === '' ? true : false
                      )
                    }
                    onChange={e =>
                      handleThresholddetails(e.target.name, e.target.value)
                    }
                    disabled={isEdit}
                  />

                  {StartdateRequiredError && (
                    <span className='text-danger error-span'>
                      Start Date is required
                    </span>
                  )}
                  {validStartdateError && (
                    <span className='text-danger error-span'>
                      Start date should be the first day of the month
                    </span>
                  )}
                </div>

                <div className='col-md-4 mb-3 position-relative'>
                  <label className='w-100 form-label custom-label'>
                    Expiration Date{' '}
                    <span className='color-warning-alert'>*</span>
                  </label>

                  <input
                    className='form-control custom-input py-2 custom-date-picker'
                    placeholder='mm/dd/yy'
                    type='date'
                    value={thresholdDetails?.EndDate}
                    name='EndDate'
                    disabled={isEdit}
                    onChange={e =>
                      handleThresholddetails(e.target.name, e.target.value)
                    }
                    onBlur={() =>
                      setenddateRequiredError(
                        thresholdDetails.EndDate === '' ? true : false
                      )
                    }
                    max={(new Date(new Date().setMonth(new Date().getMonth() + 12))).toISOString().split('T')[0]}
                    min={(new Date(new Date().setMonth(new Date().getMonth() - 12))).toISOString().split('T')[0]}
                  />

                  {enddateRequiredError && (
                    <span className='text-danger error-span'>
                      End Date is required
                    </span>
                  )}
                </div>
                {/* Threshold Details & Amount Section Ends here */}

                {/* Recipient Email ID starts here */}
                <div className='row mb-3'>
                  <div className='col-md-12 p-0'>
                    <h3 className='sub-header mb-3 px-2'>
                      Recipient Email ID{' '}
                      <span
                        data-bs-toggle='tooltip'
                        data-bs-placement='right'
                        title=''
                        data-bs-original-title='Enter the recipients Email ID`s for level escalations'
                      >
                        <img
                          className='ms-1'
                          src='images/information-icon.svg'
                          alt='info'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                        />
                      </span>
                    </h3>
                  </div>
                  <div className='col-lg-4 col-md-12 mb-3'>
                    <label className='w-100 form-label custom-label'>
                      Level 1 Email ID{' '}
                      <span className='color-warning-alert'>*</span>
                    </label>
                    <TagsInput
                      value={isEdit ? email1tags : thresholdDetails?.level1}
                      onChange={(e: any) => {
                        handleThresholddetails('level1', e)
                      }}
                      name='level1'
                      onRemoved={handlelvl1Delete}
                      placeHolder='name@company.com'
                      disabled={isEdit}
                      beforeAddValidate={e => beforeValidatelvl1(e, thresholdDetails?.level1)}
                      classNames={{
                        tag: '',
                        input: 'w-100 custom-input form-control py-2 px-2'
                      }}
                      onBlur={() =>
                        setEmailLvl1Required(
                          thresholdDetails.level1.length === 0 ? true : false
                        )
                      }
                    />
                    {emailLvl1Required && (
                      <span className='text-danger error-span'>
                        Level 1 Email ID is required
                      </span>
                    )}
                    {validemailLvl1 && (
                      <span className='text-danger error-span'>
                        Enter a valid Business Email ID
                      </span>
                    )}
                  </div>

                  <div className='col-lg-4 col-md-12 mb-3'>
                    <label className='w-100 form-label custom-label'>
                      Level 2 Email ID{' '}
                      <span className='color-warning-alert'>*</span>
                    </label>
                    <TagsInput
                      value={isEdit ? email2tags : thresholdDetails?.level2}
                      name='level2'
                      onChange={e => handleThresholddetails('level2', e)}
                      onRemoved={handlelvl2Delete}
                      beforeAddValidate={e => beforeValidatelvl2(e, thresholdDetails?.level2)}
                      placeHolder='name@company.com'

                      classNames={{
                        tag: '',
                        input: 'w-100 custom-input form-control py-2 px-2'
                      }}
                      onBlur={() =>
                        setEmailLvl2Required(
                          thresholdDetails.level2.length === 0 ? true : false
                        )
                      }
                    />

                    {emailLvl2Required && (
                      <span className='text-danger error-span'>
                        Level 2 Email ID is required
                      </span>
                    )}
                    {validemailLvl2 && (
                      <span className='text-danger error-span'>
                        Enter a valid Business Email ID
                      </span>
                    )}
                  </div>

                  <div className='col-lg-4 col-md-12 mb-3'>
                    <label className='w-100 form-label custom-label'>
                      Level 3 Email ID{' '}
                      <span className='color-warning-alert'>*</span>
                    </label>
                    <TagsInput
                      value={isEdit ? email3tags : thresholdDetails?.level3}
                      onChange={e => handleThresholddetails('level3', e)}
                      name='level3'
                      onRemoved={handlelvl3Delete}
                      placeHolder='name@company.com'
                      beforeAddValidate={e => beforeValidatelvl3(e, thresholdDetails?.level3)}
                      // disabled={isEdit}
                      onBlur={() =>
                        setEmailLvl3Required(
                          thresholdDetails.level3.length === 0 ? true : false
                        )
                      }
                      classNames={{
                        tag: '',
                        input: 'w-100 custom-input form-control py-2 px-2'
                      }}
                    />
                    {emailLvl3Required && (
                      <span className='text-danger error-span'>
                        Level 3 Email ID is required
                      </span>
                    )}
                    {validemailLvl3 && (
                      <span className='text-danger error-span'>
                        Enter a valid Business Email ID
                      </span>
                    )}
                  </div>
                </div>
                {/* --Recipient Email ID Ends here-- */}
                {/* --Alert Conditions Starts here-- */}
                <div className='row mb-3'>
                  <div className='col-md-12 p-0'>
                    <h3 className='sub-header mb-2 px-2'>
                      Alert Conditions{' '}
                      <span
                        data-bs-toggle='tooltip'
                        data-bs-placement='right'
                        title=''
                        data-bs-original-title='Please Provide the threshold percentage and amount'
                      >
                        <img
                          className='ms-1'
                          src='images/information-icon.svg'
                          alt='info'
                        />
                      </span>
                    </h3>

                    <table className='table table-borderless mb-5'>
                      <tbody>
                        {amtList?.map((each: any, index: any) => (
                          <tr key={index}>
                            <td className='py-1 col-md-4'>
                              <div className='w-100'>
                                <label className='w-100 form-label custom-label'>
                                  Amount in %<span className='text-danger error-span'>*</span>
                                </label>
                                <input
                                  className='w-100 custom-input form-control py-2 px-2'
                                  placeholder='Enter Amount In Percentage'
                                  type='text'
                                  id='Amount%'
                                  disabled={isEdit && isAWS}
                                  value={each}
                                  onChange={e =>
                                    getAmount(parseFloat(e.target.value), index)
                                  }
                                  maxLength={3}
                                  onBlur={(e:any) =>{
                                    if(e.target.value===''){
                                      setIsPerctEmpty(true)
                                  }else{
                                    setIsPerctEmpty(false)
                                  }}
                                  }
                                />
                                <span className=' text-danger error-span'>
                                  {formErrors}
                                </span>
                                {isDuplicate && index === tempIndex && (
                                  <span className='text-danger error-span'>
                                    *Amount % already exists
                                  </span>
                                )}
                                {isPerctEmpty && index === tempPerctIndex && (
                                  <span className='text-danger error-span'>
                                    Amount % is  required
                                  </span>
                                )}
                                {isfillOutPrevAlert && index === 0 && (
                                  <span className='text-danger error-span'>
                                    Fill out the alert first.
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className='py-1 px-3 '>
                              <div className='w-100'>
                                <label className='w-100 form-label custom-label'>
                                  Amount
                                  <span className='text-danger error-span'>*</span>
                                </label>
                                <div className='input-group'>
                                  <span className='input-group-text btn-custom-dollar'>
                                    $
                                  </span>
                                  <input
                                    type='text'
                                    className='custom-input form-control px-2 py-1'
                                    aria-label='Dollar amount (with dot and two decimal places)'
                                    id='Amount'
                                    placeholder='Enter  Amount in Dollar'
                                    value={thAmount && ((each * thAmount) / 100).toString()}
                                    disabled={isEdit && isAWS}
                                    onChange={event =>
                                      getPercent(parseFloat(event.target.value), index)
                                    }
                                  />
                                </div>
                                {/* <span className=' text-danger error-span'>
                                  {formErrors.finalAmount}
                                </span> */}
                              </div>
                            </td>
                            <td className='text-center py-1 px-3'>
                              <label className='w-100 custom-label'>
                                {index == 0 && 'Action'}
                              </label>
                              <div className='mt-2'>
                                <a className='w-100' >
                                  {index == 0 ? (
                                    <span
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title=''
                                      data-bs-original-title='Add'
                                      className='me-3 add-img'
                                      onClick={() => addAlertConditions(index)}
                                    ></span>
                                  ) : (
                                    <>
                                      <a
                                        data-bs-toggle='modal'
                                        data-bs-target='#delete'
                                        onClick={() => getIndexNumber(index)}
                                      >
                                        <span
                                          data-bs-toggle='tooltip'
                                          data-bs-placement='top'
                                          title='Delete'
                                          className='delete-img'
                                          id='deleteTooltip'
                                        ></span>
                                      </a>
                                    </>
                                  )}
                                </a>
                              </div>
                            </td>
                            {index == 0 && (
                              <td className='py-1 px-3 col-md-4 justify-content-end'>
                                <div className='w-100'>
                                  <label className='w-100 form-label custom-label'>
                                    Teams Integration{' '}
                                    <span
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='right'
                                      title=''
                                      data-bs-original-title='Please Provide Your Webhook Url'
                                    >
                                      <img
                                        className='ms-1'
                                        src='images/information-icon.svg'
                                        alt='info'
                                      />
                                    </span>
                                  </label>
                                  <input
                                    placeholder='Enter your webhook url'
                                    className='w-100 custom-input form-control py-2 px-2'
                                    value={thresholdDetails?.webHookUrl}
                                    name='webHookUrl'
                                    onChange={e =>
                                      handleThresholddetails(
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                  {/* <span className='text-danger error-span'>
                                    {formErrors.webHookUrl}
                                  </span> */}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className='d-flex justify-content-end mb-5 pb-5 mt-2'>
                    <button
                      type='button'
                      className='btn secondary-btn '
                      onClick={() => {
                        nav(-1)
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      disabled={isEdit == false ? ButtonEnabled : false}
                      className='btn primary-btn ms-3'
                      onClick={() => CreateThreshold()}
                    >
                      {isEdit == true ? 'Update Details' : 'Save Details'}
                      {SaveLoader ? (
                        <span className='spinner-border spinner-border-sm mr-1'></span>
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                </div>
                {/* --Alert Conditions Ends here- */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='delete'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        // tabindex="-1"
        aria-hidden='true'
      >
        <div className='modal-dialog custom-width-providerpopup'>
          <div className='modal-content popup-sty border-0 custom-border-radius'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-1'>
                <img
                  className='mt-1'
                  src='images/Delete-Confirmation.svg'
                  alt='delete'
                />
                <label className='form-label custom-bold my-4 w-100'>
                  Delete Confirmation
                </label>
                <label className='form-label custom-pop-label mb-1'>
                  Are you sure you want to delete the alert condition?
                </label>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-center pb-4'>
              <button
                type='button'
                className='btn secondary-btn me-3'
                data-bs-dismiss='modal'
                onClick={() => deleteAlert()}
              >
                Yes
              </button>
              <button
                type='button'
                className='btn color-warning-btn'
                data-bs-dismiss='modal'
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal show fade ${SuccesspopUp ? 'show-popup' : ''}`}
        tabIndex={-1}
        role='dialog'
      >
        <div
          className='modal-dialog custom-width-providerpopup custom-modal-theme'
          role='document'
        >
          <div className='modal-content custom-popup-height'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => setSuccesspopUp(false)}
              />
            </div>
            <div className='modal-body'>
              <div className='text-center mb-1'>
                <img className='mt-1' src='images/Success.svg' alt='Success' />
                {isEdit ? (
                  <label className='form-label custom-pop-label mt-4 mb-0 w-100'>
                    Threshold has been updated succesfully!
                  </label>
                ) : (
                  <label className='form-label custom-pop-label mt-4 mb-0 w-100'>
                    Threshold has been set succesfully!
                  </label>
                )}
                <label className='form-label custom-pop-label'>
                  Recipients will notified through email, once the threshold
                  limits have been reached.
                </label>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-center pb-4'>
              <button
                type='button'
                className='btn primary-btn'
                data-bs-dismiss='modal'
                onClick={() => {
                  nav(`/threshold?organizationId=${OrgId}`)
                }}
              >
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>
      <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showNotAddedErrorPopup ? 'show-popup' : ''}`}>
        <div role="document" className="custom-modal-theme modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close"
                onClick={() => { setShowNotAddedErrorPopup(false) }}></button>
            </div>
            <div className="modal-body">
              <div className="text-center mb-1">
                <img alt="Error" src="images/Error.svg" />
                <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                  Oops! Looks like there's an error.
                  {/* {`Failed to connect ${providerType === "AWS" ? awsProviderDetails.connectorName : azureProviderDetails.connectorName}. Please try again later.Error: validation failed`} */}
                </label>
                <label className="form-label custom-pop-label mb-0 w-100">Please check the details and try again</label>
              </div>
            </div>
            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
              <button type="button" className="btn primary-btn" onClick={() => { setShowNotAddedErrorPopup(false) }}>
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {pageLoader && (
        <div className='container-fluid'>
          <div
            className='modal-backdrop fade show'
            id='AddOrganization-popup'
            data-bs-keyboard='false'
            tabIndex={-1}
            aria-hidden='true'
          >
            <div className='position-absolute top-50 start-50 translate-middle'>
              <img
                src='images/cloud-load-gif.gif'
                alt='white-loader'
                className='page-loader'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
