import { SET_PROFILE_IMAGE, SET_ROLE } from "./action"

const profileImage: string =  ""
const role: string =  ""

export const profileImageReducer = (state = profileImage, action: any) => {
    switch (action?.type) {
        case SET_PROFILE_IMAGE:
            return {
                profileImage: action.payload
            }    
        default:
            return state;
    } 
}

export const roleReducer = (state = role, action: any) => {
    switch (action?.type) {
        case SET_ROLE:
            return {
                role: action.payload
            }    
        default:
            return state;
    } 
}
