import { client } from "../client/Client";

export const fetchAllCostAnomaly = async (providerId: number, organizationId : number, costAnomaliesPayload:any) => {

    // PS_CA_016
    let result = await client(`/infra/organization/${organizationId}/anomalies?providerId=${providerId}`, costAnomaliesPayload, "POST");

    return result;
}

