import { client } from '../client/Client'

export async function userLogin(loginDetails: any) {
    let credentials = JSON.stringify({
        email: loginDetails.email,
        password: loginDetails.password,
    })
    let response = await client("/authorize/user/signin", credentials, "POST");
    return response;
}

export async function checkUser(email:string){
    let response = await client(`/authorize/user/exists/${email}`, "", "GET");
    return response;
}

export async function forgetPassword(email: string) {
    // PS_FP_23, 24
    let response = await client(`/authorize/user/forgotpassword/${email}`, "", "GET");
    return response;
}

export async function resetPassword(resetCode: string, password: string, email: string) {
    // PS_RP_17
    let resetPayload = {
        resetCode,
        password,
        email
    };

    let response = await client('/authorize/user/resetpassword', resetPayload, 'PUT');
    // PS_RP_18
    return response;
}
