import axios from "axios";
import { useEncryptStorage } from "../hooks/useEncryptStorage";
import { key } from "../hooks/useEncryptStorage";

export async function client(url: string, data: any, method: string) {
    try {

        let appUrl = `${key.API_URL + url}`;

        let headers: any = ''

        if (data instanceof FormData) {
            headers = {
                "Content-Type": "multipart/form-data"
            }
        } else {
            headers = {
                "Content-Type": "application/json"
            };
        }

        if (!appUrl.includes('/authorize/user/signin')) {
            const token = useEncryptStorage.getItem("token");
            headers.Authorization = `Bearer ${token}`;
        }

        const response = await axios({
            method: method,
            url: appUrl,
            data: data,
            headers: headers,
        });

        return {
            data: response?.data,
            statusCode: response?.status
        };
    } catch (error: any) {
        return {
            statusCode: error?.response?.status,
        };
    }
}
