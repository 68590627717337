import { client } from "../client/Client";
import { CurrencyType } from "../enum/CurrencyType";
import { IExchangeRate } from "../interface/organization";

export async function getExchangeRate() {
    const response = await client(`/cost/exchangerates`, "", "GET")

    return response
}

export const convertCostData = (cost: number, sourceCurrency: string | number, destinationCurrency: any, exchangeRate: IExchangeRate) => {

    if (!exchangeRate || !sourceCurrency || !destinationCurrency || sourceCurrency === destinationCurrency) {
        return {
            cost,
            currencyType: sourceCurrency,
        };
    }

    if (sourceCurrency === CurrencyType.USD) {
        return {
            cost: exchangeRate.rates[destinationCurrency] * cost,
            currencyType: destinationCurrency,
        };
    }

    if (destinationCurrency === CurrencyType.USD) {
        return {
            cost: cost / exchangeRate.rates[sourceCurrency],
            currencyType: destinationCurrency,
        };
    }

    return { cost, currencyType: sourceCurrency };
};

export const getCurrencySymbol = (currencyCode: string) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
}).formatToParts()[0].value;