import { GridStackWidget } from 'gridstack';

interface IDashboardData {
    chartDimension: string;
    chartType: string;
    title: string;
    layout: GridStackWidget
}

interface IDefaultDashboard {
    defaultDashboardId: string;
    organizationId: number;
    azure: IDashboardData[];
    aws: IDashboardData[];
}

export const defaultDashBoardObj: IDefaultDashboard = {
    defaultDashboardId: "",
    organizationId: 0,
    azure: [],
    aws: [],
}

interface IMonthlyExpense {
    pastMonth: number;
    currentMonth: number;
    difference: number;
    currency: string;
}

export const monthlyExpenseObj: IMonthlyExpense = {
    pastMonth: 0,
    currentMonth: 0,
    difference: 0,
    currency: "",
}

interface IForecastedCost {
    forecast: number;
    currency: string;
}

export const forecastedCostObj: IForecastedCost = {
    forecast: 0,
    currency: "",
}

interface IThreshold {
    name: string;
    timeGrain: string;
    starttime: string;
    endtime: string;
    amount: number;
    currentSpend: number;
    currencyType: string;
}

export const thresholdObj: IThreshold = {
    name: "",
    timeGrain: "",
    starttime: "",
    endtime: "",
    amount: 0,
    currentSpend: 0,
    currencyType: "",
}

interface ICostCurrency {
    cost: number;
    currencyType: string;
}

export interface IExchangeRate {
    base: string;
    rates: any;
}

export const exchangeRateObj ={
    base: "",
    rates: {}
}

interface IDashboard {
    dashboardId? : string;
    organizationId: number;
    data?: IDashboardData[];
    name: string;
    isPrivate: boolean;
    isDefault?: boolean;
    providerType: string;
    previewUrl: ArrayBuffer;
    createdOn?: string;
    modifiedOn?: string;
}

interface ICostRequest {
    subscriptionId?: string;
    tenantId?: string;
    secretName: string;
    accountId?: string[] | string;
    timeframe: string;
    fromDate?: string;
    toDate?: string;
}

interface IOrganizationSelectRequest {
    requestedOrganizationId?: number,
    applyRole?: boolean,
    showAll?: boolean,
    disable?: boolean,
    skipFirst?: boolean
}

interface IProvider {
    providerId?: number;
    organizationId: number;
    providerType?: string;
    provider?: string
    connectorName: string;
    tenantId?: string;
    subscriptionId?: string;
    secretId: number;
    secretName: string;
    accountId?: string;
    isLinkedAccount?: boolean;
    description?: string
    isActive?: number
}

interface ILinkedAccount {
    accountId: string;
    accountName: string;
}

interface IRequestPayload {
    secretName: string;
    tenantId?: string;
    subscriptionId?: string;
    accountId?: string;
}

interface IDimensionCostRequest extends ICostRequest {
    groupBy?: any[]
}

interface IChartData {
    name: string;
    value: number;
    currencySymbol: string;
}
interface ICreateChartResponse {
    id?: string;
    chartDimension: string;
    chartType: string;
    title: string;
    chartData?: IChartData[]
}

interface IDimension {
    dimensionName: string;
    dimensionKey: string;
    title: string;
    chartTypes: string[]
}

interface ICreateChartRequest {
    dimensionsToSkip?: string[];
    id?: string;
    chartDimension?: string;
    chartType?: string;
    providerType: string;
    costRequest: ICostRequest[];
    organizationId: number;
}

export type {
    IDefaultDashboard,
    IMonthlyExpense,
    IForecastedCost,
    IThreshold,
    ICostCurrency,
    IDashboardData,
    IDashboard,
    ICostRequest,
    IOrganizationSelectRequest,
    IProvider,
    ILinkedAccount,
    IRequestPayload, 
    IDimensionCostRequest,
    IChartData,
    ICreateChartResponse,
    IDimension,
    ICreateChartRequest,
}
