import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { profileImageReducer, roleReducer} from "./userProfile/reducer";
import { isEnabledReducer} from "./dashboardList/reducer";

const rootReducer = combineReducers({
    profileImage: profileImageReducer,
    role: roleReducer,
    dashboardList: isEnabledReducer
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;