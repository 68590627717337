// PS_PV_25
export const padWithLeadingZeros = (number: number, totalLength: number)  => {
    // PS_PV_26, PS_PV_27
    if (!number) return '0';
    // PS_PV_28
    return String(number).padStart(totalLength, '0');
}

// PS_PV_23
export const padWithALeadingZero = (number: number)  => {
    // PS_PV_24
    return padWithLeadingZeros(number, 2)
}