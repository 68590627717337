import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react"
import { ProviderDetail } from "../../interface/organization";
import { getProviderDetails } from "../../service/managementService";

export type ScopeSelectProp = {
    showAll?: boolean,
    hideLabel?: boolean,
    organizationId: number,
    providerType: string,
    disable?: boolean,
    onScopeChange: (scope: ProviderDetail | ProviderDetail[]) => void
    onDashboardScopeChange?: (scope: ProviderDetail | ProviderDetail[]) => void
}

export const ScopeSelect = (props: ScopeSelectProp) => {

    const [scopeList, setScopeList] = useState<ProviderDetail[]>([]);
    const [selectedScope, setSelectedScope] = useState<number | string>(0);
    const [viewLink, setViewLink] = useState<string>(window.location.pathname)

    useEffect(() => {
        setViewLink(window.location.pathname)
        if (props?.organizationId && props?.providerType) {
            getAllProviders();
        }
    }, [props]);

    const getAllProviders = async () => {
        const providerPayload = {
            page: 1000,
            searchText: '',
            sortBy: '',
            orderBy: '',
            providerType: props.providerType,
        };

        const providerResoponse = await getProviderDetails(props.organizationId, providerPayload);

        setScopeList(providerResoponse.data.providerdetails)
        if (props?.showAll) {
            setSelectedScope('All');
            props.onScopeChange(providerResoponse.data.providerdetails);
            return;
        }
        setSelectedScope(providerResoponse.data.providerdetails[0].providerId);
        props.onScopeChange(providerResoponse.data.providerdetails[0])
    }

    const handleScopeChange = (e: ChangeEvent<HTMLSelectElement>) => {

        if(viewLink !== '/dashboard') {
            if (e.target.value === 'All') {
                setSelectedScope('All')
                props.onScopeChange(scopeList);
                return
            }
            let scopeFound = scopeList.find(each => each.providerId === +e.target.value)
            setSelectedScope(e.target.value)
            if (scopeFound) {
                props.onScopeChange(scopeFound);
            }
        }
        else {
            if (props.onDashboardScopeChange) {
                if (e.target.value === 'All') {
                    setSelectedScope('All')
                    props?.onDashboardScopeChange(scopeList);
                    return
                }
                let scopeFound = scopeList.find(each => each.providerId === +e.target.value)
                setSelectedScope(e.target.value)
                if (scopeFound) {
                    props?.onDashboardScopeChange(scopeFound);
                }
            }
        }

    }

    const bindProviders = () => {
        return scopeList.map((eachScope: any, index: number) => (
            <option key={index} value={eachScope.providerId}>{eachScope.connectorName}</option>
        ))
    }

    return (
        <div className={`d-lg-flex align-items-center res-margin-20 ${!props?.hideLabel} && ms-lg-2`}>
            {!props.hideLabel && <div className="w-auto">
                <label className="form-label mb-lg-0 custom-label me-2 text-nowrap" htmlFor="Scope">
                    {props.providerType === 'Azure' ? 'Scope' : 'Account ID'}
                </label>
            </div>
            }
            <div className="w-100 col-md-12">
                <select id="Scope" className="custom-input scope-dropdown cust-scope-dropdown py-2 w-100 px-3 form-select ng-valid ng-touched ng-dirty account-id-width"
                    disabled={props.disable}
                    value={selectedScope}
                    onChange={handleScopeChange}
                >
                    {props.showAll && <option value="All">All</option>}
                    {bindProviders()}
                </select>
            </div>
        </div >
    )
};
