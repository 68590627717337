interface IOrganizationDataState {
    organizationData: IOrganizationData[]
}

interface IExchangeRate {
    base: string;
    rates: { [key: string]: number };
}

interface INotification {
    errorId: number | null;
    message: string;
}

interface ProviderDetail {
    providerId: number;
    organizationId: number;
    providerType: string;
    connectorName: string;
    tenantId: string;
    subscriptionId: string;
    secretId: number;
    secretName: string;
    accountId: string;
    description: string;
    isLinkedAccount: boolean;
    isActive: boolean;
}

interface IOrganizationData {
    logoUrl: string,
    organizationId: number,
    organizationName: string,
    parentOrganizationId: number,
    providerType: string[],
    roleId: number,
    roleName: string,
}

export const defaultOrganization: IOrganizationData = {
    organizationId: 0,
    parentOrganizationId: 0,
    logoUrl: "",
    organizationName: "",
    providerType: [],
    roleId: 0,
    roleName: "",
}

interface IOrganizationDataState {
    organizationData: IOrganizationData[]
}

interface IExchangeRate {
    base: string;
    rates: { [key: string]: number };
}

interface IProviderTypeDetails {
    providerCount: number,
    providerDetails: ProviderDetail[]
} 

interface IRequestPayload {
    secretName: string;
    tenantId?: string;
    subscriptionId?: string;
    accountId?: string;
}

interface IAdvisorSummaryPayload extends IRequestPayload {
    checkIds?: string[]
}
interface IOrganizationDataState {
    organizationData: IOrganizationData[]
}

interface IExchangeRate {
    base: string;
    rates: { [key: string]: number };
}

interface INotification {
    errorId: number | null;
    message: string;
}

interface ProviderDetail {
    providerId: number;
    organizationId: number;
    providerType: string;
    connectorName: string;
    tenantId: string;
    subscriptionId: string;
    secretId: number;
    secretName: string;
    accountId: string;
    description: string;
    isLinkedAccount: boolean;
    isActive: boolean;
}

interface IProviderTypeDetails {
    providerCount: number,
    providerDetails: ProviderDetail[]
}

export type { IOrganizationData, IOrganizationDataState, IExchangeRate, INotification, IProviderTypeDetails, ProviderDetail, IAdvisorSummaryPayload }
