import '../../assets/scss/_dashboardcomponent.scss'
import '../../assets/scss/_popup-style.scss'
import 'gridstack/dist/gridstack.css';
import Header from "../Header/Header"
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { IAdvisorSummaryPayload, IExchangeRate, IOrganizationData, ProviderDetail } from "../../interface/organization";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { GridStack, GridStackWidget } from 'gridstack';
import { IChartData, ICostCurrency, ICostRequest, ICreateChartResponse, IDashboardData, IDefaultDashboard, IDimension, IDimensionCostRequest, ILinkedAccount, IOrganizationSelectRequest, IProvider, IRequestPayload } from '../../interface/dashboard';
import { ProviderType } from '../../enum/providerTypeEnum';
import { getCostAnalysis, getCostByDimension, getCostByTags, getDashboardById, getDefaultDashboard, getDimensionList, getForecastedCost, getMonthlyExpenses, saveCustomDashboardS, saveDashboardData, setDefaultDashboard } from '../../service/dashboardService';
import { StaticDashboardLayout } from '../../constants/StaticDashboardLayout';
import { getAllThresholds } from '../../service/thresholdService';
import { useEncryptStorage } from '../../hooks/useEncryptStorage';
import { convertCostData, getCurrencySymbol, getExchangeRate } from '../../service/currencyService';
import { getAwsAdvisorCheckSummaries, getAwsAdvisorChecks, getAzureRecommendations } from '../../service/environmentService';
import { BaseConstant } from '../../constants/BaseConstant';
import ChartService from './service/chartService';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';
import DashBoardList from './DashBoardList';
import html2canvas from 'html2canvas';
import { CostExpensesLayout } from '../../constants/CostExpensesLayout';
import jsPDF from 'jspdf';
import { fetchSubTagsList, fetchTagList } from '../../service/managementService';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setIsDashboardListToggled } from '../../redux/dashboardList/action';
import { getLinkedAccounts } from '../../service/providerService';
import {Select} from 'antd'
var XLSX = require("xlsx");

type DimensionElement = {
    type: string;
    name: string;
};

type IAdvanceFilter = {
    timeFrame: string,
    fromDate: string,
    toDate: string,
}

// Define a type for the result array
type DimensionGroup = DimensionElement[][];

export default function DashBoard() {

    const addChartDiv = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const costChart = useRef<HTMLDivElement>(null)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let organizationObj: IOrganizationData = {
        logoUrl: '',
        organizationId: 0,
        organizationName: '',
        parentOrganizationId: 0,
        providerType: [],
        roleId: 0,
        roleName: ''
    }

    let defaultObj: IDefaultDashboard = {
        defaultDashboardId: '',
        organizationId: 0,
        azure: [],
        aws: []
    }

    //Assigned grid Stack value to grid variable to access gridStack funcationalities
    let grid = GridStack.init();

    //start of the states to store and manipulate gloal dropdown value changes
    let selectedOrganizationData: IOrganizationData;
    const [selectedOrganizationDataState, setSelectedOrganizationDataState] = useState<IOrganizationData>(organizationObj)
    let providerType: string;
    const [providerTypeState, setProviderTypeState] = useState<string>("")
    let selectedProvider: any;
    const [selectedProviderTypeData, setSelectedProviderTypeData] = useState<any>()
    const [filterValues, setFilterValues] = useState<any>([])

    //creating object to access fucntion from class
    const serviceInstance = new ChartService()

    //assigning functionalities to variables
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    //start of the states to store and manipulate dasboard Data
    const [action, setAction] = useState<any>(queryParams || '')
    const [unsaved, setUnsaved] = useState<boolean>(false)
    const [saveLoader, setSaveLoader] = useState<boolean>(false)
    const [pageLoader, setPageLoader] = useState<boolean>(true)
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
    const [showAddChartPopup, setShowAddChartPopup] = useState<boolean>(false)
    const [deleteMessageValue, setDeleteMessageValue] = useState<string>("")
    const [organizationSelectRequest, setOrganizationSelectRequest] = useState<IOrganizationSelectRequest>({})
    let dashboard: any;
    const [dashboardState, setDashboardState] = useState<any>()
    const role = useSelector((state: any) => state.role.role)
    const [showDashboardList, setShowDashboardList] = useState<boolean>(false)

    let defaultDashboard: IDefaultDashboard;
    const [defaultDashboardStore, setDefaultDashboardStore] = useState<IDefaultDashboard>(defaultObj)
    const [savedCustomizeButton, setSavedCustomizeButton] = useState<string>("")
    const [showSaveDashBoardPopup, setshowSaveDashBoardPopup] = useState<boolean>(false)
    const [saveDashBoardName, setSaveDashBoardName] = useState<string>("")
    const [triggerUseEffect, setTriggerUseEffect] = useState<boolean>(false)
    const [pdfDownloadLoader, setPdfDownloadLoader] = useState<boolean>(false)
    const [addDashboardData, setAddDashboardData] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<string>("")
    const [tagList, setTagList] = useState<any>([])
    const [azureTagList, setAzureTagList] = useState<any>([])
    const [subTagList, setSubTagList] = useState<any>([])
    const [azureSubTagList, setAzureSubTagList] = useState<any>([])
    const [selectedTags, setSelectedTags] = useState<any>([])
    const [consolidatedAzureTags, setConsolidatedAzureTags] = useState<any>([])
    const [selectedTagIndex, setSelectedTagIndex] = useState<number | "">("")
    const [addEditLayout, setAddEitLayout] = useState<any>()

    let tempDashboard: any = {
        defaultDashboardId: '',
        organizationId: 0,
        azure: [],
        aws: []
    };

    let tempDashboardStore: any = {
        defaultDashboardId: '',
        organizationId: 0,
        azure: [],
        aws: []
    };

    const [tempDashboardState, setTempDashboardState] = useState<any>(tempDashboardStore)
    
    let nearestThresholdAmount: number | null = 0;
    let costRequestObj: ICostRequest = {
        secretName: '',
        timeframe: ''
    }
    let costRequest: ICostRequest[];
    const [costRequestState, setCostRequestState] = useState<ICostRequest[]>([costRequestObj])
    let selectedLinkedAccounts: ILinkedAccount[];
    let hideTimeFrame: boolean = true;
    let exchangeRates: IExchangeRate = {
        base: '',
        rates: {}
    }

    const [exchangeRatesState, setExchangeRatesState] = useState<IExchangeRate>(exchangeRates)

    let resourceGroupData: IChartData[];
    let awsRegionChartData: any[];
    let awsInstanceChartData: any[];
    let azureLinkedChartData: any[];
    const [deleteDimensionId, setDeleteDimensionId] = useState<string>("")
    const [deleteLinkedChartData, setDeleteLinkedChartData] = useState<Map<any, any>>()
    
    const [category, setCategory] = useState<string>("")
    const [isChartTypeEnabled, setisChartTypeEnabled] = useState<boolean>(false)
    const [chartType, setChartType] = useState<any>()

    const [dimensionListState, setDimensionListState] = useState<any>([])
    const [selectedChartType, setSelectedChartType] = useState<string>("Select Chart Type")
    const [isAddChartLoaderEnabled, setAddChartLoader] = useState<boolean>(false)
    const [selectedChartData, setSelectedChartData] = useState<any>()
    const [showResetToDefault, setShowResetToDefault] = useState<boolean>(false)
    const diableGlobalFilter = ['edit','view', 'create']
    const [dashboardUpdateLoader, setDashboardUpdateLoader] = useState<boolean>(false)
    const [saveAsDashBoardLoader, setSaveAsDashBoardLoader] = useState<boolean>(false)
    const [saveAsTemplateLoader, setSaveAsTemplateLoader] = useState<boolean>(false)
    const [isDefaultState, setIsDefaultState] = useState<boolean>(false)
    const [showSaveTemplatePopup, setShowSaveTemplatePopup] = useState<boolean>(false)
    const [showTagsList, setShowTagsList] = useState<boolean>(false)
    const [showSubTagsList, setShowSubTagsList] = useState<boolean>(false)
    const [minFromDate, setMinFromDate] = useState<any>()
    const [maxToDate, setMaxToDate] = useState<any>()
    const [selectedTag, setSelectedTag] = useState<string>("")
    const [selectedFiltrScope, setSelecteFilterScope] = useState<string>("All")
    const [selectedFiltrScopeData, setSelecteFilterScopeData] = useState<any>({})
    const [isApplyButtonDiabled, setIsApplyButtonDiabled] = useState<boolean>(true)
    const [selectedLinkedAccountsState, setSelectedLinkedAccountsState] = useState<any>()

    useEffect(() => {
        const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowTagsList(false);
            setShowSubTagsList(false);
            setSelectedTagIndex("")
        }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    let advancedFilterObj: IAdvanceFilter = {
        timeFrame: "MonthToDate",
        fromDate: "",
        toDate: "",
    }

    const [advanceFilter, setAdvanceFilter] = useState<IAdvanceFilter>(advancedFilterObj)

    const [providerTypeSelectProps, setProviderTypeSelectProps] =
        useState<ProviderSelectProps>({
            organizationId: 0,
            providerTypes: [],
            onProviderTypeChange: (organizationId, providerType) => { },
        });

    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        showAll: true,
        hideLabel: true,
        onScopeChange: (scope) => { },
        onDashboardScopeChange: (scope) => { },
    });

    const onOrganizationChange = async (
        selectedOrganization: IOrganizationData
    ) => {
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        });
        setPageLoader(true)
        selectedOrganizationData = selectedOrganization
        setSelectedOrganizationDataState(selectedOrganization)

        const exchangeRateResponse = await getExchangeRate()
        exchangeRates = exchangeRateResponse.data
        setExchangeRatesState(exchangeRateResponse.data)
    };

    const onProviderTypeChange = async (
        organizationId: number,
        selectedProviderType: string
    ) => {
        providerType = selectedProviderType
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            showAll: true,
            hideLabel: true,
            onScopeChange: onScopeChange,
            onDashboardScopeChange: onDashboardScopeChange
        });
        providerType = selectedProviderType
        setProviderTypeState(selectedProviderType)
    };

    const onScopeChange = async (
        selectedScope: ProviderDetail | ProviderDetail[]
    ) => {
        setSelecteFilterScopeData({})
        setPageLoader(true)
        selectedProvider = selectedScope instanceof Array ? selectedScope : [selectedScope];
        setSelectedProviderTypeData(selectedScope instanceof Array ? selectedScope : [selectedScope])
        hideTimeFrame = selectedScope instanceof Array
        costRequest = mapCostRequest();
        
        setCostRequestState(costRequest)
        getTagsList(selectedProvider[0].providerType, costRequest)

        if (grid && grid.getGridItems().length > 0) {
            grid.removeAll();
        }

        const defaultObj: IDefaultDashboard = {
            defaultDashboardId: '',
            organizationId: 0,
            azure: [],
            aws: []
        }

        const defaultDashBoardResponse = await getDefaultDashboard(selectedOrganizationData.organizationId)

        if (defaultDashBoardResponse.statusCode === 200) {
            defaultDashboard = defaultDashBoardResponse.data
            setDefaultDashboardStore(defaultDashBoardResponse.data)
            tempDashboard = defaultDashboard ? defaultDashboard : defaultObj
            setTempDashboardState(tempDashboard)
        }
        else {
            const defaultObj: IDefaultDashboard = {
                defaultDashboardId: '',
                organizationId: 0,
                azure: [],
                aws: []
            }
            defaultDashboard = defaultObj;
            setDefaultDashboardStore(defaultObj)
            tempDashboard = defaultObj;
            setTempDashboardState(defaultObj)
        }

        generateDashboard(selectedOrganizationData.organizationId)
        setPageLoader(false)
    };

    const onDashboardScopeChange = async (
        selectedScope: any
    ) => {
        selectedProvider = selectedScope instanceof Array ? selectedScope : [selectedScope];
        
        setSelecteFilterScope(selectedScope.secretName)
        setSelecteFilterScopeData(selectedProvider)

        setIsApplyButtonDiabled(false)

        if(selectedProvider[0]?.isLinkedAccount) {
            getLinkedAccountsData(selectedScope)
        }
    }

    const getLinkedAccountsData = async(provider: IProvider) => {
        if (provider.isLinkedAccount) {
            const linkedAccountResponse = await getLinkedAccounts(provider.secretName)
            
            selectedLinkedAccounts = linkedAccountResponse?.data

            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                linkedAccountResponse.data?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj.accountName,
                        value: obj.accountId,
                    });
                });
                return newFilterValues;
            });
        }
    }

    const organziationSelectComponent = useMemo(
        () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
        []
    );
    const providerSelectComponent = useMemo(
        () => <ProviderSelect {...providerTypeSelectProps} />,
        [providerTypeSelectProps]
    );

    const scopeSelectComponent = useMemo(
        () => <ScopeSelect {...scopeSelectProps} />,
        [scopeSelectProps]
    );

    const dashBoardListComponent = useMemo(
        () => <DashBoardList 
        showDashboardList = {showDashboardList}
        organizationId={selectedOrganizationDataState.organizationId}
        component={savedCustomizeButton}
        providerType={providerTypeState}
        onDashBoardListClose={onClose}/>,
        [showDashboardList]
    )

    useEffect(() => {
        
        setMinFromDate(moment().subtract(11, 'M').startOf('month').format('YYYY-MM-DD'))

        const setMaxEndDate = (): void => {
            let maxToDate: any = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD');
            setMaxToDate(maxToDate)
            if (advanceFilter?.fromDate && moment(advanceFilter.toDate).isBefore(moment(advanceFilter?.fromDate))) {
                setAdvanceFilter(prevState => ({
                    ...prevState,
                    toDate: maxToDate
                }));
            }
        }

        setMaxEndDate()

        //Initializes a grid layout with floating behavior, specific margins, cell height, and a designated class for the handle used for moving the grid items.
        grid = GridStack.init({
            float: true,
            margin: '0px 10px 10px 10px',
            cellHeight: '9px',
            handleClass: 'cursor-move',
        });

        // Check if the grid instance is valid before calling methods on it
        if (grid) {
            // Attach event listener to the grid for changes
            grid.on('change', (_e: Event, _items: any) => {
                setUnsaved(true);
            });
        }
        setPageLoader(true)

        actionBasedPage()

        async function actionBasedPage() {
            const actionParams = queryParams.get('action');
            const organizationIdParams = queryParams.get('organizationId')
            const providerTYpeParams = queryParams.get('providerType')
            const dashboardIdParams = queryParams.get('dashboardId')
    
            if(actionParams) {
                setAction(actionParams)
                if(
                    !(actionParams === 'create' && 
                    organizationIdParams && 
                    providerTYpeParams) &&
                    !(dashboardIdParams)
                ) {
                    navigate('/navigate')
                    return
                }
            }
    
            let organizationRequest: any = {
                disable: true
            }
    
            switch (actionParams) {
                case `create`: {
                    organizationRequest.requestedOrganizationId = organizationIdParams;
                    organizationRequest.applyRole = true;
                    setOrganizationSelectRequest(organizationRequest)
                    break;
                }
    
                case 'edit':
                case 'view': {
                    setOrganizationSelectRequest( { skipFirst: true })
                    const dashboardByIdesponse = await getDashboardById(organizationIdParams, dashboardIdParams)
                    dashboard = dashboardByIdesponse
                    setDashboardState(dashboardByIdesponse.data)
                    organizationRequest.requestedOrganizationId = dashboardByIdesponse.data?.organizationId;
                    setOrganizationSelectRequest(organizationRequest)
        
                break;
                }
            }
        }

        setPageLoader(false)

    }, [triggerUseEffect]);

    const mapCostRequest = (): ICostRequest[] => {
        if (selectedProvider || providerTypeState) {
            const providerDetailsArray = selectedProvider as ProviderDetail[];
            return providerDetailsArray.map(eachProvider => {
                const accountId = selectedLinkedAccounts?.length ?
                    selectedLinkedAccounts.map(each => each.accountId) :
                    [eachProvider.accountId];

                const costRequest: ICostRequest = {
                    subscriptionId: eachProvider.subscriptionId,
                    tenantId: eachProvider.tenantId,
                    secretName: eachProvider.secretName,
                    accountId: accountId,
                    timeframe: "MonthToDate",
                };

                return costRequest;
            });
        } else {
            return [];
        }
    };

    const mapApplyCostRequest = (): ICostRequest[] => {
        if (selectedProvider || providerTypeState) {
            const providerDetailsArray = selectedFiltrScopeData as ProviderDetail[];
            return providerDetailsArray.map(eachProvider => {
                const accountId = selectedLinkedAccounts?.length ?
                    selectedLinkedAccounts.map(each => each.accountId) :
                    [eachProvider.accountId];

                const costRequest: ICostRequest = {
                    subscriptionId: eachProvider.subscriptionId,
                    tenantId: eachProvider.tenantId,
                    secretName: eachProvider.secretName,
                    accountId: accountId,
                    timeframe: "MonthToDate",
                };

                return costRequest;
            });
        } else {
            return [];
        }
    };

    const getTagsList = async(providerType: string, payload: any) => {
        if(providerType === "AWS") {
            const tagListResponse = await fetchTagList(providerType, payload)

            if(tagListResponse.statusCode === 200) {
                setTagList(tagListResponse?.data?.tags)
            }
        }
        else {
            const tagListResponse = await fetchTagList("Azure", payload)

            if(tagListResponse.statusCode === 200) {
                const consolidatedTags: any = {};
                tagListResponse.data.tags.forEach((item: any) => {
                if (item.tagName in consolidatedTags) {
                    consolidatedTags[item.tagName].push(item.tagValue);
                } else {
                    consolidatedTags[item.tagName] = [item.tagValue];
                }
                });
                setConsolidatedAzureTags(consolidatedTags)
                setAzureTagList(Object.keys(consolidatedTags));
            }
        }
    }

    const getSubTags = async(tags: string, index: number) => {
        setSelectedTag(tags)
        setSubTagList([])
        setSelectedTagIndex(index)

        if(index !== selectedTagIndex){
            setShowSubTagsList(true);             
            if(providerTypeState === "AWS") {

                let payload = {
                    "subscriptionId": costRequestState[0].subscriptionId,
                    "tenantId": costRequestState[0].tenantId,
                    "secretName": costRequestState[0].secretName,
                    "accountId": costRequestState[0].accountId,
                    "tagKey" : tags,
                    "timeframe": costRequestState[0].timeframe
                }

                const subTagsListResponse = await fetchSubTagsList(providerTypeState, [payload])
        
                if(subTagsListResponse.data) {
                    setSubTagList(subTagsListResponse.data.tags)
                }
            }
            else {
                setAzureSubTagList(consolidatedAzureTags[tags])
            }
        }
        else if (index === selectedTagIndex) {
            setShowSubTagsList(false);
            const dropdownItems = document.querySelectorAll('.dropdown-item');

            if(dropdownItems) {
                dropdownItems.forEach(item => {
                    item.classList.remove('active');
                });
            }
        }
    }

    const generateDashboard = (organizationId: number) => {
        if (!queryParams.get("action")) {
            switch (providerType) {
                case ProviderType.AZURE:
                    if (!defaultDashboard?.azure?.length) {
                        const updatedTempDashBoardValue:any = [...StaticDashboardLayout.azureLayout];

                        tempDashboard.azure = updatedTempDashBoardValue

                        setTempDashboardState((prevState: any) => ({
                            ...prevState,
                            azure: updatedTempDashBoardValue
                        }));

                        generateCostExpenseLayout(tempDashboard.azure);
                        generateChartsLayout(tempDashboard.azure, organizationId);
                    } else {
                        generateCostExpenseLayout(tempDashboard.azure);
                        generateChartsLayout(tempDashboard.azure, organizationId);
                    }

                    break;

                case ProviderType.AWS:
                    if (!defaultDashboard?.aws?.length) {

                        const updatedTempDashBoardValue:any = [...StaticDashboardLayout.awsLayout];

                        tempDashboard.aws = updatedTempDashBoardValue

                        setTempDashboardState((prevState: any) => ({
                            ...prevState,
                            aws: updatedTempDashBoardValue
                        }));

                        generateCostExpenseLayout(tempDashboard.aws);
                        generateChartsLayout(tempDashboard.aws, organizationId);
                    }
                    else {
                        generateCostExpenseLayout(tempDashboard.aws);
                        generateChartsLayout(tempDashboard.aws, organizationId);
                    }
                    break;
            }
        }
        else {
            switch (queryParams.get("action")) {
                case 'create': {
                    dashboard = {
                        organizationId: organizationId,
                        providerType: providerTypeState || providerType,
                        data: CostExpensesLayout.customLayout
                    }
                    setDashboardState(dashboard)
                    generateCostExpenseLayout(CostExpensesLayout.customLayout);
                    break;
                }
                case 'edit':
                case 'view': {
                    generateCostExpenseLayout(CostExpensesLayout.customLayout);
                    generateChartsLayout(dashboard?.data?.data || dashboardState?.data?.data, organizationId);
                    break;
                }
            }
        }
        setPageLoader(false);
    }

    function generateCostExpenseLayout(layouts: IDashboardData[]): void {
        layouts.forEach(eachLayout => {
            switch (eachLayout.chartDimension) {
                case 'monthCost': {
                    let monthCostDiv =
                        `<div class="card h-100 dashboard-card-sty">
                    <div class="card-body">
                    <div id="monthCost-loader" class="spinner chart-spinner start-45">
                        <div class="custom-loader"></div>
                    </div>
                    <h5 class="card-title db-card-header">Monthly Expenses till Date</h5>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h5 class="db-card-amount mb-0" id="monthCost-data"> </h5>
                        <div class="d-block">
                        <span><img src="images/monthly-expensive-icon.svg" alt="monthly-expensive-icon"
                            class="db-card-icon"></span>
                    </div>
                    </div>
                    <p class="db-card-helper mb-0">
                        <span>
                        <img id="monthCost-diffImg" src="images/green-down-arrow.svg" alt="green-down-arrow" class="decrease-arrow">
                    </span>
                    <span id="monthCost-diffCost" class="decrease-text">
                    </span>
                        <span id="monthCost-diffMsg"></span>
                    </p>
                </div>
                </div>`

                    eachLayout.layout.content = monthCostDiv;
                    grid.addWidget(eachLayout.layout);
                    break;
                }
                case 'forecastedCost': {
                    let forecastedCostDiv =
                        `<div class="card h-100 dashboard-card-sty">
                    <div class="card-body">
                    <div id="forecastedCost-loader" class="spinner chart-spinner start-45">
                        <div class="custom-loader"></div>
                    </div>
                    <h5 class="card-title db-card-header">Forecasted Cost</h5>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h5 class="db-card-amount mb-0" id="forecastedCost-data"></h5>
                        <div class="d-block">
                        <span><img src="images/forecasted-cost.svg" alt="forecasted-cost"
                            class="db-card-icon"></span>
                        </div>
                    </div>
                    <p class="db-card-helper mb-0">
                        <span><img id="forecastedCost-diffImg" src="images/red-up-arrow.svg" alt="red-up-arrow" class="decrease-arrow"></span>
                        <span id="forecastedCost-diffCost" class="increase-text">
                    </span>
                        <span id="forecastedCost-diffMsg"></span>
                    </p>
                    </div>
                </div>`

                    eachLayout.layout.content = forecastedCostDiv;
                    grid.addWidget(eachLayout.layout);
                    break;
                }
                case 'thresholdLeft': {
                    let thresholdLeftDiv =
                        `<div class="card h-100 dashboard-card-sty">
                    <div class="card-body">
                    <div id="thresholdLeft-loader" class="spinner chart-spinner start-45">
                        <div class="custom-loader"></div>
                    </div>
                    <h5 class="card-title db-card-header">Amount left for Threshold
                        <span><img src="images/info-icon.svg" alt="info-icon" class="info-icon"
                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Threshold amount will be populated when only one scope is selected."
                            data-bs-original-title="Amount left for Threshold">
                        </span>
                    </h5>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h5 class="db-card-amount mb-0" id='thresholdLeft-data'></h5>
                        <div class="d-block">
                        <span><img src="images/threshold-amount-left.svg" alt="threshold-amount-left"
                            class="db-card-icon"></span>
                        </div>
                    </div>
                </div>
                </div>`

                    eachLayout.layout.content = thresholdLeftDiv;
                    grid.addWidget(eachLayout.layout);
                    break;
                }
                case 'recommendations': {
                    let recommendationsDiv =
                        `<div class="card h-100 dashboard-card-sty">
                    <div class="card-body">
                    <div id="recommendations-loader" class="spinner chart-spinner start-45">
                        <div class="custom-loader"></div>
                    </div>
                    <h5 class="card-title db-card-header">Recommendations
                        <span>
                        <img src="images/info-icon.svg" alt="info-icon" class="info-icon"
                        data-bs-toggle="tooltip" data-bs-placement="bottom" title="Recommendations will be populated when only one scope is selected."
                        data-bs-original-title="">
                    </span>
                    </h5>
                    <div class="d-flex justify-content-between">
                        <div class="d-block min-0">
                        <h5 class="db-card-amount my-2"><a id="recommendations-count" href="/recommendation" class="link-sty">0</a></h5>
                        <div id=recommendations-message>
                        </div>
                    </div>
                    <div class="d-block">
                        <span><img src="images/recommendation.svg" alt="recommendation" class="db-card-icon"></span>
                    </div>
                    </div>
                </div>
                </div>`

                    eachLayout.layout.content = recommendationsDiv;
                    grid.addWidget(eachLayout.layout);
                    break;
                }
            }
        });

        getMonthCostAndThreshold();
        getRecommendations();
    }

    async function getMonthCostAndThreshold() {

        let monthCostObs = await getMonthlyExpenses(costRequest, providerType || providerTypeState);
        let forecastCostObs = await getForecastedCost(costRequest, providerType || providerTypeState);

        let monthCostloaderElement = document.getElementById("monthCost-loader")
        let forecastedCostloaderElement = document.getElementById("forecastedCost-loader")
        let thresholdLeftloaderElement = document.getElementById("thresholdLeft-loader")
        let thresholdLeftdataElement = document.getElementById("thresholdLeft-data")

        if (selectedProvider?.length === 1 || selectedFiltrScopeData?.length === 1) {
            
            let requestPayload: any;

            if(selectedProvider?.length === 1 ) {
                requestPayload = {
                    subscriptionId: selectedProvider[0]?.subscriptionId,
                    tenantId: selectedProvider[0]?.tenantId,
                    secretName: selectedProvider[0]?.secretName,
                    accountId: selectedProvider[0]?.accountId
                }
            }
            else {
                requestPayload = {
                    subscriptionId: selectedFiltrScopeData[0]?.subscriptionId,
                    tenantId: selectedFiltrScopeData[0]?.tenantId,
                    secretName: selectedFiltrScopeData[0]?.secretName,
                    accountId: selectedFiltrScopeData[0]?.accountId
                }
            }

            let thresholdsResponse = await getAllThresholds(requestPayload, providerType || providerTypeState);
            let thresholdsData = thresholdsResponse.data
            let currentMonthConverted = convertCost(monthCostObs.data?.currentMonth, monthCostObs.data?.currency);
            let pastMonthConverted = convertCost(monthCostObs.data?.pastMonth, monthCostObs?.data?.currency);
            let forecastCostConverted = convertCost(forecastCostObs.data?.forecast, forecastCostObs.data?.currency);

            appendMonthAndForecastCost(currentMonthConverted, pastMonthConverted, forecastCostConverted);

            let thresholdLeftdataElement = document.getElementById("thresholdLeft-data")

            if (thresholdsData?.length > 0) {
                let filteredThresholds = thresholdsData.sort((a: { amount: number; }, b: { amount: number; }) => a.amount - b.amount)
                    .filter((eachThreshold: { amount: number; }) => eachThreshold.amount > monthCostObs.data?.currentMonth);

                if (filteredThresholds.length === 0) {
                    let thresholdLeft = convertCost(thresholdsData[thresholdsData.length - 1].amount - monthCostObs.data?.currentMonth, thresholdsData[thresholdsData.length - 1].currencyType);

                    if (thresholdLeftdataElement) {
                        thresholdLeftdataElement.innerHTML = `<span class="db-amount">${getCurrencySymbol(thresholdLeft.currencyType)}</span> ${(thresholdLeft.cost?.toFixed(2))}`;
                        nearestThresholdAmount = convertCost(thresholdsData[thresholdsData.length - 1].amount, thresholdsData[thresholdsData.length - 1]?.currencyType).cost;
                    }
                }
                else {
                    let thresholdLeft = convertCost(filteredThresholds[0].amount - monthCostObs.data?.currentMonth, filteredThresholds[0]?.currencyType);

                    if (thresholdLeftdataElement) {
                        thresholdLeftdataElement.innerHTML = `<span class="db-amount">${getCurrencySymbol(thresholdLeft?.currencyType)}</span> ${(thresholdLeft.cost?.toFixed(2))}`;
                    }
                    nearestThresholdAmount = convertCost(filteredThresholds[0].amount, filteredThresholds[0]?.currencyType).cost
                }
            }

            if (monthCostloaderElement && forecastedCostloaderElement && thresholdLeftloaderElement) {
                monthCostloaderElement.style.display = 'none';
                forecastedCostloaderElement.style.display = 'none';
                thresholdLeftloaderElement.style.display = 'none';

            }

            setThresholdMarkLine();
        }
        else {

            if (thresholdLeftdataElement && thresholdLeftloaderElement) {
                thresholdLeftloaderElement.style.display = 'none';
                thresholdLeftdataElement.innerText = 'Data Unavailable!';
                thresholdLeftdataElement.classList.toggle("font-16");
            }

            if (monthCostloaderElement && forecastedCostloaderElement) {
                monthCostloaderElement.style.display = 'none';
                forecastedCostloaderElement.style.display = 'none';
            }

            let currentMonthConverted = convertCost(monthCostObs.data?.currentMonth, monthCostObs.data?.currency);
            let pastMonthConverted = convertCost(monthCostObs.data?.pastMonth, monthCostObs.data?.currency);
            let forecastCostConverted = convertCost(forecastCostObs.data?.forecast, forecastCostObs.data?.currency);

            appendMonthAndForecastCost(currentMonthConverted, pastMonthConverted, forecastCostConverted);
        }
    }

    async function getRecommendations() {

        const recommendationsCountElement = document.getElementById("recommendations-count");
        const recommendationsLoaderElement = document.getElementById("recommendations-loader");
        const recommendationsMessageElement = document.getElementById("recommendations-message")

        if (recommendationsCountElement && recommendationsLoaderElement && recommendationsMessageElement) {
            if (costRequest.length > 1) {
                recommendationsCountElement.innerText = 'Data Unavailable!';
                recommendationsCountElement.classList.toggle("font-16");
                recommendationsCountElement.style.pointerEvents = 'none';
                recommendationsLoaderElement.style.display = 'none';
                return;
            }

            let requestPayload: IRequestPayload;

            if(selectedProvider?.length === 1 ) {
                requestPayload = {
                    subscriptionId: selectedProvider[0]?.subscriptionId,
                    tenantId: selectedProvider[0]?.tenantId,
                    secretName: selectedProvider[0]?.secretName,
                    accountId: selectedProvider[0]?.accountId
                }
            }
            else {
                requestPayload = {
                    subscriptionId: selectedFiltrScopeData[0]?.subscriptionId,
                    tenantId: selectedFiltrScopeData[0]?.tenantId,
                    secretName: selectedFiltrScopeData[0]?.secretName,
                    accountId: selectedFiltrScopeData[0]?.accountId
                }
            }


            switch (providerType || providerTypeState) {
                case ProviderType.AZURE:
                    const azureRecommendations = await getAzureRecommendations(requestPayload);

                    if (azureRecommendations.statusCode === 200) {
                        let recommendation = azureRecommendations.data

                        let filteredRecommendations = recommendation.values.filter((eachValue: { impact: string; shortDescription: { problem: any; }; }) => eachValue?.impact === 'High' && eachValue?.shortDescription.problem)
                            .sort((a: { category: string; }, b: { category: string; }) => BaseConstant.azureRecCategoryOrder.indexOf(a?.category) - BaseConstant.azureRecCategoryOrder.indexOf(b?.category))
                            .slice(0, 2);

                        recommendationsCountElement.innerText = filteredRecommendations.length < 10 ? `0${filteredRecommendations.length}` : filteredRecommendations.length;
                        recommendationsMessageElement.innerText = "";
                        recommendationsLoaderElement.style.display = 'none';

                        filteredRecommendations?.forEach((eachRecommendation: any) => {
                            let pTag = document.createElement('p');
                            pTag.className = 'db-card-helper mb-0 truncate-text';

                            let spanTag = document.createElement('span');
                            spanTag.className = 'legend-sty legend-pink me-2';

                            let messTag = document.createElement('span');
                            messTag.innerText = eachRecommendation?.shortDescription.problem;
                            messTag.className = 'truncated-text'

                            pTag.append(spanTag, messTag);
                            recommendationsMessageElement.appendChild(pTag);
                        });
                    }
                    else {
                        const recommendationsElement = document.getElementById("recommendations")
                        const recommendationsErrorElement = document.getElementById("recommendations-error")

                        if (recommendationsElement && recommendationsErrorElement) {
                            recommendationsElement.style.display = 'none';
                            recommendationsLoaderElement.style.display = 'none';
                            recommendationsErrorElement.style.display = 'block';
                        }
                    }
                    break;

                case ProviderType.AWS:
                    const advisorCheckResponse = await getAwsAdvisorChecks(requestPayload);

                    if (advisorCheckResponse.statusCode === 200) {
                        let checkIds = advisorCheckResponse?.data?.checks?.map((eachCheck: { id: any; }) => eachCheck.id);
                        let advisorSummaryPayload: IAdvisorSummaryPayload = { ...requestPayload, checkIds };

                        const advisorCheckSummariesRespose = await getAwsAdvisorCheckSummaries(advisorSummaryPayload)

                        if (advisorCheckSummariesRespose.statusCode === 200) {
                            let filteredRecommendations = advisorCheckSummariesRespose.data.summaries.filter((eachSummary: { status: string; }) => eachSummary.status === 'error')
                                .map((eachSummary: { checkId: any; }) => advisorCheckResponse.data.checks.find((eachCheck: { id: any; }) => eachCheck.id === eachSummary.checkId))
                                .sort((a: { category: string; }, b: { category: string; }) => BaseConstant.awsRecCategoryOrder.indexOf(a?.category) - BaseConstant.awsRecCategoryOrder.indexOf(b?.category))
                                .slice(0, 2);

                            recommendationsCountElement.innerText = filteredRecommendations.length < 10 ? `0${filteredRecommendations.length}` : filteredRecommendations.length;
                            recommendationsMessageElement.innerText = "";
                            recommendationsLoaderElement.style.display = 'none';

                            filteredRecommendations?.forEach((eachRecommendation: any) => {
                                let pTag = document.createElement('p');
                                pTag.className = 'db-card-helper mb-0 truncate-text';

                                let spanTag = document.createElement('span');
                                spanTag.className = 'legend-sty legend-pink me-2';

                                let messTag = document.createElement('span');
                                messTag.innerText = eachRecommendation?.name;
                                messTag.className = 'truncated-text'

                                pTag.append(spanTag, messTag);
                                recommendationsMessageElement.appendChild(pTag);
                            });
                        }
                    }
                    else {
                        const recommendationsElement = document.getElementById("recommendations")
                        const recommendationsErrorElement = document.getElementById("recommendations-error")

                        if (recommendationsElement && recommendationsErrorElement) {
                            recommendationsElement.style.display = 'none';
                            recommendationsLoaderElement.style.display = 'none';
                            recommendationsErrorElement.style.display = 'block';
                        }
                    }

                    break;
            }
        }

    }

    const setThresholdMarkLine = () => {
        try {
            let CostAnalysischartElement = document.getElementById(`CostAnalysis-chart`)

            if (nearestThresholdAmount && CostAnalysischartElement && echarts.getInstanceByDom(CostAnalysischartElement)) {
                let costAnalysisInstance = echarts.getInstanceByDom(CostAnalysischartElement)

                if (costAnalysisInstance) {
                    costAnalysisInstance.setOption({
                        series: [{
                            markLine: {
                                silent: true,
                                symbol: [],
                                lineStyle: {
                                    color: 'rgba(240, 6, 6, 1)',
                                    type: 'dashed',
                                },
                                data: [
                                    {
                                        yAxis: nearestThresholdAmount,
                                    },
                                ],
                                label: {
                                    color: "var(--secondary-text-color, #3C3C3C)",
                                    fontFamily: "SF Pro Text Medium",
                                    formatter: 'Threshold: {c}',
                                    position: 'insideEndTop',
                                },
                            }
                        }]
                    })
                }
            }

        } catch (e) {
            console.error(e)
        }
    }

    function appendMonthAndForecastCost(currentMonthCost: ICostCurrency, pastMonthCost: ICostCurrency, forecastCost: ICostCurrency): void {

        const monthCostElement = document.getElementById("monthCost-data")
        const monthCostdiffImgElement = document.getElementById("monthCost-diffImg")
        const monthCostDiffCost = document.getElementById("monthCost-diffCost")
        const monthCostDiffMessage = document.getElementById("monthCost-diffMsg")

        if (monthCostElement && monthCostdiffImgElement && monthCostDiffCost && monthCostDiffMessage) {
            monthCostElement.innerHTML = `<span class="db-amount">${getCurrencySymbol(currentMonthCost?.currencyType)}</span> ${(currentMonthCost.cost?.toFixed(2))}`;

            let difference = currentMonthCost.cost - pastMonthCost.cost;
            monthCostdiffImgElement.setAttribute('src', difference > 0 ? 'images/red-up-arrow.svg' : 'images/green-down-arrow.svg');
            monthCostDiffCost.className = difference > 0 ? 'increase-text' : 'decrease-text';
            monthCostDiffCost.innerHTML = `<span class="decrease-amount">${getCurrencySymbol(currentMonthCost?.currencyType)}</span>${(Math.abs(difference)?.toFixed(2))}`;
            monthCostDiffMessage.innerText = difference > 0 ? 'increase than last month' : 'decrease than last month';
        }

        const forecastedCostData = document.getElementById("forecastedCost-data")
        const forecastedCostdiffImg = document.getElementById("forecastedCost-diffImg")
        const forecastedCostdiffCost = document.getElementById("forecastedCost-diffCost")
        const forecastedCostdiffMsg = document.getElementById("forecastedCost-diffMsg")

        if (forecastedCostData && forecastedCostdiffImg && forecastedCostdiffCost && forecastedCostdiffMsg) {
            forecastedCostData.innerHTML = `<span class="db-amount">${getCurrencySymbol(currentMonthCost?.currencyType)}</span> ${(forecastCost.cost?.toFixed(2))}`;

            let difference = forecastCost.cost - pastMonthCost.cost;
            forecastedCostdiffImg.setAttribute('src', difference > 0 ? 'images/red-up-arrow.svg' : 'images/green-down-arrow.svg');

            forecastedCostdiffCost.className = difference > 0 ? 'increase-text' : 'decrease-text';
            forecastedCostdiffCost.innerHTML = `<span class="decrease-amount">${getCurrencySymbol(currentMonthCost?.currencyType)}</span>${(Math.abs(difference)?.toFixed(2))}`;
            forecastedCostdiffMsg.innerText = difference > 0 ? 'increase than last month' : 'decrease than last month';
        }
    }

    function generateChartsLayout(layouts: IDashboardData[], organizationId: number): void {
        layouts = layouts.filter(eachLayout => !BaseConstant.costExpenseIds.includes(eachLayout.chartDimension));

        layouts?.forEach(eachLayout => {
            try {
                generateSingleChartLayout(eachLayout, organizationId);
            } catch (e) {
                console.error(e);
            }
        });

        switch (providerType || providerTypeState) {
            case ProviderType.AZURE: {
                getAzureCharts(layouts);
                getAzureLinkedChartsDetails(layouts);
                break;
            }
            case ProviderType.AWS: {
                getAwsCharts(layouts);
                getAwsLinkedChartsDetails(layouts);
                break;
            }
        }
    }

    function generateSingleChartLayout(layout: IDashboardData, organizationId: number): void {
        try {
            switch (layout.chartDimension) {
                case 'CostAnalysis': {
                    let costAnalysisDiv = serviceInstance.getCostAnalysisLayout(providerType);
                    layout.layout.content = costAnalysisDiv;
                    grid.addWidget(layout.layout);
                    const costAnalysisSelectElement = document.getElementById('costAnalysisSelect')
                    if (costAnalysisSelectElement) {
                        costAnalysisSelectElement.onchange = () => getCostAnalysisChartDetails((costAnalysisSelectElement as HTMLSelectElement).value, organizationId);
                    }
                    getCostAnalysisChartDetails('monthly', organizationId);
                    break;
                }
                case 'TAG':
                case 'TagKey': {
                    // skip tag chart if present in any saved layout
                    break;
                }
                default: {
                    let chartDiv = serviceInstance.getChartLayout(layout.chartDimension, layout.title);
                    layout.layout.content = chartDiv;
                    grid.addWidget(layout.layout);
                }
            }
            const editElement = document.getElementById(`${layout.chartDimension}-edit`);
            const deleteElement = document.getElementById(`${layout.chartDimension}-delete`);

            if(editElement) {
                editElement.onclick = () => addEditGrid(layout)
            }

            if(deleteElement) {
                deleteElement.onclick = () => deleteGrid(layout.chartDimension, false);
            }
            
        } catch (e) {
            console.error(e)
        }
    }

    async function getAwsCharts(layouts: IDashboardData[]): Promise<void> {
        try {
            let filteredLayouts = layouts.filter(eachLayout => eachLayout.chartDimension !== 'TAG'
                && eachLayout.chartDimension !== 'CostAnalysis'
                && !BaseConstant.awsLinkedCharts.includes(eachLayout.chartDimension)
                && !BaseConstant.costExpenseIds.includes(eachLayout.chartDimension));

            if (!filteredLayouts || !filteredLayouts.length) return;
            let dimensionGroup: DimensionGroup = filteredLayouts.reduce((result: DimensionGroup, element, index) => {
                if (index % 2 === 0) {
                    result.push([{
                        type: 'DIMENSION',
                        name: element.chartDimension
                    }]);
                } else {
                    result[result.length - 1].push({
                        type: 'DIMENSION',
                        name: element.chartDimension
                    });
                }
                return result;
            }, []);

            let dimensionCostRequest = [...costRequest as IDimensionCostRequest[]];

            let dimensionObsArr = dimensionGroup?.map(async eachDimensionGroup => {
                // only way to copy the nested array by value(not reference).
                let tempCostRequest = JSON.parse(JSON.stringify(dimensionCostRequest));
                tempCostRequest?.forEach((eachDimensionCostRequest: any) => {
                    eachDimensionCostRequest.groupBy = eachDimensionGroup;
                });
                return await getCostByDimension(tempCostRequest, providerType || providerTypeState, selectedOrganizationData?.organizationId || selectedOrganizationDataState?.organizationId);
            });

            let response = await Promise.all(dimensionObsArr);
            let responseData = response?.map(response => response.data);


            responseData = responseData.flatMap(each => each).map((eachResponse: any) => {
                let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost.currencyType;
                return eachResponse;
            });

            filteredLayouts?.forEach(eachLayout => {
                let chartData = getChartData(response[0].data, eachLayout.chartDimension);
                createPieChart(eachLayout, chartData);
                let legendList = getLegendElement(chartData);
                let chartLegendDom = document.getElementById(`${eachLayout.chartDimension}-legend`);
                chartLegendDom?.replaceChildren(...legendList);
            });
        }
        catch (e) {
            console.log(e)
        }
    }

    async function getAwsLinkedChartsDetails(layouts: IDashboardData[]) {
        let filteredLayouts = layouts.filter(eachLayout => BaseConstant.awsLinkedCharts.includes(eachLayout.chartDimension))
            .sort((a, b) => BaseConstant.awsLinkedCharts.indexOf(a.chartDimension) - BaseConstant.awsLinkedCharts.indexOf(b.chartDimension));

        let dimensionObsArr: any = [];

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'REGION' || eachLayout.chartDimension === 'SERVICE')) {
            let dimensionCostRequest = [...costRequest as IDimensionCostRequest[]];

            // only way to copy the nested array by value(not reference).
            let tempCostRequest = JSON.parse(JSON.stringify(dimensionCostRequest));
            tempCostRequest?.forEach((eachDimensionCostRequest: any) => {
                eachDimensionCostRequest.groupBy = [
                    {
                        type: 'DIMENSION',
                        name: 'REGION'
                    },
                    {
                        type: 'DIMENSION',
                        name: 'SERVICE'
                    }
                ]
            });

            let getCostByDimensionResponse = await getCostByDimension(tempCostRequest, providerType || providerTypeState, selectedOrganizationData?.organizationId || selectedOrganizationDataState?.organizationId)
            dimensionObsArr.push(getCostByDimensionResponse.data);
        }

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'INSTANCE_TYPE')) {
            let dimensionCostRequest = [...costRequest as IDimensionCostRequest[]];
            // only way to copy the nested array by value(not reference).
            let tempCostRequest = JSON.parse(JSON.stringify(dimensionCostRequest));
            tempCostRequest?.forEach((eachDimensionCostRequest: any) => {
                eachDimensionCostRequest.groupBy = [
                    {
                        type: 'DIMENSION',
                        name: 'SERVICE'
                    },
                    {
                        type: 'DIMENSION',
                        name: 'INSTANCE_TYPE'
                    }
                ]
            });

            let getCostByDimensionResponse = await getCostByDimension(tempCostRequest, providerType || providerTypeState, selectedOrganizationData?.organizationId || selectedOrganizationDataState?.organizationId)
            dimensionObsArr.push(getCostByDimensionResponse.data);
        }

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'REGION')) {
            awsRegionChartData = dimensionObsArr[0]?.map((eachResponse: any) => {
                let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost.currencyType;
                return eachResponse;
            });
        }

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'INSTANCE_TYPE')) {
            let instanceResponse = dimensionObsArr[dimensionObsArr.length - 1];
            awsInstanceChartData = instanceResponse?.map((eachResponse: any) => {
                let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost.currencyType;
                return eachResponse;
            });
        }

        if (filteredLayouts.length) {
            drawAwsLinkedChart(filteredLayouts[0].chartDimension, null);
        }
    }

    async function getAzureCharts(layouts: IDashboardData[]) {
        let filteredLayouts = layouts.filter(eachLayout => eachLayout.chartDimension !== 'TagKey'
            && eachLayout.chartDimension !== 'CostAnalysis'
            && !BaseConstant.azureLinkedCharts.includes(eachLayout.chartDimension)
            && !BaseConstant.costExpenseIds.includes(eachLayout.chartDimension));

        if (!filteredLayouts || !filteredLayouts.length) return;

        let groupBy = filteredLayouts.map(eachLayout => {
            return {
                type: 'Dimension',
                name: eachLayout.chartDimension
            }
        });

        let dimensionCostRequest: IDimensionCostRequest[] = costRequest.map((eachRequest: any) => {
            eachRequest.groupBy = groupBy;
            return eachRequest as IDimensionCostRequest;
        });

        const costDimensionResponse = await getCostByDimension(dimensionCostRequest, providerType || providerTypeState, selectedOrganizationData?.organizationId || selectedOrganizationDataState?.organizationId)

        if (costDimensionResponse.statusCode === 200) {

            let mappedResponse = costDimensionResponse.data.map((eachResponse: { amount: number; currency: string; }) => {
                let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost?.currencyType;
                return eachResponse;
            });

            filteredLayouts?.forEach(eachLayout => {
                // TR_DA_118-120
                let chartData = getChartData(mappedResponse, eachLayout.chartDimension);
                createPieChart(eachLayout, chartData);

                // TR_DA_127-129
                let legendList = getLegendElement(chartData);
                let chartLegendDom = document.getElementById(`${eachLayout.chartDimension}-legend`);
                if (chartLegendDom) {
                    chartLegendDom.replaceChildren(...legendList);
                }
            });
        }
    }

    async function getAzureLinkedChartsDetails(layouts: IDashboardData[]) {
        try {
            let filteredLayouts = layouts.filter(eachLayout => BaseConstant.azureLinkedCharts.includes(eachLayout.chartDimension))
                .sort((a, b) => BaseConstant.azureLinkedCharts.indexOf(a.chartDimension) - BaseConstant.azureLinkedCharts.indexOf(b.chartDimension));

            let groupBy = filteredLayouts.map(eachLayout => {
                return {
                    type: 'Dimension',
                    name: eachLayout.chartDimension
                }
            });

            let dimensionCostRequest: IDimensionCostRequest[] = costRequest.map((eachRequest: any) => {
                eachRequest.groupBy = groupBy;
                return eachRequest as IDimensionCostRequest;
            });

            const costDimensionResponse = await getCostByDimension(dimensionCostRequest, providerType || providerTypeState, selectedOrganizationData?.organizationId || selectedOrganizationDataState?.organizationId)

            if (costDimensionResponse.statusCode === 200) {
                azureLinkedChartData = costDimensionResponse.data.map((eachResponse: any) => {
                    let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                    eachResponse.amount = convertedCost.cost;
                    eachResponse.currency = convertedCost.currencyType;
                    return eachResponse;
                });

                if (filteredLayouts.length) {
                    drawAzureLinkedChart(filteredLayouts[0].chartDimension, null);
                }
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    function getChartData(inputArr: any, groupKey: string): IChartData[] {

        let chartData: any[] = []
        inputArr?.reduce((res: any, each: any) => {
            if (!res[each[groupKey]]) {
                res[each[groupKey]] = {
                    name: each[groupKey],
                    value: 0,
                    currency: getCurrencySymbol(each?.currency)
                };
                chartData.push(res[each[groupKey]]);
            }
            res[each[groupKey]].value += +(each.amount?.toFixed(2))
            return res;
        }, {});

        chartData.sort((a, b) => b.value - a.value);
        return chartData;
    }

    async function getCostAnalysisChartDetails(dateRange: string, organizationId: number) {
        const costAnalysisLoaderElement = document.getElementById(`CostAnalysis-loader`)
        if (costAnalysisLoaderElement) {
            costAnalysisLoaderElement.style.display = 'block';
            let chartLayout = getDashboardDataByDimensionId('CostAnalysis');

            const costAnalysisResponse = await getCostAnalysis(costRequest, dateRange, providerType || providerTypeState, organizationId)
            const charElement = document.getElementById("CostAnalysis-chart")
            const errorElement = document.getElementById("costAnalysis-error")
            const errorMsgElement = document.getElementById("costAnalysis-errorMsg")

            if (costAnalysisResponse.statusCode !== 200) {

                if (charElement && errorElement && errorMsgElement) {
                    charElement.style.display = 'none';
                    errorElement.style.setProperty('display', 'block', 'important');
                    errorMsgElement.innerText = 'Unable to load cost analysis data';
                    return;
                }
            }

            if (charElement && costAnalysisLoaderElement && errorElement) {
                charElement.style.display = 'block';
                costAnalysisLoaderElement.style.display = 'none';
                errorElement.style.display = 'none';
            }

            let costAnalysisData = costAnalysisResponse?.data?.map((eachResponse: any) => {
                let convertedCost = convertCost(eachResponse.amountDetails, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost?.currencyType;
                return eachResponse;
            });

            let data = serviceInstance.getCostAnalysisChartData(costAnalysisData, dateRange, providerType);

            destroyChartInstance('CostAnalysis');
            let chartDom = document.getElementById(`CostAnalysis-chart`);
            let chartInstance = echarts.init(chartDom, {}, serviceInstance.initOpts);
            chartInstance.setOption(serviceInstance.getCostAnalysisChartOptions(chartLayout?.chartType, data));

            grid.on('change', (_e: any, _i: any) => chartInstance.resize());

            setThresholdMarkLine();
        }
        else {

            const charElement = document.getElementById("CostAnalysis-chart")
            const errorElement = document.getElementById("costAnalysis-error")
            const errorMsgElement = document.getElementById("costAnalysis-errorMsg")
            const costAnalysisLoaderElement = document.getElementById(`CostAnalysis-loader`)

            if (charElement && costAnalysisLoaderElement && errorElement && errorMsgElement) {
                charElement.style.display = 'none';
                costAnalysisLoaderElement.style.display = 'none';
                errorElement.style.display = 'block !important';
                errorMsgElement.innerText = 'Unable to load data';
            }
        }
    }

    function createPieChart(dimension: IDashboardData, chartData: IChartData[]): void {

        const dimensionChartDimensionElement = document.getElementById(`${dimension.chartDimension}-chart`)
        const dimensionChartLoader = document.getElementById(`${dimension.chartDimension}-loader`)

        if (dimensionChartDimensionElement && dimensionChartLoader) {

            if (dimension.chartDimension === 'ResourceGroup') {
                resourceGroupData = chartData;
                const exportbtnElement = document.getElementById("export-btn")
                if (exportbtnElement) {
                    exportbtnElement.onclick = () => resourceGroupExport();
                }
            }

            destroyChartInstance(dimension.chartDimension);
            const chartDom = dimensionChartDimensionElement;

            const chartInstance = echarts.init(chartDom, {}, serviceInstance.initOpts);
            chartInstance.setOption(serviceInstance.getPieChartOptions(dimension.chartType, chartData));

            dimensionChartLoader.style.display = 'none';

            grid.on('change', (_e: any, _i: any) => chartInstance.resize());
        }
    }

    function destroyChartInstance(dimensionId: string): void {
        const chartDom: any = document.getElementById(`${dimensionId}-chart`);
        const chartInstance = echarts.getInstanceByDom(chartDom);
        if (chartInstance) {
            chartInstance.dispose();
        }
    }

    const destroyChartInstanceByElement = (chartElement: RefObject<HTMLElement>): void => {

        if (chartElement.current){
            const chartInstance = echarts.getInstanceByDom(chartElement.current);
            if (chartInstance) chartInstance.dispose();
        }
    }
    

    function resourceGroupExport(): void {
        let sheet = XLSX.utils.json_to_sheet(resourceGroupData);
        let workbook = { Sheets: { 'Resource Group': sheet }, SheetNames: ['Resource Group'] }
        XLSX.writeFile(workbook, 'ResourceGroups.xlsx');
    }

    function getLegendElement(chartData: IChartData[]): Node[] {
        return chartData?.map((eachChartData, i) => {
            let liElement = document.createElement("li");
            liElement?.setAttribute("data-bs-toggle", "tooltip");
            liElement.title = "";
            liElement?.setAttribute("data-bs-original-title", eachChartData.name);
            liElement.className = "align-items-center cursor-pointer mb-2 legend-text";

            let legendSpan = document.createElement('span');
            legendSpan.className = `legend-sty me-2`;
            legendSpan.style.backgroundColor = serviceInstance.chartColor[i % serviceInstance.chartColor.length];

            let textSpan = document.createElement("span");
            textSpan.innerText = eachChartData.name;

            liElement?.append(legendSpan, textSpan);

            return liElement;
        });
    }

    function drawAwsLinkedChart(dimensionId: string, filterValue: string | null): void {
        let topRegion = null;
        let topService = null;

        const getDashboardDataByDimensionIdResponse = getDashboardDataByDimensionId(dimensionId)

        if (!getDashboardDataByDimensionIdResponse) return

        switch (dimensionId) {
            case 'REGION': {
                topRegion = drawLinkedChart('REGION', null, null);
                topService = drawLinkedChart('SERVICE', 'REGION', topRegion);
                drawLinkedChart('INSTANCE_TYPE', 'SERVICE', topService);
                drawLinkedChart('HIGH_UTILIZATION_REGIONS', 'SERVICE', topService);
                return;
            }
            case 'SERVICE': {
                topService = drawLinkedChart('SERVICE', 'REGION', filterValue);
                drawLinkedChart('INSTANCE_TYPE', 'SERVICE', topService);
                drawLinkedChart('HIGH_UTILIZATION_REGIONS', 'SERVICE', topService);
                return;
            }
            case 'INSTANCE_TYPE': {
                drawLinkedChart('INSTANCE_TYPE', 'SERVICE', filterValue);
                return;
            }
            case 'HIGH_UTILIZATION_REGIONS': {
                drawLinkedChart('HIGH_UTILIZATION_REGIONS', 'REGION', filterValue);
                return;
            }

        }

    }

    function drawAzureLinkedChart(dimensionId: string, filterValue: string | null) {
        let topRegion = null;
        let topService = null;
        let topResourceGroup = null;

        if (!getDashboardDataByDimensionId(dimensionId)) return

        switch (dimensionId) {
            case 'ResourceLocation': {
                topRegion = drawLinkedChart('ResourceLocation', null, null);
                topService = drawLinkedChart('ServiceName', 'ResourceLocation', topRegion);
                topResourceGroup = drawLinkedChart('ResourceGroup', 'ServiceName', topService);
                drawLinkedChart('ResourceId', 'ResourceGroup', topResourceGroup);
                return;
            }
            case 'ServiceName': {
                topService = drawLinkedChart('ServiceName', 'ResourceLocation', filterValue);
                topResourceGroup = drawLinkedChart('ResourceGroup', 'ServiceName', topService);
                drawLinkedChart('ResourceId', 'ResourceGroup', topResourceGroup);
                return;
            }
            case 'ResourceGroup': {
                topResourceGroup = drawLinkedChart('ResourceGroup', 'ServiceName', filterValue);
                drawLinkedChart('ResourceId', 'ResourceGroup', topResourceGroup);
                return;
            }
            case 'ResourceId': {
                drawLinkedChart('ResourceId', 'ResourceGroup', filterValue);
                return;
            }
        }
    }

    function getDashboardDataByDimensionId(dimensionId: string): IDashboardData {

        let tempProviderType = ''
        let tempDashBoardType: any;

        if (providerTypeState === '') {
            tempProviderType = providerType
        }
        else {
            tempProviderType = providerTypeState
        }

        if(dashboard) {
            tempDashBoardType = dashboard.data
        }
        else {
            tempDashBoardType = dashboardState
        }
        
        return queryParams.get("action")
        ? tempDashBoardType.data.find((eachLayout: any) => eachLayout.chartDimension === dimensionId)
        : (tempDashboard?.[tempProviderType.toLowerCase()]?.find((eachLayout: any) => eachLayout.chartDimension === dimensionId) ??
            tempDashboardState?.[tempProviderType.toLowerCase()]?.find((eachLayout: any) => eachLayout.chartDimension === dimensionId));
    }

    function drawLinkedChart(dimensionId: string, filterKey: any, filterValue: string | null) {
        try {
            let chartLayout = getDashboardDataByDimensionId(dimensionId);
            if (!chartLayout) return null;

            const linkedChartLabelElement = document.getElementById(`${dimensionId}-linkedChartLable`)

            if (filterKey && filterValue && linkedChartLabelElement) {
                linkedChartLabelElement.innerText = filterValue;
            }

            let chartData: string | any[] = [];
            let legendElements: any[] = [];


            switch (providerType) {
                case ProviderType.AZURE: {
                    let filteredData = azureLinkedChartData;

                    if (filterValue != null) {
                        filteredData = azureLinkedChartData.filter((each: any) => each[filterKey] === filterValue);
                    }

                    chartData = getChartData(filteredData, dimensionId);
                    createPieChart(chartLayout, chartData);
                    legendElements = getLegendElement(chartData);

                    switch (dimensionId) {
                        case 'ResourceLocation': {
                            if (getDashboardDataByDimensionId('ServiceName')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAzureLinkedChart('ServiceName', textContent);
                                        };
                                    }
                                });
                            }
                            break;
                        }
                        case 'ServiceName': {
                            if (getDashboardDataByDimensionId('ResourceGroup')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAzureLinkedChart('ResourceGroup', textContent);
                                        };
                                    }
                                });
                            }
                            break;
                        }
                        case 'ResourceGroup': {
                            if (getDashboardDataByDimensionId('ResourceId')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAzureLinkedChart('ResourceId', textContent);
                                        };
                                    }
                                });
                            }
                            break;
                        }
                    }
                    let chartLegendDom = document.getElementById(`${dimensionId}-legend`);
                    if (chartLegendDom) {
                        chartLegendDom.replaceChildren(...legendElements);
                    }

                    const deleteElement = document.getElementById(`${dimensionId}-delete`);

                    if(deleteElement) {
                        deleteElement.onclick = () => deleteGrid(dimensionId, true);
                    }
                    return chartData[0].name;
                }

                case ProviderType.AWS: {
                    switch (dimensionId) {
                        case 'REGION': {
                            let filteredData = awsRegionChartData;

                            if (filterValue != null) {
                                filteredData = awsRegionChartData.filter(each => each[filterKey] === filterValue);
                            }

                            chartData = getChartData(filteredData, dimensionId);
                            legendElements = getLegendElement(chartData);

                            if (getDashboardDataByDimensionId('SERVICE')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAwsLinkedChart('SERVICE', textContent);
                                        };
                                    }
                                });
                            }
                            break;
                        }
                        case 'SERVICE': {
                            // TR_DA_190-193
                            let filteredData = awsRegionChartData;
                            if (filterValue != null) {
                                filteredData = awsRegionChartData.filter(each => each[filterKey] === filterValue);
                            }

                            chartData = getChartData(filteredData, dimensionId);
                            legendElements = getLegendElement(chartData);

                            // TR_DA_194
                            if (getDashboardDataByDimensionId('INSTANCE_TYPE') && getDashboardDataByDimensionId('HIGH_UTILIZATION_REGIONS')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLLIElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAwsLinkedChart('INSTANCE_TYPE', textContent);
                                            drawAwsLinkedChart('HIGH_UTILIZATION_REGIONS', textContent);
                                        };
                                    }
                                });
                            }
                            else if (getDashboardDataByDimensionId('INSTANCE_TYPE')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLLIElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAwsLinkedChart('INSTANCE_TYPE', textContent);
                                        };
                                    }
                                });
                            }
                            else if (getDashboardDataByDimensionId('HIGH_UTILIZATION_REGIONS')) {
                                legendElements?.forEach(eachLegendElement => {
                                    if (eachLegendElement instanceof HTMLLIElement) {
                                        eachLegendElement.onclick = (event) => {
                                            const clickedElement = event.target as HTMLElement;
                                            const textContent = clickedElement.innerText;
                                            drawAwsLinkedChart('HIGH_UTILIZATION_REGIONS', textContent);
                                        };
                                    }
                                });
                            }
                            break;
                        }
                        case 'INSTANCE_TYPE':
                        case 'HIGH_UTILIZATION_REGIONS': {
                            let filteredData = dimensionId === 'INSTANCE_TYPE' ? awsInstanceChartData : awsRegionChartData;
                            if (filterValue != null) {
                                filteredData = filteredData.filter(each => each["SERVICE"] === filterValue);
                            }

                            chartData = getChartData(filteredData, dimensionId === 'INSTANCE_TYPE' ? dimensionId : 'REGION');
                            legendElements = getLegendElement(chartData);
                            break;
                        }
                    }

                    createPieChart(chartLayout, chartData);
                    let chartLegendDom = document.getElementById(`${dimensionId}-legend`);
                    let chardeleteElement = document.getElementById(`${dimensionId}-delete`)

                    if (chartLegendDom && chardeleteElement) {
                        chartLegendDom.replaceChildren(...legendElements);

                        chardeleteElement.onclick = () => deleteGrid(dimensionId, true);

                        return chartData.length ? chartData[0].name : null;
                    }
                }

            }
        }
        catch (e) {
            
        }
    }

    function convertCost(cost: number, sourceCurrency: string): ICostCurrency {
        return convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRates)
    }

    function deleteGrid(dimensionId: string, isLinked: boolean) {
        let linkedCharts = new Map();
        let layouts = queryParams.get("action") ? dashboard.data : tempDashboard[providerType.toLowerCase()];

        if (!isLinked) {
            deleteGridById(dimensionId);
            return;
        }

        switch (providerType) {
            case ProviderType.AZURE: {
                switch (dimensionId) {
                case 'ResourceLocation': {
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'ServiceName') ) {
                        linkedCharts.set('ServiceName', 'Services')
                    }
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'ResourceGroup')) {
                        linkedCharts.set('ResourceGroup', 'Resource Groups')
                    }
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'ResourceId')) {
                        linkedCharts.set('ResourceId', 'Resources')
                    }
                break;
                }
                case 'ServiceName': {
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'ResourceGroup')) {
                        linkedCharts.set('ResourceGroup', 'Resource Groups')
                    }
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'ResourceId')) {
                        linkedCharts.set('ResourceId', 'Resources')
                    }
                break;
                }
                case 'ResourceGroup': {
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'ResourceId')) {
                        linkedCharts.set('ResourceId', 'Resources')
                    }
                break;
                }
            }
            break;
            }
            case ProviderType.AWS: {
            switch (dimensionId) {
                case 'REGION': {
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'SERVICE')) {
                        linkedCharts.set('SERVICE', 'Services')
                    }
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'INSTANCE_TYPE')) {
                        linkedCharts.set('INSTANCE_TYPE', 'Instance Type')
                    }
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'HIGH_UTILIZATION_REGIONS')) {
                        linkedCharts.set('HIGH_UTILIZATION_REGIONS', 'High Utilized Regions')
                    }
                break;
                }
                case 'SERVICE': {
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'INSTANCE_TYPE')) {
                        linkedCharts.set('INSTANCE_TYPE', 'Instance Type')
                    }
                    if(layouts.find((eachLayout: any) => eachLayout.chartDimension === 'HIGH_UTILIZATION_REGIONS')) {
                        linkedCharts.set('HIGH_UTILIZATION_REGIONS', 'High Utilized Regions')
                    }
                break;
                }
            }
            break;
            }
        }

        if (!linkedCharts.size) {
            deleteGridById(dimensionId);
            return;
        }

        
        let deleteMessage = joinWithCommaAnd(Array.from(linkedCharts.values()));
        setDeleteLinkedChartData(linkedCharts)
        setDeleteDimensionId(dimensionId)
        setShowDeletePopup(true)
        setDeleteMessageValue(deleteMessage)
    }

    function deleteLinkedChart() {
        deleteGridById(deleteDimensionId);
        if(deleteLinkedChartData) {
            Array.from(deleteLinkedChartData.keys())?.forEach(eachDimension => {
                deleteGridById(eachDimension);
            });
        }
        setShowDeletePopup(false)
    }

    function joinWithCommaAnd(array: string[]): string {
        let prefix = '<span class="color-warning">';
        let suffix = '</span>'
        return array && array.length
            ? array.length === 1
            ? `${prefix}${array[0]}${suffix}.`
            : prefix + array.slice(0, -1).join(`${suffix}, ${prefix}`) + `${suffix} and ${prefix}` + array[array.length - 1] + suffix + '.'
            : " "
    }

    function deleteGridById(dimensionId: string): void {
        setUnsaved(true)
    
        if (queryParams.get("action")) {
            let index = dashboard.data.findIndex((eachLayout: any) => eachLayout.chartDimension === dimensionId);
            dashboard.data.splice(index, 1);
        } else {
            switch (providerType) {
                case ProviderType.AZURE: {
                    let index = tempDashboard.azure.findIndex((eachLayout: any) => eachLayout.chartDimension === dimensionId);
                    tempDashboard.azure.splice(index, 1);
                    break;
                }
                case ProviderType.AWS: {
                    let index = tempDashboard.aws.findIndex((eachLayout: any) => eachLayout.chartDimension === dimensionId);
                    tempDashboard.aws.splice(index, 1);
                    break;
                }
            }
        }
    
        let gridItem = grid.getGridItems().find((eachGrid: any) => eachGrid.gridstackNode.id === dimensionId);
        if(gridItem) {
            grid.removeWidget(gridItem);
        }
        return;
    }

    async function saveDashboard() {
        tempDashboard.defaultDashboardId = defaultDashboardStore?.defaultDashboardId;
        tempDashboard.organizationId = selectedOrganizationDataState.organizationId;
        setSaveLoader(true)
        let gridElements = grid.save(false) as GridStackWidget[];

        tempDashboardState[providerTypeState.toLowerCase()]?.forEach((eachData: any) => {
            let gridItem = gridElements.find(eachItem => eachItem.id === eachData.chartDimension);
            eachData.layout = gridItem;
        });

        try {
            const saveDashBoardResponse = await saveDashboardData(tempDashboardState, providerTypeState, selectedOrganizationDataState.organizationId)
            setSaveLoader(false)
            setUnsaved(false)
            setDefaultDashboardStore(saveDashBoardResponse.data)
            defaultDashboard = saveDashBoardResponse.data
            setTempDashboardState(saveDashBoardResponse.data)
            tempDashboard = saveDashBoardResponse.data
        }
        catch(e) {
            console.log(e, "Error occurred during saveDashboard")
            setSaveLoader(false)
            setUnsaved(false)
        }
    } 

    async function addEditGrid(layout: any) {
        
        setShowAddChartPopup(true)
        setAddEitLayout(layout)
        
        const dimensionListResponse = await getDimensionList(providerTypeState || providerType)

        if(dimensionListResponse.statusCode === 200) {

            let dimensionsToSkip = grid.getGridItems()
            .filter((each: any) => each.gridstackNode.id !== layout?.layout.id).map((each: any) => each.gridstackNode.id);

            let dimensionList = dimensionListResponse.data.filter((eachDimension: any) => !dimensionsToSkip.includes(eachDimension.dimensionKey))
            setDimensionListState(dimensionList)

            if(layout && layout?.dimensionId !== null) {
                let tempData = {
                    chartTypes:  layout.chartType,
                    dimensionKey: layout.chartDimension,
                    dimensionName: layout.title,
                    title: layout.title
                }
    
                handleCategoryChange("Category", layout.title, JSON.stringify(tempData), dimensionList)
            }
        }

    }

    function getChartSrc(chartType: string): string {
        return chartType === 'bar' ? 'images/bar-graph.svg'
            : chartType === 'line' ? 'images/Line-chart.svg'
            : chartType === 'doughNut' ? 'images/Donut-Chart-Icon.svg'
            : 'images/pie-chart.svg'
    }

    function handleCategoryChange(id: string, value: string, data: any, dimensionListParam: any) {
        if (id === "Category") {
            if (value !== "") {
                setCategory(value);
                setisChartTypeEnabled(true);
        
                const jsonData = JSON.parse(data);

                if(value === "Cost Analysis" ) {
                    setChartType(['line', 'bar']);
                }
                else {
                    setChartType(["pie", "doughNut"]);
                }

                let dimensionList;

                if (dimensionListState.length === 0) {
                    dimensionList = dimensionListParam;
                } else {
                    dimensionList = dimensionListState;
                }

                let dimensionFound = dimensionList.find((eachDimension: any) => eachDimension.dimensionKey === jsonData.dimensionKey);
                setSelectedChartType(dimensionFound.chartTypes[0])
                getChartDetails(jsonData.dimensionKey, dimensionFound.chartTypes[0]);
                setSelectedChartData(jsonData)
                setSelectedCategory(jsonData.dimensionName)
            }
            else {
                return
            }
        }
    }
    
    async function getChartDetails(categoryParam: string, type: string): Promise<void> {
        try {
            setisChartTypeEnabled(true)
            switch (categoryParam) {
                case 'TAG':
                case 'TagKey': {
                    break;
                }
                case 'Cost Analysis':
                case 'CostAnalysis': {
                    getAddCostAnalysisChartDetails('monthly', type);
                    break;
                }
                default: {
                
                let dimensionCostRequest: IDimensionCostRequest[] = [...costRequestState];
                dimensionCostRequest?.forEach(eachDimension => eachDimension.groupBy = [{
                    type: providerTypeState === ProviderType.AZURE ? 'Dimension' : 'DIMENSION',
                    name: categoryParam
                }]);
    
                let getCostByDimensionResponse = await getCostByDimension(dimensionCostRequest, providerType || providerTypeState, selectedOrganizationData?.organizationId || selectedOrganizationDataState?.organizationId)
    
                getCostByDimensionResponse = getCostByDimensionResponse?.data?.map((eachResponse: any) => {
                        let convertedCost = convertCost(eachResponse.amount, eachResponse.currency);
                        eachResponse.amount = convertedCost.cost;
                        eachResponse.currency = convertedCost.currencyType;
                        return eachResponse;
                    });
    
                    let chartData = getChartData(getCostByDimensionResponse, categoryParam);
                    setAddDashboardData(chartData)
                    destroyChartInstanceByElement(costChart);
                    destroyChartInstanceByElement(addChartDiv);
    
                    let chartInstance = echarts.init(addChartDiv.current, {}, serviceInstance.initOpts);
                    chartInstance.setOption(serviceInstance.getPieChartOptions(type, chartData));
                    setisChartTypeEnabled(true)
                }
            }
        }
        catch(e) {
            console.log(e, "Error in getChartDetails")
        }
    }

    async function getAddCostAnalysisChartDetails(dateRange: string, type: string) {
        const costAnalysisResponse = await getCostAnalysis(costRequestState, dateRange, providerType || providerTypeState, selectedOrganizationDataState.organizationId)

        let costAnalysisData = costAnalysisResponse?.data.map((eachResponse: any) => {
            let convertedCost = convertCost(eachResponse?.amountDetails, eachResponse?.currency);
            eachResponse.amountDetails = convertedCost.cost;
            eachResponse.currency = convertedCost.currencyType;
            return eachResponse;
        });

        let data = serviceInstance.getCostAnalysisChartData(costAnalysisData, dateRange, providerType || providerTypeState);
        
        destroyChartInstanceByElement(costChart);
        let chartInstance = echarts.init(costChart.current, {}, serviceInstance.initOpts);
        chartInstance.setOption(serviceInstance.getCostAnalysisChartOptions(type, data));

    }

    function setSChartType(chartType: string) {
        setSelectedChartType(chartType)
        getChartDetails(category, chartType)
    }

    function onClose() {
        setShowDashboardList(false)
    }

    async function handleResetToDefault(resetConfirmed: boolean) {
        if(resetConfirmed) {
            setshowSaveDashBoardPopup(true)
            setShowResetToDefault(false)
        }
        else {
            setPageLoader(true)
            tempDashboardState[providerTypeState.toLowerCase()] = [];
            setSaveDashBoardName("")
            setTriggerUseEffect(true)
            setShowResetToDefault(false)
            await saveDashboard();
            setPageLoader(false)
            window.location.reload()
        }
    }

    async function handleSaveDashBoardR(isPrivate: boolean) {
        setSaveAsTemplateLoader(true)
        setSaveAsDashBoardLoader(true)
        dashboard = {
            organizationId: selectedOrganizationDataState.organizationId,
            providerType: providerTypeState,
            data: tempDashboardState[providerTypeState.toLowerCase()],
            name: saveDashBoardName,
            isPrivate: isPrivate
        }

        saveCustomDashboard(isPrivate, isDefaultState);

        tempDashboard[providerTypeState.toLowerCase()] = [];
        tempDashboardState[providerTypeState.toLowerCase()] = [];
        await saveDashboard();
        setTriggerUseEffect(!triggerUseEffect)
        setshowSaveDashBoardPopup(false)
        setShowResetToDefault(false)
        setPageLoader(false)
        setSaveAsTemplateLoader(false)
        setSaveAsDashBoardLoader(false)
    }

    async function saveCustomDashboard(isPrivate: boolean, isDefault: boolean) {
        setUnsaved(false)
        setSaveLoader(true)

        dashboard.name = saveDashBoardName
        dashboard.isPrivate = isPrivate

        let gridElements = grid.save(false) as GridStackWidget[];

        dashboardState?.data?.forEach((eachData: any) => {
            let gridItem = gridElements.find((eachGrid: any) => eachGrid.id === eachData.chartDimension);
            eachData.layout = gridItem;
        });

        let dashboardComponentElement = document.getElementById("dashboard-component")

        if(dashboardComponentElement) {

            let canvas = await html2canvas(dashboardComponentElement, { removeContainer: true });
            canvas.toBlob(async (blob: any) => {
    
            let arrayBuffer = await blob.arrayBuffer();

            let dashboardPayload = {
                data: dashboard.data,
                isPrivate: isPrivate,
                name: saveDashBoardName,
                organzationId: dashboard.organizationId,
                previewUrl: arrayBuffer,
                providerType: providerTypeState
            }

            const saveCustomDashboardSResponse = await saveCustomDashboardS(selectedOrganizationDataState.organizationId, dashboardPayload, isDefault)

            setSaveLoader(false)
            dashboard = saveCustomDashboardSResponse.data;
            setDashboardState(saveCustomDashboardSResponse.data)
            setUnsaved(true)
            setSaveDashBoardName("")
            
            navUsingAnchor(saveCustomDashboardSResponse.data.dashboardId)
    
            }, 'image/jpeg', 0.1);
        }
    }

    const navUsingAnchor = async(id: any) => {

        var tempAnchor = document.createElement('a');
        navigate(`/dashboard?action=view&organizationId=${selectedOrganizationDataState.organizationId}&dashboardId=${id}`);

        document.body.appendChild(tempAnchor);

        var event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });
        tempAnchor.dispatchEvent(event);
    
        document.body.removeChild(tempAnchor);
    }


    const handleSave = () => {

        const addChartDetailToDashboard = (chartDetail: ICreateChartResponse, layout: GridStackWidget) => {
            layout.content = undefined;
            let dashboardData: IDashboardData = {
                chartDimension: chartDetail.chartDimension,
                chartType: chartDetail.chartType,
                title: chartDetail.title,
                layout: layout
            }

            switch (providerTypeState) {
                case ProviderType.AZURE: {
                    if (chartDetail.id) {
                    let index = queryParams.get("action")
                        ? dashboardState.data?.findIndex((eachLayout: any) => eachLayout.chartDimension === chartDetail.id)
                        : tempDashboardState.azure.findIndex((eachLayout: any) => eachLayout.chartDimension === chartDetail.id);
                        queryParams.get("action") ? dashboardState.data[index] = dashboardData : tempDashboardState.azure[index] = dashboardData;
                    } else {
                        queryParams.get("action") ? dashboardState.data.push(dashboardData) : tempDashboardState.azure.push(dashboardData);
                    }
                    break;
                }
                case ProviderType.AWS: {
                    if (chartDetail.id) {
                        let index = queryParams.get("action")
                        ? dashboardState.data.findIndex((eachLayout: any) => eachLayout.chartDimension === chartDetail.id)
                        : tempDashboardState.aws.findIndex((eachLayout: any) => eachLayout.chartDimension === chartDetail.id);
                        queryParams.get("action") ? dashboardState.data[index] = dashboardData : tempDashboardState.aws[index] = dashboardData;
                    } else {
                        queryParams.get("action") ? dashboardState?.data.push(dashboardData) : tempDashboardState.aws.push(dashboardData);
                    }
                    break;
                }
            }
            setShowAddChartPopup(false)
            setSelectedChartType("Select Chart Type")

        }

        let chartDetail: any = {
            id: addEditLayout?.layout?.id || null,
            chartDimension: selectedChartData?.dimensionKey,
            chartType: selectedChartType,
            title: selectedChartData?.dimensionName,
            chartData: addDashboardData
        }

        setUnsaved(true)
        let layout: GridStackWidget = {
            w: 4,
            h: 38,
            minW: 4,
            minH: 38,
            maxH: 38,
            id: chartDetail.chartDimension
        }

        switch (chartDetail.chartDimension) {
            case 'TAG':
            case 'TagKey': {
                break
            }
            case `CostAnalysis`: {
                let costAnalysisDiv = serviceInstance.getCostAnalysisLayout(providerTypeState);
                layout.content = costAnalysisDiv;
                layout.w = 8;
                layout.minW = 8;

                if (chartDetail.id) {
                    let item: any;
                    item = grid.getGridItems().find((eachItem: any) => eachItem.gridstackNode.id === chartDetail.id);
                    grid.removeWidget(item);
                }

                grid.addWidget(layout);

                addChartDetailToDashboard(chartDetail, layout);

                const costAnalysisSelectElement = document.getElementById('costAnalysisSelect')

                if(costAnalysisSelectElement) {
                    costAnalysisSelectElement.onchange = () => getCostAnalysisChartDetails((costAnalysisSelectElement as HTMLSelectElement).value, selectedOrganizationDataState.organizationId);
                }
                
                getCostAnalysisChartDetails('monthly', selectedOrganizationDataState.organizationId);

                break;
            }
            default: {
                let chartDiv = serviceInstance.getChartLayout(chartDetail.chartDimension, chartDetail.title);
                layout.content = chartDiv;

                if (chartDetail.id) {
                    let item: any;
                    item = grid.getGridItems().find((eachItem: any) => eachItem.gridstackNode.id === chartDetail.id);
                    grid.removeWidget(item);
                }
                grid.addWidget(layout);

                addChartDetailToDashboard(chartDetail, layout);
                let dimension: IDashboardData = {
                    chartDimension: chartDetail.chartDimension,
                    chartType: chartDetail.chartType,
                    title: chartDetail.title,
                    layout: layout
                }

                createPieChart(dimension, chartDetail.chartData);
                let legendList = getLegendElement(chartDetail.chartData);
                let chartLegendDom = document.getElementById(`${chartDetail.chartDimension}-legend`);
                if(chartLegendDom) {
                    chartLegendDom.replaceChildren(...legendList);
                }
            }
        }
        destroyChartInstanceByElement(costChart);
        destroyChartInstanceByElement(addChartDiv);
        setSelectedChartData({})
        setSelectedCategory("")
    }

    const downloadDashboard = async() => {

        let dashboardComponentElement = document.getElementById("dashboard-component")

        if(dashboardComponentElement) {
            let canvas = await html2canvas(dashboardComponentElement, { removeContainer: true, scale: 2 });
            let dataUrl = canvas.toDataURL('image/png', 1.0);
            let pdf = new jsPDF({
                orientation: 'l',
                unit: "mm",
                format: [canvas.width, canvas.height]
            });
            pdf.addImage(dataUrl, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save(dashboardState.name);
        }
    }

    const setDefault = async() => {
        await setDefaultDashboard(selectedOrganizationDataState.organizationId, dashboardState.dashboardId)
        navigate('/dashboard')
    }

    const navigateEditDashboard = () => {
        navigate(`/dashboard?action=edit&organizationId=${selectedOrganizationDataState.organizationId}&dashboardId=${dashboardState.dashboardId}`)
    }

    const handleAddChartClose = () => {
        destroyChartInstanceByElement(costChart);
        destroyChartInstanceByElement(addChartDiv);
        setSelectedChartData({})
        setSelectedCategory("")
        setSelectedChartType("Select Chart Type")
    }

    const updateCustomDashboard = async() => {
        setDashboardUpdateLoader(true)
        setSaveLoader(true)
        setUnsaved(false)
    
        let gridElements = grid.save(false) as GridStackWidget[];
        dashboardState?.data?.forEach((eachData: any) => {
            let gridItem = gridElements.find((eachGrid: any) => eachGrid.id === eachData.chartDimension);
            eachData.layout = gridItem;
        });
    
        let dashbooardElement = document.getElementById("dashboard-component")

        if(dashbooardElement) {
            let canvas = await html2canvas(dashbooardElement, { removeContainer: true });
            canvas.toBlob(async blob => {
                if(blob) {
                    let arrayBuffer = await blob.arrayBuffer();
                    dashboardState.previewUrl = arrayBuffer;
    
                    const saveCustomDashboardSResponse = await saveCustomDashboardS(selectedOrganizationDataState.organizationId, dashboardState, false)
                    setSaveLoader(false)
                    setUnsaved(false)
                    navigate(`/dashboard?action=view&organizationId=${selectedOrganizationDataState.organizationId}&dashboardId=${dashboardState.dashboardId}`)
                }
            }, 'image/jpeg', 0.1);
        }
        setDashboardUpdateLoader(false)
    }

    const handleDashBoardSavePopupClose = () => {
        if(!(queryParams.get("action"))) {
            setshowSaveDashBoardPopup(false)
            setShowResetToDefault(true)
        }
        else {
            setshowSaveDashBoardPopup(false)
        }
    }

    const handleApplyFilter = () => {
            setPageLoader(true)
            costRequest = mapApplyCostRequest()

            costRequest[0].accountId = selectedLinkedAccountsState
            costRequest[0].fromDate = advanceFilter.fromDate
            costRequest[0].toDate = advanceFilter.toDate
            costRequest[0].timeframe = advanceFilter.timeFrame
            
            getMonthCostAndThreshold();
            getRecommendations();
        
            switch (providerTypeState) {
                case ProviderType.AZURE: {
                if (queryParams.get("action")) {
                    getAzureCharts(dashboardState.data);
                    getAzureLinkedChartsDetails(dashboardState.data);
                } else {
                    getAzureCharts(tempDashboardState.azure);
                    getAzureLinkedChartsDetails(tempDashboardState.azure);
                }
                if (getDashboardDataByDimensionId('TagKey')) {
                    break
                }
                break;
            }
                case ProviderType.AWS: {
                if (queryParams.get("action")) {
                    getAwsCharts(dashboardState);
                    getAzureLinkedChartsDetails(dashboardState);
                } else {
                    getAwsCharts(tempDashboardState.aws);
                    getAwsLinkedChartsDetails(tempDashboardState.aws);
                }
                if (getDashboardDataByDimensionId('TAG')) {
                    break
                }
                break;
            }
        }
        setPageLoader(false)
    }

    const handleAdvanceFilterClose = () => {
        setAdvanceFilter(advancedFilterObj)
        setIsApplyButtonDiabled(true)
    }

    const handleFilterChange = (id: any, value: any) => {
        setAdvanceFilter(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleLinkedAccountChange = (value: any) => {
        setSelectedLinkedAccountsState(value)
    }

    async function getAwsLinkedChartsDetailsByTags(layouts: IDashboardData[], tags: any) {
        let filteredLayouts = layouts.filter(eachLayout => BaseConstant.awsLinkedCharts.includes(eachLayout.chartDimension))
            .sort((a, b) => BaseConstant.awsLinkedCharts.indexOf(a.chartDimension) - BaseConstant.awsLinkedCharts.indexOf(b.chartDimension));

        let dimensionObsArr: any = [];

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'REGION' || eachLayout.chartDimension === 'SERVICE')) {
            let dimensionCostRequest = [...costRequestState as IDimensionCostRequest[]];

            // only way to copy the nested array by value(not reference).
            let tempCostRequest = JSON.parse(JSON.stringify(dimensionCostRequest));
            tempCostRequest.forEach((eachDimensionCostRequest: any) => {
                eachDimensionCostRequest.groupBy = [
                    {
                        type: 'DIMENSION',
                        name: 'REGION'
                    },
                    {
                        type: 'DIMENSION',
                        name: 'SERVICE'
                    }
                ]
            });

            tempCostRequest[0].tagKey = selectedTag
            tempCostRequest[0].tagValues = tags

            let getCostByDimensionResponse = await getCostByTags(tempCostRequest, providerTypeState)
            dimensionObsArr.push(getCostByDimensionResponse.data);
        }

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'INSTANCE_TYPE')) {
            let dimensionCostRequest = [...costRequestState as IDimensionCostRequest[]];
            // only way to copy the nested array by value(not reference).
            let tempCostRequest = JSON.parse(JSON.stringify(dimensionCostRequest));
            tempCostRequest.forEach((eachDimensionCostRequest: any) => {
                eachDimensionCostRequest.groupBy = [
                    {
                        type: 'DIMENSION',
                        name: 'SERVICE'
                    },
                    {
                        type: 'DIMENSION',
                        name: 'INSTANCE_TYPE'
                    }
                ]
            });

            tempCostRequest[0].tagKey = selectedTag
            tempCostRequest[0].tagValues = tags

            let getCostByDimensionResponse = await getCostByTags(tempCostRequest, providerTypeState)
            dimensionObsArr.push(getCostByDimensionResponse.data);
        }

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'REGION')) {
            awsRegionChartData = dimensionObsArr[0]?.map((eachResponse: any) => {
                let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost.currencyType;
                return eachResponse;
            });
        }

        if (filteredLayouts.find(eachLayout => eachLayout.chartDimension === 'INSTANCE_TYPE')) {
            let instanceResponse = dimensionObsArr[dimensionObsArr.length - 1];
            awsInstanceChartData = instanceResponse?.map((eachResponse: any) => {
                let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                eachResponse.amount = convertedCost.cost;
                eachResponse.currency = convertedCost.currencyType;
                return eachResponse;
            });
        }

        if (filteredLayouts.length) {
            drawAwsLinkedChart(filteredLayouts[0].chartDimension, null);
        }
    }

    async function getAzureLinkedChartsDetailsByTags(layouts: IDashboardData[], tags: any) {
        try {
            let filteredLayouts = layouts.filter(eachLayout => BaseConstant.azureLinkedCharts.includes(eachLayout.chartDimension))
                .sort((a, b) => BaseConstant.azureLinkedCharts.indexOf(a.chartDimension) - BaseConstant.azureLinkedCharts.indexOf(b.chartDimension));

            let groupBy = filteredLayouts.map(eachLayout => {
                return {
                    type: 'Dimension',
                    name: eachLayout.chartDimension
                }
            });

            let dimensionCostRequest: any[] = costRequestState.map((eachRequest: any) => {
                eachRequest.groupBy = groupBy;
                return eachRequest as any;
            });

            
            dimensionCostRequest[0].tagKey = selectedTag
            dimensionCostRequest[0].tagValues = tags

            const costDimensionResponse = await getCostByTags(dimensionCostRequest, providerTypeState)

            if (costDimensionResponse.statusCode === 200) {
                azureLinkedChartData = costDimensionResponse.data.map((eachResponse: any) => {
                    let convertedCost = convertCost(eachResponse.amount, eachResponse?.currency);
                    eachResponse.amount = convertedCost.cost;
                    eachResponse.currency = convertedCost.currencyType;
                    return eachResponse;
                });

                if (filteredLayouts.length) {
                    drawAzureLinkedChart(filteredLayouts[0].chartDimension, null);
                }
            }
        }
        catch (e) {
            console.error(e)
        }
    }


    const handlePushTags = (tags: any) => {
        setSelectedTags((prevSelectedTags: any) => {
            const tagIndex = prevSelectedTags.indexOf(tags);
            if (tagIndex === -1) {
                const addedTag = [...prevSelectedTags, tags]
                if(addedTag.length !== 0) {
                    if(providerTypeState === "AWS") {
                        getAwsLinkedChartsDetailsByTags(tempDashboardState.aws, addedTag)
                    }
                    else {
                        getAzureLinkedChartsDetailsByTags(tempDashboardState.azure, addedTag)
                    }
                }
                return addedTag
            } else {
                const newTags = [...prevSelectedTags];
                newTags.splice(tagIndex, 1);

                if(newTags.length !== 0) {
                    if(providerTypeState === "AWS") {
                        getAwsLinkedChartsDetailsByTags(tempDashboardState.aws, newTags)
                    }
                    else {
                        getAzureLinkedChartsDetailsByTags(tempDashboardState.azure, newTags)
                    }
                }

                return newTags;
            }
        });
    
    }

    return (
        <>
            {/* start of dashboard component */}
            <Header />
            <div className="container-fluid" id="dashboard-component">
                <div className="row justify-content-center">
                    {/* filters starts here */}
                    <div className="col-md-12 dashboard-relative mb-5">
                        <div className="row dashboard-header pb-5 justify-content-center">
                            <div className="col-md-10">
                                {/* <!-- mobile and tab responsive start here--> */}
                                <div className="d-lg-none d-flex mt-4 justify-content-between align-items-center">
                                    <h5 className="db-heading mb-0">Overview</h5>
                                    <div className="dropdown">
                                        <button className="btn secondary-btn filter-icon-sty db-filter-btn px-2 d-flex align-items-center"
                                            data-bs-toggle="dropdown" aria-expanded="false"><span
                                                className="filter-img"></span></button>
                                        <div className="dropdown-menu filter-dropdown p-3" onClick={(e: any) => { e.stopPropagation() }}>
                                            <div className="d-flex justify-content-between">
                                                <h6 className="table-inner-header mb-3">Advanced Filter</h6>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12 mb-3">
                                                    <select className="custom-input py-2 w-100 px-3 form-select" id="Account-ID">
                                                        <option>Select</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 col-sm-12 mb-3"
                                                >
                                                    <label htmlFor="Linked-Account-ID" className="form-label custom-label">Linked
                                                        Account ID</label>
                                                </div>
                                                <div className="col-md-12 col-sm-12 mb-3">
                                                    <label htmlFor="date" className="form-label custom-label">Date</label>
                                                    <select className="custom-input py-2 w-100 px-3 form-select" id="date"
                                                    >
                                                        <option value="MonthToDate">Month to Date</option>
                                                        <option value="Custom">Custom</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mb-3 position-relative"
                                                >
                                                    <label htmlFor="fromDate" className="form-label custom-label">From</label>
                                                    <input className="form-control custom-input py-2 custom-date-picker" id="fromDate" type = "date" autoComplete="disabled" placeholder="mm/dd/yy" />
                                                    <span className="spanPosition">
                                                        <button className="buttonPosition" type="button">
                                                            <img className="imgPosition" src="images/CALANDER.svg" alt="Calendar" />
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="col-md-6 mb-3 position-relative">
                                                    <label htmlFor="toDate" className="form-label custom-label">To</label>
                                                    <input className="form-control custom-input py-2 custom-date-picker" id="toDate" autoComplete="disabled" placeholder="mm/dd/yy" />
                                                    <span className="spanPosition">
                                                        <button className="buttonPosition" type="button">
                                                            <img className="imgPosition" src="images/CALANDER.svg" alt="Calendar" />
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="button" className="btn me-3 secondary-btn "
                                                    >Cancel</button>
                                                    <button type="button" className="btn primary-btn" disabled = {isApplyButtonDiabled} onClick={() => {handleApplyFilter()}}
                                                    >Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- mobile and tab responsive end here--> */}
                                <div className="d-lg-flex d-md-block mt-4 mb-5 justify-content-between align-items-center">
                                    <h5 className="db-heading d-lg-block d-none mb-lg-0 mb-3">Overview</h5>
                                    <div className="d-md-flex d-sm-block align-items-top">
                                        <div className={`dropdown res-margin-20 me-md-4 app-organization-select ${diableGlobalFilter.includes(action)
                                            ? 'action-disabled'
                                            : ''}`}>
                                            {organziationSelectComponent}
                                        </div>
                                        <div className={diableGlobalFilter.includes(action)
                                            ? 'action-disabled'
                                            : ''
                                        }>
                                            {providerSelectComponent}
                                        </div>
                                        <div className={diableGlobalFilter.includes(action)
                                            ? 'action-disabled'
                                            : ''
                                        }>
                                            <div ref={dropdownRef} className="dropdown custom-me me-0 me-lg-4 res-margin-10">
                                                <button className="txt-spacing text-start custom-dropdown-toggle db-custom-input w-100 py-2 text-nowrap d-flex align-items-center dark-theme" type="button" onClick={() => {setShowTagsList(!showTagsList); setShowSubTagsList(false); setSelectedTagIndex("")}}>
                                                <span className="me-3 template-dropdown-icon tag-icon"></span> Tags </button>
                                                {providerTypeState === "AWS" &&
                                                    <>
                                                        {showTagsList &&
                                                            <ul className="dropdown-wid custom-dropdown-sty-dashboard-tags border-0 p-2 submenu-parent" tabIndex={-1} style={{position: "absolute", maxWidth: "max-content", listStyle: "none"}}>
                                                                {tagList.map((eachTags: string, index: number) => (
                                                                    <li className="dropdown submenu-toggle custom-dropdown-brd pb-1">
                                                                        <a id = 'tagAnchorId' key = {index} className={`dropdown-item custom-dropdown-li py-2 px-2 d-flex align-items-center ${index === selectedTagIndex && 'active'}`} style={{marginRight: "10px"}} onClick={() => {getSubTags(eachTags, index)}}>
                                                                            {eachTags} {" "}</a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        }
                                                        {showSubTagsList &&
                                                            <ul id="sub-dropdown" className="dropdown-menu dropdown-wid sub-dropdown-dashboard-tags custom-dropdown-sty border-0 p-2" 
                                                            tabIndex={-1} style={{position: "absolute", maxWidth: "max-content", listStyle: "none"}}>
                                                                {
                                                                    subTagList.map((eachSubTags: any) => (
                                                                        <li className="custom-dropdown-brd pb-1">
                                                                            <span className='dropdown-item custom-dropdown-li py-0 px-2 d-flex align-items-center'>
                                                                                <input type='checkbox' onClick={() => {handlePushTags(eachSubTags)}}/><span className='custom-dropdown-li py-2 px-2'>{eachSubTags}</span>
                                                                            </span>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </>
                                                }
                                                {providerTypeState === "Azure" &&
                                                <>
                                                    {showTagsList &&
                                                        <ul className="dropdown-wid custom-dropdown-sty-dashboard-tags border-0 p-2 submenu-parent" tabIndex={-1} style={{position: "absolute", maxWidth: "max-content", listStyle: "none"}}>
                                                            {azureTagList.map((eachTags: string, index: number) => (
                                                                <li className="dropdown submenu-toggle custom-dropdown-brd pb-1">
                                                                    <a className={`dropdown-item custom-dropdown-li py-2 px-2 d-flex align-items-center ${index === selectedTagIndex && 'active'}`} style={{marginRight: "10px"}} onClick={() => {setShowSubTagsList(!showSubTagsList); getSubTags(eachTags, index)}}>
                                                                        {eachTags} {" "}</a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                    {showSubTagsList &&
                                                        <ul id="sub-dropdown" className="dropdown-menu dropdown-wid sub-dropdown-dashboard-tags custom-dropdown-sty border-0 p-2" 
                                                        tabIndex={-1} style={{position: "absolute", maxWidth: "max-content", listStyle: "none"}}>
                                                            {
                                                                azureSubTagList.map((eachSubTags: any) => (
                                                                    <li className="custom-dropdown-brd pb-1">
                                                                        <span className='dropdown-item custom-dropdown-li py-0 px-2 d-flex align-items-center'>
                                                                            <input type='checkbox' onClick={() => {handlePushTags(eachSubTags)}}/><span className='custom-dropdown-li py-2 px-2'>{eachSubTags}</span>
                                                                        </span>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                </>
                                                }
                                            </div>
                                        </div>
                                        {!diableGlobalFilter.includes(action) &&
                                        <>
                                            <button type="button" className="btn filter-mbl-res res-margin-20 db-btn me-4" disabled = {role === "BillingReader"} onClick={() => {addEditGrid(null)}}>
                                                <span><img src="images/add-chart-icon.svg" alt="add-chart-icon" className="me-2" /></span>
                                                <span className="d-none d-lg-inline-block">Add</span>
                                            </button>
                                            <button type="button" className="btn filter-mbl-res res-margin-20 db-btn me-4" disabled = {!unsaved} onClick={() => {saveDashboard()}}>
                                                {saveLoader &&
                                                    <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader" />
                                                }
                                                <span>
                                                    <img src="images/save-icon.svg" alt="save-icon" className="me-2" />
                                                </span>
                                                <span className="d-none d-lg-inline-block ">Save</span>
                                            </button>
                                        </>
                                        }
                                        <div className={`dropdown filter-mbl-res res-margin-20 me-md-4 ${diableGlobalFilter.includes(action) && 'action-disabled'}`}>
                                            <button className="customize-btn py-2 align-items-center" type="button"
                                                id="customize-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="me-1"><img src="images/customize-icon.svg"
                                                    alt="customize-icon" /></span>
                                                <span className="d-none d-lg-inline-block">Customize</span>
                                                <span className="ms-1"><img src="images/dropdown-arrow-white.svg"
                                                    alt="dropdown-arrow-white" className="customize-arrow" /></span>
                                            </button>
                                            <ul className="dropdown-menu custom-dropdown-sty border-0 p-2"
                                                aria-labelledby="customize-dropdown">
                                                {role !== "BillingReader" &&
                                                    <li className="custom-dropdown-brd py-1">
                                                        <a className="dropdown-item custom-dropdown-li py-2 px-2"
                                                        href = {`/dashboard?action=create&organizationId=${selectedOrganizationDataState.organizationId}&providerType=${providerTypeState}`}>
                                                            Create Dashboard
                                                        </a>
                                                    </li>

                                                }
                                                <li className="custom-dropdown-brd  py-1">
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2" onClick={() => {setShowDashboardList(true);  setSavedCustomizeButton("Templates"); dispatch(setIsDashboardListToggled(true))}}>
                                                        Saved Templates
                                                    </a></li>
                                                {role !== "BillingReader" &&
                                                    <li className="custom-dropdown-brd  py-1"
                                                    >
                                                        <a className="dropdown-item custom-dropdown-li py-2 px-2" onClick={() => {setShowDashboardList(true);  setSavedCustomizeButton("Dashboards"); dispatch(setIsDashboardListToggled(true))}}>
                                                            Saved Dashboards</a></li>

                                                }
                                                <li className="pt-1">
                                                    <a className="dropdown-item py-2 px-2 custom-dropdown-li" onClick={() => setShowResetToDefault(true)}>
                                                        Reset to Default Dashboard
                                                    </a></li>
                                            </ul>
                                        </div>

                                        {/* Advanced Filter */}
                                        <div className="dropdown res-margin-20 d-lg-block d-none">
                                            <button className="btn secondary-btn filter-icon-sty db-filter-btn px-2 d-flex align-items-center"
                                                data-bs-toggle="dropdown" aria-expanded="false" id="dashboard-filter">
                                                <span className="filter-img"></span>
                                            </button>
                                            <div className="dropdown-menu filter-dropdown p-3" onClick={(e: any) => { e.stopPropagation() }}>
                                                <div className="d-flex justify-content-between">
                                                    <h6 className="table-inner-header mb-3">Advanced Filter</h6>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={() => $('#dashboard-filter').trigger('click')}>
                                                    </button>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12 mb-3">
                                                    <label htmlFor="Account-ID" className="form-label custom-label">Account ID</label>
                                                        {scopeSelectComponent}
                                                    </div>
                                                    {selectedFiltrScopeData[0]?.isLinkedAccount &&
                                                        <div className="col-md-6 col-sm-12 mb-3">
                                                            <label htmlFor="Linked-Account-ID" className="form-label custom-label">Linked Account ID</label>
                                                            <Select
                                                                className='multiselect-dropdown'
                                                                options={filterValues}
                                                                onChange={handleLinkedAccountChange}
                                                                allowClear
                                                                mode="multiple"
                                                                placeholder="Select"
                                                                showSearch={false}
                                                            />
                                                        </div>
                                                    }
                                                    {selectedFiltrScope !== "All" &&
                                                        <div className="col-md-12 col-sm-12 mb-3">
                                                            <label htmlFor="date" className="form-label custom-label">Date</label>
                                                            <select className="custom-input py-2 w-100 px-3 form-select" id="timeFrame" onChange={(e) => {handleFilterChange(e.target.id, e.target.value)}}>
                                                                <option value="MonthToDate">Month to Date</option>
                                                                <option value="Custom">Custom</option>
                                                            </select>
                                                        </div>
                                                    }
                                                    {advanceFilter.timeFrame !== "MonthToDate" &&
                                                        <>
                                                            <div className="col-md-6 mb-3 position-relative"
                                                            >
                                                                <label htmlFor="fromDate" className="form-label custom-label">From</label>
                                                                <input className="form-control custom-input py-2 custom-date-picker" type='date' id="fromDate" min={minFromDate} autoComplete="disabled" placeholder="mm/dd/yy" 
                                                                onChange={(e) => {handleFilterChange(e.target.id, e.target.value)}}/>
                                                            </div>
                                                            <div className="col-md-6 mb-3 position-relative">
                                                                <label htmlFor="toDate" className="form-label custom-label">To</label>
                                                                <input className="form-control custom-input py-2 custom-date-picker" type="date" id="toDate" max = {maxToDate} autoComplete="disabled" placeholder="mm/dd/yy" 
                                                                onChange={(e) => {handleFilterChange(e.target.id, e.target.value)}}/>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="d-flex justify-content-end mt-4">
                                                        <button type="button" className="btn me-3 secondary-btn"
                                                            onClick={() => {$('#dashboard-filter').trigger('click'); handleAdvanceFilterClose()}}
                                                        >Cancel</button>
                                                        <button type="button" className="btn primary-btn" disabled = {isApplyButtonDiabled} onClick={() =>{handleApplyFilter(); $('#dashboard-filter').trigger('click');}}
                                                        >Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="grid-stack-parent" className="row justify-content-center" style={{height: '684px'}}>
                        <div id="grid-stack-element" className="grid-stack col-md-10 mt-md-3 pb-5 dashboard-card-pos">

                        </div>
                    </div>
                </div>
            </div>

            {/* Page loader */}
            {pageLoader &&
                <div className="container-fluid">
                    <div className="modal-backdrop fade show" tabIndex={-1}>
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                        </div>
                    </div>
                </div>
            }

            {showDeletePopup && 
            <div id = "deletePopup" className={`modal show fade successModal  ${showDeletePopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog custom-modal-theme" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setShowDeletePopup(false)}}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img className="mt-1" src="images/Alert-Icon.svg" alt="alter"/>
                                <label className="form-label custom-pop-label mt-4 mb-1 w-100">This chart is linked with </label>
                                <label className="form-label custom-pop-label mb-1" id="deletegrid-span"><div
                                    dangerouslySetInnerHTML={{
                                        __html: deleteMessageValue
                                    }}
                                ></div></label>
                                <label className="form-label custom-pop-label mb-1"> If you delete this, the linked charts will also be
                                    deleted.</label>
                                <label className="form-label custom-pop-label mb-1"> Do you want to proceed?</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn secondary-btn me-3" data-bs-dismiss="modal" onClick={() => {deleteLinkedChart()}}
                            >Yes</button>
                            <button type="button" className="btn color-warning-btn" data-bs-dismiss="modal" onClick={() => {setShowDeletePopup(false)}}
                            >No</button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div id="addChart" className={`modal show fade ${showAddChartPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog custom-width-newchart custom-modal-style" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style">Add New Chart</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setShowAddChartPopup(false)}}
                            ></button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-6 mb-3">
                                    <label className="w-100 form-label custom-label" htmlFor="Category">Category</label>
                                    <select className="custom-input py-2 px-3 form-select" id="Category" value={selectedCategory} onChange = {(e) => handleCategoryChange(e.target.id, e.target.value, e.target.selectedOptions[0].dataset.custom, "")}
                                    >
                                        <option value="">Select Category</option>
                                        {dimensionListState?.map((eachDimension: any, index: number) => (
                                            <option key = {index} value={eachDimension?.dimensionName} data-custom={JSON.stringify(eachDimension)} onClick={() => console.log("clicked")}>{eachDimension?.dimensionName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="w-100 form-label custom-label">Chart Type</label>
                                    <div className="dropdown">
                                        <button className="txt-spacing text-start custom-dropdown-toggle custom-input py-2 w-100 text-nowrap" type="button"
                                            id="donut-chart" data-bs-toggle="dropdown" aria-expanded="false" disabled = {!isChartTypeEnabled}
                                        >
                                            <span className="me-3"><img src = {getChartSrc(selectedChartType)} alt="donut" className="template-dropdown-icon" />
                                            </span>{selectedChartType}
                                        </button>
                                        <ul className="dropdown-menu custom-dropdown-sty w-100 border-0 p-2" aria-labelledby="donut-chart">
                                            {chartType?.map((eachType: any) =>(
                                                <li className="custom-dropdown-brd py-1"  onClick={() => {setSChartType(eachType)}}
                                                >
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2">
                                                        <span className="me-2">
                                                            <img src={getChartSrc(eachType)}
                                                                alt="line chart icon" className="template-dropdown-icon"/>
                                                        </span>{eachType}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="custom-subhead mt-4">Preview </h5>
                                    <div className="Preview-chart-bg d-flex justify-content-center align-item-center p-1">
                                        <span className="Preview-img-bg">
                                            <div className="card h-100 dashboard-card-sty position-relative">
                                                <div className="customized-card py-1">
                                                    <div className="d-flex  justify-content-between align-items-center pe-4">
                                                        <h5 className="card-title mb-0 db-card-header">{selectedChartData?.title}</h5>
                                                    </div>
                                                </div>
                                                {isAddChartLoaderEnabled &&
                                                    <div className="gillsans-font font-28 text-end my-3 spinnig-center" >
                                                        <div className="center-aligned">
                                                            <div className="spinner-border" role="status">
                                                                <span className="loader" id="monthTotalCost"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7" ref={addChartDiv}>
                                                        </div>
                                                        <div className="col-md-5 mt-2">
                                                            <ul className="list-unstyled db-wid-scrl mb-1"style={{height: `115px`}}>
                                                                <li
                                                                    className="align-items-center cursor-pointer mb-0 legend-text">
                                                                    <span className="legend-sty me-2"></span>
                                                                    <span ></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mx-1" ref = {costChart} style={{height: '125px'}}>

                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-2">
                            <button type="button" className="btn secondary-btn" onClick={() => {{setShowAddChartPopup(false)}; handleAddChartClose()}}
                            >Cancel</button>
                            <button type="button" className="btn primary-btn ms-3" onClick={() => handleSave()}
                            >save</button>
                        </div>
                    </div>
                </div>
            </div>

            <>
                {dashBoardListComponent}
            </>

            <div id="addChart" className={`modal show fade ${showResetToDefault ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog custom-width-editpopup custom-modal-style" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowResetToDefault(false)}
                            ></button>
                        </div>
                        <div className="modal-body text-center">
                            <label className="form-label custom-pop-label mb-1">Restoring the default dashboard view will overwrite any changes made.</label>
                            <label className="form-label custom-pop-label mb-0">Would you like to save your current settings before proceeding?</label>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4 pt-3">
                            <button type="button" className="btn secondary-btn" onClick={() => handleResetToDefault(true)}
                            >Yes</button>
                            <button type="button" className="btn primary-btn ms-3" onClick={() => handleResetToDefault(false)}
                            >No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="addChart" className={`modal show fade ${showSaveDashBoardPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog custom-modal-style" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style">Save Dashboard</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleDashBoardSavePopupClose() }}
                            ></button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12 mb-3">
                                    <label className="w-100 form-label custom-label" htmlFor="DashboardName">Dashboard Name</label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Dashboard Name" id="DashboardName" onKeyUp={(e) => setSaveDashBoardName((e.target as HTMLInputElement).value)}/>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input type="checkbox" value="" id="defaultdashboard" className="form-check-input custom-checked" onClick={() => setIsDefaultState(true)}/>
                                        <label htmlFor="defaultdashboard" className="form-check-label custom-label"> Set as default dashboard </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-2">
                            <button type="button" className="btn secondary-btn" onClick={() => { handleDashBoardSavePopupClose() }}
                            >Cancel</button>
                            <button type="button" className="btn primary-btn ms-3" onClick={() => handleSaveDashBoardR(true)}
                            >Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal show fade ${showSaveTemplatePopup ? 'show-popup' : ''}`}  id="savetemplate-popup" data-html2canvas-ignore data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content popup-sty border-0 px-3 py-2 custom-border-radius">
                        <div className="modal-header border-0 pb-1">
                            <h5 className="modal-title custom-header-style">Save Template</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ></button>
                        </div>
                        <label className="w-100 px-3 mb-3 custom-template-label">Your template will be visible to everyone in the
                            organization.</label>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12 mb-2">
                                    <label className="w-100 form-label custom-label" htmlFor="TemplateName">Template Name</label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text"
                                        placeholder="Enter Template Name" id="TemplateName" onKeyUp={(e) => setSaveDashBoardName((e.target as HTMLInputElement).value)}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input className="form-check-input custom-checked" type="checkbox" value="" id="defaultTemplate"/>
                                            <label className="form-check-label custom-label" htmlFor="defaultTemplate">
                                                Set as default dashboard
                                            </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-2">
                            <button type="button" className="btn secondary-btn" onClick={() => { handleDashBoardSavePopupClose() }}
                            >Cancel</button>
                            <button type="button" className="btn primary-btn ms-3" onClick={() => handleSaveDashBoardR(false)}
                            >
                                {saveAsTemplateLoader &&
                                    <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader"/>
                                }
                                    Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {queryParams.get("action") &&
                <div className="row">
                    <footer className="px-0 fixed-bottom">
                        <div className="row justify-content-center">
                            <div className="static-strip py-2 position-fixed">
                                <div className="d-flex justify-content-between align-items-center py-1 px-2">
                                    <button type="button" className="btn ms-2 strip-secondary-btn rounded-pill d-lg-block d-none" onClick={() => window.location.href = '/dashboard'}
                                    >
                                        Discard
                                    </button>
                                    {/* <!-- mobile responsive start here--> */}
                                    <div className="d-block d-lg-none">
                                        <button type="button" className="btn rounded-pill p-0">
                                            <span className="d-block d-lg-none"><img src="images/static-lable-close-icon.svg"
                                                alt="static-lable-close-icon" /></span>
                                        </button>
                                    </div>
                                    {/* <!-- mobile responsive end here--> */}
                                    {/* <!-- Create Chart --> */}
                                    {queryParams.get("action") === "create" &&
                                        <div className="d-lg-block d-none">
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2" onClick={() => {addEditGrid(null)}}
                                            >
                                                <span className="me-2"><img src="images/static-lable-add.svg" alt="static-lable-add" /></span>Add chart
                                            </button>
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2"
                                                onClick={() => {setshowSaveDashBoardPopup(true)}}
                                            >
                                                {saveAsDashBoardLoader &&
                                                    <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader" />
                                                }
                                                <span className="me-2"><img src="images/static-label-savedash.svg" alt="static-label-savedash"/>
                                                </span>Save as Dashboard
                                            </button>
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2"
                                                onClick={() => {setShowSaveTemplatePopup(true)}}
                                            >
                                                {saveAsTemplateLoader &&
                                                    <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader" />
                                                }
                                                <span className="me-2">
                                                    <img src="images/static-lable-savetemp.svg" alt="static-lable-savetemp" />
                                                </span>Save as Template
                                            </button>
                                        </div>
                                    }
                                    {/* <!-- Edit Chart --> */}
                                    {queryParams.get("action") === "edit" &&
                                        <div className="d-lg-block d-none">
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2" onClick={() => {addEditGrid(null)}}>
                                                <span className="me-2"><img src="images/static-lable-add.svg" alt="static-lable-add" /></span>
                                                Add chart
                                            </button>
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2" onClick={() => {updateCustomDashboard()}}>
                                                {dashboardUpdateLoader &&
                                                    <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader" />
                                                }
                                                <span className="me-2"><img src="images/static-db-update-icon.svg" alt="static-label-update" /></span>
                                                Update
                                            </button>
                                        </div>
                                    }
                                    {/* <!-- View Chart --> */}
                                    {queryParams.get("action") === "view" &&
                                        <div className="d-lg-block d-none">
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2" onClick={() => {setDefault()}}
                                            >
                                                <span className="me-2"><img src="images/customize-icon.svg" alt="customize-icon" /></span>Set as default</button>
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2" onClick={() => {navigateEditDashboard()}}
                                            >
                                                <span className="me-2"><img src="images/edit-dashboard-view.svg" alt="edit-dashboard-view" /></span>
                                                Edit {`${queryParams.get("type") === "Templates" ? "Template" : "Dashboard"}`}
                                            </button>
                                            <button type="button" className="btn strip-primary-btn rounded-pill mx-2" onClick={() => {downloadDashboard()}}
                                            >
                                                {pdfDownloadLoader &&
                                                    <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader" />
                                                }
                                                <span className="me-2"><img src="images/download-pdf.svg" alt="download-pdf" /></span>
                                                Download as PDF
                                            </button>
                                        </div>
                                    }
                                    {/* <!-- mobile responsive start here--> */}
                                    {/* <!-- Create Chart --> */}
                                    {queryParams.get("action") === "create" && 
                                        <div className="dropup-center dropup d-block d-lg-none">
                                            <button className="btn border-0 p-0 bg-transparent shadow-none" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="images/kebab-icon.svg" alt="kebab-icon" />
                                            </button>
                                            <ul className="dropdown-menu custom-dropdown-sty border-0 p-2">
                                                <li className="custom-dropdown-brd pb-1"
                                                >
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2"
                                                    >
                                                        <span className="me-2"><img src="images/add-green-icon.svg"
                                                            alt="static-lable-add" /></span>Add chart</a>
                                                </li>
                                                <li className="custom-dropdown-brd py-1">
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2"
                                                        data-bs-toggle="modal" data-bs-target="#savedashboard-popup">
                                                        <span className="me-2"><img src="images/save-dashboard-icon.svg"
                                                            alt="static-label-dashboard" /></span>Save as Dashboard
                                                    </a>
                                                </li>
                                                <li className="pt-1">
                                                    <a className="dropdown-item py-2 px-2 custom-dropdown-li"
                                                        data-bs-toggle="modal" data-bs-target="#savetemplate-popup">
                                                        <span className="me-2"><img src="images/save-template-icon.svg"
                                                            alt="static-lable-savetemp"/></span>Save as Template</a>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    {/* <!-- Edit Chart --> */}
                                    {queryParams.get("action") === "edit" &&
                                        <div className="dropup-center dropup d-block d-lg-none">
                                            <button className="btn border-0 p-0 bg-transparent shadow-none" type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="images/kebab-icon.svg" alt="kebab-icon" />
                                            </button>
                                            <ul className="dropdown-menu custom-dropdown-sty border-0 p-2">
                                                <li className="custom-dropdown-brd pb-1">
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2"  onClick={() => {addEditGrid(null)}}
                                                    >
                                                        <span className="me-2"><img src="images/add-green-icon.svg" alt="static-lable-add" /></span>Add
                                                        chart</a>
                                                </li>
                                                <li className="pt-1">
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2" onClick={() => {updateCustomDashboard()}}
                                                    >
                                                        <span className="me-2"><img src="images/static-db-mbl-update-icon.svg" alt="static-label-updtae" /></span>
                                                        Update
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    {/* <!-- View Chart --> */}
                                    {queryParams.get("action") === "view" && 
                                        <div className="dropup-center dropup d-block d-lg-none">
                                            <button className="btn border-0 p-0 bg-transparent shadow-none" type="button" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img src="images/kebab-icon.svg" alt="kebab-icon" />
                                            </button>
                                            <ul className="dropdown-menu custom-dropdown-sty border-0 p-2">
                                                <li className="custom-dropdown-brd pb-1">
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2" onClick={() => {setDefault()}}
                                                    >
                                                        <span className="me-2"><img src="images/static-default-db-mbl-icon.svg" alt="customize-icon" /></span>
                                                        Set as default</a>
                                                </li>
                                                <li className="custom-dropdown-brd py-1">
                                                    <a className="dropdown-item custom-dropdown-li py-2 px-2" onClick={() => {navigateEditDashboard()}}
                                                    >
                                                        <span className="me-2"><img src="images/static-db-mbl-edit-icon.svg" alt="edit-dashboard-view" /></span>
                                                        Edit {`${queryParams.get("type") === "Templates" ? "Template" : "Dashboard"}`}</a>
                                                </li>
                                                <li className="pt-1" onClick={() => {downloadDashboard()}}>
                                                    <a className="dropdown-item py-2 px-2 custom-dropdown-li"
                                                    >
                                                    {pdfDownloadLoader &&
                                                        <img src="gif/ccm-button-loader.gif" alt="white-loader" className="btn-loader" />
                                                    }
                                                        <span className="me-2"><img src="images/static-db-mbl-export-icon.svg" alt="download-pdf" /></span>
                                                        Download as PDF</a>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    {/* <!-- mobile responsive ends here--> */}
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            }
        </>
    )
}
