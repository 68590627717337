import '../../assets/scss/invoice.css'
import { useState, useMemo,useEffect } from 'react'
import {
  IOrganizationData,
  ProviderDetail,
  defaultOrganization
} from '../../interface/organization'
import $ from 'jquery'
import Header from '../Header/Header'
import { formatDate, formatShortDate } from '../../service/dateFormatterService'
import {
  ProviderSelect,
  ProviderSelectProps
} from '../ResuableComponents/ProviderSelect'
import { ScopeSelect, ScopeSelectProp } from '../ResuableComponents/ScopeSelect'
import { OrganizationSelect } from '../ResuableComponents/OrganizationSelect'
import { compare } from '../../utils/comparator'
import {
  downloadInvoice,
  getDueInvoices
} from '../../service/environmentService'
import moment from 'moment'
import { useEncryptStorage } from '../../hooks/useEncryptStorage';
import { IExchangeRate, exchangeRateObj } from '../../interface/dashboard';
import {convertCostData, getExchangeRate} from '../../service/currencyService'
import { roundToDecimalPlaces } from "../../service/numberFormat";

export const Invoices = () => {
  const TDate = moment(new Date())
  const TodayDate = TDate.format('YYYY-MM-DD')
  const oneMonthAgo = TDate.subtract(1, 'months')
  const MonthDate = oneMonthAgo.format('YYYY-MM-DD')

  const [invoiceFilter, setinvoiceFilter] = useState<any>({
    invoiceId: '',
    type: '',
    status: '',
    toDate: TodayDate,
    fromDate: MonthDate
  })

  const [selectedIndex, setselectedIndex] = useState<number>()
  const [applyDispable, setApplyDispable] = useState<boolean>(false)
  const [invoiceData, setInvoiceData] = useState<any[]>([])
  const [filteredInvoiceData, setFilteredInvoiceData] = useState<any[]>([])
  const [invoiceAwsData, setInvoiceAwsData] = useState<any[]>([])
  const [filteredAWSInvoiceData, setFilteredAWSInvoiceData] = useState<any>([])
  const [exchangeRate,setExchangeRate] = useState<IExchangeRate>(exchangeRateObj)
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false)
  const [organization, setOrganization] =
    useState<IOrganizationData>(defaultOrganization)
  const [providerType, setProviderType] = useState<any>('')
  // reusable dropdowns
  useEffect(() => {
    async function fetchExchangeRate() {
        const response = await getExchangeRate();
        // Assuming the response contains the exchange rate as a string
        const rate = response.data; // Extract exchange rate from response data
        setExchangeRate(rate);
    }
    fetchExchangeRate();
  }, []);
  const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
      organizationId: 0,
      providerTypes: [],
      onProviderTypeChange: (organizationId, providerType) => {}
    })

  const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: '',
    onScopeChange: scope => {}
  })
  // pagination
  const pageLimit = 7
  const [currentLength, setCurrentLength] = useState(pageLimit)
  // loader
  const [pageLoader, setPageLoader] = useState(true)
  const [loader, setLoader] = useState(false)
  const [awsloader, setawsLoader] = useState(false)
  const [tenentId, settenentId] = useState<any>()
  const [selectedScope, SetselectedScope] = useState<ProviderDetail>()
  const [secretName, SetsecretName] = useState<any>()
  const [SubId, SetsubId] = useState<any>()
  const [accId, SetaccId] = useState<any>()
  const [aws, setAws] = useState<boolean>()
  const [organizationId, setOrganizationId] = useState<any>()
  const [providerId, SetproviderId] = useState<any>()
  function handleLoadMore () {
    setCurrentLength(currentLength => currentLength + pageLimit)
    // call fetchAllEvents()
  }
  useEffect(() => {
    async function fetchExchangeRate() {
        const response = await getExchangeRate();
        const rate = response.data; 
        setExchangeRate(rate);
    }
    fetchExchangeRate();
  }, []);
  const convertCost = (cost: number, sourceCurrency: string) =>{
    if (useEncryptStorage.getItem("currency") === sourceCurrency ) {
        return cost
    } else {
        const convertedCost = convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRate)
        const convertedExchangeCost = roundToDecimalPlaces(convertedCost.cost,2)
        return convertedExchangeCost
    }
  }

  const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
  ) => {
    setPageLoader(true)
    setOrganization(selectedOrganization)
    setProviderTypeSelectProps({
      organizationId: selectedOrganization.organizationId,
      onProviderTypeChange: onProviderTypeChange,
      providerTypes: selectedOrganization.providerType
    })
  }

  const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
  ) => {
    setPageLoader(true)
    setScopeSelectProps({
      organizationId: organizationId,
      providerType: selectedProviderType,
      onScopeChange: onScopeChange
    })
    setProviderType(selectedProviderType)
  }

  const onScopeChange = async (
    selectedScope: ProviderDetail | ProviderDetail[]
  ) => {
    setPageLoader(true)
    selectedScope = selectedScope as ProviderDetail
    SetselectedScope(selectedScope)
    SetsecretName(selectedScope.secretName)
    settenentId(selectedScope.tenantId)
    SetsubId(selectedScope.subscriptionId)
    SetaccId(selectedScope.accountId)
    SetproviderId(selectedScope.providerId)
    setOrganizationId(selectedScope.organizationId)
    let Payload
    if (selectedScope.providerType === 'AWS') {
      setAws(true)
      Payload = {
        accountId: selectedScope.accountId,
        secretName: selectedScope.secretName,
        subscriptionId: '',
        tenantId: ''
      }
    } else if (selectedScope.providerType === 'Azure') {
      setAws(false)
      Payload = {
        accountId: '',
        secretName: selectedScope.secretName,
        subscriptionId: selectedScope.subscriptionId,
        tenantId: selectedScope.tenantId
      }
    }
    await getInvoices(selectedScope.providerType,invoiceFilter,Payload)
  }

  const handleFilterDetails = (e: any) => {
    setinvoiceFilter({
      ...invoiceFilter,
      [e?.target?.id]: e?.target?.value
    })
  }

  const DownloadAWS = async (index: number) => {
    setawsLoader(true)
    setselectedIndex(index)
    let request = {
      secretName: secretName,
      accountId: accId,
      providerId: providerId,
      fromDate: invoiceFilter.fromDate,
      toDate: invoiceFilter.toDate
    }
    const response = await downloadInvoice(
      request,
      organizationId,
      providerType
    )
    if (response.statusCode == 200) {
      if (response?.data) {
        setawsLoader(false)
        const reader = new FileReader()
        const NewDate = moment(new Date()).format('HH:mm:ss DD/MM/YYYY')
        const blob = new Blob([response?.data], {
          type: 'text/csv'
        })
        reader.onload = () => {
          // Create a temporary anchor element and trigger the download
          const link: any = document.createElement('a')
          link.href = reader.result
          link.download = NewDate + '.csv'
          document.body.appendChild(link)
          link.click()
          // Cleanup
          document.body.removeChild(link)
        }
        reader.readAsDataURL(blob)
      }
    }else{
      setErrorPopUp(true)
    }
  }
  const download = async (docs: any, index: any) => {
    setLoader(true)
    setselectedIndex(index)
    let docUrls = docs.map((each:any) => each.url)
    let request = {
      secretName: secretName,
      tenantId: tenentId,
      subscriptionId: SubId,
      documentUrls: docUrls,
      providerId: providerId
    }
    const response = await downloadInvoice(
      request,
      organizationId,
      providerType
    )
    if (response.statusCode == 200 && !aws) {
      window.location.href = response?.data?.url ? response?.data?.url : ''
      setLoader(false)
    }else{
      setErrorPopUp(true)
      setLoader(false)
    }
  }
  const ClearInput=()=>{
    // $('#invoice-filter').trigger('click')
    setinvoiceFilter((prevFilter: any) => {
      const updatedFilterValue = {
        invoiceId: '',
        type: '',
        status: '',
        toDate: TodayDate,
        fromDate: MonthDate
      }
        getInvoices('',updatedFilterValue,'')
        return updatedFilterValue
    })
  }
  const getInvoices =async(providerValue: string,invoiceFilter:any,Payload:any)=>{
    setPageLoader(true)
    const invoicePayload = {
      secretName: secretName?secretName:Payload.secretName,
      tenantId: tenentId ? tenentId:Payload.tenantId,
      subscriptionId: SubId ? SubId : Payload.subscriptionId,
      accountId: accId ? accId:Payload.accountId,
      fromDate: invoiceFilter.fromDate,
      toDate: invoiceFilter.toDate
    }

    let result
    setTimeout(async () => {
      if (providerValue === '') {
        $('#invoice-filter').trigger('click')
        result = await getDueInvoices(invoicePayload, providerType)
      } else {
        result = await getDueInvoices(invoicePayload, providerValue)
      }
   
      if (result.statusCode === 200) {
        if (!result.data || !result.data.length) {
          setInvoiceData([])
          setFilteredInvoiceData([])
          setPageLoader(false)
          setinvoiceFilter({
            ...invoiceFilter,
              invoiceId: '',
              type: '',
              status: '',
              toDate: TodayDate,
              fromDate: MonthDate
        })
        return
      }
      if (providerValue === 'Azure' || providerType === 'Azure') {
        setInvoiceData(result.data)
        setFilteredInvoiceData(result.data)
        setPageLoader(false)
        filterDueInvoices()
      }
      if (providerValue === 'AWS' || providerType === 'AWS') {
        setInvoiceAwsData(result.data)
        setFilteredAWSInvoiceData(result.data)
        setPageLoader(false)
      }
    }else{
      setErrorPopUp(true)
      setPageLoader(false)
    }
  },3000)
  }

  const filterDueInvoices = () => {
    if (invoiceFilter.invoiceId != '') {
      const filtereddata=filteredInvoice(invoiceFilter.invoiceId, 'invoiceId')
      setFilteredInvoiceData(filtereddata)
    } else if (invoiceFilter.type != '') {
      setFilteredInvoiceData(filteredInvoice(invoiceFilter.type, 'type'))
    } else if (invoiceFilter.status != '') {
      setFilteredInvoiceData(filteredInvoice(invoiceFilter.status, 'status'))
    }
  }

  const filteredInvoice = (value: any, key: any) => {
    return key == 'invoiceId'
      ? invoiceData.filter(each => each.invoiceId.includes(value))
      : key === 'type'
      ? invoiceData.filter(each => each.invoiceType === value)
      : invoiceData.filter(each => each.status === value)
  }

  const sort = (sortKey: any, order: string): void => {
    let sortedList = filteredInvoiceData?.sort((a: any, b: any) =>
      compare(a[sortKey], b[sortKey], order)
    )
    setFilteredInvoiceData([...sortedList])
  }
  const sortAws = (sortKey: any, order: string): void => {
    let sortedList = filteredAWSInvoiceData?.sort((a: any, b: any) =>
      compare(a[sortKey], b[sortKey], order)
    )
    setFilteredAWSInvoiceData([...sortedList])
  }
  const uniqueStatuses = Array.from(
    new Set(filteredInvoiceData?.map((eachDetail: any) => eachDetail.status))
  )
  const type = Array.from(
    new Set(filteredAWSInvoiceData?.map((eachDetail: any) => eachDetail.type))
  )
  const statusval = Array.from(
    new Set(filteredAWSInvoiceData?.map((eachDetail: any) => eachDetail.value))
  )
  // organization, provider, scope components
  const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
    []
  )
  const providerSelectComponent = useMemo(
    () => <ProviderSelect {...providerTypeSelectProps} />,
    [providerTypeSelectProps]
  )
  const scopeSelectComponent = useMemo(
    () => <ScopeSelect {...scopeSelectProps} />,
    [scopeSelectProps]
  )
  

  const InvoiceGridGeneration = () => {
    return(
    filteredAWSInvoiceData 
    &&(filteredAWSInvoiceData
      .slice(0, currentLength)
      .map((eachInvoice: any, index: number) => (
            <tr>
              <td className='grid-primary-text'>{' '}{useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseInt(roundToDecimalPlaces(parseFloat(eachInvoice?.totalBlendedCost), 2)), "USD")}</td>
              <td>{formatDate(new Date(eachInvoice.billingPeriodStart))}</td>
              <td>{formatDate(new Date(eachInvoice.billingPeriodEnd))}</td>
              <td className='text-center'>
                {' '}
                <button
                  type='button'
                  className='btn export-btn'
                  onClick={() => {
                    DownloadAWS(index)
                  }}
                >
                  Export
                  {awsloader ? (
                    <span className='spinner-border spinner-border-sm mr-1'></span>
                  ) : (
                    <></>
                  )}
                </button>
              </td>
            </tr>
    )))
    )
  }
  const InvoiceAzureGrid = () => {
    return(
    filteredInvoiceData &&
          (filteredInvoiceData
            .slice(0, currentLength)
            .map((eachInvoice: any, index: any) => (
                <tr key={index}>
                  <td className='grid-primary-text'> {eachInvoice.invoiceId} </td>
                  <td>
                    {`${formatDate(new Date(eachInvoice.invoicePeriodStartDate))} 
                  - ${formatDate(new Date(eachInvoice.invoicePeriodEndDate))} `}
                  </td>
                  <td>{formatDate(new Date(eachInvoice.dueDate))}</td>
                  <td>{eachInvoice.invoiceType}</td>
                  <td className="grid-primary-text text-end">
                    {useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}
                    {convertCost(parseInt(roundToDecimalPlaces(eachInvoice.amountDue, 2)),eachInvoice.currency)
                  }</td>
                  <td className='grid-primary-text text-end'>
                    {useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}
                    {convertCost(parseInt(roundToDecimalPlaces(eachInvoice.totalAmount, 2)),eachInvoice.currency)}
                  </td>
                  <td className="grid-primary-text text-center">{eachInvoice.status}</td>
                  <td className='text-center'>
                    {' '}
                    <button
                      type='button'
                      className='btn export-btn'
                      onClick={() => {
                        download(eachInvoice.documents, index)
                      }}
                    >
                      Export
                      {loader && selectedIndex == index ? (
                        <span className='spinner-border spinner-border-sm mr-1'></span>
                      ) : (
                        <></>
                      )}
                    </button>
                  </td>
                </tr>
            )))
        )
      }

  return (
    <>
      <Header />
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          {/* <div className='col-md-12 dashboard-relative mb-5'> */}
          {/* <div className='row dashboard-header pb-5 justify-content-center'> */}
          <div className='col-md-10'>
            {/* <!-- mobile and tab responsive start here--> */}
            <div className='d-block d-lg-none d-flex justify-content-between align-items-center my-4'>
              <h5 className='page-header mb-0'>Invoices</h5>
              <div className='dropdown ms-md-3'>
                {/* Add formGroup prop and onClick event */}
                <button
                  className='btn secondary-btn filter-icon-sty px-2 d-flex align-items-center'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  //  onClick={toggleDropdown()}
                >
                  <span className='filter-img'></span>
                </button>
                <div className='dropdown-menu filter-dropdown p-3'>
                  <div className='d-flex justify-content-between'>
                    <h6 className='table-inner-header mb-3'>Advanced Filter</h6>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {ClearInput()}}
                    ></button>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-sm-12 mb-3'>
                      <label
                        htmlFor='Invoice-ID'
                        className='form-label custom-label'
                      >
                        Invoice ID
                      </label>
                      <input
                        type='text'
                        disabled={aws}
                        className='form-control custom-input py-2 button-disabled'
                        id='invoiceId'
                        placeholder='Enter Invoice ID'
                        value={invoiceFilter.invoiceId}
                        onChange={e => handleFilterDetails(e)}
                      />
                      {invoiceFilter.invoiceId.length > 32 && (
                    <span className='text-danger error-span'>
                     Invoice ID should not be more than 32 characters
                    </span>
                  )}
                    </div>
                    <div className='col-md-6 col-sm-12 mb-3'>
                      <label htmlFor='Type' className='form-label custom-label'>
                        Type
                      </label>
                      <select
                        disabled={aws}
                        className='custom-input py-2 w-100 px-3 form-select'
                        id='type'
                        onChange={e => handleFilterDetails(e)}
                        value={invoiceFilter.type}
                      >
                        <option value='' selected>
                          Select
                        </option>

                        {aws
                          ? type.map((eachDetail: any, index: any) => (
                              <option key={index} >{eachDetail}</option>
                            ))
                          : filteredInvoiceData.map(
                              (eachDetail: any, index: any) => (
                                <option key={index} value={invoiceFilter.type}>
                                  {eachDetail.invoiceType}
                                </option>
                              )
                            )}
                      </select>
                    </div>
                    <div className='col-md-6 col-sm-12 mb-3'>
                      <label
                        htmlFor='Status'
                        className='form-label custom-label'
                      >
                        Status
                      </label>
                      <select
                        disabled={aws}
                        className='custom-input py-2 w-100 px-3 form-select'
                        id='status'
                        onChange={e => handleFilterDetails(e)}
                        value={invoiceFilter.status}
                      >
                        <option value='' selected>
                          Select
                        </option>
                        {/* Map over status array and render options */}
                        {aws
                          ? statusval.map((eachDetail: any, index: any) => (
                              <option key={index} >{eachDetail}</option>
                            ))
                          : uniqueStatuses.map(
                              (eachDetail: any, index: any) => (
                                <option key={index} >{eachDetail}</option>
                              )
                            )}
                      </select>
                    </div>
                    <h6 className='table-inner-header mb-2'>Date Range</h6>
                    {/* Date pickers */}
                    {/* Add onClick event for date picker */}
                    <div className='col-md-6 mb-3 custom-date-picker'>
                      <label
                        htmlFor='fromDate'
                        className='form-label custom-label'
                      >
                        From
                      </label>
                      <input
                        className='w-100 custom-input form-control py-2 px-2'
                        type='date'
                        id='fromDate'
                        max={new Date().toISOString().split('T')[0]} 
                        min={(new Date(new Date().setMonth(new Date().getMonth() - 12))).toISOString().split('T')[0]}
                        value={invoiceFilter?.fromDate}
                        onBlur={() =>
                          setApplyDispable(
                            invoiceFilter?.fromDate === '' ? true : false
                          )
                        }
                        onChange={e => handleFilterDetails(e)}
                      />
                    </div>
                    <div className='col-md-6 mb-3 custom-date-picker'>
                      <label
                        htmlFor='toDate'
                        className='form-label custom-label'
                      >
                        To
                      </label>
                      <input
                        className='w-100 custom-input form-control py-2 px-2'
                        type='date'
                        id='toDate'
                        value={invoiceFilter?.toDate}
                        onBlur={() =>
                          setApplyDispable(
                            invoiceFilter?.toDate === '' ? true : false
                          )
                        }
                        onChange={e => handleFilterDetails(e)}
                        max={new Date().toISOString().split('T')[0]} 
                        min={(new Date(new Date().setMonth(new Date().getMonth() - 12))).toISOString().split('T')[0]}
                      />
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                      <button type='button' className='btn me-3 secondary-btn'
                      onClick={() => {ClearInput()}}>
                        Cancel
                      </button>
                      <button
                        type='button'
                        className='btn primary-btn'
                        onClick={() => getInvoices('',invoiceFilter,'')}
                        disabled={applyDispable}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- mobile and tab responsive ends here--> */}
            <div className='d-lg-flex d-md-block justify-content-between align-items-center my-4'>
              <h5 className='page-header mb-0 d-lg-block d-none'>Invoices</h5>
              <div className='d-md-flex d-sm-block'>
                <div className=' dropdown me-md-4 res-margin-20 app-organization-select'>
                  {organziationSelectComponent}
                </div>
                {providerSelectComponent}
                {scopeSelectComponent}
                {/* Advanced Filter */}
                <div className='dropdown ms-md-3 d-lg-block d-none'>
                  <button
                    className='btn secondary-btn filter-icon-sty px-2 d-flex align-items-center'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    id='invoice-filter'
                  >
                    <span className='filter-img'></span>
                  </button>
                  <div onClick={(event) => event.stopPropagation()} className='dropdown-menu filter-dropdown p-3'>
                    <div className='d-flex justify-content-between'>
                      <h6 className='table-inner-header mb-3'>
                        Advanced Filter
                      </h6>
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={() => {ClearInput()}}
                      ></button>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-sm-12 mb-3'>
                        <label
                          htmlFor='Invoice-ID'
                          className='form-label custom-label'
                        >
                          Invoice ID
                        </label>
                        <input
                          type='text'
                          className='form-control custom-input py-2'
                          id='invoiceId'
                          placeholder='Enter Invoice ID'
                          disabled={aws}
                          onChange={e => handleFilterDetails(e)}
                          value={invoiceFilter.invoiceId}
                        />
                        {invoiceFilter.invoiceId.length > 32 && (
                    <span className='text-danger error-span'>
                     Invoice ID should not be more than 32 characters
                    </span>
                  )}
                      </div>
                      <div className='col-md-6 col-sm-12 mb-3'>
                        <label
                          htmlFor='Type'
                          className='form-label custom-label'
                        >
                          Type
                        </label>
                        <select
                          disabled={aws}
                          className='custom-input py-2 w-100 px-3 form-select'
                          id='type'
                          onChange={e => handleFilterDetails(e)}
                          value={invoiceFilter.type}
                        >
                          <option value='' selected>
                            Select
                          </option>
                          {/* Map over type array and render options */}
                          {aws
                            ? type.map((eachDetail: any, index: any) => (
                                <option key={index}>{eachDetail}</option>
                              ))
                            : filteredInvoiceData.map(
                                (eachDetail: any, index: any) => (
                                  <option key={index}>
                                    {eachDetail.invoiceType}
                                  </option>
                                )
                              )}
                        </select>
                      </div>
                      <div className='col-md-6 col-sm-12 mb-3'>
                        <label
                          htmlFor='Status'
                          className='form-label custom-label'
                        >
                          Status
                        </label>
                        <select
                          disabled={aws}
                          className='custom-input py-2 w-100 px-3 form-select'
                          id='status'
                          onChange={e => handleFilterDetails(e)}
                          value={invoiceFilter.status}
                        >
                          <option value='' selected>
                            Select
                          </option>
                          {/* Map over type array and render options */}
                          {aws
                            ? statusval.map((eachDetail: any, index: any) => (
                                <option key={index} >{eachDetail}</option>
                              ))
                            : uniqueStatuses.map(
                                (eachDetail: any, index: any) => (
                                  <option key={index} >{eachDetail}</option>
                                )
                              )}
                        </select>
                      </div>
                      <h6 className='table-inner-header mb-2'>Date Range</h6>
                      {/* Date pickers */}
                      {/* Add onClick event for date picker */}
                      <div className='col-md-6 mb-3 custom-date-picker'>
                        <label
                          htmlFor='fromDate'
                          className='form-label custom-label'
                        >
                          From
                        </label>
                        <input
                          className='w-100 custom-input form-control py-2 px-2'
                          type='date'
                          id='fromDate'
                          value={invoiceFilter?.fromDate}
                          max={new Date().toISOString().split('T')[0]} 
                          min={(new Date(new Date().setMonth(new Date().getMonth() - 12))).toISOString().split('T')[0]}
                          onBlur={() =>
                            setApplyDispable(
                              invoiceFilter?.fromDate === '' ? true : false
                            )
                          }
                          onChange={e => handleFilterDetails(e)}
                        />
                      </div>
                      <div className='col-md-6 mb-3 custom-date-picker'>
                        <label
                          htmlFor='toDate'
                          className='form-label custom-label'
                        >
                          To
                        </label>
                        <input
                          className='w-100 custom-input form-control py-2 px-2'
                          type='date'
                          id='toDate'
                          value={invoiceFilter?.toDate}
                          onBlur={() =>
                            setApplyDispable(
                              invoiceFilter?.toDate === '' ? true : false
                            )
                          }
                          onChange={e => handleFilterDetails(e)}
                          max={new Date().toISOString().split('T')[0]} 
                          min={(new Date(new Date().setMonth(new Date().getMonth() - 12))).toISOString().split('T')[0]}
                        />
                      </div>
                      <div className='d-flex justify-content-end mt-4'>
                        <button
                          type='button'
                          className='btn me-3 secondary-btn'
                          onClick={() => {ClearInput()}}
                        >
                          Cancel
                        </button>
                        <button
                          type='button'
                          className='btn primary-btn'
                          onClick={() => getInvoices('',invoiceFilter,'')}
                          disabled={applyDispable}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {providerType === 'AWS' && (
              <div className='row mb-5'>
                <div className='col-md-12'>
                  {/* <!--table starts  --> */}
                  <div className='table-responsive'>
                    <table className='table table-borderless custom-grid '>
                      <thead className='custom-table-head'>
                        <tr>
                          <th className='custom-grid-font-weight position-relative'>
                            TOTAL BLENDED COST
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() =>
                                  sortAws('totalBlendedCost', 'Asc')
                                }
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() =>
                                  sortAws('totalBlendedCost', 'Desc')
                                }
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            BILLING PERIOD START
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() =>
                                  sortAws('billingPeriodStart', 'Asc')
                                }
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() =>
                                  sortAws('billingPeriodStart', 'Desc')
                                }
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            BILLING PERIOD END
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() =>
                                  sortAws('billingPeriodEnd', 'Asc')
                                }
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() =>
                                  sortAws('billingPeriodEnd', 'Desc')
                                }
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th
                            className='text-center custom-grid-font-weight'
                          >
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      {filteredAWSInvoiceData.length !=0 ? (
                        <tbody>
                          {InvoiceGridGeneration()}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr className='custom-alternative-line'>
                            <td colSpan={8} className='text-center'>
                              <img
                                src='images/no-rec-found.gif'
                                alt='no-rec-found'
                                className='no-data-found-sty mb-4'
                              />
                              <p className='para-text'>No data found</p>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <p className='mb-3 mt-2 para-text'>
                    # of Records:{' '}
                    <span>
                      {filteredAWSInvoiceData?.length} out of{' '}
                      {filteredAWSInvoiceData?.length}
                    </span>
                  </p>
                  {filteredAWSInvoiceData?.length > currentLength && (
                    <div className='row justify-content-center'>
                      <button
                        type='button'
                        className='btn secondary-btn mt-2 mb-5'
                        onClick={handleLoadMore}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {/* < !--table ends-- > */}
                </div>
              </div>
            )}
            {providerType === 'Azure' && (
              <div className='row mb-5'>
                <div className='col-md-12'>
                  {/* <!--table starts  --> */}
                  <div className='table-responsive'>
                    <table className='table table-borderless custom-grid '>
                      <thead className='custom-table-head'>
                        <tr>
                          <th className='custom-grid-font-weight position-relative'>
                            INVOICE ID
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('invoiceId', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('invoiceId', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            BILLING PERIOD
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() =>
                                  sort('invoicePeriodStartDate', 'Asc')
                                }
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() =>
                                  sort('invoicePeriodStartDate', 'Desc')
                                }
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            DUE DATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('dueDate', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('dueDate', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            TYPE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('invoiceType', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('invoiceType', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='text-end custom-grid-font-weight position-relative'>
                            DUE AMOUNT
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('amountDue', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('amountDue', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='text-end custom-grid-font-weight position-relative'>
                            TOTAL AMOUNT
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('totalAmount', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('totalAmount', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='text-end custom-grid-font-weight position-relative'>
                            STATUS
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('status', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('status', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th
                            className='text-center custom-grid-font-weight'
                          >
                            ACTION
                          </th>
                        </tr>
                      </thead>
                      {filteredInvoiceData.length != 0 ? (
                        <tbody>{InvoiceAzureGrid()}</tbody>
                      ) : (
                        <tbody>
                          <tr className='custom-alternative-line'>
                            <td colSpan={8} className='text-center'>
                              <img
                                src='images/no-rec-found.gif'
                                alt='no-rec-found'
                                className='no-data-found-sty mb-4'
                              />
                              <p className='para-text'>No data found</p>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <p className='mb-3 mt-2 para-text'>
                    # of Records:{' '}
                    <span>
                      {filteredInvoiceData?.length} out of{' '}
                      {filteredInvoiceData?.length}
                    </span>
                  </p>
                  {filteredInvoiceData?.length > currentLength && (
                    <div className='row justify-content-center'>
                      <button
                        type='button'
                        className='btn secondary-btn mt-2 mb-5'
                        onClick={handleLoadMore}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {/* < !--table ends-- > */}
                </div>
              </div>
            )}

            {/* < !--cost anomly ends here-- > */}
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      {pageLoader ? (
        <div className='container-fluid'>
          <div
            className='modal-backdrop fade show'
            id='AddOrganization-popup'
            data-bs-keyboard='false'
            tabIndex={-1}
            aria-hidden='true'
          >
            <div className='position-absolute top-50 start-50 translate-middle'>
              <img
                src='images/cloud-load-gif.gif'
                alt='white-loader'
                className='page-loader'
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* start of error popup */}
				<div className={`modal show fade ${errorPopUp ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setErrorPopUp(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Error.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Error Occured
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setErrorPopUp(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of error popup */}
    </>
  )
}
