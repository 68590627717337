// ForgotPassword.tsx
import { useState, useEffect } from 'react';
import { checkUserExist } from '../../service/userService';
import { forgetPassword } from '../../service/authService';
import { useNavigate} from 'react-router-dom'

export default function ForgetPassword() {
	const navigate = useNavigate()
	// PS_FP_03
	const [email, setEmail] = useState<string>('');
	// PS_FP_04
	const [emailRequieredError, setEmailRequieredError] = useState<boolean>(false);
	const [validEmailError, setvalidEmailError] = useState<boolean>(false);
	const [emailLengthError, setEmailLengthError] = useState<boolean>(false);
	const [userCheckError, setUserCheckError] = useState<boolean>(false);
	const [businessEmailError, setBusinessEmailError] = useState<boolean>(false);
	// PS_FP_05
	const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
	const [buttonDisable, setButtonDisable] = useState<boolean>(true);
	const [loader, setLoader] = useState<boolean>(false)
	// PS_FP_06
	const [errorMessage, setErrorMessage] = useState<string>("")

	// PS_FP_12
	const validateBusinessEmail = (email : string) => {
		const invalidDomains = ["gmail", "yahoo", "live", "outlook"]; // Replace with your list of invalid domains
		const invalidEmailFound = invalidDomains.some(eachDomain => {
		  return eachDomain.toLowerCase() === email.split('@')[1].split('.')[0].toLowerCase();
		});
		return invalidEmailFound;
	  }
	  
	const handleInputChange = (e: any) => {
		// PS_FP_08
		const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

		// PS_FP_09
		setEmail((prevEmail) => {
			const updatedEmail = prevEmail = e.target.value

			// PS_FP_10
			if (e.target?.value === "") {
				setEmailRequieredError(true)
				setEmailLengthError(false)
				setvalidEmailError(false)
				setUserCheckError(false)
				setBusinessEmailError(false)
				setButtonDisable(true)
			}
			else if (!regex.test(updatedEmail)) {
				setvalidEmailError(true)
				setEmailRequieredError(false)
				setBusinessEmailError(false)
				setUserCheckError(false)
				setEmailLengthError(false)
				setButtonDisable(true)
			}
			else if (updatedEmail.length > 320) {
				setEmailLengthError(true)
				setvalidEmailError(false)
				setEmailRequieredError(false)
				setBusinessEmailError(false)
				setUserCheckError(false)
				setButtonDisable(true)
			}
			// PS_FP_11
			else if (validateBusinessEmail(updatedEmail)) {
				setBusinessEmailError(true)
				setvalidEmailError(false)
				setEmailRequieredError(false)
				setEmailLengthError(false)
				setUserCheckError(false)
				setButtonDisable(true)
			}
			else if (!validateBusinessEmail(updatedEmail)) {
				setBusinessEmailError(false)
				setvalidEmailError(false)
				setEmailRequieredError(false)
				setEmailLengthError(false)
				setUserCheckError(false)
				setButtonDisable(true)
			}
			return updatedEmail
		})
	}

	const userCheck = async(e: any) => {
		// PS_FP_14
		if (e.target.value !== "" && emailLengthError === false && validEmailError === false && emailRequieredError === false) {
			// PS_FP_15
			const userExistsResponse = await checkUserExist(email);
	
			// PS_FP_18
			if (userExistsResponse.statusCode === 200 && userExistsResponse.data.message === "Already Exists") {
				setvalidEmailError(false)
				setButtonDisable(false)
				setUserCheckError(false)
				setEmailLengthError(false)
				setEmailRequieredError(false)
				setBusinessEmailError(false)
			}
			else if (userExistsResponse.statusCode == 200 && userExistsResponse.data.message === "Does Not Exists") {
				setUserCheckError(true)
				setvalidEmailError(false)
				setEmailLengthError(false)
				setEmailRequieredError(false)
				setBusinessEmailError(false)
				setButtonDisable(true)
			}
		}
	}

	const handleSendInstruction = async() => {
		// PS_FP_21
		setLoader(true)

		// PS_FP_22
		if (!validateBusinessEmail(email)) {
			// PS_FP_23
			const sendInstructionResponse = await forgetPassword(email)

			// PS_FP_25
			if (sendInstructionResponse.statusCode === 200) {
				setShowSuccessMessage(true)
				setEmail('')
				setButtonDisable(true)
				setLoader(false)
			}
			else if (sendInstructionResponse.statusCode !== 200) {
				setErrorMessage(sendInstructionResponse.data.message)
				setShowErrorMessage(true)
				setLoader(false)
			}
		}
		else {
			setLoader(false)
		}
	}

	return (
		<>
		  <div className="container-fluid">
			{/* <!-- login starts here --> */}
			<div className="row">
			  {/* <!-- login left part starts here --> */}
			  <div className="col-lg-6 d-lg-block d-none p-0">
				<div className="bg-gradient-primary bg-height text-center position-relative my-0 rounded-0">
				  <div className="mx-auto">
					<img src="images/login-image.svg" alt="login-image" className="login-image-sty mt-5 mb-5" />
				  </div>
				</div>
			  </div>
			  {/* <!--login left part ends here --> */}
			  <div className="col-lg-6 col-md-12 login-mbl-margin align-self-center">
				<div className="form-signup">
				  <h5 className="page-header mb-3">Forgot Password</h5>
				  <div className="col-md-12 mb-3">
					<label className="form-label custom-labelcustom-label">Enter the email associated with your account and we'll send an email with the instructions to reset your password</label>
				  </div>
				  <div className="row">
					<div className="col-md-12 mb-3">
					  <label htmlFor="email" className="form-label custom-label">Email</label>
					  <input
						type="email"
						className="form-control custom-input py-2"
						id="email"
						placeholder="Enter email address"
						value={email}
						// PS_FP_07
						onChange={(e) => handleInputChange(e)}
						// PS_FP_13
						onBlur={(e) => { userCheck(e); setEmailRequieredError(email === ""); setShowSuccessMessage(false)}}
					  />
	  
					  {emailRequieredError &&
						<span className="signup-errors d-block">
						  Email ID is required
						</span>
					  }
					  {validEmailError &&
						<span className="signup-errors d-block">
						  Enter a valid Email ID
						</span>
					  }
					  {emailLengthError &&
						<span className="signup-errors d-block">
						  Email should not be more than 320 characters
						</span>
					  }
					  {userCheckError &&
						<span className="signup-errors d-block">
						  User does not exist
						</span>
					  }
					  {businessEmailError &&
						<span className="signup-errors d-block">
						  Please enter a valid business Email ID
						</span>
					  }
	  
					</div>
					<div className="col-md-12 my-3">
					  {/* PS_FP_20 */}
					  <button className="btn primary-btn w-100" type="button" disabled={buttonDisable} onClick={() => handleSendInstruction()}>
						{loader &&
						  <span className="spinner-border spinner-border-sm mr-1"></span>
						}
						Send Instructions
					  </button>
					</div>
					<div className="col-md-12 mb-2">
					  {showSuccessMessage &&
						<div className="alert alert-success py-2" role="alert">
						  <strong className="font-12">The Instruction E mail has been successfully sent</strong>
						</div>
					  }
					  {showErrorMessage &&
						<div className="alert alert-warning py-2" role="alert">
						  <strong className="font-12">{errorMessage}</strong>
						</div>
					  }
					</div>
					<div className="col-md-12 mb-2">
					  {/* PS_FP_27, 28*/}
					  <p className="login-link-text">Redirect to
						<span onClick={() => navigate('/login')}>
						  <a className='text-decoration-none link-sty ms-2'>Sign In</a>
						</span>
					  </p>
					</div>
				  </div>
				</div>
			  </div>
	  
			  {/* <!-- login form ends here --> */}
			</div>
			{/* <!-- login ends here --> */}
		  </div>
		</>
	  )
}
				
	  
