import { client } from '../client/Client'
import { BlobServiceClient } from "@azure/storage-blob";
import { key } from '../hooks/useEncryptStorage'
import { IFilterRequest, INewUserDetails } from '../interface/manage';

export async function fetchNewAccessToken() {
    let response = await client("/user/refreshToken", "", "GET");
    return response;
}

export async function fetchUserProfile() {
    let response = await client("/user/profile", "", "GET");
    return response;
}

export async function fetchPossibleOwner() {
    let response = await client("/user/ownershipTransfer/users", "", "GET");
    return response;
}

export async function postNewOwnerDetail(email: string, selectedLoginId: number | null) {
    let newOwnerObj = {
        email: email,
        loginId: selectedLoginId

    }
    let response = await client("/user/ownershipTransfer/transfer", newOwnerObj, "PUT");
    return response;
}

export async function putUserProfileDetails(userProfile: any, tempProfileUrl: any) {
    let newProfileObj = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        profileUrl: tempProfileUrl !== "" ? tempProfileUrl : userProfile.profileUrl,
        currency: userProfile.currency
    }
    let response = await client("/user/profile", newProfileObj, "PUT")

    return response
}

export async function updateUserProfileImage(file: any, fileName: any, isUserProfile: boolean) {
    let blobClient = new BlobServiceClient(`https://${key.ACCOUNT_NAME}.blob.core.windows.net?${key.PROFILE_IMAGE_SAS_TOKEN}`).getContainerClient(`${key.PROFILE_IMAGE_CONTAINER}`);
        
    let bClient = blobClient.getBlockBlobClient(fileName);
    bClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });
    
    let newProfileUrl = `https://${key.ACCOUNT_NAME}.blob.core.windows.net/${key.PROFILE_IMAGE_CONTAINER}/` + fileName;

    return newProfileUrl
}   

export async function updateOrganizationProfileImage(fileName: any, file : any) {
    // PS_ORG_1.21
    let blobClient = new BlobServiceClient(`https://${key.ACCOUNT_NAME}.blob.core.windows.net?${key.SAS_TOKEN}`).getContainerClient(`${key.CONTAINER_NAME}`);
    let bClient = blobClient.getBlockBlobClient(fileName);
    bClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });
    
    let newProfileUrl = `https://${key.ACCOUNT_NAME}.blob.core.windows.net/${key.CONTAINER_NAME}/` + fileName;

    return newProfileUrl
}

export async function putUserPasswordDetails(passwordData: any) {

    let newPasswordObj = {
        currentPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
    }
    let response = await client("/user/updatepassword", newPasswordObj, "PUT")
    
    return response
}

export async function postUpdatedTheme(theme: any) {
    let payload = {
        theme: theme
    }

    let response = await client("/user/darkMode", payload, "PUT")
    return response
}

export async function getUsers(payload: IFilterRequest, organizationId: number) {
    let response = await client(`/manage/users/organization/${organizationId}`,payload, "POST")

    return response
}

export async function getUserByEmail(email: string) {
    let response = await client(`/manage/user/email/${email}`,"", "GET")

    return response
}

export async function createUser(payload: any) {
    let response = await client(`/manage/users`,payload, "POST")

    return response
}

export async function deleteUserData(payload: any) {
    let response = await client('/manage/user',payload, "DELETE")

    return response
}

export async function updateUser(payload: any) {
    let response = await client(`/manage/user`,payload, "PUT")

    return response
}

export async function checkUserExist(email: string) {
    // PS_FP_16
    let response = await client(`/authorize/user/exists/${email}`, "", "GET");
    // PS_FP_17
    return response;
}
export async function checkPasswordExist(payload: any) {
    // PS_FP_16
    let response = await client(`/user/checkpassword`, payload, "POST");
    // PS_FP_17
    return response;
}