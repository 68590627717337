//PS_PV_01 - PS_PV_13
import "../../assets/scss/_popup-style.scss"
import "../../assets/scss/_appcomponent.scss"
import "../../assets/scss/manageproviders.css";
import { Tooltip } from "antd";
import { useMemo, useState,useEffect } from "react";
import { deleteProviderDetail, fetchProviders, fetchTestConnection, updateProviderDetails } from "../../service/providerService";
import Header from "../Header/Header";
import {IOrganizationData, ProviderDetail, defaultOrganization } from "../../interface/organization";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { IAwsProviderPayload, IAzureProviderDetails, IProviderPayload, IProviderResponse, IAWSSecret, ISecretKey, ISecretKeyResponse, IAzureSecret } from "../../interface/manage";
import { fetchSecretKeyDetails, postSecretKeyDetails } from "../../service/secretservice";
import { padWithALeadingZero } from "../../utils/numberUtils";
import { useSelector } from "react-redux";

//PS_PV_14
export default function Provider() {
    let providerPayload: IProviderPayload = {
        page: 10,
        searchText: "",
        sortBy: "desc",
        orderBy: "",
        providerType: "",
    };

    let providerResponse: IProviderResponse = {
        providercount: 0,
        providerdetails: [],
    };

    let secretKeyObj: ISecretKey = {
        organizationId: null,
        page: null,
        providerType: "",
        searchText: ""
    }

    let secretKeyResponseObj: ISecretKeyResponse = {
        secretcount: 0,
        secretdetails: []
    }

    let awsProviderPayload: IAwsProviderPayload = {
        accountId: "",
        connectorName: "",
        description: "",
        isLinkedAccount: false,
        organizationId: 0,
        provider: "",
        secretId: 0,
        secretName: "",
        providerId: 0
    }

    let awsAddProviderObject: IAWSSecret = {
        secretId: 0,
        secretName: "",
        providerType: "",
        organizationId: 0,
        values: {
            accessKey: "",
            secretKey: ""
        }
    }

    let azureAddProviderObject: IAzureSecret = {
        organizationId: 0,
        providerType: "",
        secretId: 0,
        secretName: "",
        values: {
            clientid: "",
            clientSecret: ""
        }
    }

    let azureProviderPayload: IAzureProviderDetails = {
        connectorName: "",
        description: "",
        organizationId: 0,
        provider: "",
        secretId: 0,
        secretName: "",
        subscriptionId: "",
        tenantId: "",
        providerId: 0
    };

    //common states
    const [showPageLoader, setShowPageLoader] = useState<boolean>(false);
    const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
    const [providerType, setProviderType] = useState("");
    const [providerDetails, setProviderDetail] = useState<IProviderResponse>(providerResponse);
    const [editIndex, setEditIndex] = useState<number>(0);
    const [deleteIndex, setDeleteIndex] = useState<number>(0);
    const [addedProviderSuccessPopup, setAddedProviderSuccessPopup] = useState<boolean>(false)
    const [testConnectionSuccessPopup, setTestConnectionSuccessPopup] = useState<boolean>(false)
    const [showAzurePopup, setShowAzurePopup] = useState<boolean>(false)
    const [showAWSPopup, setShowAWSPopup] = useState<boolean>(false)
    const [secretKeys, setSecretKeys] = useState<ISecretKeyResponse>(secretKeyResponseObj)
    const [showAWSAddSecretPopup, setShowAWSAddSecretPopup] = useState<boolean>(false)
    const [showAzureAddSecretPopup, setShowAzureAddSecretPopup] = useState<boolean>(false)

    //states for storing values regarding aws
    const [awsConnectorNameRequired, setAwsConnectorNameRequires] = useState<boolean>(false)
    const [awsAccountIdRequired, setAwsAccountIdRequired] = useState<boolean>(false)
    const [awsValidAccountIdRequired, setAwsValidAccountIdRequired] = useState<boolean>(false)
    const [awsSecretKeyRequired, setAwsSecretKeyRequired] = useState<boolean>(false)
    const [isAwsTestConnectionButtonDiabled, setIsAwsTestConnectionButtonDiabled] = useState<boolean>(true)
    const [awsTestConnectionLoaderActive, setAwsTestConnectionLoaderActive] = useState<boolean>(false)
    const [isAwsSaveButtonDiabled, setIsAwsSaveButtonDiabled] = useState<boolean>(true)
    const [awsSaveLoaderActive, setAwsSaveLoaderActive] = useState<boolean>(false)
    const [showNotAddedErrorPopup, setShowNotAddedErrorPopup] = useState<boolean>(false)
    const [awsAddSecretPayload, setAwsAddSecretPayload] = useState<IAWSSecret>(awsAddProviderObject)
    const [awsSecretNameRequired, setAwsSecretNameRequired] = useState<boolean>(false)
    const [awsAccessRequired, setAwsAccessRequired] = useState<boolean>(false)
    const [awsNewSecretKeyRequired, setAwsNewSecretKeyRequired] = useState<boolean>(false)
    const [awsAddProvderSaveLoader, setAwsAddProvderSaveLoader] = useState<boolean>(false)
    const [awsAddProvderSaveButton, setAwsAddProvderSaveButton] = useState<boolean>(true)
    const [awsProviderDetails, setAwsProviderDetails] = useState<IAwsProviderPayload>(awsProviderPayload)
    
    //states for storing values regarding azure
    const [azureConnectorNameRequired, setAzureConnectorNameRequired] = useState<boolean>(false)
    const [azureTenantIdRequired, setAzureTenantAccountIdRequired] = useState<boolean>(false)
    const [azureSubscriptionIdRequired, setAzureSubscriptionIdRequired] = useState<boolean>(false)
    const [azureSecretKeyRequired, setAzureSecretKeyRequired] = useState<boolean>(false)
    const [azureInValidTenantIdError, setAzureInValidTenantAccountIdError] = useState<boolean>(false)
    const [azureInValidSubscriptionIdError, setAzureInValidSubscriptionIdRequired] = useState<boolean>(false)
    const [isAzureTestConnectionButtonDiabled, setIsAzureTestConnectionButtonDiabled] = useState<boolean>(true)
    const [azureTestConnectionLoaderActive, setAzureTestConnectionLoaderActive] = useState<boolean>(false)
    const [isAzureSaveButtonDiabled, setIsAzureSaveButtonDiabled] = useState<boolean>(true)
    const [azureSaveLoaderActive, setAzureSaveLoaderActive] = useState<boolean>(false)
    const [azureAddSecretPayload, setAzureAddSecretPayload] = useState<IAzureSecret>(azureAddProviderObject)
    const [azureSecretNameRequired, setAzureSecretNameRequired] = useState<boolean>(false)
    const [azureClientIdRequired, setAzureClientIdRequired] = useState<boolean>(false)
    const [azureClientSecretKeyRequired, setAzureClientSecretKeyRequired] = useState<boolean>(false)
    const [azureAddProvderSaveLoader, setAzureAddProvderSaveLoader] = useState<boolean>(false)
    const [azureAddProvderSaveButton, setAzureAddProvderSaveButton] = useState<boolean>(true)
    const [azureProviderDetails, setAzureProviderDetails] = useState<IAzureProviderDetails>(azureProviderPayload)
    const [isEditing, setIsEditing] = useState(false);
    const role = useSelector((state: any) => state.role.role)
    useEffect(() => {
        const tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        )
        const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl: Element) => {
          return new (window as any).bootstrap.Tooltip(tooltipTriggerEl)
        })
    
        return () => {
          tooltipList.forEach((tooltip: any) => tooltip.dispose())
        }
      }, [])
    const [pageIndex, setPageIndex] = useState<number>(10)

    const [providerTypeSelectProps, setProviderTypeSelectProps] = useState<ProviderSelectProps>({
        organizationId: 0,
        providerTypes: [],
        onProviderTypeChange: (organizationId, providerType) => { },
    });

    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        onScopeChange: (scope) => { },
    });

    const onOrganizationChange = async (
        selectedOrganization: IOrganizationData
    ) => {
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: ["AWS", "Azure"]
        });
        setOrganization(selectedOrganization);
    };

    const onProviderTypeChange = async (
        organizationId: number,
        selectedProviderType: string
    ) => {
        setProviderType(selectedProviderType);
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            onScopeChange: onScopeChange,
        });
        setProviderType(selectedProviderType);
        getProviderGridDetails(organizationId, selectedProviderType);
    };

    const onScopeChange = async (
        selectedScope: ProviderDetail | ProviderDetail[]
    ) => {
        selectedScope = selectedScope as ProviderDetail;
    };

    //PS_PV_15
    const organziationSelectComponent = useMemo(
        () => <OrganizationSelect onOrganizationChange={onOrganizationChange}
                showAll = {true}/>,
        []
    );
    const providerSelectComponent = useMemo(
        () => <ProviderSelect {...providerTypeSelectProps} />,
        [providerTypeSelectProps]
    );

    const getProviderGridDetails = async (
        organizationId: number,
        selectedProviderType: string
    ) => {
        setShowPageLoader(true);
        providerPayload.providerType = selectedProviderType;
        const providerDetailsResponse = await fetchProviders(
            providerPayload,
            organizationId
        );

        if (providerDetailsResponse.statusCode === 200) {
            setProviderDetail(providerDetailsResponse.data);
        }

        setShowPageLoader(false);
    };

    // PS_PV_32 - PS_PV_45
    const handleSort = async (orderBy: string, sortBy: string) => {
        setShowPageLoader(true)
        providerPayload.orderBy = orderBy;
        providerPayload.sortBy = sortBy;
        providerPayload.providerType = providerType;

        const providerDetailsResponse = await fetchProviders(
            providerPayload,
            organization.organizationId
        );

        if (providerDetailsResponse.statusCode === 200) {
            setProviderDetail(providerDetailsResponse.data);
        }

        setShowPageLoader(false);
    };

    //PS_PV_52 - PS_PV_64, PS_PV_70
    const getSecretKeyDetails = async () => {
        secretKeyObj.organizationId = organization.organizationId
        secretKeyObj.page = 1000
        secretKeyObj.providerType = providerType

        const secretKeyResponse = await fetchSecretKeyDetails(secretKeyObj)

        if (secretKeyResponse.statusCode === 200) {
            setSecretKeys(secretKeyResponse.data)
        }
    }

    const handleAwsProviderDetailsChange = (id: string, value: any) => {
        const accountIdRegex = /^[0-9]{12}$/;
        setAwsProviderDetails((prevData: any) => {
            let updatedAwsProviderDetails = { ...prevData }
            if (id !== "secretId") {
                updatedAwsProviderDetails = { ...prevData, [id]: value }

                //validations
                if (id === "connectorName" && value === "") {
                    setAwsConnectorNameRequires(true)
                }
                else if (id === "connectorName" && value !== "") {
                    setAwsConnectorNameRequires(false)
                }

                if (id === "accountId" && value === "") {
                    setAwsAccountIdRequired(true)
                    setAwsValidAccountIdRequired(false)
                }
                else if (id === "accountId" && value !== "") {
                    setAwsAccountIdRequired(false)
                    if (accountIdRegex.test(value)) {
                        setAwsValidAccountIdRequired(false)
                    }
                    else if (!(accountIdRegex.test(value))) {
                        setAwsValidAccountIdRequired(true)
                    }
                }
            }
            else if (id === "secretId") {
                if (value !== "Select") {
                    setAwsSecretKeyRequired(false)
                    const secrets = value.split("#$%");

                    updatedAwsProviderDetails = {
                        ...prevData,
                        secretId: parseInt(secrets[0]),
                        secretName: secrets[1]
                    };
                }
                else if (value === "Select") {
                    updatedAwsProviderDetails = {
                        ...prevData,
                        secretId: 0,
                        secretName: ""
                    };
                    setAwsSecretKeyRequired(true)
                }
            }

            if (
                updatedAwsProviderDetails.connectorName !== "" &&
                updatedAwsProviderDetails.accountId !== "" &&
                updatedAwsProviderDetails.secretId !== 0 &&
                (accountIdRegex.test(updatedAwsProviderDetails.accountId))
            ) {
                setIsAwsTestConnectionButtonDiabled(false)
                setIsAwsSaveButtonDiabled(false)
            }
            else {
                setIsAwsTestConnectionButtonDiabled(true)
                setIsAwsSaveButtonDiabled(true)
            }
            return updatedAwsProviderDetails;
        })
    }

    const handleAzureProviderDetailsChange = (id: string, value: any) => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
        setAzureProviderDetails((prevData: any) => {
            let updatedAzureProviderDetails;
            if(id !== "secretId") {
                updatedAzureProviderDetails = { ...prevData, [id]: value }

                if(id === "connectorName") {
                    if(value === "") {
                        setAzureConnectorNameRequired(true)
                    }
                    else if (value !== "") {
                        setAzureConnectorNameRequired(false)
                    }
                }

                if(id === "tenantId") {
                    if(value === "") {
                        setAzureTenantAccountIdRequired(true)
                        setAzureInValidTenantAccountIdError(false)
                    }
                    else if (value !== "") {
                        setAzureTenantAccountIdRequired(false)
                        if (!(uuidRegex.test(value))) {
                            setAzureInValidTenantAccountIdError(true)
                        }
                        else if ((uuidRegex.test(value))) {
                            setAzureInValidTenantAccountIdError(false)
                        }
                    }
                }

                if(id === "subscriptionId") {
                    if(value === "") {
                        setAzureSubscriptionIdRequired(true)
                        setAzureInValidSubscriptionIdRequired(false)
                    }
                    else if (value !== "") {
                        setAzureSubscriptionIdRequired(false)
                        if (!(uuidRegex.test(value))) {
                            setAzureInValidSubscriptionIdRequired(true)
                        }
                        else if ((uuidRegex.test(value))) {
                            setAzureInValidSubscriptionIdRequired(false)
                        }
                    }
                }

            }
            else if (id === "secretId") {
                if (value !== "") {
                    setAzureSecretKeyRequired(false)
                    const secrets = value.split("#$%");
                    updatedAzureProviderDetails = {
                        ...prevData,
                        secretId: parseInt(secrets[0]),
                        secretName: secrets[1]
                    };
                }
                else if (value === "") {
                    updatedAzureProviderDetails = {
                        ...prevData,
                        secretId: 0,
                        secretName: ""
                    };
                    setAzureSecretKeyRequired(true)
                }
            }

            if (
                updatedAzureProviderDetails.connectorName !== "" &&
                updatedAzureProviderDetails.subscriptionId !== 0 &&
                updatedAzureProviderDetails.secretId !== 0 &&
                updatedAzureProviderDetails.tenantId !== 0 &&
                (uuidRegex.test(updatedAzureProviderDetails.tenantId)) &&
                (uuidRegex.test(updatedAzureProviderDetails.subscriptionId))
            ) {
                setIsAzureTestConnectionButtonDiabled(false)
                setIsAzureSaveButtonDiabled(false)
            }
            else {
                setIsAzureTestConnectionButtonDiabled(true)
                setIsAzureSaveButtonDiabled(true)
            }

            return updatedAzureProviderDetails
        })
    }

    const awsTestConnection = async () => {
        setAwsTestConnectionLoaderActive(true)
        awsProviderDetails.organizationId = organization.organizationId
        awsProviderDetails.provider = providerType

        const testConnectionResponse = await fetchTestConnection(awsProviderDetails)

        if (testConnectionResponse.statusCode === 200) {
            setTestConnectionSuccessPopup(true)
        }
        else if (testConnectionResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAWSPopup(false)
        }
        setAwsTestConnectionLoaderActive(false)
    }

    const awsAddProvider = async () => {
        setAwsSaveLoaderActive(true)
        awsProviderDetails.organizationId = organization.organizationId
        awsProviderDetails.provider = providerType
        const updateProviderResponse = await updateProviderDetails(awsProviderDetails, awsProviderDetails.providerId)

        if (updateProviderResponse.statusCode === 200) {
            setAddedProviderSuccessPopup(true)
            handleAwsPopupClose()
            await getProviderGridDetails(organization.organizationId, providerType)
        }
        else if (updateProviderResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAWSPopup(false)
        }
        setAwsSaveLoaderActive(false)
    }

    const azureTestConnection = async () => {
        setAzureTestConnectionLoaderActive(true)
        azureProviderDetails.organizationId = organization.organizationId
        azureProviderDetails.provider = providerType

        const testConnectionResponse = await fetchTestConnection(azureProviderDetails)

        if (testConnectionResponse.statusCode === 200) {
            setTestConnectionSuccessPopup(true)
        }
        else if (testConnectionResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAzurePopup(false)
        }
        setAzureTestConnectionLoaderActive(false)
    }

    const azureAddProvider = async () => {
        setAzureSaveLoaderActive(true)
        azureProviderDetails.organizationId = organization.organizationId
        azureProviderDetails.provider = providerType
        const updateProviderResponse = await updateProviderDetails(azureProviderDetails, azureProviderDetails.providerId)

        if (updateProviderResponse.statusCode === 200) {
            setAddedProviderSuccessPopup(true)
            handleAzurePopupClose()
            getProviderGridDetails(organization.organizationId, providerType)
        }
        else if (updateProviderResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAzurePopup(false)
        }
        setAzureSaveLoaderActive(false)
    }

    const handleAwsPopupClose = () => {
        setShowAWSPopup(false)
        setAwsProviderDetails(awsProviderPayload)
    }

    const handleAzurePopupClose = () => {
        setShowAzurePopup(false)
        setAzureProviderDetails(azureProviderPayload)
        setAzureConnectorNameRequired(false)
        setAzureInValidSubscriptionIdRequired(false)
        setAzureInValidTenantAccountIdError(false)
        setAzureSubscriptionIdRequired(false)
        setAzureTenantAccountIdRequired(false)
        setAzureSecretKeyRequired(false)
    }

    // PS_PV_47
    const handleAddProviderButtonClick = () => {
        setIsEditing(false)
        // PS_PV_48
        if (providerType === "AWS") {
            // PS_PV_49, PS_PV_50, PS_PV_51
            setShowAWSPopup(true)
            getSecretKeyDetails()
        }
        else if (providerType === "Azure") {
            //PS_PV_65
            setShowAzurePopup(true)
            getSecretKeyDetails()
        }
    }

    const handleAwsAddSecretDataChange = (id: string, value: string) => {
        setAwsAddSecretPayload((prevData: any) => {
            let updatedAwsSecret = { ...prevData }
            if (id === "secretName") {
                updatedAwsSecret = { ...prevData, [id]: value }
            }
            else if (id === "accessKey") {
                updatedAwsSecret.values.accessKey = value
            }
            else if (id === "secretKey") {
                updatedAwsSecret.values.secretKey = value
            }

            if (id === "secretName" && value === "") {
                setAwsSecretNameRequired(true)
            }
            else if (id === "secretName" && value !== "") {
                setAwsSecretNameRequired(false)
            }

            if (id === "accessKey" && value === "") {
                setAwsAccessRequired(true)
            }
            else if (id === "accessKey" && value !== "") {
                setAwsAccessRequired(false)
            }

            if (id === "secretKey" && value === "") {
                setAwsNewSecretKeyRequired(true)
            }
            else if (id === "secretKey" && value !== "") {
                setAwsNewSecretKeyRequired(false)
            }

            if (
                updatedAwsSecret.secretName !== "" &&
                updatedAwsSecret.values.accessKey !== "" &&
                updatedAwsSecret.values.secretKey !== ""
            ) {
                setAwsAddProvderSaveButton(false)
            }
            else {
                setAwsAddProvderSaveButton(true)
            }
            return updatedAwsSecret
        })
    }

    const handleAzureAddSecretDataChange = (id: string, value: string) => {
        setAzureAddSecretPayload((prevData: any) => {
            let updatedAzureSecret = { ...prevData }
            if (id === "secretName") {
                updatedAzureSecret = { ...prevData, [id]: value }
            }
            else if (id === "clientid") {
                updatedAzureSecret.values.clientid = value
            }
            else if (id === "clientSecret") {
                updatedAzureSecret.values.clientSecret = value
            }

            if (id === "secretName" && value === "") {
                setAzureSecretNameRequired(true)
            }
            else if (id === "secretName" && value !== "") {
                setAzureSecretNameRequired(false)
            }

            if (id === "clientid" && value === "") {
                setAzureClientIdRequired(true)
            }
            else if (id === "clientid" && value !== "") {
                setAzureClientIdRequired(false)
            }

            if (id === "clientSecret" && value === "") {
                setAzureClientSecretKeyRequired(true)
            }
            else if (id === "clientSecret" && value !== "") {
                setAzureClientSecretKeyRequired(false)
            }

            if (
                updatedAzureSecret.secretName !== "" &&
                updatedAzureSecret.values.clientid !== "" &&
                updatedAzureSecret.values.clientSecret !== ""
            ) {
                setAzureAddProvderSaveButton(false)
            }
            else {
                setAzureAddProvderSaveButton(true)
            }
            return updatedAzureSecret
        })
    }

    const handleAwsSecretAdd = async () => {
        setAwsAddProvderSaveLoader(true)
        let postSecretPayload = {
            organizationId: organization.organizationId,
            providerType: providerType,
            secretId: 0,
            secretName: awsAddSecretPayload.secretName,
            values: JSON.stringify({
                accessKey: awsAddSecretPayload.values.accessKey,
                secretKey: awsAddSecretPayload.values.secretKey
            })
        }
        const updateSecretKeyResponse = await postSecretKeyDetails(postSecretPayload)

        if (updateSecretKeyResponse.statusCode === 200) {
            await getSecretKeyDetails()

            let awsAddProviderObject: IAWSSecret = {
                secretId: 0,
                secretName: "",
                providerType: "",
                organizationId: 0,
                values: {
                    accessKey: "",
                    secretKey: ""
                }
            }

            setAwsAddSecretPayload(awsAddProviderObject)
            setShowAWSAddSecretPopup(false)
            setShowAWSPopup(true)
        }

        setAwsAddProvderSaveLoader(true)
    }

    const handleAzureSecretAdd = async () => {
        setAzureAddProvderSaveLoader(true)
        let postSecretPayload = {
            organizationId: organization.organizationId,
            providerType: providerType,
            secretId: 0,
            secretName: azureAddSecretPayload.secretName,
            values: JSON.stringify({
                clientid: azureAddSecretPayload.values.clientid,
                clientSecret: azureAddSecretPayload.values.clientSecret
            })
        }
        const updateSecretKeyResponse = await postSecretKeyDetails(postSecretPayload)

        if (updateSecretKeyResponse.statusCode === 200) {
            await getSecretKeyDetails()

            let azureAddProviderObject: IAzureSecret = {
                secretId: 0,
                secretName: "",
                providerType: "",
                organizationId: 0,
                values: {
                    clientid: "",
                    clientSecret: ""
                }
            }

            setAzureAddSecretPayload(azureAddProviderObject)
            setShowAzureAddSecretPopup(false)
            setShowAzurePopup(true)
        }

        setAwsAddProvderSaveLoader(true)
    }

    const handleAwsAddSecretClose = () => {

        let awsAddProviderObject: IAWSSecret = {
            secretId: 0,
            secretName: "",
            providerType: "",
            organizationId: 0,
            values: {
                accessKey: "",
                secretKey: ""
            }
        }

        setShowAWSAddSecretPopup(false);
        setShowAWSPopup(true)
        setAwsAddSecretPayload(awsAddProviderObject)
    }

    const handleAzureAddSecretClose = () => {

        let azureAddProviderObject: IAzureSecret = {
            secretId: 0,
            secretName: "",
            providerType: "",
            organizationId: 0,
            values: {
                clientid: "",
                clientSecret: ""
            }
        }

        setShowAzureAddSecretPopup(false);
        setShowAzurePopup(true)
        setAzureAddSecretPayload(azureAddProviderObject)
    }

    const handleDeleteProvider = async() => {
        setShowPageLoader(true)
        const deleteProviderId = providerDetails.providerdetails[deleteIndex].providerId
        const deleteOrganizationId = providerDetails.providerdetails[deleteIndex].organizationId

        const deleteProviderResponse = await deleteProviderDetail(deleteOrganizationId, deleteProviderId)
        if(deleteProviderResponse.statusCode === 200) {
            await getProviderGridDetails(organization.organizationId, providerType)
        }
        setShowPageLoader(false)
    }

    const handleAwsProviderEdit = () => {
        setAwsProviderDetails((prevData: any) => {
            const populateEditData = { ...prevData, 
                connectorName: providerDetails.providerdetails[editIndex].connectorName,
                accountId: String(providerDetails.providerdetails[editIndex].accountId),
                secretId: providerDetails.providerdetails[editIndex].secretId,
                secretName: providerDetails.providerdetails[editIndex].secretName,
                description: providerDetails.providerdetails[editIndex].description,
                providerId: providerDetails.providerdetails[editIndex].providerId,
            }
            setIsEditing(true)
            getSecretKeyDetails()
            return populateEditData
        })
        setShowAWSPopup(true)
    }

    const handleAzureProviderEdit = () => {
        setAzureProviderDetails((prevData: any) => {
            const populateEditData = { ...prevData, 
                connectorName: providerDetails.providerdetails[editIndex].connectorName,
                tenantId: providerDetails.providerdetails[editIndex].tenantId,
                subscriptionId: providerDetails.providerdetails[editIndex].subscriptionId,
                secretId: providerDetails.providerdetails[editIndex].secretId,
                secretName: providerDetails.providerdetails[editIndex].secretName,
                description: providerDetails.providerdetails[editIndex].description,
                providerId: providerDetails.providerdetails[editIndex].providerId,
            }
            getSecretKeyDetails()
            setIsEditing(true);
            return populateEditData
        })
        setShowAzurePopup(true)
    }

    //PS_PV_17
    const bindAwsProviderDetails = () => {
        return providerDetails.providerdetails.map(
            (eachProvider: any, index: number) => (
                <tr className="custom-alternative-line" key={index}>
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        {eachProvider.connectorName}
                    </td>
                    <td className="custom-grid-font-weight grid-primary-text ">
                        {eachProvider.accountId}
                    </td>
                    <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                        {eachProvider.secretName}
                    </td>
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        <a onClick={() => {setEditIndex(index); handleAwsProviderEdit()}}>
                            <span
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            title='Edit'
                            className="me-3 edit-img"
                            id='editTooltip'
                            data-bs-original-title="Edit"
                            ></span>
                        </a>
                        <a
                        data-bs-toggle="modal"
                        data-bs-target="#DeleteConfirmation-popup"
                        onClick={() => {setDeleteIndex(index)}}
                        > 
                            <span
                               data-bs-toggle='tooltip'
                               data-bs-placement='top'
                               title='Delete'
                               className='delete-img'
                               id='deleteTooltip'
                               data-bs-original-title="Delete"
                            ></span>
                        </a>
                    </td>
                </tr>
            )
        );
    };

    //PS_PV_19
    const bindAzureProviderDetails = () => {
        return providerDetails.providerdetails.map(
            (eachProvider: any, index: number) => (
                <tr className="custom-alternative-line" key={index}>
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        {eachProvider.connectorName}
                    </td>
                    <td className="custom-grid-font-weight grid-primary-text td-wrap">
                        {eachProvider.tenantId}
                    </td>
                    <td className="custom-grid-font-weight grid-primary-text">
                        {eachProvider.subscriptionId}
                    </td>
                    <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                        <span className="font-size-14">{eachProvider.secretName}</span>
                    </td>
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        <a onClick={() => {setEditIndex(index); handleAzureProviderEdit()}}>
                            <span
                               data-bs-toggle='tooltip'
                               data-bs-placement='top'
                               title='Edit'
                               className="me-3 edit-img"
                               id='editTooltip'
                               data-bs-original-title="Edit"
                            ></span>
                        </a>
                        <a onClick={() => {setDeleteIndex(index)}} data-bs-toggle="modal"
                                data-bs-target="#DeleteConfirmation-popup">
                            <span
                               data-bs-toggle='tooltip'
                               data-bs-placement='top'
                               title='Delete'
                               className='delete-img'
                               id='deleteTooltip'
                               data-bs-original-title="Delete"
                            ></span>
                        </a>
                    </td>
                </tr>
            )
        );
    };

    const bindSecretKeys = () => {
        return secretKeys.secretdetails.map((eachSecretKey: any, index: number) => (
            <option key={index} value={`${eachSecretKey.secretId + "#$%" + eachSecretKey.secretName}`}>{eachSecretKey.secretName}</option>
        ))
    }

    const loadMoreClicked = () => {
        setShowPageLoader(true);
        providerPayload.providerType = providerType
        providerPayload.page = pageIndex + 10
    
        fetchProviders(providerPayload, organization.organizationId)
            .then(providerDetailsResponse => {
                if (providerDetailsResponse.statusCode === 200) {
                    setProviderDetail(providerDetailsResponse.data);
                }
                setShowPageLoader(false);
            })
            .catch(error => {
                setShowPageLoader(false);
                console.error('Error fetching providers:', error);
            });
    
        setPageIndex(prevPageIndex => prevPageIndex + 10);
    };

    const handleErrorPopupClose = () => {
        setShowNotAddedErrorPopup(false)
        if(providerType === "AWS") {
            setShowAWSPopup(true)
        }
        else if(providerType === "Azure") { 
            setShowAzurePopup(true)
        }
    }

    return (
        <div>
            <Header />
            <div className="container-fluid">
                {/* <!-- Manage Users Starts here --> */}
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center pt-3">
                            <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                                <h5 className="page-header mb-0 responsive-margin-bottom">
                                    Manage Providers
                                </h5>
                                <div className="d-md-flex d-sm-block">
                                    <div className=" dropdown me-md-4 res-margin-20 app-organization-select">
                                        {organziationSelectComponent}
                                    </div>
                                    {providerSelectComponent}
                                    <button
                                        type="button"
                                        className="btn primary-btn d-lg-block d-none ms-3"
                                        onClick={() => handleAddProviderButtonClick()}
                                        disabled={(role === "BillingReader")}
                                    >
                                        <span>
                                            <img
                                                src="images/static-lable-add.svg"
                                                alt="add-icon"
                                                className="me-2"
                                            />
                                        </span>
                                        Add Provider
                                    </button>
                                    <button
                                        type="button"
                                        className="btn primary-btn d-block d-lg-none"
                                    >
                                        <span>
                                            <img
                                                src="images/static-lable-add.svg"
                                                alt="add-icon"
                                                className="me-2"
                                            />
                                        </span>
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                {/* <!--InlineTable section starts here--> */}
                                <div className="table-responsive">
                                    <table className="table table-borderless custom-grid mt-4 mb-4">
                                        <thead className="custom-table-head">
                                            <tr>
                                                <th className="custom-grid-font-weight text-center text-nowrap position-relative">
                                                    CONNECTOR NAME
                                                    <span className="sorting">
                                                        <a
                                                            className="sortup"
                                                            onClick={() => {
                                                                handleSort("p.connector_name", "asc");
                                                            }}
                                                        >
                                                            <span className="sort-icon sortup-img"></span>
                                                        </a>
                                                        <a
                                                            className="sortdown"
                                                            onClick={() => {
                                                                handleSort("p.connector_name", "desc");
                                                            }}
                                                        >
                                                            <span className="sort-icon sortdown-img"></span>
                                                        </a>
                                                    </span>
                                                </th>
                                                {providerType === "Azure" && (
                                                    <>
                                                        <th className="custom-grid-font-weight text-nowrap position-relative">
                                                            TENANT ID{" "}
                                                        </th>
                                                        <th className="custom-grid-font-weight text-nowrap">
                                                            SUBSCRIPTION ID
                                                        </th>
                                                    </>
                                                )}
                                                {providerType === "AWS" && (
                                                    <th className="custom-grid-font-weight ">
                                                        Account ID
                                                    </th>
                                                )}
                                                <th className="custom-grid-font-weight text-nowrap position-relative">
                                                    SECRET NAME
                                                    <span className="sorting">
                                                        <a className="sortup">
                                                            <span
                                                                className="sort-icon sortup-img"
                                                                onClick={() => {
                                                                    handleSort("s.secret_name", "asc");
                                                                }}
                                                            ></span>
                                                        </a>
                                                        <a className="sortdown">
                                                            <span
                                                                className="sort-icon sortdown-img"
                                                                onClick={() => {
                                                                    handleSort("s.secret_name", "desc");
                                                                }}
                                                            ></span>
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="custom-grid-font-weight text-center text-nowrap">
                                                    ACTION
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* PS_PV_20 */}
                                            {providerDetails.providercount <= 0 && (
                                                <tr className="custom-alternative-line">
                                                    <td colSpan={6} className="text-center">
                                                        <img
                                                            src="images/no-rec-found.gif"
                                                            alt="no-rec-found"
                                                            className="no-data-found-sty mb-4"
                                                        />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr>
                                            )}
                                            {/* PS_PV_16, PS_PV_18 */}
                                            {providerType === "AWS"
                                                ? bindAwsProviderDetails()
                                                : bindAzureProviderDetails()}
                                        </tbody>
                                    </table>
                                    <p className="mb-3 mt-2 para-text">
                                        # of Records: {" "}
                                        {/* PS_PV_21 */}
                                        <span className="primary-color">
                                            {/* PS_PV_22 */}
                                            {padWithALeadingZero(providerDetails.providerdetails.length)} out of {padWithALeadingZero(providerDetails.providercount)}
                                        </span>
                                    </p>
                                </div>
                                {/* <!--InlineTable section ends here--> */}
                                {/* PS_PV_29 */}
                                {!(providerDetails.providerdetails.length < 9 || (providerDetails.providerdetails.length == providerDetails.providercount)) && (
                                    // PS_PV_30, PS_PV_31
                                    <div className="row justify-content-center">
                                        <button
                                            type="button"
                                            className="btn secondary-btn mt-2 mb-5"
                                            onClick={() => {loadMoreClicked()}}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Manage Users Ends here --> */}
            </div>
            {/* start of loader */}
            {showPageLoader && (
                <div className="container-fluid">
                    <div
                        className="modal-backdrop fade show"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-hidden="true"
                    >
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img
                                src="images/cloud-load-gif.gif"
                                alt="white-loader"
                                className="page-loader"
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* start of Azure add provider popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAzurePopup ? 'show-popup' : ''}`}>
                <div role="document" className="custom-width-connect-style modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center">
                                <span className="me-3 provider-icon azure-bg-img shadow-sm"></span>
                                {isEditing ? "Update" : "Add"} Azure Connect
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {handleAzurePopupClose()}}
                            ></button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-6 mb-3">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="connectorName"
                                    >
                                        Connector Name
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter Connector Name"
                                        id="connectorName"
                                        maxLength={33}
                                        value = {azureProviderDetails.connectorName}
                                        onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureConnectorNameRequired(e.target.value === "") }}
                                    />
                                    {azureConnectorNameRequired &&
                                        <   span className="text-danger d-block error-span">Connector Name is required</span>
                                    }
                                    {azureProviderDetails.connectorName.length>32 &&
                                        <   span className="text-danger d-block error-span">Connector Name should not be more than 32 characters</span>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="tenantId"
                                    >
                                        Tenant ID
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter Tenant ID"
                                        id="tenantId"
                                        value = {azureProviderDetails.tenantId}
                                        onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureTenantAccountIdRequired(e.target.value === "") }}
                                    />
                                    {azureTenantIdRequired &&
                                        <   span className="text-danger d-block error-span">Tenant ID is required</span>
                                    }
                                    {azureInValidTenantIdError &&
                                        <   span className="text-danger d-block error-span">Enter a valid Tenant ID</span>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="subscriptionId"
                                    >
                                        Subscription ID
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter Subscription ID"
                                        id="subscriptionId"
                                        value = {azureProviderDetails.subscriptionId}
                                        onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureSubscriptionIdRequired(e.target.value === "") }}
                                    />
                                    {azureSubscriptionIdRequired &&
                                        <   span className="text-danger d-block error-span">Subscription ID is required</span>
                                    }
                                    {azureInValidSubscriptionIdError &&
                                        <   span className="text-danger d-block error-span">Enter a valid Subscription ID</span>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <label
                                                className="w-100 form-label custom-label"
                                                htmlFor="secretId"
                                            >
                                                Secret Key
                                            </label>
                                            <select
                                                className="custom-input py-2 px-3 form-select"
                                                id="secretId"
                                                value={`${azureProviderDetails.secretId + "#$%" + azureProviderDetails.secretName}`}
                                                onChange={(e) => {
                                                    handleAzureProviderDetailsChange(e.target.id, e.target.value);
                                                }}
                                                onBlur={(e) => { setAzureSecretKeyRequired(e.target.value === "") }}
                                            >
                                                <option value="">Select</option>
                                                {bindSecretKeys()}
                                            </select>
                                        </div>
                                        <div className="col-md-3 px-md-0 d-flex align-items-end">
                                            <button type="button" className="btn primary-btn mt-3" onClick={() => { setShowAzureAddSecretPopup(true); setShowAzurePopup(false) }}>
                                                Add
                                            </button>
                                        </div>
                                        {azureSecretKeyRequired &&
                                            <span className="text-danger d-block error-span">Secret Key is required</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="description"
                                    >
                                        Description (optional)
                                    </label>
                                    <textarea
                                        className="form-control custom-input custom-resize"
                                        rows={3}
                                        placeholder="Enter Description"
                                        id="description"
                                        value = {azureProviderDetails.description}
                                        onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                        maxLength={320}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-5 responsive-padding-none">
                            <button type="button" className="btn ternary-btn" onClick={() => {handleAzurePopupClose()}}>
                                Cancel
                            </button>
                            <button type="button" className="btn secondary-btn ms-3" disabled = {isAzureTestConnectionButtonDiabled} onClick={() => azureTestConnection()}>
                                {azureTestConnectionLoaderActive &&
                                    <img
                                        src="images/white-loader.gif"
                                        alt="white-loader"
                                        className="btn-loader"
                                    />
                                }
                                Test
                                <span className="responsive-display-none">Connection</span>
                            </button>
                            <button type="button" className="btn primary-btn ms-3" disabled = {isAzureSaveButtonDiabled} onClick={() => azureAddProvider()}>
                                {azureSaveLoaderActive &&
                                    <img
                                        src="images/white-loader.gif"
                                        alt="white-loader"
                                        className="btn-loader"
                                    />
                                }
                                {isEditing ? "Update" : "Save"}
                                <span className="responsive-display-none">Details</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of azure add secret popUp */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAzureAddSecretPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center"><span
                                className="me-3 provider-icon azure-bg-img shadow-sm"></span>Add new secret key</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleAzureAddSecretClose() }}
                            ></button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="SecretName">Secret Name
                                        <span className="color-warning">*</span>
                                        <span>
                                            <img className="ms-2" src="images/information-icon.svg" alt="info" />
                                        </span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Name"
                                        id="secretName"
                                        value={azureAddSecretPayload.secretName}
                                        onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureSecretNameRequired(e.target.value === "") }}/>
                                    <span className={`text-danger d-block error-span ${azureSecretNameRequired ? "" : "invisible"}`}>
                                        Secret Name is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="clientid">Client ID<span
                                        className="color-warning">*</span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Client ID"
                                        id="clientid"
                                        value={azureAddSecretPayload.values.clientid}
                                        onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureClientIdRequired(e.target.value === "") }}/>
                                    <span className={`text-danger d-block error-span ${azureClientIdRequired ? "" : "invisible"}`}>
                                        Client ID is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="clientSecret">Client Secret<span
                                        className="color-warning">*</span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Client Secret"
                                        id="clientSecret"
                                        value={azureAddSecretPayload.values.clientSecret}
                                        onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureClientSecretKeyRequired(e.target.value === "") }}/>
                                    <span className={`text-danger d-block error-span ${azureClientSecretKeyRequired ? "" : "invisible"}`}>
                                        Client Secret is required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                            <button type="button" className="btn secondary-btn" onClick={() => { handleAzureAddSecretClose() }}
                            >Cancel</button>
                            <button type="button" className="btn primary-btn ms-3"
                            disabled={azureAddProvderSaveButton}
                            onClick={() => handleAzureSecretAdd()}
                            >
                                {azureAddProvderSaveLoader &&
                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                }
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of Aws add provider popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAWSPopup ? 'show-popup' : ''}`}>
                <div role="document" className="custom-width-connect-style modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center">
                                <span className="me-3 provider-icon aws-bg-img shadow-sm"></span>{isEditing ? "Update" : "Add"}
                                {" "}AWS Connect
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { handleAwsPopupClose() }}
                            ></button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-6 mb-3">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="connectorName"
                                    >
                                        Connector Name
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter Connector Name"
                                        id="connectorName"
                                        maxLength={32}
                                        value={awsProviderDetails.connectorName}
                                        onChange={(e) => {
                                            handleAwsProviderDetailsChange(e.target.id, e.target.value);
                                        }}
                                        onBlur={(e) => { setAwsConnectorNameRequires(e.target.value === "") }}
                                    />
                                    {awsConnectorNameRequired &&
                                        <span className="text-danger d-block error-span">Connector Name is required</span>
                                    }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="accountId"
                                    >
                                        Account ID
                                    </label>
                                    <input
                                        className="w-100 custom-input form-control py-2 px-2"
                                        type="text"
                                        placeholder="Enter Account ID"
                                        id="accountId"
                                        value={awsProviderDetails.accountId}
                                        onChange={(e) => {
                                            handleAwsProviderDetailsChange(e.target.id, e.target.value);
                                        }}
                                        onBlur={(e) => { setAwsAccountIdRequired(e.target.value === "") }}
                                    />
                                    {awsAccountIdRequired &&
                                        <span className="text-danger d-block error-span">Account ID is required</span>
                                    }
                                    {awsValidAccountIdRequired &&
                                        <span className="text-danger d-block error-span">Enter a valid Account ID</span>
                                    }
                                    <div className="form-check mt-2">
                                        <input
                                            className="form-check-input custom-checked"
                                            type="checkbox"
                                            value=""
                                            id="isLinkedAccount"
                                            checked={awsProviderDetails.isLinkedAccount}
                                            onChange={(e) => {
                                                handleAwsProviderDetailsChange(e.target.id, e.target.checked);
                                            }}
                                        />
                                        <label
                                            className="form-check-label custom-label"
                                            htmlFor="isLinkedAccount"
                                        >
                                            Is this linked account ?
                                        </label>{" "}
                                        <span>
                                            <img
                                                className="ms-1"
                                                src="images/information-icon.svg"
                                                alt="info"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title = "Enable this, if you are using consolidated billing in AWS"
                                                data-bs-original-title="Edit"                
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <label
                                                className="w-100 form-label custom-label"
                                                htmlFor="secretId"
                                            >
                                                Secret Key
                                            </label>
                                            <select
                                                className="custom-input py-2 px-3 form-select"
                                                id="secretId"
                                                value={`${awsProviderDetails.secretId + "#$%" + awsProviderDetails.secretName}`}
                                                onChange={(e) => {
                                                    handleAwsProviderDetailsChange(e.target.id, e.target.value);
                                                }}
                                                onBlur={(e) => { setAwsSecretKeyRequired(e.target.value === "") }}
                                            >
                                                <option value="">
                                                    Select
                                                </option>
                                                {bindSecretKeys()}
                                            </select>
                                        </div>
                                        <div className="col-md-3 px-md-0 d-flex align-items-end">
                                            <button type="button" className="btn primary-btn mt-3" onClick={() => { setShowAWSAddSecretPopup(true); setShowAWSPopup(false) }}>
                                                Add
                                            </button>
                                        </div>
                                        {awsSecretKeyRequired &&
                                            <span className="text-danger d-block error-span">Secret Key is required</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label
                                        className="w-100 form-label custom-label"
                                        htmlFor="Description"
                                    >
                                        Description (optional)
                                    </label>
                                    <textarea
                                        className="form-control custom-input custom-resize"
                                        rows={3}
                                        placeholder="Enter Description"
                                        id="description"
                                        onChange={(e) => { handleAwsProviderDetailsChange(e.target.id, e.target.value) }}
                                        maxLength={320}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-5 responsive-padding-none">
                            <button type="button" className="btn ternary-btn" onClick={() => { handleAwsPopupClose() }}>
                                Cancel
                            </button>
                            <button type="button"
                                className="btn secondary-btn ms-3"
                                disabled={isAwsTestConnectionButtonDiabled}
                                onClick={() => { awsTestConnection() }}>
                                {awsTestConnectionLoaderActive &&
                                    <img
                                        src="images/white-loader.gif"
                                        alt="white-loader"
                                        className="btn-loader"
                                    />
                                }
                                Test <span className="responsive-display-none">Connection</span>
                            </button>
                            <button type="button"
                                className="btn primary-btn ms-3"
                                disabled={isAwsSaveButtonDiabled}
                                onClick={() => { awsAddProvider() }}>
                                {awsSaveLoaderActive &&
                                    <img
                                        src="images/white-loader.gif"
                                        alt="white-loader"
                                        className="btn-loader"
                                    />
                                }
                            {isEditing ? "Update" : "Save"} <span className="responsive-display-none">Details</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of aws add secret popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAWSAddSecretPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center"><span
                                className="me-3 provider-icon aws-bg-img shadow-sm"></span>Add new secret key</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { handleAwsAddSecretClose() }}>
                            </button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="secretName">Secret Name
                                        <span className="color-warning">*</span>
                                        <span>
                                            <img className="ms-2" src="images/information-icon.svg" alt="info" />
                                        </span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Name"
                                        value={awsAddSecretPayload.secretName}
                                        id="secretName"
                                        onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAwsSecretNameRequired(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${awsSecretNameRequired ? "" : "invisible"}`}>
                                        Secret Name is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="accessKey">
                                        Access Key
                                        <span className="color-warning">*</span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Access Key"
                                        value={awsAddSecretPayload.values.accessKey}
                                        id="accessKey"
                                        onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAwsAccessRequired(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${awsAccessRequired ? "" : "invisible"}`}>
                                        Access Key is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="secretKey">Secret Key<span
                                        className="color-warning">*</span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Key"
                                        id="secretKey"
                                        onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAwsNewSecretKeyRequired(e.target.value === "") }}
                                        value={awsAddSecretPayload.values.secretKey} />
                                    <span className={`text-danger d-block error-span ${awsNewSecretKeyRequired ? "" : "invisible"}`}>
                                        Secret Key is required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                            <button type="button" className="btn secondary-btn"
                                onClick={() => { handleAwsAddSecretClose() }}
                            >Cancel</button>
                            <button type="button"
                                className="btn primary-btn ms-3"
                                disabled={awsAddProvderSaveButton}
                                onClick={() => handleAwsSecretAdd()}
                            >
                                {awsAddProvderSaveLoader &&
                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                }
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of provider not able to add error popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showNotAddedErrorPopup ? 'show-popup' : ''}`}>
                <div role="document" className="custom-modal-theme modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="btn-close"
                                onClick={() => { setShowNotAddedErrorPopup(false); setShowAWSPopup(true) }}></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img alt="Error" src="images/Error.svg" />
                                <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                                Oops! Looks like there's an error.
                                    {/* {`Failed to connect ${providerType === "AWS" ? awsProviderDetails.connectorName : azureProviderDetails.connectorName}. Please try again later.Error: validation failed`} */}
                                </label>
                                <label className="form-label custom-pop-label mb-0 w-100">Please check the details and try again</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn primary-btn" onClick={() => { handleErrorPopupClose()}}>
                                Got It
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of added provider success popup */}
            <div className={`modal show fade ${addedProviderSuccessPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="custom-modal-theme modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAddedProviderSuccessPopup(false)} />
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img className="mt-1" src="images/Success.svg" alt="Success" />
                                <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                                    {/* Updated wordings */}
                                    {`Connector ${providerType} connectivity has been ${isEditing ? "edited" : "added"} successfully`}
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setAddedProviderSuccessPopup(false)}>Got It</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* start of test connection success popup */}
            <div className={`modal show fade ${testConnectionSuccessPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                <div className="custom-modal-theme modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setTestConnectionSuccessPopup(false)} />
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img className="mt-1" src="images/Success.svg" alt="Success" />
                                <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                                    {`The ${providerType} connectivity has been verified`}
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setTestConnectionSuccessPopup(false)}>Got It</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of delete provider confirmation popup */}
            <div className="modal fade" id="DeleteConfirmation-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}>
                <div className="modal-dialog custom-width-providerpopup">
                    <div className="modal-content popup-sty border-0 custom-border-radius">
                        <div className="modal-header border-0">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                            <img className="mt-1" src="images/Delete-Confirmation.svg" alt="delete"/>
                            <label className="form-label custom-bold my-4 w-100">Delete Confirmation</label>
                            <label className="form-label custom-pop-label mb-1"> Are you sure to delete the provider?</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn secondary-btn me-3" data-bs-dismiss="modal" onClick={() => {handleDeleteProvider()}}>Yes</button>
                            <button type="button" className="btn color-warning-btn"  data-bs-dismiss="modal" >No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
