import '../../assets/scss/organization-select.css'
import { useEffect, useState } from 'react';
import { getOrganization } from '../../service/managementService';
import { IOrganizationData } from '../../interface/organization';
import { useEncryptStorage } from '../../hooks/useEncryptStorage';
import { useSelector } from 'react-redux';

export const OrganizationSelect = (organizationSelectRequest: any) => {

    const [organizations, setOrganizations] = useState<any[]>([]);
    const [organization, setOrganization] = useState<any>();
    const isDashBoardListEnabled = useSelector((state: any) => state.dashboardList.isEnabled)

    useEffect(() => {
        getAllOrganizations();
    }, []);

    const getAllOrganizations = async () => {
        let organizationsResponse = await getOrganization();
        if(organizationsResponse) {
            let organizationArr: any[] = organizationSelectRequest?.applyRole
                ? organizationsResponse?.data.filter((eachOrganization: any) => eachOrganization.roleId !== "BILLING_READER")
                : organizationsResponse?.data;
    
            if (!organizationSelectRequest?.showAll) {
                organizationArr = organizationArr.filter(eachOrganization => eachOrganization.providerType && eachOrganization.providerType.length > 0)
            }
    
            let organizationToEmit = organizationArr[0]
            if (organizationSelectRequest?.requestedOrganizationId) {
                let organizationFound = organizationArr.find(eachOrganization => eachOrganization.organizationId === organizationSelectRequest?.requestedOrganizationId)
                if (organizationFound) {
                    organizationToEmit = organizationFound;
                }
            }
    
            setOrganizations(organizationArr);
            await handleOrganizationChange(organizationToEmit);
        }
    }

    const bindOrganizationDropDown = () => {

        return organizations.map((eachOrganization, index) => (
            <li key={index} className="custom-dropdown-brd pb-1" onClick={() => handleOrganizationChange(eachOrganization)}>
                <a className="dropdown-item custom-dropdown-li py-2 px-2">
                    <span className="me-1">
                        <img src={eachOrganization.logoUrl} alt="logo" className="template-dropdown-icon" />
                    </span>
                    {eachOrganization.organizationName}
                </a>
            </li>
        ))
    }

    const handleOrganizationChange  = async (organization: IOrganizationData) => {
        setOrganization(organization);
        await organizationSelectRequest?.onOrganizationChange(organization);
    }
    return (
        <>
            <button
                className={`txt-spacing text-start custom-dropdown-toggle db-custom-input py-2 w-100 text-nowrap ${(useEncryptStorage.getItem("theme") === "light" && isDashBoardListEnabled) && "light-class"}`}
                type="button"
                id="organization-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="me-1">
                    <img src={organization?.logoUrl} alt="logo" className="template-dropdown-icon" />
                </span>{organization?.organizationName}
            </button>
            <ul className="dropdown-menu custom-dropdown-sty dropdown-wid border-0 p-1 custom-dropdown-scrl"
                aria-labelledby="organization-dropdown">
                {bindOrganizationDropDown()}
            </ul>
        </>
    )
}