import { useEffect, useMemo, useState } from "react";
import Header from "../Header/Header";
import { IAWSSecret, IAzureSecret, IDeleteSecretPayload, ISecretKey, ISecretKeyResponse } from "../../interface/manage";
import { deleteSecret, fetchSecretKeyDetails, postSecretKeyDetails } from "../../service/secretservice";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { IOrganizationData, ProviderDetail, defaultOrganization } from "../../interface/organization";
import { ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { padWithALeadingZero } from "../../utils/numberUtils";

export default function Secrets() {

    let awsAddProviderObject: IAWSSecret = {
        secretId: 0,
        secretName: "",
        providerType: "",
        organizationId: 0,
        values: {
            accessKey: "",
            secretKey: ""
        }
    }

    let azureAddProviderObject: IAzureSecret = {
        organizationId: 0,
        providerType: "",
        secretId: 0,
        secretName: "",
        values: {
            clientid: "",
            clientSecret: ""
        }
    }

    let deleteRequestPayload: IDeleteSecretPayload = {
        organizationId: 0,
        secretId: 0,
        secretName: ""
    }

    const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
    const [providerType, setProviderType] = useState("");
    const role = useSelector((state: any) => state.role.role)

    const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
        organizationId: 0,
        providerTypes: [],
        onProviderTypeChange: (organizationId, providerType) => { },
    });

const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: "",
    onScopeChange: (scope) => { },
});

const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
) => {
    setProviderTypeSelectProps({
        organizationId: selectedOrganization.organizationId,
        onProviderTypeChange: onProviderTypeChange,
        providerTypes:  ["AWS","Azure"],
    });
    setOrganization(selectedOrganization);
};

const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
) => {
    setProviderType(selectedProviderType);
    setScopeSelectProps({
        organizationId: organizationId,
        providerType: selectedProviderType,
        onScopeChange: onScopeChange,
    });
    setProviderType(selectedProviderType);
    getSecretKeyDetails(organizationId, selectedProviderType)
};

const onScopeChange = async (
    selectedScope: ProviderDetail | ProviderDetail[]
) => {
    selectedScope = selectedScope as ProviderDetail;
    let costAnomaliesPayload;
    let providerId = selectedScope.providerId;

    if (providerType === "AWS") {
        costAnomaliesPayload = {
            accountId: selectedScope.accountId,
            secretName: selectedScope.secretName,
            subscriptionId: "",
            tenantId: "",
        };
    } else if (providerType === "Azure") {
        costAnomaliesPayload = {
            accountId: "",
            secretName: selectedScope.secretName,
            subscriptionId: selectedScope.subscriptionId,
            tenantId: selectedScope.tenantId,
        };
    }
};

const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange}
            showAll = {true} />,
    []
);
const providerSelectComponent = useMemo(
    () => <ProviderSelect {...providerTypeSelectProps} />,
    [providerTypeSelectProps]
);

    let secretKeyObj: ISecretKey = {
        organizationId: null,
        page: null,
        providerType: "",
        searchText: ""
    }

    let secretKeyResponseObj: ISecretKeyResponse = {
        secretcount: 0,
        secretdetails: []
    }

    const [secretKeys, setSecretKeys] = useState<ISecretKeyResponse>(secretKeyResponseObj)
    const [showAWSAddSecretPopup, setShowAWSAddSecretPopup] = useState<boolean>(false)
    const [showAzureAddSecretPopup, setShowAzureAddSecretPopup] = useState<boolean>(false)
    
    const [awsAddSecretPayload, setAwsAddSecretPayload] = useState<IAWSSecret>(awsAddProviderObject)
    const [awsSecretNameRequired, setAwsSecretNameRequired] = useState<boolean>(false)
    const [awsAccessRequired, setAwsAccessRequired] = useState<boolean>(false)
    const [awsNewSecretKeyRequired, setAwsNewSecretKeyRequired] = useState<boolean>(false)
    const [awsAddProvderSaveLoader, setAwsAddProvderSaveLoader] = useState<boolean>(false)
    const [awsAddProvderSaveButton, setAwsAddProvderSaveButton] = useState<boolean>(true)

    const [deleteIndex, setDeleteIndex] = useState<number>(0)
    const [editIndex, setEditIndex] = useState<number>(0)
    const [showPageLoader, setShowPageLoader] = useState<boolean>(false)
    const [isEditClick, setIsEditClicked] = useState<boolean>(false)

    const [azureAddSecretPayload, setAzureAddSecretPayload] = useState<IAzureSecret>(azureAddProviderObject)
    const [azureSecretNameRequired, setAzureSecretNameRequired] = useState<boolean>(false)
    const [azureClientIdRequired, setAzureClientIdRequired] = useState<boolean>(false)
    const [azureClientSecretKeyRequired, setAzureClientSecretKeyRequired] = useState<boolean>(false)
    const [azureAddProvderSaveLoader, setAzureAddProvderSaveLoader] = useState<boolean>(false)
    const [azureAddProvderSaveButton, setAzureAddProvderSaveButton] = useState<boolean>(true)

    const [pageCount, setPageCount] = useState<number>(10)

    const getSecretKeyDetails = async (organizationId: number, providerType:string ) => {
        setShowPageLoader(true)
        secretKeyObj.organizationId = organizationId
        secretKeyObj.page = 10
        secretKeyObj.providerType = providerType

        const secretKeyResponse = await fetchSecretKeyDetails(secretKeyObj)

        if (secretKeyResponse.statusCode === 200) {
            setSecretKeys(secretKeyResponse.data)
        }
        setShowPageLoader(false)
    }

    const handleAwsAddSecretClose = () => {

        let awsAddProviderObject: IAWSSecret = {
            secretId: 0,
            secretName: "",
            providerType: "",
            organizationId: 0,
            values: {
                accessKey: "",
                secretKey: ""
            }
        }

        setShowAWSAddSecretPopup(false);
        setAwsAddSecretPayload(awsAddProviderObject)
        setAwsAccessRequired(false)
        setAwsSecretNameRequired(false)
        setAwsNewSecretKeyRequired(false)
    }

    const handleAwsAddSecretDataChange = (id: string, value: string) => {
        setAwsAddSecretPayload((prevData: any) => {
            let updatedAwsSecret = { ...prevData }
            if (id === "secretName") {
                updatedAwsSecret = { ...prevData, [id]: value }
            }
            else if (id === "accessKey") {
                updatedAwsSecret.values.accessKey = value
            }
            else if (id === "secretKey") {
                updatedAwsSecret.values.secretKey = value
            }

            if (id === "secretName" && value === "") {
                setAwsSecretNameRequired(true)
            }
            else if (id === "secretName" && value !== "") {
                setAwsSecretNameRequired(false)
            }

            if (id === "accessKey" && value === "") {
                setAwsAccessRequired(true)
            }
            else if (id === "accessKey" && value !== "") {
                setAwsAccessRequired(false)
            }

            if (id === "secretKey" && value === "") {
                setAwsNewSecretKeyRequired(true)
            }
            else if (id === "secretKey" && value !== "") {
                setAwsNewSecretKeyRequired(false)
            }

            if (
                updatedAwsSecret.secretName !== "" &&
                updatedAwsSecret.values.accessKey !== "" &&
                updatedAwsSecret.values.secretKey !== ""
            ) {
                setAwsAddProvderSaveButton(false)
            }
            else {
                setAwsAddProvderSaveButton(true)
            }
            console.log(updatedAwsSecret)
            return updatedAwsSecret
        })
    }

    const handleAwsSecretAdd = async () => {
        setAwsAddProvderSaveLoader(true)
        let postSecretPayload = {
            organizationId: organization.organizationId,
            providerType: providerType,
            secretId: 0,
            secretName: awsAddSecretPayload.secretName,
            values: JSON.stringify({
                accessKey: awsAddSecretPayload.values.accessKey,
                secretKey: awsAddSecretPayload.values.secretKey
            })
        }
        const updateSecretKeyResponse = await postSecretKeyDetails(postSecretPayload)

        if (updateSecretKeyResponse.statusCode === 200) {
            await getSecretKeyDetails(organization.organizationId, providerType)

            let awsAddProviderObject: IAWSSecret = {
                secretId: 0,
                secretName: "",
                providerType: "",
                organizationId: 0,
                values: {
                    accessKey: "",
                    secretKey: ""
                }
            }

            setAwsAddSecretPayload(awsAddProviderObject)
            setShowAWSAddSecretPopup(false)
        }

        setAwsAddProvderSaveLoader(false)
    }

    const handleSecretDelete = async() => {

        deleteRequestPayload = {
            organizationId: organization.organizationId,
            secretId: secretKeys.secretdetails[deleteIndex].secretId,
            secretName: secretKeys.secretdetails[deleteIndex].secretName
        }
        const deleteSecretResponse = await deleteSecret(deleteRequestPayload)

        if(deleteSecretResponse.statusCode = 200) {
            getSecretKeyDetails(organization.organizationId, providerType)
        }
    }

    const handleEditButtonClick = async(index: number) => {
        if(providerType === "AWS") {
            setIsEditClicked(true)
            setShowAWSAddSecretPopup(true)
            setAwsAddSecretPayload((prevData: any) => {
                const populateEditData = { ...prevData, 
                    secretName: secretKeys.secretdetails[index].secretName,
                }
                return populateEditData
            })
            setAwsAddProvderSaveLoader(false)
        }
        else {
            setIsEditClicked(true)
            setShowAzureAddSecretPopup(true)
            setAzureAddSecretPayload((prevData: any) => {
                const populateEditData = { ...prevData, 
                    secretName: secretKeys.secretdetails[index].secretName,
                }
                return populateEditData
            })
            setAzureAddProvderSaveLoader(false)
        }
    }

    const handleAzureAddSecretClose = () => {

        let azureAddProviderObject: IAzureSecret = {
            secretId: 0,
            secretName: "",
            providerType: "",
            organizationId: 0,
            values: {
                clientid: "",
                clientSecret: ""
            }
        }

        setShowAzureAddSecretPopup(false);
        setAzureAddSecretPayload(azureAddProviderObject)
        setAzureClientIdRequired(false)
        setAzureClientSecretKeyRequired(false)
        setAzureSecretNameRequired(false)
    }

    const handleAzureAddSecretDataChange = (id: string, value: string) => {
        setAzureAddSecretPayload((prevData: any) => {
            let updatedAzureSecret = { ...prevData }
            if (id === "secretName") {
                updatedAzureSecret = { ...prevData, [id]: value }
            }
            else if (id === "clientid") {
                updatedAzureSecret.values.clientid = value
            }
            else if (id === "clientSecret") {
                updatedAzureSecret.values.clientSecret = value
            }

            if (id === "secretName" && value === "") {
                setAzureSecretNameRequired(true)
            }
            else if (id === "secretName" && value !== "") {
                setAzureSecretNameRequired(false)
            }

            if (id === "clientid" && value === "") {
                setAzureClientIdRequired(true)
            }
            else if (id === "clientid" && value !== "") {
                setAzureClientIdRequired(false)
            }

            if (id === "clientSecret" && value === "") {
                setAzureClientSecretKeyRequired(true)
            }
            else if (id === "clientSecret" && value !== "") {
                setAzureClientSecretKeyRequired(false)
            }

            if (
                updatedAzureSecret.secretName !== "" &&
                updatedAzureSecret.values.clientid !== "" &&
                updatedAzureSecret.values.clientSecret !== ""
            ) {
                setAzureAddProvderSaveButton(false)
            }
            else {
                setAzureAddProvderSaveButton(true)
            }
            return updatedAzureSecret
        })
    }

    const handleAzureSecretAdd = async () => {
        setAzureAddProvderSaveLoader(true)
        let postSecretPayload = {
            organizationId: organization.organizationId,
            providerType: providerType,
            secretId: 0,
            secretName: azureAddSecretPayload.secretName,
            values: JSON.stringify({
                clientid: azureAddSecretPayload.values.clientid,
                clientSecret: azureAddSecretPayload.values.clientSecret
            })
        }
        const updateSecretKeyResponse = await postSecretKeyDetails(postSecretPayload)

        if (updateSecretKeyResponse.statusCode === 200) {
            await getSecretKeyDetails(organization.organizationId,providerType)

            let azureAddProviderObject: IAzureSecret = {
                secretId: 0,
                secretName: "",
                providerType: "",
                organizationId: 0,
                values: {
                    clientid: "",
                    clientSecret: ""
                }
            }

            setAzureAddSecretPayload(azureAddProviderObject)
            setShowAzureAddSecretPopup(false)
        }

        setAzureAddProvderSaveLoader(false)
    }

    const loadMore = async() => {
        secretKeyObj = {
            organizationId: organization.organizationId,
            page: pageCount + 10,
            providerType: providerType,
            searchText: ""
        }

        const secretKeyResponse = await fetchSecretKeyDetails(secretKeyObj)

        if (secretKeyResponse.statusCode === 200) {
            setSecretKeys(secretKeyResponse.data)
        }
        setShowPageLoader(false)
    
    }

    const bindSecretsGrid = () => {
        return secretKeys.secretdetails.map((eachSecrets: any, index: number) => (
            <tr key = {index} className="custom-alternative-line">
                <td className="custom-grid-font-weight text-start text-nowrap">
                    {eachSecrets.secretName}
                </td>
                <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                    {eachSecrets.providerType}
                </td>
                <td className="custom-grid-font-weight text-center text-nowrap">
                    <a onClick={() => {setEditIndex(index);handleEditButtonClick(index)}}>
                        <span className="me-3 edit-img" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="" data-bs-original-title="Edit">
                        </span>
                    </a>
                    <a onClick={() => {setDeleteIndex(index)}}>
                        <span className="delete-img"
                            data-bs-toggle="modal" data-bs-placement="top" title="" data-bs-original-title="Delete"
                            data-bs-target="#DeleteConfirmation-popup">
                        </span>
                    </a>
                </td>
            </tr>
        ))
    }

    return (
        <div>
            <Header />
            <div className="container-fluid">
                {/* <!-- Manage Users Starts here --> */}
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center pt-3">
                            <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                                <h5 className="page-header mb-0 responsive-margin-bottom">Manage Secrets</h5>
                                <div className="d-md-flex d-sm-block">
                                    <div className=" dropdown me-md-4 res-margin-20 app-organization-select">
                                        {organziationSelectComponent}
                                    </div>
                                    {providerSelectComponent}
                                    <button type="button" className="btn primary-btn d-lg-block d-none ms-3" onClick={() => {(providerType === "AWS" ? setShowAWSAddSecretPopup(true) : setShowAzureAddSecretPopup(true)); setIsEditClicked(false)}} disabled={role === "BillingReader"}>
                                        <span><img src="images/static-lable-add.svg" alt="add-icon" className="me-2" /></span>New
                                        Secret
                                    </button>
                                    <button type="button" className="btn primary-btn d-block d-lg-none">
                                        <span><img src="images/static-lable-add.svg" alt="add-icon" className="me-2" /></span>Add
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                {/* <!--InlineTable section starts here--> */}
                                <div className="table-responsive">
                                    <table className="table table-borderless custom-grid mt-4 mb-4">
                                        <thead className="custom-table-head">
                                            <tr>
                                                <th className="custom-grid-font-weight  text-nowrap text-start">SECRET NAME</th>
                                                <th className="custom-grid-font-weight text-nowrap">TYPE</th>
                                                <th
                                                    className="custom-grid-font-weight text-center text-nowrap">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {secretKeys.secretcount === 0 &&
                                                <tr className="custom-alternative-line">
                                                    <td colSpan={6} className="text-center">
                                                        <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                            className="no-data-found-sty mb-4" />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr>
                                            }
                                            {bindSecretsGrid()}
                                        </tbody>
                                    </table>
                                    <p className="mb-3 mt-2 para-text"># of Records: 
                                    <span className="primary-color">
                                        {padWithALeadingZero(secretKeys.secretdetails.length)} out of {padWithALeadingZero(secretKeys.secretcount)}</span>
                                    </p>
                                </div>
                                {/* <!--InlineTable section ends here--> */}
                                {!(secretKeys.secretdetails.length < 9 || (secretKeys.secretdetails.length == secretKeys.secretcount)) &&
                                    <div className="row justify-content-center" onClick={() => {loadMore()}}>
                                        <button type="button" className="btn secondary-btn mt-2 mb-5">Load
                                            More</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Manage Users Ends here --> */}
            </div>

            {/* start of delete Confirmation popup */}
            <div className="modal fade" id="DeleteConfirmation-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog custom-width-providerpopup">
                    <div className="modal-content popup-sty border-0 custom-border-radius">
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img className="mt-1" src="images/Delete-Confirmation.svg" alt="delete" />
                                <label className="form-label custom-bold my-4 w-100">Delete Confirmation</label>
                                <label className="form-label custom-pop-label mb-1"> Do you really want to delete?</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" className="btn secondary-btn me-3" data-bs-dismiss="modal" onClick={() => {handleSecretDelete()}}>Yes</button>
                            <button type="button" className="btn color-warning-btn" data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of aws add secret popup */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAWSAddSecretPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center"><span
                                className="me-3 provider-icon aws-bg-img shadow-sm"></span>{`${isEditClick ? "Edit" : "Add new"} secret key`}</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { handleAwsAddSecretClose() }}>
                            </button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="secretName">Secret Name
                                        <span className="color-warning">*</span>
                                        <span>
                                          <Tooltip title={"Organization Name will be added in prefix by default. Only Alphanumerics and hyphens are allowed"}>
                                            <img className="ms-2" src="images/information-icon.svg" alt="info" />
                                          </Tooltip>
                                        </span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Name"
                                        disabled = {isEditClick}
                                        value={awsAddSecretPayload.secretName}
                                        id="secretName"
                                        onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAwsSecretNameRequired(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${awsSecretNameRequired ? "" : "invisible"}`}>
                                        Secret Name is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="accessKey">
                                        Access Key
                                        <span className="color-warning">*</span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Access Key"
                                        value={awsAddSecretPayload.values.accessKey}
                                        id="accessKey"
                                        onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAwsAccessRequired(e.target.value === "") }} />
                                    <span className={`text-danger d-block error-span ${awsAccessRequired ? "" : "invisible"}`}>
                                        Access Key is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="secretKey">Secret Key<span
                                        className="color-warning">*</span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Key"
                                        id="secretKey"
                                        onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAwsNewSecretKeyRequired(e.target.value === "") }}
                                        value={awsAddSecretPayload.values.secretKey} />
                                    <span className={`text-danger d-block error-span ${awsNewSecretKeyRequired ? "" : "invisible"}`}>
                                        Secret Key is required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                            <button type="button" className="btn secondary-btn"
                                onClick={() => { handleAwsAddSecretClose() }}
                            >Cancel</button>
                            <button type="button"
                                className="btn primary-btn ms-3"
                                disabled={awsAddProvderSaveButton}
                                onClick={() => handleAwsSecretAdd()}
                            >
                                {awsAddProvderSaveLoader &&
                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                }
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of azure add secret popUp */}
            <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAzureAddSecretPopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title custom-header-style d-flex align-items-center"><span
                                className="me-3 provider-icon azure-bg-img shadow-sm"></span>{`${isEditClick ? "Edit" : "Add new"} secret key`}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleAzureAddSecretClose() }}
                            ></button>
                        </div>
                        <div className="modal-body pt-2 pb-0">
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="SecretName">Secret Name
                                        <span className="color-warning">*</span>
                                        <span>
                                          <Tooltip title={"Organization Name will be added in prefix by default. Only Alphanumerics and hyphens are allowed"}>
                                            <img className="ms-2" src="images/information-icon.svg" alt="info" />
                                          </Tooltip>
                                        </span>
                                    </label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Name"
                                        id="secretName"
                                        value={azureAddSecretPayload.secretName}
                                        onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureSecretNameRequired(e.target.value === "") }}/>
                                    <span className={`text-danger d-block error-span ${azureSecretNameRequired ? "" : "invisible"}`}>
                                        Secret Name is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="clientid">Client ID<span
                                        className="color-warning">*</span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Client ID"
                                        id="clientid"
                                        value={azureAddSecretPayload.values.clientid}
                                        onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureClientIdRequired(e.target.value === "") }}/>
                                    <span className={`text-danger d-block error-span ${azureClientIdRequired ? "" : "invisible"}`}>
                                        Client ID is required
                                    </span>
                                </div>
                                <div className="col-md-12">
                                    <label className="w-100 form-label custom-label" htmlFor="clientSecret">Client Secret<span
                                        className="color-warning">*</span></label>
                                    <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Client Secret"
                                        id="clientSecret"
                                        value={azureAddSecretPayload.values.clientSecret}
                                        onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                        onBlur={(e) => { setAzureClientSecretKeyRequired(e.target.value === "") }}/>
                                    <span className={`text-danger d-block error-span ${azureClientSecretKeyRequired ? "" : "invisible"}`}>
                                        Client Secret is required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                            <button type="button" className="btn secondary-btn" onClick={() => { handleAzureAddSecretClose() }}
                            >Cancel</button>
                            <button type="button" className="btn primary-btn ms-3"
                            disabled={azureAddProvderSaveButton}
                            onClick={() => handleAzureSecretAdd()}
                            >
                                {azureAddProvderSaveLoader &&
                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                }
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* start of loader */}
            {showPageLoader && (
                <div className="container-fluid">
                    <div
                        className="modal-backdrop fade show"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-hidden="true"
                    >
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img
                                src="images/cloud-load-gif.gif"
                                alt="white-loader"
                                className="page-loader"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};
