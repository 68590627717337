import '../../assets/scss/_userprofile.scss'
import Header from "../Header/Header"
import { IUserProfileData, IPassword } from "../../interface/userProfile"
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { fetchUserProfile, fetchPossibleOwner, postNewOwnerDetail, putUserProfileDetails, updateUserProfileImage, putUserPasswordDetails, checkPasswordExist } from "../../service/userService"
import { AutoComplete, Input } from "antd"
import { useEncryptStorage } from "../../hooks/useEncryptStorage";
import { useDispatch } from 'react-redux'
import { setProfileImage } from '../../redux/userProfile/action'

export default function UserProfile() {

	const navigate = useNavigate()
	const dipatach = useDispatch()

	let userDataObj: IUserProfileData = {
		firstName: "",
		lastName: "",
		email: "",
		currency: "",
		loginId: 0,
		organizations: [],
		profileUrl: "",
		status: "",
		theme: ""
	}

	let passworobj: IPassword = {
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	}


	const [userProfile, setUserProfile] = useState<IUserProfileData>(userDataObj)
	const [tempProfileUrl, setTempProfileUrl] = useState<string>("")

	const [firstNameRequired, setFirstNameRequired] = useState<boolean>(false)
	const [firstNameLength, setFirstNameLength] = useState<boolean>(false)
	const [validFirstName, setValidFirstName] = useState<boolean>(false)

	const [lastNameRequired, setLastNameRequired] = useState<boolean>(false)
	const [lastNameLength, setLastNameLength] = useState<boolean>(false)
	const [validLastName, setValidLastName] = useState<boolean>(false)

	const [profileUpdateButton, setProfileUpdateButton] = useState<boolean>(true)

	const [password, setPassword] = useState<IPassword>(passworobj)
	const [message, setMessage] = useState<any>('')
	const [passwordloader, setPasswordloader] = useState<boolean>(false)
	const [oldPasswordRequired, setOldPasswordRequired] = useState<boolean>(false)
	const [showoldPassword, setshowoldPassword] = useState<boolean>(false)

	const [newPasswordRequired, setNewPasswordRequired] = useState<boolean>(false)
	const [newPasswordValidLen, setNewPasswordRequiredLen] = useState<boolean>(false)
	const [newPasswordValid, setNewPasswordValid] = useState<boolean>(false)
	const [shownewPassword, setshownewPassword] = useState<boolean>(false)

	const [confirmPassworkMatch, setConfirmPassworkMatch] = useState<boolean>(false)
	const [confirmPassworkRequired, setconfirmPassworkRequired] = useState<boolean>(false)
	const [showconfirmPassword, setshowconfirmPassword] = useState<boolean>(false)


	const [passwordUpdateButton, setPasswordUpdateButton] = useState<boolean>(true)

	const [transferButton, setTransferButton] = useState<boolean>(true)

	const [possibleOwnerDetailsList, setPossibleOwnerDetailsList] = useState<any[]>([])

	const [options, setOptions] = useState<any[]>([]);

	const [email, setEmail] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [selectedLoginId, setSelectedLoginId] = useState<number | null>(null);

	const [ownerChangedSuccessPopUpHidden, setOwnerChangedSuccessPopUpHidden] = useState<boolean>(false)

	const [fileSizeError, setFileSizeError] = useState<boolean>(false)
	const [fileTypeError, setFileTypeError] = useState<boolean>(false)

	const [profileUpdateSucessPopup, setProfileUpdateSucessPopup] = useState<boolean>(false)
	const [passwordUpdateSucessPopup, setPasswordUpdateSucessPopup] = useState<boolean>(false)
	const [errorPopUp, setErrorPopUp] = useState<boolean>(false)
	const [imageSaveDisabled, setImageSaveDisabled] = useState<boolean>(true)

	useEffect(() => {
		getUserDetails();
	}, [])

	const getUserDetails = async () => {
		const userProfileResponse = await fetchUserProfile();

		if (userProfileResponse.statusCode === 200) {
			setUserProfile(userProfileResponse.data)
			dipatach(setProfileImage(userProfileResponse.data.profileUrl))
		}
		else if (userProfileResponse.statusCode !== 200) {
			setErrorPopUp(false)
		}
	}

	const handleInputChange = (e: any) => {

		const regex = /^[a-zA-Z\s]+$/;
		let required = false;

		setUserProfile((prevUserProfile) => {
			const updatedProfile = { ...prevUserProfile, [e.target.id]: e.target.value };

			//handling firstName validations
			if (e.target.id === "firstName" && updatedProfile.firstName === "") {
				setFirstNameRequired(true)
				setFirstNameLength(false)
				setValidFirstName(false);
				required = true;
			}
			else if (e.target.id === "firstName" && updatedProfile.firstName !== "") {
				setFirstNameRequired(false)
				required = false;
			}

			if (e.target.id === "firstName" && updatedProfile.firstName?.length > 32) {
				setFirstNameLength(true)
				setFirstNameRequired(false)
				required = false;
			}
			else if (e.target.id === "firstName" && updatedProfile.firstName?.length <= 32) {
				setFirstNameLength(false)
				required = false;
			}

			if (e.target.id === "firstName" && updatedProfile.firstName !== "" && required === false) {
				const isValid = regex.test(updatedProfile.firstName)
				setValidFirstName(!isValid);
				setFirstNameLength(false)
			}

			//handling lastName validations
			if (e.target.id === "lastName" && updatedProfile.lastName === "") {
				setLastNameRequired(true)
				setValidLastName(false)
				setLastNameLength(false)
			}
			else if (e.target.id === "lastName" && updatedProfile.lastName !== "") {
				setLastNameRequired(false)
			}

			if (e.target.id === "lastName" && updatedProfile.lastName?.length > 32) {
				setLastNameLength(true)
			}
			else if (e.target.id === "lastName" && updatedProfile.lastName?.length <= 32) {
				setLastNameLength(false)
			}

			if (e.target.id === "lastName" && updatedProfile.lastName !== "") {
				setValidLastName(!(regex.test(updatedProfile.lastName)));
			}

			//handling update button of profile tab disable attribute
			if (updatedProfile.firstName !== "" && updatedProfile.lastName !== ""
				&& updatedProfile.firstName?.length <= 32 && updatedProfile.lastName?.length <= 32
				&& (regex.test(updatedProfile.firstName)) && (regex.test(updatedProfile.lastName))) {
				setProfileUpdateButton(false)
			}
			else {
				setProfileUpdateButton(true)
			}

			return updatedProfile
		});
	};
	const checkNewPassword = async (password: any) => {
		const payload = {
			"newPassword": password
		}
		setPasswordloader(true)
		const checkPasswordResponse = await checkPasswordExist(payload)
		if (checkPasswordResponse.statusCode === 200) {
			setMessage(checkPasswordResponse.data.message)
			setPasswordloader(false)
		}
		else if (checkPasswordResponse.statusCode !== 200) {
			setErrorPopUp(true)
			setPasswordloader(false)
		}

	}

	//function for handling password change
	const handlePasswordChange = (e: any) => {

		const lengthRegex = /^.{8,20}$/;
		// const passwordRegex =/^[A-Za-z0-9._%+-]@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/

		setPassword((prevpassword) => {
			const updatedPassword = { ...prevpassword, [e.target.id]: e.target.value };

			//oldPassword Input checks
			if (e.target.id === "oldPassword" && e.target.value === "") {
				setOldPasswordRequired(true)
			}
			else if (e.target.id === "oldPassword" && e.target.value !== "") {
				setOldPasswordRequired(false)
			}

			//newPassword Input checks
			if (e.target.id === "newPassword" && e.target.value === "") {
				setNewPasswordRequired(true)
				setNewPasswordRequiredLen(false)
			}
			else if (e.target.id === "newPassword" && e.target.value?.length >= 1 && !(lengthRegex.test(e.target.value))) {
				setNewPasswordRequiredLen(true)
				setNewPasswordRequired(false)
				setNewPasswordValid(false)
			}
			else if (e.target.id === "newPassword" && e.target.value?.length >= 1 && (lengthRegex.test(e.target.value))) {
				setNewPasswordRequiredLen(false)
			}
			else if (e.target.id === "newPassword" && e.target.value !== "") {
				setNewPasswordRequired(false)
			}

			if (e.target.id === "newPassword" && (lengthRegex.test(e.target.value)) && !(passwordRegex.test(e.target.value))) {
				setNewPasswordValid(true)
			}
			else if (e.target.id === "newPassword" && (lengthRegex.test(e.target.value)) && (passwordRegex.test(e.target.value))) {
				setNewPasswordValid(false)
			}


			if (e.target.id === "newPassword" && e.target.value !== ""
				&& password.confirmPassword !== "" && e.target.value !== password.confirmPassword) {
				setConfirmPassworkMatch(true)
				setconfirmPassworkRequired(false)
			}
			else if (e.target.id === "newPassword" && e.target.value !== ""
				&& password.confirmPassword !== "" && e.target.value === password.confirmPassword) {
				setConfirmPassworkMatch(false)
				setconfirmPassworkRequired(false)
			}

			//confirmPassword input checks
			if (e.target.id === "confirmPassword" && e.target.value !== "") {
				if (password.newPassword !== e.target.value) {
					setConfirmPassworkMatch(true)
					setconfirmPassworkRequired(false)
				}
				else if (password.newPassword === e.target.value) {
					setConfirmPassworkMatch(false)
					setconfirmPassworkRequired(false)
				}
			}
			else if (e.target.id === "confirmPassword" && e.target.value === "") {
				setConfirmPassworkMatch(false)
				setconfirmPassworkRequired(true)
			}

			//handling update button of password tab disable attribute
			if (updatedPassword.newPassword !== "" && updatedPassword.oldPassword !== ""
				&& updatedPassword.confirmPassword !== "" && (lengthRegex.test(e.target.value))
				&& (password.newPassword === updatedPassword.confirmPassword)) {
				setPasswordUpdateButton(false)
			}
			else {
				setPasswordUpdateButton(true)
			}

			return updatedPassword;
		})
	}

	// fetching possible owner details
	const transferOwnershipTabData = async () => {
		const possibleOwnerListResponse = await fetchPossibleOwner()

		if (possibleOwnerListResponse.statusCode === 200) {
			setPossibleOwnerDetailsList(possibleOwnerListResponse.data)
		}
		else if (possibleOwnerListResponse.statusCode !== 200) {
			setErrorPopUp(true)
		}
	}

	const handleProfileUpdateButtonClick = async () => {
		const updateUserProfileResponse = await putUserProfileDetails(userProfile, "")

		if (updateUserProfileResponse.statusCode === 200) {
			setUserProfile(updateUserProfileResponse.data)
			setTempProfileUrl("")
			setProfileUpdateSucessPopup(true)
			setProfileUpdateButton(true)
			useEncryptStorage.setItem("currency", updateUserProfileResponse.data.currency);
		}
		else if (updateUserProfileResponse.statusCode !== 200) {
			setErrorPopUp(false)
		}
	}

	const handleGotItClick = () => {
		setPasswordUpdateSucessPopup(false);
		navigate('/login'); // Redirect to the login page
	};

	const handleImageUpload = async (e: any) => {
		const allowedExtensions = [".png", ".jpeg", ".jpg"];
		const fileData = e.target.files[0]
		const fileType = fileData?.name.substring(fileData.name.lastIndexOf('.')).toLowerCase();
		const fileSize = fileData?.size
		const fileName = `${fileData?.name.substring(0, fileData?.name.lastIndexOf('.'))}_${userProfile.firstName}_${Math.floor(Math.random() * 90000) + 10000}_${fileType}`

		if (!allowedExtensions.includes(fileType)) {
			setFileTypeError(true)
		}
		else if (allowedExtensions.includes(fileType)) {
			setFileTypeError(false)
		}

		if (fileSize >= 250000) {
			setFileSizeError(true)
		}
		else if (fileSize <= 250000) {
			setFileSizeError(false)
		}

		if ((allowedExtensions.includes(fileType)) && fileSize <= 250000) {
			const updateProfileImageResponse = await updateUserProfileImage(fileData, fileName, true)
			setImageSaveDisabled(false)
			setTempProfileUrl(updateProfileImageResponse)
		}
}

	const updateProfile = async () => {
		const updateUserProfileResponse = await putUserProfileDetails(userProfile, tempProfileUrl)

		if (updateUserProfileResponse.statusCode === 200) {
			useEncryptStorage.setItem('profileUrl', updateUserProfileResponse.data)
			setUserProfile(updateUserProfileResponse.data)
			dipatach(setProfileImage(updateUserProfileResponse.data.profileUrl))
		}
		else if (updateUserProfileResponse.statusCode !== 200) {
			setErrorPopUp(false)
		}
	}

	const updatePassword = async () => {
		const updatePasswordResponse = await putUserPasswordDetails(password)

		if (updatePasswordResponse.statusCode === 200) {
			setPasswordUpdateSucessPopup(true)
			setPassword(passworobj)
			useEncryptStorage.clear()
			document.body.classList.remove('dark-header-theme');
			document.body.classList.remove('dark-theme');
			document.body.classList.remove('modal-open');
			document.body.classList.remove('modal-backdrop')
			document.body.classList.remove('fade')
			document.documentElement.removeAttribute('style');
			navigate('/login')
		}
		else if (updatePasswordResponse.statusCode !== 200) {
			setErrorPopUp(false)
		}
	}

	//start of handling Auto Completer
	const handleSearch = (value: string) => {
		const filteredData = possibleOwnerDetailsList?.filter(
			(item) =>
				item.firstName?.toLowerCase().includes(value?.toLowerCase()) ||
				item.email?.toLowerCase().includes(value?.toLowerCase())
		);
		setOptions(filteredData.map((item) => ({ value: `${item.firstName} - ${item.email}` })));
	};


	const onSelect = (value: string) => {
		const selectedData = possibleOwnerDetailsList.find((item: any) => `${item.firstName} - ${item.email}` === value);
		if (selectedData) {
			setEmail(selectedData.email);
			setName(selectedData.firstName);
			setSelectedLoginId(selectedData.loginId);
			setTransferButton(false)
		}
	};

	//start of handling Auto Completer

	const handleEmailChange = (e: any) => {
		setEmail(e.target?.value);
	};

	const handleNameChange = (e: any) => {
		setName(e.target?.value);
	};

	const handleTransferButtonClick = async () => {

		const newOwnerResponse = await postNewOwnerDetail(email, selectedLoginId)

		if (newOwnerResponse.statusCode === 200) {
			setOwnerChangedSuccessPopUpHidden(true)
		}
		else if (newOwnerResponse.statusCode !== 200) {
			setErrorPopUp(false)
		}
	}

	return (
		<div>
			<Header />
			<div>
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-10">
							<div className="row justify-content-center pt-3">
								<h3 className="page-header">
									User Profile
								</h3>
								<div className="col-md-12 col-lg-6">
									<div className="row justify-content-center">
										<ul
											className="nav nav-pills my-3 justify-content-between"
											id="pills-tab"
											role="tablist"
										>
											<li
												className="nav-item "
												role="presentation"
											>
												<button
													aria-controls="primary-info"
													aria-selected="true"
													className="nav-link custom-tab-style responsive-show px-2 mx-1 active"
													data-bs-target="#primary-info"
													data-bs-toggle="pill"
													id="primary-info-tab"
													role="tab"
													type="button"
												>
													Primary Info
													<span className="responsive-display-none">
														rmation
													</span>
												</button>
											</li>
											<li
												className="nav-item "
												role="presentation"
											>
												<button
													aria-controls="password"
													aria-selected="false"
													className="nav-link custom-tab-style responsive-show px-2 mx-1"
													data-bs-target="#password"
													data-bs-toggle="pill"
													id="password-tab"
													role="tab"
													type="button"
												>
													Password
												</button>
											</li>
											<li
												className="nav-item "
												role="presentation"
											>
												<button
													aria-controls="Ownership"
													aria-selected="false"
													className="nav-link custom-tab-style responsive-show px-2 mx-1"
													data-bs-target="#Ownership"
													data-bs-toggle="pill"
													id="Ownership-tab"
													role="tab"
													type="button"
													onClick={() => transferOwnershipTabData()}
												>
													Transfer Ownership
												</button>
											</li>
										</ul>
										<div className="col-md-10">
											<div
												className="tab-content"
												id="pills-tabContent"
											>
												<div
													aria-labelledby="primary-info-tab"
													className="tab-pane fade mt-4 active show"
													id="primary-info"
													role="tabpanel"
												>
													<div
														className="row"
													>
														<div className="text-center mb-4">
															<span className="profile-camera-btn profile-img-btn">
																<button
																	className="border-0 position-relative bg-transparent"
																	data-bs-target="#ChangeProfilePicture-popup"
																	data-bs-toggle="modal"
																	type="button"
																>
																	<img
																		src={userProfile.profileUrl || 'images/User-Profile.svg'}
																		alt="User-profile"
																		className="sk-profileimage-edit user-profile-sty"
																		onError={({ currentTarget }) => {
																			currentTarget.onerror = null;
																			currentTarget.src = "images/User-Profile.svg";
																		}}
																	/>
																	<img
																		alt="profile-camera"
																		className="position-absolute top-50 start-50 translate-middle"
																		src="images/Profile-camera-hover.svg"
																	/>
																</button>
															</span>
														</div>
														<div className="col-md-6 col-sm-12 col-lg-6 mb-3">
															<label
																className="form-label custom-label"
																htmlFor="firstName"
															>
																First Name
															</label>
															<input
																className="form-control custom-input py-2 px-2"
																id="firstName"
																placeholder="First Name"
																type="text"
																value={userProfile.firstName}
																onBlur={() => setFirstNameRequired((userProfile.firstName === "" ? true : false))}
																onChange={(e) => handleInputChange(e)}
															/>
															{firstNameRequired &&
																<span
																	className="text-danger d-block error-span"
																>
																	First Name is required.
																</span>
															}
															{firstNameLength &&
																<span
																	className="text-danger d-block error-span"
																>
																	First Name should not be more than 32 characters
																</span>
															}
															{validFirstName &&
																<span
																	className="text-danger d-block error-span"
																>
																	Enter a valid Name.
																</span>
															}
														</div>
														<div className="col-md-6 col-sm-12 col-lg-6 mb-4">
															<label
																className="form-label custom-label"
																htmlFor="lastName"
															>
																Last Name
															</label>
															<input
																className="form-control custom-input py-2 px-2"
																id="lastName"
																placeholder="Last Name"
																type="text"
																value={userProfile.lastName}
																onBlur={() => setLastNameRequired((userProfile.lastName === "" ? true : false))}
																onChange={(e) => handleInputChange(e)}
															/>
															{lastNameRequired &&
																<span
																	className="text-danger d-block error-span"
																>
																	Last Name is required.
																</span>
															}
															{lastNameLength &&
																<span
																	className="text-danger d-block error-span"
																>
																	Last Name should not be more than 32 characters
																</span>
															}
															{validLastName &&
																<span
																	className="text-danger d-block error-span"
																>
																	Enter a valid Name.
																</span>
															}
														</div>
														<div className="col-md-12 col-sm-12 col-lg-12 mb-3">
															<label
																className="form-label custom-label"
																htmlFor="EmailID"
															>
																Email ID
															</label>
															<input
																className="form-control custom-input py-2 px-2"
																disabled
																id="EmailID"
																placeholder="Email ID"
																type="text"
																value={userProfile.email}
															/>
														</div>
														<div className="col-md-6 col-sm-12 col-lg-6 mb-4">
															<label
																className="form-label custom-label"
																htmlFor="Role"
															>
																Role
															</label>
															<input
																className="form-control custom-input py-2 px-2"
																disabled
																id="Role"
																placeholder="Owner"
																type="text"
																value={userProfile.organizations[0]?.roleName || ""}
															/>
														</div>
														<div className="col-md-6 col-sm-12 col-lg-6 mb-4">
															<label
																className="form-label custom-label"
																htmlFor="currency"
															>
																Currency Type
															</label>
															<select
																aria-label="Default select example"
																className="form-select custom-input py-2 px-2"
																id="currency"
																onChange={(e) => handleInputChange(e)}
																value={userProfile.currency}
															>
																<option value="USD" selected={userProfile.currency === "USD" ? true : false}>
																	USD $ - US Dollar
																</option>
																<option value="INR" selected={userProfile.currency === "ISD" ? true : false}>
																	INR ₹ - Indian Rupee
																</option>
															</select>
														</div>
														<div className="d-flex justify-content-end mt-2 mb-5">
															<button
																className="btn secondary-btn"
																type="button"
																onClick={() => navigate('/dashboard')}
															>
																Cancel
															</button>
															<button
																className="btn primary-btn ms-3"
																type="button"
																disabled={profileUpdateButton}
																onClick={() => handleProfileUpdateButtonClick()}
															>
																Update
															</button>
														</div>
													</div>
												</div>
												<div
													aria-labelledby="password-tab"
													className="tab-pane fade mt-4"
													id="password"
													role="tabpanel"
												>
													<div
														className="row"
													>
														<div className="col-md-12 col-sm-12 col-lg-12 mb-3">
															<label
																className="form-label custom-label"
																htmlFor="oldPassword"
															>
																Old Password
															</label>
															<div className="position-relative">
																<input
																	className="form-control custom-input py-2 px-2"
																	autoComplete='new-password'
																	id="oldPassword"
																	placeholder="Enter old password"
																	type={showoldPassword ? 'text' : 'password'}
																	value={password.oldPassword}
																	onBlur={() => setOldPasswordRequired((password.oldPassword === "" ? true : false))}
																	onChange={(e) => handlePasswordChange(e)}
																/>
																{!showoldPassword ? <a onClick={() => setshowoldPassword(true)}><span className="position-absolute custom-postion cus-eye-close-icon"></span></a> :
																	<a onClick={() => setshowoldPassword(false)}><span className="position-absolute custom-postion cus-eye-open-icon"></span></a>}
															</div>
															{oldPasswordRequired &&
																<span
																	className="text-danger d-block error-span"
																>
																	Old Password is required
																</span>
															}
														</div>
														<div className="col-md-12 col-sm-12 col-lg-12 mb-3">
															<label
																className="form-label custom-label"
																htmlFor="newPassword"
															>
																New Password
															</label>
															<div className="position-relative">
																<input
																	className="form-control custom-input py-2 px-2"
																	id="newPassword"
																	placeholder="Enter new password"
																	type={shownewPassword ? 'text' : 'password'}
																	value={password.newPassword}
																	onBlur={() => {
																		setNewPasswordRequired((password.newPassword === "" ? true : false))
																		checkNewPassword(password.newPassword)
																	}}
																	onChange={(e) => handlePasswordChange(e)}
																/>
																{/* {passwordloader ? (
																	<span className='position-absolute custom-postion-spinner spinner-border spinner-border-sm mr-1'></span>
																) : ( 
																	<></>
																)} */}
																{!shownewPassword ? <a onClick={() => setshownewPassword(true)}><span className="position-absolute custom-postion cus-eye-close-icon"></span></a> :
																	<a onClick={() => setshownewPassword(false)}><span className="position-absolute custom-postion cus-eye-open-icon"></span></a>}
															</div>
															{newPasswordRequired &&
																<span
																	className=" text-danger d-block error-span"
																>
																	Password is required
																</span>
															}
															{newPasswordValidLen &&
																<span
																	className=" text-danger d-block error-span"
																>
																	The password field accepts 8-20 characters
																</span>
															}
															{newPasswordValid &&
																<span
																	className=" text-danger d-block error-span"
																>
																	Enter a valid password
																</span>
															}
															{message === "Passwords match" &&
																<span className=" text-danger d-block error-span">
																	Password already Exists
																</span>}
														</div>
														<div className="col-md-12 col-sm-12 col-lg-12 mb-5">
															<label
																className="form-label custom-label"
																htmlFor="confirmPassword"
															>
																Confirm New Password
															</label>
															<div className="position-relative">
																<input
																	className="form-control password-eye-hide custom-input py-2 px-2"
																	id="confirmPassword"
																	placeholder="Re enter the Password"
																	type={showconfirmPassword ? 'text' : 'password'}
																	value={password.confirmPassword}
																	onBlur={() => { setconfirmPassworkRequired(password.confirmPassword === "" ? true : false) }}
																	onChange={(e) => { handlePasswordChange(e) }}
																/>

																{!showconfirmPassword ? <a onClick={() => setshowconfirmPassword(true)}><span className="position-absolute custom-postion cus-eye-close-icon"></span></a> :
																	<a onClick={() => setshowconfirmPassword(false)}><span className="position-absolute custom-postion cus-eye-open-icon"></span></a>}

															</div>




															{confirmPassworkMatch &&
																<span
																	className="text-danger d-block error-span"
																>
																	Password and Confirm Password doesn't match
																</span>
															}
															{confirmPassworkRequired &&
																<span
																	className="text-danger d-block error-span"
																>
																	Confirm Password is required
																</span>
															}
														</div>
														<div className="d-flex justify-content-end mb-5 pb-5 mt-2">
															<button
																className="btn secondary-btn"
																type="button"
																onClick={() => navigate('/dashboard')}
															>
																Cancel
															</button>
															<button
																className="btn primary-btn ms-3"
																type="button"
																disabled={passwordUpdateButton}
																onClick={() => updatePassword()}
															>
																Update
															</button>
														</div>
													</div>
												</div>
												<div
													aria-labelledby="Ownership-tab"
													className="tab-pane fade mt-4"
													id="Ownership"
													role="tabpanel"
												>
													<div className="row">
														<div className="bd-callout">
															<p className="mb-0">
																User needs to be present in every organization that the owner belongs.
															</p>
														</div>
														<div className="col-md-12 col-sm-12 col-lg-12 mb-3">
															<label
																className="form-label custom-label"
																htmlFor="name"
															>
																Name
															</label>
															<div>
																<AutoComplete
																	options={options}
																	style={{ width: 574 }}
																	onSelect={onSelect}
																	onSearch={handleSearch}
																	value={name}
																	maxLength={33}
																>
																	<Input placeholder="Enter the name" className="form-control custom-input py-2 px-2 ng-untouched ng-pristine ng-valid" onChange={handleNameChange} value={name} />
																</AutoComplete>
															</div>
														</div>
														{name.length>32 &&
                                        					<span className="text-danger d-block error-span">Name should not be more than 32 characters</span>
                                    					}
														<div className="col-md-12 col-sm-12 col-lg-12 mb-5">
															<label
																className="form-label custom-label"
																htmlFor="email"
															>
																Email ID
															</label>
															<div>
																<AutoComplete
																	options={options}
																	style={{ width: 574 }}
																	onSelect={onSelect}
																	onSearch={handleSearch}
																	value={email}
																>
																	<Input placeholder="Enter the Email ID" className="form-control custom-input py-2 px-2 ng-untouched ng-pristine ng-valid" onChange={handleEmailChange} value={email} />
																</AutoComplete>
															</div>
														</div>
														<div className="d-flex justify-content-end mb-5 pb-5 mt-2">
															<button
																className="btn secondary-btn"
																type="button"
																onClick={() => navigate('/dashboard')}
															>
																Cancel
															</button>
															<button
																className="btn primary-btn ms-3"
																type="button"
																onClick={() => handleTransferButtonClick()}
																disabled={transferButton}
															>
																Transfer
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					aria-hidden="true"
					className="modal fade"
					data-bs-keyboard="false"
					id="ChangeProfilePicture-popup"
					tabIndex={-1}
				>
					<div className="modal-dialog">
						<div className="modal-content popup-sty border-0 px-3 py-2 custom-border-radius">
							<div className="modal-header border-0">
								<h5 className="modal-title custom-header-style">
									Change your profile picture
								</h5>
								<button
									aria-label="Close"
									className="btn-close"
									data-bs-dismiss="modal"
									type="button"
								/>
							</div>
							<div className="modal-body pt-2 pb-0">
								<div className="row mb-4 align-items-center responsive-text-center">
									<div className="col-md-5 mb-4">
										<img
											src={tempProfileUrl !== "" ? tempProfileUrl : userProfile.profileUrl}
											alt='images/User-Profile.svg'
											className="user-profile-sty"
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src = "images/User-Profile.svg";
											}}
										/>
									</div>
									<div className="col-md-7">
										<div className="row text-center">
											<div className="upload-btn-wrapper responsive-text-left responsive-text-center mb-3 px-4">
												<button
													className="upload-profilepic-btn-style border-0 mb-0 px-0 pointer-cursor"
													type="button"
												>
													<span className="me-2 upload-img" />
													Upload Picture
												</button>
												<input
													className="custom-cursor"
													name="myfile"
													type="file"
													onChange={(e) => handleImageUpload(e)}
												/>
												{fileSizeError &&
													<span
														className="text-danger d-block error-span"
													>
														The uploaded file should be less than 250 KB.
													</span>
												}
												{fileTypeError &&
													<span
														className="text-danger d-block error-span"
													>
														The uploaded file should be in JPG, JPEG or PNG format.
													</span>
												}
											</div>
											<button
												className="upload-profilepic-btn-style responsive-text-left responsive-text-center border-0 mb-2 px-4"
												type="button"
												onClick={() => setTempProfileUrl("images/User-Profile.svg")}
											>
												<img
													alt="Delete icon"
													className="me-2"
													src="images/Profile-Delete.svg"
												/>
												Remove Picture
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-end responsive-btn-center pb-4 pt-2">
								<button
									aria-label="Close"
									className="btn secondary-btn"
									data-bs-dismiss="modal"
									type="button"
									onClick={() => { setTempProfileUrl(""); setImageSaveDisabled(true) }}
								>
									Cancel
								</button>
								<button
									className="btn primary-btn ms-3"
									data-bs-dismiss="modal"
									type="button"
									disabled={imageSaveDisabled}
									onClick={() => updateProfile()}
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* start of profile update sucess popup */}
				<div id="profileSuccessPopup" className={`modal show fade successModal  ${profileUpdateSucessPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog custom-modal-theme" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setProfileUpdateSucessPopup(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Success.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Profile Updated Successfully
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setProfileUpdateSucessPopup(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of profile update sucess popup */}
				{/* start of password update sucess popup */}
				<div className={`modal show fade ${passwordUpdateSucessPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog custom-modal-theme" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setPasswordUpdateSucessPopup(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Success.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Password changed successfully
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setPasswordUpdateSucessPopup(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of password update sucess popup */}
				{/* start of owner changed sucess popup */}
				<div className={`modal show fade ${ownerChangedSuccessPopUpHidden ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setOwnerChangedSuccessPopUpHidden(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Success.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										You have successfully transferred the ownership to {email}
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => { setPasswordUpdateSucessPopup(false); handleGotItClick(); }}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of owner changed sucess popup */}
				{/* start of error popup */}
				<div className={`modal show fade ${errorPopUp ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setErrorPopUp(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Error.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Error Occured
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setErrorPopUp(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of error popup */}
			</div>
		</div>
	)
};
