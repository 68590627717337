import React,{ useState, useEffect, useMemo } from "react";
import "../../assets/scss/_popup-style.scss";
import { useSelector } from "react-redux";
import { IOrganizationData, ProviderDetail, defaultOrganization, IExchangeRate } from "../../interface/organization";
import { ICostCurrency, exchangeRateObj } from '../../interface/dashboard';
import { CostAnomalySortKeys, ICostAnomalyData } from "../../interface/costAnomalyDetection";
import {BaseConstant } from "../../constants/BaseConstant"
import { fetchAllCostAnomaly, } from "../../service/costAnomalyService";
import Header from "../Header/Header";
import { formatDate, formatShortDate } from "../../service/dateFormatterService";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { padWithALeadingZero } from "../../utils/numberUtils";
import { ProviderType } from "../../enum/providerTypeEnum";
import { compare } from "../../utils/comparator";
import { roundToDecimalPlaces } from "../../service/numberFormat";
import { getCurrencySymbol } from "../../service/currencyConverterService";
import { getCostAnomalyChartOptions } from "../../service/chartService";
import { LineChart } from "../ResuableComponents/Chart/LineChart";
import { IWebHookConnectionDetails } from "../../interface/manage";
import { webHookSaveDetails, fetchExistingWebHookUrl } from "../../service/infraService";
import { useEncryptStorage } from "../../hooks/useEncryptStorage";
import {convertCostData, getExchangeRate} from '../../service/currencyService'


export default function CostAnomalyDetection () {

    // PS_CA_01
    let webHookUrlPayloadObj: IWebHookConnectionDetails = {
        webHookUrl: ""
    };


    // PS_CA_02
    const [costAnomalyData, setCostAnomalyData] = useState<ICostAnomalyData[]>([]);
    const [filteredCostAnomalyData, setFilteredCostAnomalyData] = useState<ICostAnomalyData[]>([]);
    const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
    const [providerType, setProviderType] = useState("");
    const [showWebHookUrlPopup, setshowWebHookUrlPopup] = useState<boolean>(false)
    const [webHookUrlRequiered, setWebHookUrlRequried] = useState<boolean>(false)
    const [isWebHookSaveDisabled, setWebHookSaveDisabled] = useState<boolean>(true)
    const [isWebHookSaveLoaderEnabled, setWebHookSaveLoaderEnabled] = useState<boolean>(false)
    const [showNotAddedErrorPopup, setShowNotAddedErrorPopup] = useState<boolean>(false)
    const [webHookPayloadDetails, setWebHookPayloadDetails] = useState<IWebHookConnectionDetails>(webHookUrlPayloadObj)
    const [webHookConnectionDetails, setWebHookConnectionDetails] = useState<IWebHookConnectionDetails>(webHookUrlPayloadObj)
    const [isWebHookUrlExist, setIsWebHookUrlExist] = useState(false);
    const [exchangeRate,setExchangeRate] = useState<IExchangeRate>(exchangeRateObj)
    const [saveButtonLabel, setSaveButtonLabel] = useState("Save");
    const [pageLoader, setPageLoader] = useState(false)
    const role = useSelector((state: any) => state.role.role)


    // PS_CA_03
    const [providerTypeSelectProps, setProviderTypeSelectProps] = useState<ProviderSelectProps>({
        organizationId: 0,
        providerTypes: [],
        onProviderTypeChange: (organizationId, providerType) => { },
    });

    // PS_CA_04
    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        onScopeChange: (scope) => { },
    });
    
    // PS_CA_05
    const [currentLength, setCurrentLength] = useState(7)


    // PS_CA_06
    useEffect(() => {
        setSaveButtonLabel(isWebHookUrlExist ? "Update" : "Save");
    }, [isWebHookUrlExist]);

    useEffect(() => {
        async function fetchExchangeRate() {
            const response = await getExchangeRate();
            // Assuming the response contains the exchange rate as a string
            const rate = response.data; // Extract exchange rate from response data
            setExchangeRate(rate);
        }
        fetchExchangeRate();
    }, []);

    // PS_CA_07
    const handleWebHookSave = async () => {
        setPageLoader(true);
        setWebHookSaveLoaderEnabled(true);
        // PS_CA_08
        let webHookPayload = {
            webHookUrl: webHookPayloadDetails.webHookUrl,
        };
    
        // PS_CA_09
        const updateWebHookResponse = await webHookSaveDetails(webHookPayload, organization.organizationId);
    
        // PS_CA_011
        if (updateWebHookResponse.statusCode === 200) {
            handleClosePopup();
            setPageLoader(false);
    
            // Check web hook existence again and update the button label
            await checkWebHookUrlExistence();
        } else if (updateWebHookResponse.statusCode === 400 || updateWebHookResponse.statusCode === 500 || updateWebHookResponse.statusCode === 502 || updateWebHookResponse.statusCode === 503) {
            setShowNotAddedErrorPopup(true);
            setPageLoader(false);
        }
        setWebHookSaveLoaderEnabled(false);
    };
    
    
    // PS_CA_012
    const onOrganizationChange = async (selectedOrganization: IOrganizationData) => {
        setOrganization(selectedOrganization);
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        })
    }

    // PS_CA_013
    const onProviderTypeChange = async (organizationId: number, selectedProviderType: string) => {
        setPageLoader(true)
        console.log(organization)
        setProviderType(selectedProviderType);
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            onScopeChange: onScopeChange,
        })
    }

    // PS_CA_014
    const onScopeChange = async (selectedScope: ProviderDetail | ProviderDetail[]) => {
        setPageLoader(true)
        selectedScope = selectedScope as ProviderDetail
        console.log(organization)
        console.log(selectedScope)
        let costAnomaliesPayload;
        let providerId = selectedScope.providerId

        if (selectedScope.providerType === "AWS") {
            costAnomaliesPayload = {
                accountId: selectedScope.accountId,
                secretName: selectedScope.secretName,
                subscriptionId: "",
                tenantId: ""
            }
            await getAllCostAnomaly(providerId, selectedScope.organizationId, costAnomaliesPayload);

        }
        else if (selectedScope.providerType === "Azure") {
            costAnomaliesPayload = {
                accountId: "",
                secretName: selectedScope.secretName,
                subscriptionId: selectedScope.subscriptionId,
                tenantId: selectedScope.tenantId
            }
            await getAllCostAnomaly(providerId, selectedScope.organizationId, costAnomaliesPayload);
        }
    }

    const getAllCostAnomaly = async (providerId: number, organizationId : number, costAnomaliesPayload: any) => {

        // PS_CA_015
        const result = await fetchAllCostAnomaly(providerId,organizationId, costAnomaliesPayload);

        // PS_CA_017
        if (result.statusCode === 200 && result.data.values!==null) {

            let costAnomalies = result.data.values

            setCostAnomalyData(costAnomalies);
            setFilteredCostAnomalyData(costAnomalies)
            setPageLoader(false)
        }
        else if (result.statusCode === 400 || result.statusCode === 500 || result.statusCode === 502 || result.statusCode === 503 ) {
            setShowNotAddedErrorPopup(true);
            setPageLoader(false)
        } else if (result.data.values === null) {
            setPageLoader(false)
            setCostAnomalyData([]);
            setFilteredCostAnomalyData([])

        }

    }
    // PS_CA_018
    const organziationSelectComponent = useMemo(() => <OrganizationSelect onOrganizationChange={onOrganizationChange} />, []);
    const providerSelectComponent = useMemo(() => <ProviderSelect {...providerTypeSelectProps} />, [providerTypeSelectProps]);
    const scopeSelectComponent = useMemo(() => <ScopeSelect {...scopeSelectProps} onScopeChange={onScopeChange} />, [scopeSelectProps]);

    // PS_CA_019
    function handleLoadMore() {
        let limit = 7
        setCurrentLength(currentLength => currentLength + limit);
        // call fetchAllEvents()
    }

    // PS_CA_020
    const sort = (sortKey: CostAnomalySortKeys, order: string): void => {
        if (sortKey === 'severity') {
            let sortedList = filteredCostAnomalyData.sort((a, b) => compare(BaseConstant.severitySortOrder.indexOf(a?.severity), BaseConstant.severitySortOrder.indexOf(b?.severity), order));
            setFilteredCostAnomalyData([...sortedList])
            return
        }
        let sortedList = filteredCostAnomalyData.sort((a, b) => compare(a[sortKey], b[sortKey], order));
        setFilteredCostAnomalyData([...sortedList])
    }

    const convertCost = (cost: number, sourceCurrency: string) =>{
        if (useEncryptStorage.getItem("currency") === sourceCurrency ) {
            return cost
        } else {
            const convertedCost = convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRate)
            console.log(convertedCost);
            const convertedExchangeCost = roundToDecimalPlaces(convertedCost.cost,2)
            return convertedExchangeCost
        }
    }


    // PS_CA_021
    const costAnomalyChart = () => {
        const data = filteredDataForCurrentYear.map(eachData => {
            const valueAsString = convertCost(parseInt(eachData.actual?.toFixed(2)), eachData.currency).toString();
            const parsedValue = parseInt(valueAsString);
            if (isNaN(parsedValue)) {
                console.error(`Error: Failed to parse value "${valueAsString}" to a number.`);
            }
            return {
                xAxis: formatShortDate(new Date(eachData.eventDate)),
                value: parsedValue,
                currency: useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"
            }
        });
        console.log("Data for chart:", data); // Log the data to inspect it
        const chartOptions = getCostAnomalyChartOptions(data);
        return (
            <>
                <LineChart options={chartOptions}></LineChart>
            </>
        )
    }
    

    // PS_CA_022
    const handleClosePopup = () => {
        setWebHookPayloadDetails((prevData: any) => {
            const clearWebHookUrlConnectionPayload = {
                ...prevData,
                webHookUrl: ""
            }

            return clearWebHookUrlConnectionPayload
        })
        setWebHookSaveDisabled(true)
        setshowWebHookUrlPopup(false)
        setWebHookUrlRequried(false)
    }

    // PS_CA_023
    const handleWebHookUrlToolClick = async () => {
        await checkWebHookUrlExistence();
        setshowWebHookUrlPopup(true);
        setWebHookConnectionDetails((prevData) => {
            const populateToolDetails = {
                ...prevData,
                webHookUrl: webHookPayloadDetails.webHookUrl,
            };
            return populateToolDetails;
        });
    
        // PS_CA_024
        const existingWebHookUrl = await fetchExistingWebHookUrl(organization.organizationId);
        setWebHookPayloadDetails((prevData) => ({
            ...prevData,
            webHookUrl: existingWebHookUrl.data || "", // set to empty string if it doesn't exist
        }));
    };
    
        // PS_CA_025
        const handleWebHookUrlDataChange = (id: string, value: any) => {
            setWebHookPayloadDetails((prevData: any) => {
              const newConnectionDetail = { ...prevData, [id]: value };
              // PS_CA_026
              if (id === "webHookUrl") {
                if (value === "") {
                  setWebHookUrlRequried(true);
                } else {
                  setWebHookUrlRequried(false);
                }
              }
          
              // PS_CA_027
              if (newConnectionDetail.webHookUrl !== "") {
                setWebHookSaveDisabled(false);
              }
              return newConnectionDetail;
            });
          };

          // PS_CA_028
          const checkWebHookUrlExistence = async () => {
            // PS_CA_029
            const existingWebHookUrl = await fetchExistingWebHookUrl(organization.organizationId);
            setIsWebHookUrlExist(!!existingWebHookUrl?.data);
            setSaveButtonLabel((prevLabel) => {
                return !!existingWebHookUrl.data ? "Update" : "Save";
            });
        };

        const currentDate = new Date();

        // Get the first day of the current year
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        
        // Get the last day of the current year
        const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);
        
        // Filter the data for the current year
        const filteredDataForCurrentYear = filteredCostAnomalyData.filter(item => {
            const eventDate = new Date(item.eventDate);
            return eventDate >= firstDayOfYear && eventDate <= lastDayOfYear;
        });
        

        return (
            <>
                <div>
                    <Header />
                    <div className="container-fluid">
                        {/* <!-- cost anomly starts here --> */}
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                                    <h5 className="page-header mb-lg-0 mb-3">Cost Anomaly Detection</h5>
                                    <div className="d-md-flex d-sm-block">
                                        <div className=" dropdown me-md-4 res-margin-20">
                                            {organziationSelectComponent}
                                        </div>
                                        {/* // <!--organization ends--> */}
                                        {/* <!--Provider starts --> */}
                                        <div className="dropdown me-md-4 res-margin-20">
                                            {providerSelectComponent}
                                        </div>
                                        {   /* <!--Provider ends --> */}
                                        {/* <!--Scope starts--> */}
                                        <div className="input-group ms-3">
                                            {scopeSelectComponent}
                                        </div>
                                        <button
                                            type="button"
                                            className="ms-4 btn primary-btn d-flex align-items-center px-3"
                                            onClick={() => handleWebHookUrlToolClick()}
                                            disabled={role === "BillingReader"}
                                        >
                                            <span>
                                                <img
                                                    src="images/Microsoft_team _circle.svg"
                                                    alt="teams-logo"
                                                    className="me-2 btn-logo-hw"
                                                />
                                            </span>
                                            Configure
                                        </button>
                                    </div>
    
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3" style={{ height: "250px" }}>
                                    {(!costAnomalyData || costAnomalyData.length === 0) ? (
                                        <div style={{ textAlign: "center" }}>
                                            <img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-2" />
                                            <p className="para-text">No data found</p>
                                        </div>
                                    ) : (
                                        costAnomalyChart()
                                    )}
                                </div>

                                <div className="row mb-5">
                                    <h3 className="sub-header mb-3">Cost Anomaly Detection</h3>
                                    <div className="col-md-12">
                                        {/* <!--table starts  --> */}
                                        <div className="table-responsive">
                                            <table className="table table-borderless custom-grid ">
                                                <thead className="custom-table-head">
                                                    <tr>
                                                        <th className="custom-grid-font-weight">EVENT DATE</th>
                                                        <th className="text-center custom-grid-font-weight position-relative">SEVERITY
                                                            <span className="sorting">
                                                                <a className="sortup"
                                                                    onClick={() => sort('severity', 'Asc')}
                                                                ><span className="sort-icon sortup-img"></span></a>
                                                                <a className="sortdown"
                                                                    onClick={() => sort('severity', 'Desc')}
                                                                ><span className="sort-icon sortdown-img"></span></a>
                                                            </span>
                                                        </th>
                                                        <th className="text-end custom-grid-font-weight position-relative">ACTUAL COST
                                                            <span className="sorting">
                                                                <a className="sortup"
                                                                    onClick={() => sort('actual', 'Asc')}
                                                                ><span className="sort-icon sortup-img"></span></a>
                                                                <a className="sortdown"
                                                                    onClick={() => sort('actual', 'Desc')}
                                                                ><span className="sort-icon sortdown-img"></span></a>
                                                            </span>
                                                        </th>
                                                        <th className="text-end custom-grid-font-weight position-relative">AVERAGE COST
                                                            <span className="sorting">
                                                                <a className="sortup"
                                                                    onClick={() => sort('average', 'Asc')}
                                                                ><span className="sort-icon sortup-img"></span></a>
                                                                <a className="sortdown"
                                                                    onClick={() => sort('average', 'Desc')}
                                                                ><span className="sort-icon sortdown-img"></span></a>
                                                            </span>
                                                        </th>
                                                        <th className="custom-grid-font-weight position-relative">
                                                            {providerType === ProviderType.AWS ? 'HIGH UTILIZED SERVICE' : 'HIGH UTILIZED RESOURCE GROUP'}
                                                            <span className="sorting">
                                                                <a className="sortup"
                                                                    onClick={() => sort(providerType === ProviderType.AWS ? 'highUtilizedService' : 'highUtilizedResourceGroup', 'Asc')}
                                                                ><span className="sort-icon sortup-img"></span></a>
                                                                <a className="sortdown"
                                                                    onClick={() => sort(providerType === ProviderType.AWS ? 'highUtilizedService' : 'highUtilizedResourceGroup', 'Desc')}
                                                                ><span className="sort-icon sortdown-img"></span></a>
                                                            </span >
                                                        </th >
                                                        <th className="text-end custom-grid-font-weight position-relative">HIGH UTILIZED COST
                                                            <span className="sorting">
                                                                <a className="sortup"
                                                                    onClick={() => sort('highUtilizedCost', 'Asc')}
                                                                ><span className="sort-icon sortup-img"></span></a>
                                                                <a className="sortdown"
                                                                    onClick={() => sort('highUtilizedCost', 'Desc')}
                                                                ><span className="sort-icon sortdown-img"></span></a>
                                                            </span >
                                                        </th >
                                                    </tr >
                                                </thead >
                                                {filteredDataForCurrentYear?.length > 0 ?
                                                <tbody>
                                                    {
                                                        filteredDataForCurrentYear.slice(0, currentLength).map((each) => {
    
                                                            return (
                                                                <tr>
                                                                    <td className="grid-primary-text">
                                                                        {formatDate(new Date(each.eventDate))}
                                                                    </td>
                                                                    <td className="align-items-center text-center">
                                                                        <span className={
                                                                            each.severity === 'High' ? 'legend-sty legend-red' :
                                                                                each.severity === 'Medium' ? 'legend-sty legend-yellow' :
                                                                                    'legend-sty legend-darkgreen'
                                                                        }>
                                                                        </span>
                                                                    </td>
                                                                    <td className="grid-primary-text text-end">{useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseInt(roundToDecimalPlaces(each.actual, 2)),each.currency)}</td>
                                                                    <td className="text-end">{useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseInt(roundToDecimalPlaces(each.average, 2)),each.currency)}</td>
                                                                    <td >{
                                                                        (providerType === ProviderType.AWS ? each.highUtilizedService : each.highUtilizedResourceGroup)
                                                                    }</td>
                                                                    <td className="text-end">{useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseInt(roundToDecimalPlaces(each.highUtilizedCost, 2)),each.currency)}</td>
                                                                </tr >
    
                                                            )
                                                        })
                                                    }
    
                                                </tbody >:<tbody>
                                                <tr className="custom-alternative-line">
                                                    <td colSpan={7} className="text-center">
                                                        <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                            className="no-data-found-sty mb-4" />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr></tbody>}
                                            </table >
                                        </div >
                                        {(filteredDataForCurrentYear?.length <= 0 || filteredDataForCurrentYear === null)? (
                                            <p className="mb-3 mt-2 para-text"># of Records:
                                                <span
                                                    className="primary-color">
                                                </span> 0 out of 0
                                            </p>
                                        ) :
                                        (
                                            <p className="mb-3 mt-2 para-text"># of Records:
                                                <span
                                                    className="primary-color">{
                                                        padWithALeadingZero(filteredDataForCurrentYear?.length < currentLength ? filteredDataForCurrentYear?.length : currentLength)} out of {padWithALeadingZero(filteredDataForCurrentYear?.length)}
                                                </span>
                                            </p>
                                        )
                                        }
                                        {filteredCostAnomalyData?.length > currentLength &&
                                            <div className="row justify-content-center">
                                                <button type="button" className="btn secondary-btn mt-2 mb-5"
                                                    onClick={handleLoadMore}>
                                                    Load More
                                                </button>
                                            </div>
                                        }
                                    </div >
                                    {/* < !--table ends-- > */}
                                </div >
                            </div >
                        </div >
                    </div >
                    {/* < !--cost anomly ends here-- > */}
                </div >
    
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showWebHookUrlPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="modal-dialog custom-width-providerpopup ">
                        <div className="modal-content popup-sty border-0 custom-border-radius">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style d-flex align-items-center">
                                    Teams Configuration
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => { handleClosePopup() }}>
                                </button>
                            </div>
                            <div className="modal-body pt-2 pb-0">
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="instanceEmailId">Teams Intergration
                                            <span className="color-warning">*</span>
                                        </label>
                                        <input className="w-100 custom-input form-control py-2 px-2"
                                            type="text"
                                            placeholder="Enter your web hook URL"
                                            id="webHookUrl"
                                            value={webHookPayloadDetails.webHookUrl}
                                            onChange={(e) => { handleWebHookUrlDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setWebHookUrlRequried(e.target.value === "") }} />
                                        <span className={`text-danger d-block error-span ${webHookUrlRequiered ? "" : "invisible"}`}>
                                           Enter a webhook URL
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                                <button type="button" className="btn secondary-btn"
                                    onClick={() => { handleClosePopup() }}
                                >Cancel</button>
                                <button type="button"
                                    className="btn primary-btn ms-3"
                                    disabled={isWebHookSaveDisabled}
                                    onClick={() => {handleWebHookSave()}}
                                >
                                    {isWebHookSaveLoaderEnabled &&
                                        <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                    }
                                    {saveButtonLabel}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    
                {/* start of URL not able to add error popup */}
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showNotAddedErrorPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="custom-modal-theme modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="btn-close"
                                    onClick={() => { setShowNotAddedErrorPopup(false)}}></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-center mb-1">
                                    <img alt="Error" src="images/Error.svg" />
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn primary-btn" onClick={() => { setShowNotAddedErrorPopup(false)}}>
                                    Got It
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    
                {pageLoader &&
                        <div className="container-fluid" >
                            <div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                                </div>
                            </div>
                        </div >
                    }
        
            </>
        )
    };