import ReactECharts, { EChartsInstance, EChartsOption } from 'echarts-for-react'
import { LineChartProps } from '../../../interface/chart/chartData'
import { getCurrencySymbol } from '../../../service/currencyConverterService'
import { useEffect, useRef } from 'react'

export const LineChart = (props: LineChartProps) => {
  const { options, dynamicChartOptions } = props
  const chartRef = useRef<EChartsInstance>(null)

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance: EChartsInstance = chartRef.current.getEchartsInstance()
      if (chartInstance && dynamicChartOptions) {
        chartInstance.setOption(dynamicChartOptions)
      }
    }
  }, [dynamicChartOptions])

  return (
    <ReactECharts
      ref={chartRef}
      option={options}
      opts={{ renderer: 'svg' }}
      style={{ height: '255px' }}
      onChartReady={instance => {
        instance.resize()
      }}
    />
  )
}
