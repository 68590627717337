import "../../assets/scss/_criticalEvents.scss";

import React, { useState, useEffect, useMemo } from "react";
import {
  CriticalEventSortKeys,
  ICriticalEvents,
  IEventSearchData,
} from "../../interface/criticalEvents";
import { fetchAllEvents } from "../../service/environmentService";
import { useSelector } from "react-redux";
import { formatDateWithTime, formatResourceAvailabilityDateWithTime } from "../../service/dateFormatterService";
import Header from "../Header/Header";
import { padWithALeadingZero } from "../../utils/numberUtils";
import { IOrganizationData, ProviderDetail, defaultOrganization } from "../../interface/organization";
import { compare } from "../../utils/comparator";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { ProviderType } from "../../enum/providerTypeEnum";

export default function CriticalEvent () {

  // PS_CE_01
  const [criticalEventData, setCriticalEventData] = useState<ICriticalEvents[]>([]);
  const [filteredCriticalEventData, setFilteredCriticalEventData] = useState<ICriticalEvents[]>([]);
  const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
  const [providerType, setProviderType] = useState("");
  const [pageLoader, setPageLoader] = useState(false)

  // PS_CE_02
  const [providerTypeSelectProps, setProviderTypeSelectProps] = useState<ProviderSelectProps>({
    organizationId: 0,
    providerTypes: [],
    onProviderTypeChange: (organizationId, providerType) => { },
  });

  // PS_CE_03
  const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: "",
    onScopeChange: (scope) => { },
  });

  // PS_CE_04
  const pageLimit = 10
  const [currentLength, setCurrentLength] = useState(pageLimit)

  // PS_CE_05
  const onOrganizationChange = async (selectedOrganization: IOrganizationData) => {
    setCurrentLength(10)
    setPageLoader(true);
    setOrganization(selectedOrganization);
    setProviderTypeSelectProps({
        organizationId: selectedOrganization.organizationId,
        onProviderTypeChange: onProviderTypeChange,
        providerTypes: selectedOrganization.providerType,
    })
    setCriticalEventData([]); 
    setFilteredCriticalEventData([]); 
   }

  // PS_CE_06
  const onProviderTypeChange = async (organizationId: number, selectedProviderType: string) => {
    setCurrentLength(10)
    setPageLoader(true);
    setProviderType(selectedProviderType);
    setScopeSelectProps({
        organizationId: organizationId,
        providerType: selectedProviderType,
        onScopeChange: onScopeChange,
    })
    setCriticalEventData([]);  
    setFilteredCriticalEventData([]);  
  }

  // PS_CE_07
  const onScopeChange = async (selectedScope: ProviderDetail | ProviderDetail[]) => {
    setCurrentLength(10)
    setPageLoader(true);
    selectedScope = selectedScope as ProviderDetail
    let criticalEventsPayload
    let providerId = selectedScope.providerId

    if (providerType === "AWS") {
      criticalEventsPayload = {
        secretName: selectedScope.secretName,
        sub_id: "",
        tenant_id: "",
        accountId: selectedScope.accountId,
        subscriptionId: "",
        tenantId: ""
      }
    } else if (providerType === "Azure") {
      criticalEventsPayload = {
        secretName: selectedScope.secretName,
        sub_id: selectedScope.subscriptionId,
        tenant_id: selectedScope.tenantId,
        accountId: "",
      }
    }
    await getAllEvents(criticalEventsPayload, providerType);
  }
 
  // PS_CE_08
  async function getAllEvents(criticalEventsPayload:any, providerType : any) {
    setPageLoader(true);
    // PS_CE_09
    const result = await fetchAllEvents(providerType, criticalEventsPayload);

      // PS_CE_010
      if (!result!.data?.activityDetails || !result!.data.activityDetails.length) {
        setPageLoader(false);
        return;
      }

      // PS_CE_011
      let criticalEvents = result!.data.activityDetails.sort((a: ICriticalEvents, _: any) =>
        a.status === 'Failed' ? -1 : 1
      )
      setPageLoader(false);

      setCriticalEventData(criticalEvents);
      setFilteredCriticalEventData(criticalEvents);
  }
  
  // PS_CE_012
  const organziationSelectComponent = useMemo(() => <OrganizationSelect onOrganizationChange={onOrganizationChange} />, []);
  const providerSelectComponent = useMemo(() => <ProviderSelect {...providerTypeSelectProps} />, [providerTypeSelectProps]);
  const scopeSelectComponent = useMemo(() => <ScopeSelect {...scopeSelectProps} onScopeChange={onScopeChange} />, [scopeSelectProps]);


  // PS_CE_013
  function handleLoadMore() {
    setCurrentLength(currentLength => currentLength + pageLimit);
  }

  // PS_CE_014
  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = e.target.value.toLowerCase();
  
    if (searchValue === "") {
      // If the search input is empty, restore the grid to its normal state
      setFilteredCriticalEventData(criticalEventData);
    } else {
      // If there's a search input, filter the critical events
      let criticalEventsFiltered = criticalEventData.filter(each => {
        if (providerType === ProviderType.AWS) {
          return (
            each.name?.toLowerCase().includes(searchValue) ||
            each.userName?.toLowerCase().includes(searchValue) ||
            each.eventSource?.toLowerCase().includes(searchValue)
          );
        } else if (providerType === ProviderType.AZURE) {
          return (
            each.name?.toLowerCase().includes(searchValue) ||
            each.resourceName?.toLowerCase().includes(searchValue) ||
            each.status?.toLowerCase().includes(searchValue)
          );
        }
  
        return false;
      });
  
      setFilteredCriticalEventData(criticalEventsFiltered);
    }
  }
  
  // PS_CE_015
  const sort = (sortKey: CriticalEventSortKeys, order: string): void => {
    let sortedList = filteredCriticalEventData.sort((a, b) => compare(a[sortKey].toLowerCase(), b[sortKey].toLowerCase(), order));
    setFilteredCriticalEventData([...sortedList])
  }

  return (
    <>
      <div>
        <Header />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                <h5 className="page-header mb-lg-0 mb-3">Critical Events</h5>
                <div className="d-md-flex d-sm-block">
                   <div className=" dropdown me-md-4 res-margin-20 app-organization-select">
                      {organziationSelectComponent}
                    </div>
                    {providerSelectComponent}
                    <div className="db-provider">
                       {scopeSelectComponent}
                    </div>
                  <div className="input-group ms-3">
                    <input
                      type="text"
                      className="form-control text-field border-radius font-14 font-medium pe-5 custom-input"
                      id="search"
                      placeholder="Search"
                      onChange={handleSearch}
                    />
                    <span className="inputfield-icon-sty">
                      <a href="#">
                        <img src="images/search.svg" alt="Search icon" className="close-icon" />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-borderless custom-grid ">
                      <thead className="custom-table-head">
                        <tr>
                          <th className="custom-grid-font-weight">
                            {(providerType === ProviderType.AWS ? 'EVENT NAME' : 'USER NAME')}
                          </th>
                          <th className="custom-grid-font-weight">
                            {(providerType === ProviderType.AWS ? 'EVENT TIME' : 'RESOURCE')}
                          </th>
                          <th className="custom-grid-font-weight">
                            {(providerType === ProviderType.AWS ? 'USERNAME' : 'TENANT ID')}
                          </th>
                          <th className="custom-grid-font-weight">
                            {(providerType === ProviderType.AWS ? 'EVENT SOURCE' : 'EVENT TIME STAMP')}
                          </th>
                          <th className="custom-grid-font-weight text-center">
                            {(providerType === ProviderType.AWS ? 'EVENT ID' : 'STATUS')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCriticalEventData.length > 0 ? (
                          filteredCriticalEventData.slice(0, currentLength).map((each, index) => {
                            return (
                              <tr key={index}>
                                <td className="grid-primary-text td-wrap">
                                  {(providerType === ProviderType.AWS ? each.name : each.name ? each.name : "-")}
                                </td>
                                <td className={
                                  each.status === "Failed" ? "text-red" :
                                  each.status === "Succeeded" ? "text-green" :
                                  "grid-primary-text td-wrap"
                                }>
                                  {(providerType === ProviderType.AWS ? formatResourceAvailabilityDateWithTime(new Date(each.eventTime), providerType) : each.resourceName)}
                                </td> 
                                <td className="grid-primary-text td-wrap-anywhere">
                                  {(providerType === ProviderType.AWS ? each.userName : each.tenantId ? each.tenantId : "-")}
                                </td>
                                {providerType === "Azure" && 
                                  <td className="grid-primary-text td-wrap">
                                    {formatResourceAvailabilityDateWithTime(new Date(each.eventTimestamp), providerType)}
                                  </td>
                                }
                                {providerType === "AWS" &&
                                  <td className="grid-primary-text td-wrap ">
                                    {each.eventSource}
                                </td>
                                }
                                <td className={
                                  "grid-primary-text td-wrap text-center"
                                }>
                                  {providerType === ProviderType.AWS ?
                                  <span>{each.eventId}</span>:
                                  <span className={`custom-batch ${each.status === "Succeeded" ? "batch-green" : "batch-red"}`}>
                                       {each.status ==="Succeeded"? 'Success':'Failed'}
                                   </span>}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              <img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-4" />
                              <p className="para-text">No data found</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
    
                      <tbody>
                        {/* Load more content */}
                      </tbody>
    
                    </table>
                  </div>
    
                  <p className="mb-3 mt-2 para-text"># of Records:
                    <span className="primary-color">
                      {padWithALeadingZero(filteredCriticalEventData.length < currentLength ? filteredCriticalEventData.length : currentLength)} out of {padWithALeadingZero(filteredCriticalEventData.length)}
                    </span>
                  </p>
                  {filteredCriticalEventData.length > currentLength &&
                    <div className="row justify-content-center">
                      <button type="button" className="btn secondary-btn mt-2 mb-5" onClick={handleLoadMore}>
                        Load More
                      </button>
                    </div>
                  }
                  {pageLoader &&
                    <div className="container-fluid" >
                        <div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                            <div className="position-absolute top-50 start-50 translate-middle">
                                <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                            </div>
                        </div>
                    </div >
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};