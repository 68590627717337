import { client } from "../client/Client";
import { IAdvisorSummaryPayload } from "../interface/organization";
import { ISecurityGroupRequest } from '../interface/ISecurityGroupRequest';
import { IRequestPayload } from '../interface/IRequestPayload';

export async function getAzureRecommendations(payload: any) {
    let response = await client(`/infra/recommendations`, payload, "POST");
    return response;
}

export async function getAwsAdvisorChecks(payload: any) {
    let response = await client(`/infra/advisor/checks`, payload, "POST");
    return response;
}

export async function getAwsAdvisorCheckSummaries(payload: IAdvisorSummaryPayload) {
    let response = await client(`/infra/advisor/checks/summaries`, payload, "POST");
    return response;
}
export const getDueInvoices = async (request: any,providerType:any) => {

  let result = await client(`/infra/dueInvoices/${providerType}`, request, 'POST')

  return result
}

export const downloadInvoice = async (request: any, organizationId: number,providerType:any) => {
  
  let result = await client(
    `/infra/organization/${organizationId}/dueInvoices/download/${providerType}`,
    request,
    'POST'
  )

  return result

}

export async function getSecurityGroups(providerType: string, request: ISecurityGroupRequest) {
    // PS_SR_16
    let response = await client(`/infra/securityRules/${providerType}`, request, "POST");
    return response;
}

export async function getRegions(request: IRequestPayload) {
    // PS_SR_12
    let response = await client(`/infra/regions`, request, "POST");
    return response;
}

export const fetchAllEvents = async (providerType: string, criticalEventsPayload:any) => {
    // PS_CE_010
    let result = await client(`/infra/criticalEvents/${providerType}`, criticalEventsPayload, "POST");
    
    return result;
}
