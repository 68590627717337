import { EChartsOption } from 'echarts';
import { ProviderType } from '../../../enum/providerTypeEnum';
import getSymbolFromCurrency from 'currency-symbol-map'
import { IChartData } from '../../../interface/dashboard';
let moment = require('moment');

export default class ChartService {

    initOpts: {} = { renderer: 'svg' };

    awsColor: any[] = ["#E29578", "#F2CC8F", "#F9DED4", "#457E81", "#83C5BE"];
    azureColor: any[] = ['#C188F7', '#7FCBFA', '#FD92C6', '#8E74FF', '#FCDA81', "#00ffcc", "#ff9933", "#00e68a"];
    awsCostAnalysisColor: string = "#83C5BE";
    azureCostAnalysisColor: string = "#65BAEF";
    awsThresholdColor: string = "#F5B014";
    awsCurrentSpendsColor: string = "#0F97B9";
    azureThresholdColor: string = "#FDA3AD";
    azureCurrentSpendsColor: string = "#65BAEF";
    chartColor = ['#8D83FB', '#3abdb7', '#E65656', '#FFC65A', '#596FFF', '#F286A2', '#51AF58', '#7E7E7E', '#1767ED', '#647DB1']

    constructor() { }

    getCostAnalysisLayout(providerType: string): any {
        return `<div class="card h-100 dashboard-card-sty">
            <div class="customized-card cursor-move">
            <div class="d-md-flex  justify-content-between align-items-center pe-4">
                <h5 class="card-title mb-lg-0 db-card-header">Cost Analysis</h5>
                <div class="d-md-flex align-items-center">
                <p class="mb-md-0 align-items-center d-inline-flex mx-2 legend-text"><span
                    class="legend-sty legend-red me-2"></span>Threshold</p>
                <p class="mb-md-0 align-items-center d-inline-flex mx-2 legend-text"><span
                    class="legend-sty legend-green me-2"></span>Cost</p>
                <select id='costAnalysisSelect' class="db-card-custom-input ms-2 tag-dropdw py-1 ps-2 form-select">
                    <option value='monthly' selected>Monthly</option>
                    ${providerType === ProviderType.AWS ? "<option value='hourly'>Hourly</option>" : ""}
                    <option value='daily'>Daily</option>
                </select>
                </div>
                <div id="gridToolTip-CostAnalysis" class="kebab-icon">
                <button class="btn border-0 p-0 shadow-none" type="button"
                    id="monthly-kebab-button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="images/3Dot-Dropdown-Button.svg" alt="3Dot-Dropdown-Button">
                </button>
                <ul class="dropdown-menu custom-dropdown-sty border-0 p-2">
                    <li class="custom-dropdown-brd pb-1">
                    <a id="CostAnalysis-edit" class="dropdown-item custom-dropdown-li py-2 px-2">
                        <img src="images/Edit-Dropdown.svg" alt="Edit-Dropdown" class="me-2">Edit</a>
                    </li>
                    <li class="pt-1">
                    <a id="CostAnalysis-delete" class="dropdown-item py-2 px-2 custom-dropdown-li">
                        <img src="images/Delete-Dropdown.svg" alt="Delete-Dropdown" class="me-2">Delete</a>
                    </li>
                </ul>
                </div>
            </div>
            </div>
            <div id="CostAnalysis-loader" class="spinner chart-spinner start-50">
                <div class="custom-loader"></div>
            </div>
            <div id="CostAnalysis-chart" class="card-body" style="height: 270px;">
            </div>
            <div id="costAnalysis-error" class="card-body d-none chart-error" style="height: 270px;">
            <p id="costAnalysis-errorMsg" class="text-center"></p>
            </div>
        </div>`
    }

    getCostAnalysisChartData(costAnalysisData: any[], dateRange: string, providerType: string) {

        let data: { xAxis: any; value: any; currency: any; }[] | { xAxis: any; calue: any; currency: any; }[] = [];

        switch (dateRange) {
            case 'monthly': {
                // TR_DA_216
                switch (providerType) {
                    case ProviderType.AZURE: {
                        data = costAnalysisData.map(eachData => {
                            let xAxis = moment(`${eachData.date}`).format('MMM YY');
                            return { xAxis, value: eachData.amountDetails, currency: getSymbolFromCurrency(eachData.currency) };
                        });
                        break;
                    }
                    case ProviderType.AWS: {
                        data = costAnalysisData.map(eachData => {
                            let xAxis = moment(eachData.date).format('MMM YY');
                            return { xAxis, value: eachData.amountDetails, currency: getSymbolFromCurrency(eachData.currency) };
                        });
                        break;
                    }
                }
                break;
            }
            case 'daily': {
                // TR_DA_217
                switch (providerType) {
                    case ProviderType.AZURE: {
                        data = costAnalysisData.map(eachData => {
                            let xAxis = moment(eachData.date, 'YYYYMMDD').format('DD');
                            return { xAxis, value: eachData.amountDetails, currency: getSymbolFromCurrency(eachData.currency) };
                        });
                        break;
                    }
                    case ProviderType.AWS: {
                        data = costAnalysisData.map(eachData => {
                            let xAxis = moment(eachData.date).format('DD');
                            return { xAxis, value: eachData.amountDetails, currency: getSymbolFromCurrency(eachData.currency) };
                        });
                        break;
                    }
                }
                break;
            }
            case 'hourly': {
                data = costAnalysisData.map(eachData => {
                    let xAxis = moment(eachData.time).format('hh');
                    return { xAxis, calue: eachData.amountDetails, currency: getSymbolFromCurrency(eachData.currency) }
                })
                break;
            }
        }

        return data;
    }

    getCostAnalysisChartOptions(type: any, data: any[]): EChartsOption {
        let currency = data.map(each => each.currency).find(each => each != undefined);
        return {
            title: {
                textStyle: {
                    color: 'black',
                }
            },
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'none'
                }
            },
            grid: {
                top: "10%",
                bottom: "20%",
                right: "0%",
                left: "10%"
            },
            xAxis: this.getXaxis(data.map(each => each.xAxis), 0),
            yAxis: {
                type: 'value',
                axisLabel: {
                    show: true,
                    margin: 10,
                    color: 'var(--sub-text-color, #3C3C3C)',
                    formatter: currency + ' {value}'
                },
                splitLine: {
                    show: false
                }
            },
            series: [{
                data: data.map(each => Math.floor(each.value)),
                type: type,
                barWidth: '50%',
                color: this.azureCostAnalysisColor,
            }]
        }
    }

    getXaxis = (data: any[], rotate: number): any => {
        return {
            type: 'category',
            data,
            axisLine: {
                lineStyle: {
                    color: 'var(--sub-text-color, #3C3C3C)',
                    width: 1
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                rotate: rotate,
                interval: 0,
                margin: 10,
                color: 'var(--sub-text-color, #3C3C3C)',
            }
        }
    }

    getChartLayout(dimensionId: string, chartTitle: string): any {
        return `<div class="card h-100 dashboard-card-sty">
            <div class="customized-card cursor-move">
            <div class="d-flex  justify-content-between align-items-center pe-4">
                <h5 class="card-title mb-0 db-card-header">${chartTitle}</h5>
                ${dimensionId === 'ResourceGroup' ? '<button type="button" class="btn export-btn" id="export-btn">Export</button>' : ''}
                <div id="gridToolTip-${dimensionId}" class="kebab-icon">
                <button class="btn border-0 p-0 shadow-none" type="button"
                    id="kebab-button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="images/3Dot-Dropdown-Button.svg" alt="3Dot-Dropdown-Button">
                </button>
                <ul class="dropdown-menu custom-dropdown-sty border-0 p-2">
                ${dimensionId !== 'HIGH_UTILIZATION_REGIONS'
                ? `<li class="custom-dropdown-brd pb-1">
                    <a id="${dimensionId}-edit" class="dropdown-item custom-dropdown-li py-2 px-2">
                        <img src="images/Edit-Dropdown.svg" alt="Edit-Dropdown" class="me-2">Edit</a>
                    </li>`
                : ''}
                    <li class="pt-1">
                    <a id="${dimensionId}-delete" class="dropdown-item py-2 px-2 custom-dropdown-li">
                        <img src="images/Delete-Dropdown.svg" alt="Delete-Dropdown" class="me-2">Delete</a>
                    </li>
                </ul>
            </div>
            </div>
            <h5 id="${dimensionId}-linkedChartLable" class="chart-label mt-1 mb-0"></h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div id="${dimensionId}-loader" class="spinner chart-spinner">
                    <div class="custom-loader"></div>
                </div>
                <div id="${dimensionId}-chart" class="col-md-7" style="height: 225px;">
            </div>

            <div class="col-md-5 mt-2">
                <ul id="${dimensionId}-legend" class="list-unstyled db-wid-scrl mb-0">  
                </ul>
            </div>
            </div>
        </div>
        </div>`
    }

    getPieChartOptions(type: string, data: any[]): any {
        return {
            color: this.chartColor,
            series: [{
            type: 'pie',
            data,
            showEmptyCircle: true,
            avoidLabelOverlap: true,
            emptyCircleStyle: {
                opacity: 0.5
            },
            label: {
                show: false,
            },
            radius: type === 'pie' ? '95%' : ['75%', '95%'],
            itemStyle: {
                borderWidth: 0,
                borderColor: '#fff'
            }
        }],
        tooltip: {
            show: true,
            position: 'right',
            confine: true,
            // // backgroundColor: "#F2F0FF",
            padding: 0,
            extraCssText: 'border-radius: 7px; border: none',
            formatter: (ticket: any, _html: any) => {
                return `<div class="chart-tooltip">
                        <span class="chart-tooltip-spn"></span>
                        <div style="display:block">
                            <span
                            style="display:block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${ticket.color};margin-top:6px;">
                        </span>
                        </div>
                        <div style="display:block;">
                            <span style="display:block; margin-left:8px; padding-top:0px">${ticket.name}</span>
                            <span style="display:block; margin-left:8px; font-weight: bold; font-family: Gill Sans Medium">
                            $${ticket.value}
                        </span>
                        </div>
                    </div>`;
                }
            },
        legend: {
            show: false
            }
        }
    }

}