export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE"
export const SET_ROLE = "SET_ROLE"

export const setProfileImage = (values: any) => ({
    type: SET_PROFILE_IMAGE,
    payload: values,
});

export const setRole = (values: any) => ({
    type: SET_ROLE,
    payload: values,
});
