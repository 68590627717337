import { useEffect, useState } from 'react';
import '../../assets/scss/provider-select.css'
import { useEncryptStorage } from '../../hooks/useEncryptStorage';
import { useSelector } from 'react-redux';

export type ProviderSelectProps = {
    organizationId: number,
    providerTypes: string[];
    selectElement?: string;
    disable?: boolean;
    onProviderTypeChange: (organizationId: number, providerType: string) => void,
}

export const ProviderSelect = (props: ProviderSelectProps) => {

    const [providerTypeSelected, setProviderTypeSelected] = useState("");
    const isDashBoardListEnabled = useSelector((state: any) => state.dashboardList.isEnabled)


    useEffect(() => {

        if (!props?.providerTypes || props.providerTypes.length === 0) return

        let tempProviderType = props.providerTypes ? props.providerTypes[0] : ""
        if (props.selectElement) {
            let providerTypeFound = props.providerTypes.find(eachType => eachType === props?.selectElement);
            if (providerTypeFound) {
                tempProviderType = providerTypeFound;
            }
        }

        handleProviderTypeChange(props.organizationId, tempProviderType);
    }, [props])

    const handleProviderTypeChange = async (organizationId: number, providerType: string) => {
        setProviderTypeSelected(providerType);
        await props.onProviderTypeChange(organizationId, providerType);
    }

    const bindProviders = () => {

        return props.providerTypes?.map((eachProviderType: string) => (
                <li key={eachProviderType} className="custom-dropdown-brd pb-1" onClick={() => handleProviderTypeChange(props.organizationId, eachProviderType)}>
                    <a className="dropdown-item custom-dropdown-li py-2 px-2 d-flex align-items-center">
                        <span className={`me-3 template-dropdown-icon ${eachProviderType === "AWS" ? "aws-img" : "azure-img"}`}>
                        </span>
                        {eachProviderType}
                    </a>
                </li>
            ))
    }

    return (
        <>
                <div className="dropdown custom-me me-0 me-lg-4 res-margin-10">
                    <button
                        className={`txt-spacing text-start custom-dropdown-toggle db-custom-input w-100 py-2 text-nowrap d-flex align-items-center ${(useEncryptStorage.getItem("theme") === "light" && isDashBoardListEnabled) && "light-class"}`}
                        type="button" id="provider-dropdown" data-bs-toggle="dropdown" aria-expanded="false"
                    >
                        <span className={`me-3 template-dropdown-icon ${providerTypeSelected === "AWS" ? "aws-img" : "azure-img"} ${(useEncryptStorage.getItem("theme") === "light" && isDashBoardListEnabled && providerTypeSelected === "AWS") && "aws-light-image"}`} />
                        {providerTypeSelected}
                    </button>
                    <ul className="dropdown-menu dropdown-wid custom-dropdown-sty border-0 p-2" aria-labelledby="provider-dropdown">
                        {bindProviders()}
                    </ul>
                </div>
        </>
    )
};
    