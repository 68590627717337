import { IDashboardData } from "../interface/dashboard";

export class StaticDashboardLayout {

    static azureLayout: readonly IDashboardData[] = [
        {
            chartDimension: 'monthCost',
            chartType: '',
            title: 'Monthly Expenses till Date',
            layout: {
                x: 0,
                y: 0,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'monthCost',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'forecastedCost',
            chartType: '',
            title: 'Forecasted Cost',
            layout: {
                x: 4,
                y: 0,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'forecastedCost',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'thresholdLeft',
            chartType: '',
            title: 'Amount left for Threshold',
            layout: {
                x: 0,
                y: 20,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'thresholdLeft',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'recommendations',
            chartType: '',
            title: 'Recommendations',
            layout: {
                x: 4,
                y: 20,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'recommendations',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'ResourceLocation',
            chartType: 'doughNut',
            title: 'Locations',
            layout: {
                x: 8,
                y: 0,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'ResourceLocation',
            }
        },
        {
            chartDimension: 'ServiceName',
            chartType: 'pie',
            title: 'Services',
            layout: {
                x: 0,
                y: 40,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'ServiceName',
            }
        },
        {
            chartDimension: 'ResourceGroup',
            chartType: 'doughNut',
            title: 'Resource Groups',
            layout: {
                x: 4,
                y: 40,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'ResourceGroup',
            }
        },
        {
            chartDimension: 'ResourceId',
            chartType: 'doughNut',
            title: 'Resources',
            layout: {
                x: 8,
                y: 40,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'ResourceId',
            }
        },
        {
            chartDimension: 'TagKey',
            chartType: 'doughNut',
            title: 'Tag',
            layout: {
                x: 0,
                y: 80,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'TagKey',
            }
        },
        {
            chartDimension: 'CostAnalysis',
            chartType: 'line',
            title: 'Cost Analysis',
            layout: {
                x: 4,
                y: 80,
                w: 12,
                h: 38,
                minW: 8,
                minH: 38,
                maxH: 38,
                id: 'CostAnalysis',
            }
        }
    ];

    static awsLayout: readonly IDashboardData[] = [
        {
            chartDimension: 'monthCost',
            chartType: '',
            title: 'Monthly Expenses till Date',
            layout: {
                x: 0,
                y: 0,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'monthCost',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'forecastedCost',
            chartType: '',
            title: 'Forecasted Cost',
            layout: {
                x: 4,
                y: 0,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'forecastedCost',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'thresholdLeft',
            chartType: '',
            title: 'Amount left for Threshold',
            layout: {
                x: 0,
                y: 20,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'thresholdLeft',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'recommendations',
            chartType: '',
            title: 'Recommendations',
            layout: {
                x: 4,
                y: 20,
                w: 4,
                h: 18,
                minW: 4,
                minH: 18,
                id: 'recommendations',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'REGION',
            chartType: 'doughNut',
            title: 'Region',
            layout: {
                x: 8,
                y: 0,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'REGION',
            }
        },
        {
            chartDimension: 'SERVICE',
            chartType: 'pie',
            title: 'Services',
            layout: {
                x: 0,
                y: 40,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'SERVICE',
            }
        },
        {
            chartDimension: 'INSTANCE_TYPE',
            chartType: 'pie',
            title: 'Instance Types',
            layout: {
                x: 4,
                y: 40,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'INSTANCE_TYPE',
            }
        },
        {
            chartDimension: 'HIGH_UTILIZATION_REGIONS',
            chartType: 'doughNut',
            title: 'High Utilized Regions',
            layout: {
                x: 8,
                y: 40,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'HIGH_UTILIZATION_REGIONS',
            }
        },
        {
            chartDimension: 'TAG',
            chartType: 'doughNut',
            title: 'Tag',
            layout: {
                x: 0,
                y: 80,
                w: 4,
                h: 38,
                minW: 4,
                minH: 38,
                maxH: 38,
                id: 'TAG',
            }
        },
        {
            chartDimension: 'CostAnalysis',
            chartType: 'line',
            title: 'Cost Analysis',
            layout: {
                x: 4,
                y: 80,
                w: 12,
                h: 38,
                minW: 8,
                minH: 38,
                maxH: 38,
                id: 'CostAnalysis',
            }
        }
    ];

}