import React, { useState, useMemo } from 'react'
import {
  IOrganizationData,
  ProviderDetail,
  defaultOrganization
} from '../../interface/organization'
import $ from 'jquery'
import {
  ProviderSelect,
  ProviderSelectProps
} from '../ResuableComponents/ProviderSelect'
import { ScopeSelect, ScopeSelectProp } from '../ResuableComponents/ScopeSelect'
import {
  getAwsAvailabilitydropdowns,
  getResourceAvailability
} from '../../service/resourceAvailability'
import { OrganizationSelect } from '../ResuableComponents/OrganizationSelect'
import Header from '../Header/Header'
import { padWithALeadingZero } from '../../utils/numberUtils'
import moment from 'moment'

export const ResourceAvailability = () => {
  const [resourceFilterData, setResourceFilterData] = useState<any>([])
  const [awsResourceFilterData, setAwsResourceFilterData] = useState<any>([])  
  const [organization, setOrganization] =
    useState<IOrganizationData>(defaultOrganization)
  const [providerType, setProviderType] = useState('')

  // reusable dropdowns
  const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
      organizationId: 0,
      providerTypes: [],
      onProviderTypeChange: (organizationId, providerType) => {}
    })

  const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: '',
    onScopeChange: scope => {}
  })
  const [organizationId, setOrganizationId] = useState<any>()
  const [providerId, SetproviderId] = useState<any>()
  // pagination
  const pageLimit = 10
  const[AwsCount,setAwsCount]=useState<any>()
  const[count,setCount]=useState<any>()
  const [currentLengthAws, setCurrentLengthAws] = useState(10)
  const [currentLengthAzure, setCurrentLengthAzure] = useState(10)
  const [aws, setAws] = useState<boolean>()
  const [type, settype] = useState<any>([])
  const [location, setLocation] = useState<any>([])
  const [skip, setskip] = useState<number>(10)
  const [skipazure, setskipazure] = useState<number>(10)
  const [limit, setLimit] = useState<number>(10)
  const [limitaws, setLimitaws] = useState<number>(10) 
  const [order, setOrder] = useState<any>('')
  const [Key, setkey] = useState<any>('')
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false)
  // loader
  const [pageLoader, setPageLoader] = useState(false)

  const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
  ) => {
    setPageLoader(true)
    setOrganization(selectedOrganization)
    setProviderTypeSelectProps({
      organizationId: selectedOrganization.organizationId,
      onProviderTypeChange: onProviderTypeChange,
      providerTypes: selectedOrganization.providerType
    })
  }

  const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
  ) => {
    setPageLoader(true)
    setScopeSelectProps({
      organizationId: organizationId,
      providerType: selectedProviderType,
      onScopeChange: onScopeChange
    })
  }

  const [filter, setfilter] = useState({
    ResourceName: '',
    type: '',
    status: '',
    location: ''
  })
  const handleFilterDetails = (e: any) => {
    setfilter({
      ...filter,
      [e?.target?.id]: e?.target?.value
    })
  }
  
  const sort = (sortKey: string, order: number) => {
    setAwsResourceFilterData([])
    setResourceFilterData([])
    setOrder(order)
    setkey(sortKey)
    Applyfn(
      organizationId,
      providerId,
      providerType,
      0,10,sortKey,order,filter
    )
    // getAllResources(
    //   organizationId,
    // providerId,
    // providerType,
    // 0,10,sortKey,order
    // )
  }
  const onScopeChange = async (
    selectedScope: ProviderDetail | ProviderDetail[]
  ) => {
    selectedScope = selectedScope as ProviderDetail
    SetproviderId(selectedScope.providerId)
    setOrganizationId(selectedScope.organizationId)
    setProviderType(selectedScope.providerType)
    if (selectedScope.providerType === 'AWS') {
      setAws(true)
      await getAwsdropdowns(
        selectedScope.organizationId,
        selectedScope.providerId
      )
    } else if (selectedScope.providerType === 'Azure') {
      setAws(false)
    }
    await Applyfn(
      selectedScope.organizationId,
      selectedScope.providerId,
      selectedScope.providerType,0,10,'',1,filter
    )
  }
  const getAwsdropdowns = async (orgId: any, ProviderId: any) => {
    const response = await getAwsAvailabilitydropdowns(orgId, ProviderId)
    if (response?.statusCode == 200) {
      settype(response?.data?.type)
      setLocation(response?.data?.location)
    }else{
      setErrorPopUp(true)
    }
  }
  const ClearInputs=()=>{
    $('#invoice-filter').trigger('click')
    setfilter((prevFilter: any) => {
      const updatedFilterValue = {
        ResourceName: '',
        type: '',
        status: '',
        location: ''
      }
      Applyfn(organizationId,
        providerId,
        providerType,
        0,10,'',1,updatedFilterValue)

        return updatedFilterValue
    })
  }
  const Applyfn= async(organizationId: any,
    providerId: any,
    providerType: any,
    skip:number,limit:number,sortKey:string,order:number,filter:any)=>{
    setPageLoader(true)
    setAwsResourceFilterData([])
    setResourceFilterData([])
    setAwsCount(0)
    setCount(0)
    const resourceAvailabilityPayload = {
      providerId: providerId,
      search: filter?.ResourceName,
      type: filter?.type,
      location: filter?.location,
      state: filter?.status
    }
    const result = await getResourceAvailability(
      providerType,
      organizationId,
      resourceAvailabilityPayload,
      sortKey,
      order,
      skip,
      limit
    )
    if (result?.statusCode === 200) {
      setPageLoader(false)
      if (!result?.data?.resourceAvailabilities || !result?.data?.count) {
        console.error("No data found")
      }
      if (providerType === 'AWS'){
        setAwsCount(result?.data?.count)
      setAwsResourceFilterData(result?.data?.resourceAvailabilities)
      }

      if (providerType === 'Azure'){
        setCount(result?.data?.count)
      setResourceFilterData(result?.data?.resourceAvailabilities);
      }
    }
    else{
      setPageLoader(false)
      setErrorPopUp(true)
      console.error('500 Errors')
    }
  } 
  const getAllResources = async (
    organizationId: any,
    providerId: any,
    providerType: any,
    skip:number,limit:number,sortKey:string,order:number
  ) => {
    setPageLoader(true)
    const resourceAvailabilityPayload = {
      providerId: providerId,
      search: filter.ResourceName,
      type: filter.type,
      location: filter.location,
      state: filter.status
    }
    const result = await getResourceAvailability(
      providerType,
      organizationId,
      resourceAvailabilityPayload,
      sortKey,
      order,
      skip,
      limit
    )
    if (result?.statusCode === 200) {
      setPageLoader(false)
      if (!result?.data?.resourceAvailabilities || !result?.data?.count) {
        console.error("No data found")
      }
      if (providerType === 'AWS'){
        setAwsCount(result?.data?.count)
      let newValues = result?.data?.resourceAvailabilities
      if (Array.isArray(newValues)) {
        setAwsResourceFilterData((prevState:any) => [...prevState, ...newValues]);
      } else {
        console.error('newValues is not an array');
      }
      }

      if (providerType === 'Azure'){
        setCount(result?.data?.count)
        let Values = result?.data?.resourceAvailabilities
      if (Array.isArray(Values)) {
        setResourceFilterData((prevState:any) => [...prevState, ...Values]);
      } else {
        console.error('newValues is not an array');
      }
      }
    }
    else{
      setPageLoader(false)
      setErrorPopUp(true)
      console.error('500 Errors')
    }
  }
  function handleLoadMore () {
    setskip(skip+10)
    // setLimitaws(limitaws+10)
    setCurrentLengthAws(currentLengthAws=>currentLengthAws + 10)
    getAllResources(organizationId,
      providerId,
      providerType,skip,limit,'',1)

  }
  function handleAzureLoadMore(){
    setskipazure(skipazure+10)
    // setLimit(limit+10)
    setCurrentLengthAzure(currentLengthAzure=>currentLengthAzure + 10)
    getAllResources(organizationId,
      providerId,
      providerType,skipazure,limit,'',1)
  }
  const uniqueStatuses = Array.from(
    new Set(resourceFilterData?.map((eachDetail: any) => eachDetail.type))
  )
  const uniquelocation = Array.from(
    new Set(resourceFilterData?.map((eachDetail: any) => eachDetail.location))
  )
  const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
    []
  )
  const providerSelectComponent = useMemo(
    () => <ProviderSelect {...providerTypeSelectProps} />,
    [providerTypeSelectProps]
  )
  const scopeSelectComponent = useMemo(
    () => <ScopeSelect {...scopeSelectProps} />,
    [scopeSelectProps]
  )
  const resourceGridGeneration = () => {
    return (providerType === "AWS"&& awsResourceFilterData &&
      (awsResourceFilterData?.map((resource: any, index: any) => (
        <tr key={index}>
          <td className='grid-primary-text td-wrap'>{resource.name}</td>
          <td className='grid-primary-text'>{resource.type}</td>
          <td>{resource.location}</td>
          <td>{resource.availabilityState}</td>
          <td>{resource.occuredTime?moment(resource.occuredTime).format('MM/DD/YY'):'-'}</td>
        </tr>
      )))) 
  } 
  const resourceAzureGrid =()=>{
    return(
      providerType === "Azure"&& resourceFilterData &&
    (resourceFilterData?.map((resource: any, index: any) => (
      <tr key={index}>
        <td className='grid-primary-text'>{resource.id}</td>
        <td className='grid-primary-text'>{resource.type}</td>
        <td>{resource.location}</td>
        <td>{resource.availabilityState}</td>
        <td className='grid-primary-text td-wrap-Availabilty '>{resource.summary}</td>
        <td>{resource.occurredTime?moment(resource.occurredTime).format('MM/DD/YY'):'-'}</td>
      </tr>
    )))
    )
  }
  const Awsoptions=[
    "stopped", 
    "terminated",
     "Inactive", 
     "INACTIVE",
      "Stopped",
      "running"
  ]
  const AZureoptions=[
    "Available", 
    "Not Available",
    "Degraded"
  ]

  return (
    <>
      <div>
        <Header />
        <div className='container-fluid'>
          {/* <!-- resource availability grid starts here --> */}
          <div className='row justify-content-center'>
            <div className='col-md-10'>
              {/* <!-- mobile responsive starts here --> */}
              <div className='d-block d-lg-none d-flex justify-content-between align-items-center my-4'>
                <h5 className='page-header mb-0'>Invoices</h5>
                <div className='dropdown ms-md-3'>
                  {/* Add formGroup prop and onClick event */}
                  <button
                    className='btn secondary-btn filter-icon-sty px-2 d-flex align-items-center'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <span className='filter-img'></span>
                  </button>
                  <div className='dropdown-menu filter-dropdown p-3' onClick={(event) => event.stopPropagation()} >
                    <div className='d-flex justify-content-between'>
                      <h6 className='table-inner-header mb-3'>
                        Advanced Filter
                      </h6>
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={() => ClearInputs()}
                      ></button>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-sm-12 mb-3'>
                        <label
                          htmlFor='ResourceName'
                          className='form-label custom-label'
                        >
                          Resource Name
                        </label>
                        <input
                          type='text'
                          className='form-control custom-input py-2'
                          id='ResourceName'
                          value={filter.ResourceName}
                          placeholder='Enter Resource Name'
                          onChange={e => handleFilterDetails(e)}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12 mb-3'>
                        <label
                          htmlFor='type'
                          className='form-label custom-label'
                        >
                          Type
                        </label>
                        <select
                          className='custom-input py-2 w-100 px-3 form-select'
                          id='type'
                          value={filter.type}
                          onChange={e => handleFilterDetails(e)}
                        >
                          <option value='' selected> select </option>
                            
                          {aws
                            ? type?.map((eachDetail: any, index: any) => (
                                <option key={index}>{eachDetail}</option>
                              ))
                            : uniqueStatuses?.map(
                                (eachDetail: any, index: any) => (
                                  <option key={index}>{eachDetail}</option>
                                )
                              )}
                        </select>
                      </div>
                      <div className='col-md-6 col-sm-12 mb-3'>
                        <label
                          htmlFor='location'
                          className='form-label custom-label'
                        >
                          Location
                        </label>
                        <select
                          className='custom-input py-2 w-100 px-3 form-select'
                          id='location'
                          value={filter.location}
                          onChange={e => handleFilterDetails(e)}
                        >
                          <option value='' selected>
                            select
                          </option>
                          {aws
                            ? location?.map((eachDetail: any, index: any) => (
                                <option key={index}>{eachDetail}</option>
                              ))
                            : uniquelocation?.map(
                                (eachDetail: any, index: any) => (
                                  <option key={index}>{eachDetail}</option>
                                )
                              )}
                        </select>
                      </div>
                      {/* Date pickers */}
                      {/* Add onClick event for date picker */}
                      <div className='col-md-6 col-sm-12 mb-3'>
                        <label
                          htmlFor='Status'
                          className='form-label custom-label'
                        >
                          Status
                        </label>
                        <select
                          className='custom-input py-2 w-100 px-3 form-select'
                          id='status'
                          value={filter.status}
                          onChange={e => handleFilterDetails(e)}
                        >
                          <option selected>Select</option>
                          {aws
                            ? Awsoptions?.map((eachDetail: any, index: any) => (
                                <option key={index}>{eachDetail}</option>
                              ))
                            : AZureoptions?.map(
                                (eachDetail: any, index: any) => (
                                  <option key={index}>{eachDetail}</option>
                                )
                              )}
                        </select>
                      </div>
                      {/* </div> */}
                      <div className='d-flex justify-content-end mt-4'>
                        <button
                          type='button'
                          className='btn me-3 secondary-btn'
                          onClick={() => {ClearInputs()}}
                        >
                          Cancel
                        </button>
                        <button
                          type='button'
                          className='btn primary-btn'
                          onClick={() =>
                            Applyfn(organizationId,
                              providerId,
                              providerType,
                              0,10,'',1,filter)
                          }
                          // disabled={applyDispable}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* // <!-- mobile responsive ends here --> */}
              <div className='d-lg-flex d-md-block justify-content-between align-items-center my-4'>
                <h5 className='page-header mb-md-3 mb-lg-0 d-lg-block d-none'>
                  Resource Availability
                </h5>
                <div className='d-md-flex d-sm-block'>
                  {/* <!--organization starts--> */}
                  <div className='dropdown me-md-4 res-margin-20 app-organization-select'>
                    {organziationSelectComponent}
                  </div>
                  {/* <!--organization ends--> */}
                  {/* <!--Provider starts --> */}
                  {/* //<div className="dropdown me-md-4 res-margin-20"> */}
                  {providerSelectComponent}
                  {/* </div>
              {/* <!--Provider ends --> */}
                  {/* <!--Scope starts--> */}
                  {/* <div className="input-group ms-3"> */}
                  {scopeSelectComponent}
                  {/* </div> */}
                  {/* <!--Scope ends--> */}
                  {/* Advanced Filter */}
                  <div className='dropdown ms-md-3 d-lg-block d-none'>
                    <button
                      className='btn secondary-btn filter-icon-sty px-2 d-flex align-items-center'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                      id='invoice-filter'
                    >
                      <span className='filter-img'></span>
                    </button>
                    <div className='dropdown-menu filter-dropdown p-3' onClick={(event) => event.stopPropagation()} >
                      <div className='d-flex justify-content-between'>
                        <h6 className='table-inner-header mb-3'>
                          Advanced Filter
                        </h6>
                        <button
                          type='button'
                          className='btn-close'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                          onClick={() => ClearInputs()}
                        ></button>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 col-sm-12 mb-3'>
                          <label
                            htmlFor='ResourceName'
                            className='form-label custom-label'
                          >
                            Resource Name
                          </label>
                          <input
                            type='text'
                            className='form-control custom-input py-2'
                            id='ResourceName'
                            value={filter.ResourceName}
                            placeholder='Enter Resource Name'
                            onChange={e => handleFilterDetails(e)}
                          />
                        </div>
                        <div className='col-md-6 col-sm-12 mb-3'>
                          <label
                            htmlFor='type'
                            className='form-label custom-label'
                          >
                            Type
                          </label>
                          <select
                            className='custom-input py-2 w-100 px-3 form-select'
                            id='type'
                            value={filter.type}
                            onChange={e => handleFilterDetails(e)}
                          >
                            <option value='' selected>
                              select
                            </option>
                            {aws
                              ? type?.map((eachDetail: any, index: any) => (
                                  <option key={index}>{eachDetail}</option>
                                ))
                              : uniqueStatuses?.map(
                                  (eachDetail: any, index: any) => (
                                    <option key={index}>{eachDetail}</option>
                                  )
                                )}
                          </select>
                        </div>
                        <div className='col-md-6 col-sm-12 mb-3'>
                          <label
                            htmlFor='location'
                            className='form-label custom-label'
                          >
                            Location
                          </label>
                          <select
                            className='custom-input py-2 w-100 px-3 form-select'
                            id='location'
                            value={filter.location}
                            onChange={e => handleFilterDetails(e)}
                          >
                            <option value='' selected>
                              select
                            </option>
                            {aws
                              ? location?.map((eachDetail: any, index: any) => (
                                  <option key={index}>{eachDetail}</option>
                                ))
                              : uniquelocation?.map(
                                  (eachDetail: any, index: any) => (
                                    <option key={index}>{eachDetail}</option>
                                  )
                                )}
                          </select>
                        </div>
                        {/* Date pickers */}
                        {/* Add onClick event for date picker */}
                        <div className='col-md-6 col-sm-12 mb-3'>
                          <label
                            htmlFor='Status'
                            className='form-label custom-label'
                          >
                            Status
                          </label>
                          <select
                            className='custom-input py-2 w-100 px-3 form-select'
                            id='status'
                            value={filter.status}
                            onChange={e => handleFilterDetails(e)}
                          >
                            <option value='' selected>
                              Select
                            </option>
                            {aws
                            ? Awsoptions?.map((eachDetail: any, index: any) => (
                                <option key={index}>{eachDetail}</option>
                              ))
                            : AZureoptions?.map(
                                (eachDetail: any, index: any) => (
                                  <option key={index}>{eachDetail}</option>
                                )
                              )}
                          </select>
                        </div>
                        {/* </div> */}
                        <div className='d-flex justify-content-end mt-4'>
                          <button
                            type='button'
                            className='btn me-3 secondary-btn'
                            onClick={() => {ClearInputs()}}

                          >
                            Cancel
                          </button>
                          <button
                            type='button'
                            className='btn primary-btn'
                            onClick={() =>
                              Applyfn(
                                organizationId,
                                providerId,
                                providerType,
                                0,10,'',1,filter
                              )
                            }
                            // disabled={applyDispable}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {providerType === "AWS" &&
              <div className='row mb-5'>
                <div className='col-md-12'>
                  {/* <!--table starts  --> */}
                  <div className='table-responsive'>
                    <table className='table table-borderless custom-grid '>
                      <thead className='custom-table-head'>
                        <tr>
                          <th className='custom-grid-font-weight position-relative'>
                             NAME
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.name', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.name', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            TYPE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.type', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.type', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            REGION
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.location', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.location', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            STATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.availabilityState', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.availabilityState', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight '>
                            OCCURRED DATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.occuredTime', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.occuredTime', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      {awsResourceFilterData && <tbody>{aws ? resourceGridGeneration():resourceAzureGrid()}</tbody>}
                      {!awsResourceFilterData && <tbody><tr className="custom-alternative-line">
                                                    <td colSpan={8} className="text-center">
                                                        <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                            className="no-data-found-sty mb-4" />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr></tbody>}
                    </table>
                  </div>
                  <p className='mb-3 mt-2 para-text'>
                    # of Records:
                    <span className='primary-color'>
                      {padWithALeadingZero(
                        count < currentLengthAws
                          ? awsResourceFilterData?.length
                          : currentLengthAws
                      )}{' '}
                      out of {padWithALeadingZero(AwsCount)}
                    </span>
                  </p>
                  {AwsCount > currentLengthAws && (
                    <div className='row justify-content-center'>
                      <button
                        type='button'
                        className='btn secondary-btn mt-2 mb-5'
                        onClick={handleLoadMore}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {/* < !--table ends-- > */}
                </div>
              </div>}
            
              {providerType === "Azure" && <div className='row mb-5'>
                <div className='col-md-12'>
                  {/* <!--table starts  --> */}
                  <div className='table-responsive'>
                    <table className='table table-borderless custom-grid '>
                      <thead className='custom-table-head'>
                      <tr>
                          <th className='custom-grid-font-weight position-relative'>
                            RESOURCE NAME
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.id', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.id', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            TYPE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.type', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.type', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            LOCATION
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.location', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.location', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            STATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.availabilityState', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.availabilityState', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight'>
                            DESCRIPTION
                          </th>
                          <th className='custom-grid-font-weight '>
                            OCCURRED DATE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('resource_data.occurredTime', 1)}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('resource_data.occurredTime', -1)}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      {resourceFilterData && <tbody>{resourceAzureGrid()}</tbody>}
                      {!resourceFilterData && <tbody><tr className="custom-alternative-line">
                                                    <td colSpan={6} className="text-center">
                                                        <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                            className="no-data-found-sty mb-4" />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr></tbody>}
                    </table>
                  </div>
                  <p className='mb-3 mt-2 para-text'>
                    # of Records:
                    <span className='primary-color'>
                      {padWithALeadingZero(
                        count > currentLengthAzure
                          ? currentLengthAzure
                          : count
                      )}{' '}
                      out of {padWithALeadingZero(count)}
                    </span>
                  </p>
                  {count > currentLengthAzure && (
                    <div className='row justify-content-center'>
                      <button
                        type='button'
                        className='btn secondary-btn mt-2 mb-5'
                        onClick={handleAzureLoadMore}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                  {/* < !--table ends-- > */}
                </div>
              </div>}

            </div>
          </div>
          {/* < !--resource availability grid ends here-- > */}
        </div>
      </div>
      {/* < !--Page Loader-- > */}
      {pageLoader ? (
        <div className='container-fluid'>
          <div
            className='modal-backdrop fade show'
            id='AddOrganization-popup'
            data-bs-keyboard='false'
            tabIndex={-1}
            aria-hidden='true'
          >
            <div className='position-absolute top-50 start-50 translate-middle'>
              <img
                src='images/cloud-load-gif.gif'
                alt='white-loader'
                className='page-loader'
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* start of error popup */}
				<div className={`modal show fade ${errorPopUp ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setErrorPopUp(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Error.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Error Occured
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setErrorPopUp(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of error popup */}
    </>
  )
}
