import { EncryptStorage } from 'encrypt-storage';

const REACT_APP_SECRET_NAME = "cloudCostManagement";

export const useEncryptStorage = new EncryptStorage(REACT_APP_SECRET_NAME, {
    stateManagementUse: true,
    storageType: 'localStorage',
});

export const key = {
    SAS_TOKEN: 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-03-11T22:38:13Z&st=2024-03-11T14:38:13Z&spr=https&sig=F4jrm2RHjREcRFAvgii0IL6liAF62ewPhNRXlwGJI4k%3D',
    CONTAINER_NAME: 'mediafiles',
    ACCOUNT_NAME: 'avaeusccmstgdev',
    PROFILE_IMAGE_SAS_TOKEN: 'sp=racwli&st=2024-03-11T14:19:48Z&se=2025-03-11T22:19:48Z&spr=https&sv=2022-11-02&sr=c&sig=Ac2CUJtYLsHaNVMTG0lLRqo6AyUH9UFeiJvziItbDkM%3D',
    PROFILE_IMAGE_CONTAINER: 'profileimages',
    API_URL: 'https://app.trinetra.io',
    PUBLIC_API_URL: 'https://app.trinetra.io',
    TEST_ENV: 'TestENV',
}
