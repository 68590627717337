import { client } from '../client/Client'
import { ISecretKey } from '../interface/manage'

export async function fetchSecretKeyDetails(secretKeyObj: ISecretKey) {
    let response = await client("/manage/secrets/listing",secretKeyObj, "POST")

    return response
}

export async function postSecretKeyDetails(secretObj: any) {
    let response = await client("/manage/secret",secretObj, "POST")

    return response
}

export async function deleteSecret(deleteRequestObj: any) {
    let response = await client("/manage/secret/delete",deleteRequestObj, "PUT")

    return response
}
