		
import { useEffect } from "react";
import { useEncryptStorage } from "../hooks/useEncryptStorage"
import { postUpdatedTheme } from "../service/userService";

export const useThemeSwitcher = (viewLink: any) => {
    
    const darkThemeVariables: any = {
        //Primary Variables
        "--primary-color": "#F7F7F7",
        "--primary-color-focus": "#0441aa",

        "--secondary-color": "#404149",
        "--secondary-color-focus": "#7f829a",

        "--db-btn-bg": "#404149",
        "--db-btn-bg-focus": "#7f829a",
        "--db-btn-color": "#F7F7F7",
        "--drpdwn-bg": "#404149",

        "--page-heading-color": "#F7F7F7",
        "--primary-text-color": "#CACACA",
        "--secondary-text-color": "#707D94",
        "--sub-text-color": "#B8B8B8",
        "--custom-grid-text-color": "#cdcdcd",

        "--card-header-color": "#F7F7F7",

        "--grid-pri-text-color": "#cdcdcd",

        "--currency-field-bg": "#404149",
        "--currency-color": "#CACACA",
        "--multiselect-bg-color": "#404149",
        "--monthly-expense-bg": "#28282E",

        "--template-card-bg": "#28282E",

        //Background Color
        "--body-bg-color": "#141518",
        "--primary-bg-color": "#404149",
        "--secondary-bg-color": "#28282E",
        "--light-bg-color": "#212127",
        "--popup-bg-color": "#1B1C21",

        //header color
        "--dashboard-head-bg": "#141518",
        "--dashboard-filter-bg": "#404149",

        //top nav styles
        "--top-nav-shadow": "none",

        //Form Variables
        "--form-label-color": "#CACACA",
        "--form-text-color": "#DBDBDB",
        "--form-label-margin-bottom": "8px",
        "--form-input-bordercolor": "1px solid #28282E",
        "--form-check-bordercolor": "#1767ED 1px solid",

        //batch style
        "--batch-green-bg": "none",
        "--batch-red-bg": "none",
        "--batch-grey-bgcolor": "none",
        "--batch-padding": "0px 0px",

        //button
        "--primary-button-bg-color": "#1767ED",
        "--secondary-button-bg-color": "#404149",
        "--primary-button-border": "none",
        "--secondary-button-border": "none",
        "--ternary-bg-color": "translate",
        "--ternary-bg-focus": "#1767ED",
        "--ternary-border-color": "1px solid #1767ED",
        "--ternary-color": "#1767ED",

        "--popup-subheader-color": "#CACACA",

        //Popup Button Card style
        "--custom-buttoncard-border": "1px solid #F7F7F7",
        "--upload-button-dashedborder": "1px dashed #ACACAC",

        //Popup bg
        "--custom-bg-chart": "#141518",

        //Inline table style
        "--inline-grid-th-color": "#F7F7F7",
        "--inline-grid-th-bg-color": "#404149",

        //box shadow
        "--custom-box-shadow": "0 0.125rem 0.25rem #ADADAD",

        //dashboard style starts here
        "--dashboard-card-shadow": "none",
        "--db-static-label-bg-color": "#36373F",

        //Tab style starts here
        "--tab-label-color": "#DBDBDB",

    };

    const darkThemeHeaderVariables: any = {
        "--topnav-custom-box-shadow": "0 0.125rem 0.25rem #ADADAD",

        "--topnav-primary-color": "#F7F7F7",
        "--topnav-primary-color-focus": "#0441aa",
        "--topnav-primary-text-color": "#CACACA",

        "--topnav-secondary-button-bg-color": "#404149",
        "--topnav-secondary-button-border": "none",

        "--topnav-secondary-color": "#404149",
        "--topnav-secondary-bg-color": "#28282E",

        "--topnav-body-bg-color": "#141518",
        "--topnav-top-nav-shadow": "none",

        "--footer-primary-color": "#F7F7F7",
        "--footer-body-bg-color": "#141518",

        "--topnav-drpdwn-form-bg": "#404149",
        "--topnav-drpdwn-text-color": "#DBDBDB",
        "--topnav-drpdwn-bg": "#404149",
        "--topnav-drpdwn-bordercolor": "1px solid #F7F7F7"
    }

    useEffect(() => {
        initTheme();
    }, []);
    
    const initTheme = () => {
        let theme = useEncryptStorage.getItem("theme");

        if (theme === "" || theme === "null") {
            theme = window.matchMedia("(prefers-color-scheme: dark)")?.matches ? "dark" : "light";
            useEncryptStorage.getItem("theme", theme)
            postUpdatedTheme(theme)
        }

        if (viewLink === "/dashboard") {
            changeHeaderFooterTheme("dark");
            changeComponentsTheme(theme);
        }
        else if (viewLink !== "/dashboard")
            changeHeaderFooterTheme(theme);
            changeComponentsTheme(theme);
    }

    const setCssProperties = (theme: string, variables: any, toggleClass: string) => {
        if (theme === "dark") {

            document.body.classList.toggle(toggleClass, true);
            Object.keys(variables).forEach(eachVariable => {
                document.documentElement.style.setProperty(eachVariable, variables[eachVariable]);
            });
        }
        else {
            // PS_HD_06
            document.body.classList.toggle(toggleClass, false);
            Object.keys(variables).forEach(eachVariable => document.documentElement.style.setProperty(eachVariable, ""));
        }
    };


    const changeHeaderFooterTheme = (theme: string) => {
        setCssProperties(theme, darkThemeHeaderVariables, "dark-header-theme");
    };

    const changeComponentsTheme = (theme: string) => {
        setCssProperties(theme, darkThemeVariables, "dark-theme");
    };
    
    return initTheme;
};

