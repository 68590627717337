import { EChartsOption } from "echarts-for-react";
import { getCurrencySymbol } from "./currencyConverterService";
import moment from 'moment';

export const getCostAnomalyChartOptions = (data: any[]): EChartsOption => {
    let currency = data.find(each => each.currency).currency;
    let max = data.reduce((r, each) => Math.max(r, each.value), 0)/1.2;
    return {
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 0,
          min: 0,
          max: max,
          color: ["#a45339", "#4575b4", "#74add1"]
        }
      ],
      tooltip: {
        position: 'right',
        padding: 0,
        extraCssText: 'border-radius: 7px; border: none',
        trigger: 'axis',
        formatter: (ticket: any, _html: string) => {
          return `<div class="chart-tooltip">
                    <span class="chart-tooltip-spn"></span>
                    <div style="display:block">
                      <span
                        style="display:block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${ticket[0].color};margin-top:6px;">
                      </span>
                    </div>
                    <div style="display:block;">
                      <span style="display:block; margin-left:8px; padding-top:0px">${ticket[0].name}</span>
                      <span style="display:block; margin-left:8px; font-weight: bold; font-family: Gill Sans Medium">
                        ${currency}${ticket[0].value}
                      </span>
                    </div>
                  </div>`;
        }
      },
      grid: {
        top: "10%",
        bottom: "10%",
        right: "5%",
        left: "5%"
      },
      xAxis: [
        {
          data: data.map(each => each.xAxis),
          axisTick: {
            show: false
          },
          axisLabel: {
            align: 'center'
          }
        }
      ],
      yAxis: [
        {
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            margin: 10,
            color: 'var(--sub-text-color, #3C3C3C)',
            formatter: currency + ' {value}'
          },
        }
      ],
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: data.map(each => each.value)
        }
      ]
    }
  };
  export const getCostAnalysisChartData = (costAnalysisData:any, dateRange:any, providerType:any) => {
    let data = [];
    switch (dateRange) {
      case 'monthly': {
        switch (providerType) {
          case 'Azure': {
            data = costAnalysisData?.map((eachData:any) => {
              let xAxis = moment(eachData.date).format('MMM YY');
              return { xAxis, value: eachData.amountDetails, currency: getCurrencySymbol(eachData.currency) };
            });
            break;
          }
          case 'AWS': {
            data = costAnalysisData?.map((eachData:any) => {
              let xAxis = moment(eachData.date).format('MMM YY');
              return { xAxis, value: eachData.amountDetails, currency: getCurrencySymbol(eachData.currency) };
            });
            break;
          }
        }
        break;
      }
      case 'daily': {
        switch (providerType) {
          case 'AZURE': {
            data = costAnalysisData?.map((eachData:any) => {
              let xAxis = moment(eachData.date, 'YYYYMMDD').format('DD');
              return { xAxis, value: eachData.amountDetails, currency: getCurrencySymbol(eachData.currency) };
            });
            break;
          }
          case 'AWS': {
            data = costAnalysisData?.map((eachData:any) => {
              let xAxis = moment(eachData.date).format('DD');
              return { xAxis, value: eachData.amountDetails, currency: getCurrencySymbol(eachData.currency) };
            });
            break;
          }
        }
        break;
      }
      case 'hourly': {
        data = costAnalysisData?.map((eachData:any) => {
          let xAxis = moment(eachData.time).format('hh');
          return { xAxis, value: eachData.amountDetails, currency: getCurrencySymbol(eachData.currency) }
        })
        break;
      }
    }
  
    return data;
  };
  export const getThresholdChartOptions = (thresholdData: any[]): EChartsOption => {
    let currency = thresholdData.find(each => each.currencyType).currencyType;
    if(currency){
      currency = getCurrencySymbol(currency);
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          }
        },
        legend: {
          data: ['Threshold', 'Current Spends'],
          left: 'right',
          icon: 'circle'
        },
        grid: {
          top: "10%",
          bottom: "10%",
          right: "10%",
          left: "10%"
        },
        xAxis: [
          {
            type: 'category',
            data: thresholdData.map(each => each.name),
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: 0,
              overflow: 'truncate',
              width: thresholdData?.length > 8 ? 70 : 100,
              hideOverlap: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: `${currency}{value}`
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: 'Current Spends',
            type: 'bar',
            barWidth: '20%',
            color: '#35abcf',
            itemStyle: {
              borderRadius: [4, 4, 0, 0]
            },
            tooltip: {
              valueFormatter: function (value:any) {
                return currency + value;
              }
            },
            data: thresholdData.map(each => each.currentSpend)
          },
          {
            name: 'Threshold',
            type: 'line',
            color: '#DB6565',
            symbol: 'circle',
            symbolSize: 6,
            tooltip: {
              valueFormatter: function (value:any) {
                return currency + value;
              }
            },
            data: thresholdData.map(each => each.amount)
          }
        ]
      }
    }
  }
  export const getCostAnalysisChartOptionsForThreshold = (data: any[],type: string): EChartsOption =>{
    let currency = data?.map(each => each.currency).find(each => each != undefined);
    return {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'none'
        }
      },
      grid: {
        top: "10%",
        bottom: "15%",
        right: "0%",
        left: "10%"
      },
      xAxis: {
        type: 'category',
        data: data.map(each => each.xAxis),
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          interval: 'auto',
          margin: 10,
          color: 'var(--sub-text-color, #3C3C3C)',
        },
        offset: 10
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: true,
          margin: 10,
          color: 'var(--sub-text-color, #3C3C3C)',
          formatter: currency + ' {value}'
        },
        splitLine: {
          show: false
        }
      },
      series: [{
        data: data.map(each => Math.floor(each.value)),
        type: type,
        barWidth: 15,
        itemStyle: {
          borderRadius: 10
        },
        color: "#65BAEF",
      }]
    }
  }

  export const getXaxis = (data: any[], rotate: number): any => {
    return {
      type: 'category',
      data,
      axisLine: {
        lineStyle: {
          color: 'var(--sub-text-color, #3C3C3C)',
          width: 1
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        rotate: rotate,
        interval: 0,
        margin: 10,
        color: 'var(--sub-text-color, #3C3C3C)',
      }
    }
  }