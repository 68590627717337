import { client } from "../client/Client";
export const getAllThresholds = async ( Payload:any,providerType:any) => {

    let result = await client(`/manage/thresholds/${providerType}`, Payload, "POST");

    return result;
}
export const deleteThresholds = async ( thresholdName: string, Payload: any, providerType: string, organizationId:any) => {

    let result = await client(`/manage/organization/${organizationId}/threshold/delete/${thresholdName}?providerType=${providerType}`, Payload, "POST");

    return result;
}
// PS_CT_22
export const thresholdByName = async ( thresholdName: string, creds: any, provider: string, organizationId:any) => {
    // PS_CT_23
    let result = await client(`/manage/organization/${organizationId}/threshold/${thresholdName}?providerType=${provider}`, creds, "POST");
    // PS_CT_24
    return result;
}
export const saveThreshold = async ( newThresholdPayload: string,  provider: string) => {
    let payload = JSON.stringify(newThresholdPayload);
    let result = await client(`/manage/threshold/${provider}`, payload, "POST");

    return result;
}
