import '../../assets/scss/_recommendation.scss'
import $ from 'jquery'
import { useState, useEffect, useMemo } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import makeAnimated from 'react-select/animated'
import {
  IExchangeRate,
  IOrganizationData,
  ProviderDetail,
  defaultOrganization
} from '../../interface/organization'
import Header from '../Header/Header'
import {
  formatDate,
  formatDateWithTime,
  formatShortDate
} from '../../service/dateFormatterService'
import {
  ProviderSelect,
  ProviderSelectProps
} from '../ResuableComponents/ProviderSelect'
import { ScopeSelect, ScopeSelectProp } from '../ResuableComponents/ScopeSelect'
import { OrganizationSelect } from '../ResuableComponents/OrganizationSelect'
import { padWithALeadingZero } from '../../utils/numberUtils'
import { ProviderType } from '../../enum/providerTypeEnum'
import { compare } from '../../utils/comparator'
import { roundToDecimalPlaces } from '../../service/numberFormat'
// import { convertCost } from '../../service/currencyConverterService'
import { getCostAnomalyChartOptions } from '../../service/chartService'
import { LineChart } from '../ResuableComponents/Chart/LineChart'
import { userLogin } from '../../service/authService'
import {
  getAzureRecommendations,
  getAwsAdvisorChecks,
  getAwsAdvisorCheckSummaries,
  getAwsAdvisorCheckById,
  getProjectKeys,
  getmailId,
  createTicket
} from '../../service/RecommendationService'
import { IRequestPayload } from '../../interface/treshold'
import { getCurrencySymbol } from '../../service/currencyConverterService'
import { MultiSelect } from 'react-multi-select-component'
import { useSelector } from 'react-redux'
import { fetchTicketingConnectionDetails } from '../../service/managementService'
import { useEncryptStorage } from '../../hooks/useEncryptStorage'
import { exchangeRateObj } from '../../interface/dashboard'
import {convertCostData, getExchangeRate} from '../../service/currencyService'
import { CurrencyType } from '../../enum/CurrencyType'
export const Recommendations = () => {
  const nav = useNavigate()
  const [recommendationData, setRecommendationData] = useState<any[]>([])
  const [filteredAzureData, setFilteredAzureData] = useState<any[]>([])
  const [recommendationAwsData, setrecommendationAwsData] = useState<any>([])
  const [filteredAWSData, setFilteredAWSData] = useState<any>([])
  const [selected, setSelected] = useState<any>([])
  const [categoryCount, setCategoryCount] = useState<any>()
  const [currencyType, setcurrencyType] = useState<any>()
  const [SaveLoader, SetSaveLoader] = useState<any>(false)
  const [ticketPopup, setticketpopup] = useState<any>(false)
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false)
  const [jiraConnectionPayload, setjiraConnectionPayload] = useState<any>({
    issueType: '',
    description: '',
    summary: '',
    projectKey: selected,
    critical: ''
  })
  const Issueoptions=[
    'Story',
    'Task'
  ]
  const RadioOptions = ['Low', 'Medium', 'High'];
  const [organization, setOrganization] =
    useState<IOrganizationData>(defaultOrganization)
  const [providerType, setProviderType] = useState('')
  const [sScope, setSscope] = useState<ProviderDetail>()
  const [SNConnectionPayload, setSNConnectionPayload] = useState({
    mailId: '',
    category: '',
    subCategory: '',
    description: '',
    shortDescription: ''
  })
  const [descriptionRequired, setDescriptionRequired] = useState<boolean>(false)
  const [shortDescriptionRequired, setshortDescriptionRequired] = useState<boolean>(false)
  const [jiraissueTypeRequired, setJiraissueTypeRequired] = useState<boolean>(false)
  const [jiraSummaryRequired, setJirasummaryRequired] = useState<boolean>(false)
  const [jiradescriptionRequired, setJiradescriptionRequired] = useState<boolean>(false) 
  const [jiraProjectKeyRequired, setJiraProjectKeyRequired] = useState<boolean>(false) 
  const [jiraCriticalRequired, setJiraCriticalRequired] = useState<boolean>(false) 
  const [msg, setmsg] = useState<string>('')
  const [isJiraSaveLoaderEnabled, setisJiraSaveLoaderEnabled] =
    useState<boolean>(false)
  const [isSuccess, setisSuccess] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<any>([])
  const [SNErrors, setSNErrors] = useState<any>([])
  const [categorySelected, setCategorySelected] = useState('all')
  const [advisorChecks, SetadvisorChecks] = useState<any>()
  const [costSavings, setCostSavings] = useState<any>(0)
  const [summaryLoader, setSummaryLoader] = useState(false)
  const [checkDetail, setCheckDetail] = useState<any>()
  const [isSelected, SetIsSelected] = useState<string>('')
  const [projectKeys, SetProjectKeys] = useState<any>([])
  const [UsermailId, SetUsermailId] = useState<any>()
  const [jiraDisable, setjiraDisable] = useState<boolean>(false)
  const [SNDisable, setSNDisable] = useState<boolean>(false)
  const [DefinedFilterValue, setDefinedFilterValue] = useState<any>([])
  const [options, Setoptions] = useState<any>([{ label: '', value: '' }])
  // reusable dropdowns
  const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
      organizationId: 0,
      providerTypes: [],
      onProviderTypeChange: (organizationId, providerType) => {}
    })

  const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
    organizationId: 0,
    providerType: '',
    onScopeChange: scope => {}
  })
  // pagination
  const pageLimit = 7
  const [currentLength, setCurrentLength] = useState(pageLimit)
  const [showJiraTicketPopup, SetShowJiraTicketPopup] = useState<boolean>(false)
  const [showSNTicketPopup, SetShowSNTicketPopup] = useState<boolean>(false)
  const [isAws, SetIsAws] = useState<boolean>(true)
  const [exchangeRate,setExchangeRate] = useState<IExchangeRate>(exchangeRateObj)
  const [selectedScope, setSelectedScope] = useState<string>('')
  const role = useSelector((state: any) => state.role.role)

  // loader
  const [pageLoader, setPageLoader] = useState(true)

  useEffect(() => {
    async function fetchExchangeRate() {
        const response = await getExchangeRate();
        const rate = response.data; 
        setExchangeRate(rate);
    }
    fetchExchangeRate();
}, []);
const convertCost = (cost: number, sourceCurrency: string) =>{
  if (useEncryptStorage.getItem("currency") === sourceCurrency ) {
      return cost
  } else {
      const convertedCost = convertCostData(cost, sourceCurrency, useEncryptStorage.getItem("currency"), exchangeRate)
      const convertedExchangeCost = roundToDecimalPlaces(convertedCost.cost,2)
      return convertedExchangeCost
  }
}
  const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
  ) => {
      setPageLoader(true)
      setProviderTypeSelectProps({
        organizationId: selectedOrganization.organizationId,
        onProviderTypeChange: onProviderTypeChange,
        providerTypes: selectedOrganization.providerType
      })
      setOrganization(selectedOrganization)
  }
  const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
  ) => {
    setPageLoader(true)
    setProviderType(selectedProviderType)
    setScopeSelectProps({
      organizationId: organizationId,
      providerType: selectedProviderType,
      onScopeChange: onScopeChange
    })
  }
  const onScopeChange = async (
    selectedScope: ProviderDetail | ProviderDetail[]
  ) => {
    selectedScope = selectedScope as ProviderDetail
    setPageLoader(true)
    setSscope(selectedScope)
    let Payload
    let ProviderType = selectedScope.providerType
    Payload = {
      secretName: selectedScope.secretName,
      accountId: selectedScope.accountId,
      subscriptionId: selectedScope.subscriptionId,
      tenantId: selectedScope.tenantId
    }
    if (ProviderType === 'AWS') {
      // setPageLoader(true)
      SetIsAws(true)
      await getAwsRecommends(Payload)
    } else {
      // setPageLoader(true)
      SetIsAws(false)
      await getAzureRecommends(Payload, selectedScope.organizationId)
    }
  }
  const gotIt = () => {
    setticketpopup(false)
    setisSuccess(false)
    {
      showSNTicketPopup
        ? SetShowSNTicketPopup(false)
        : SetShowJiraTicketPopup(false)
    }
    setSNConnectionPayload({
      ...SNConnectionPayload,
      description: '',
      shortDescription: ''
    })
    setjiraConnectionPayload({
      ...jiraConnectionPayload,
      issueType: '',
      description: '',
      summary: '',
      projectKey: []
    })
  }
  const getAzureRecommends = async (Payload: any, organizationId: any) => {
    const AzureResult = await getAzureRecommendations(Payload)
    if (AzureResult.statusCode === 200) {
      let Recommends = AzureResult.data.values
      setRecommendationData(Recommends)
      setFilteredAzureData(Recommends)
      handleCreateTicket(organizationId)
      setPageLoader(false)
    } else if (AzureResult.statusCode !== 200 ) {
      setErrorPopUp(true)
      setPageLoader(false)
    }
  }
  const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
    []
  )
  const providerSelectComponent = useMemo(
    () => <ProviderSelect {...providerTypeSelectProps} />,
    [providerTypeSelectProps]
  )
  const scopeSelectComponent = useMemo(
    () => <ScopeSelect {...scopeSelectProps} />,
    [scopeSelectProps]
  )

  function handleLoadMore () {
    setCurrentLength(currentLength => currentLength + pageLimit)
    // call fetchAllEvents()
  }

  const sort = (sortKey: any, order: string): void => {
    let sortedList = filteredAzureData.sort((a, b) =>
      compare(a[sortKey], b[sortKey], order)
    )
    setFilteredAzureData([...sortedList])
  }

  const getAwsRecommends = async (Payload: any) => {
    setPageLoader(true)
    const advisorChecksResponse = await getAwsAdvisorChecks(Payload)
    const advisorChecks = advisorChecksResponse?.data
    if (advisorChecksResponse.statusCode===200  && advisorChecks) {
      let checkIds = advisorChecks.checks
        ? advisorChecks.checks.map((eachCheck: any) => eachCheck.id)
        : []
      const summaryPayload = {
        ...Payload,
        checkIds
      }
      const checkSummariesResponse = await getAwsAdvisorCheckSummaries(
        summaryPayload
      )
      const checkSummaries = checkSummariesResponse?.data
      if (checkSummariesResponse.statusCode == 200) {
        advisorChecks?.checks?.forEach((eachAdvisorCheck: any) => {
          let summary = checkSummaries?.summaries?.find(
            (eachCheckSummary: any) =>
              eachAdvisorCheck.id === eachCheckSummary.checkId
          )
          eachAdvisorCheck.title = eachAdvisorCheck?.description.split('.')[0]
          eachAdvisorCheck.status = summary?.status
          eachAdvisorCheck.resourcesSummary = summary?.resourcesSummary
          eachAdvisorCheck.description = eachAdvisorCheck?.description?.replace(
            /<br(\/)?>|\r|\n/g,
            ''
          )
          return eachAdvisorCheck
        })

        setrecommendationAwsData(advisorChecks.checks)
        setFilteredAWSData(advisorChecks.checks)
        setPageLoader(false)
      } else if(checkSummaries!=null){
        setPageLoader(false)
      }
      let categoryCountMap = new Map()

      categoryCountMap.set('ok', filterAwsRecommendations('ok').length)
      categoryCountMap.set(
        'warning',
        filterAwsRecommendations('warning').length
      )
      categoryCountMap.set('error', filterAwsRecommendations('error').length)
      categoryCountMap.set(
        'suppressed',
        filterAwsRecommendations('suppressed').length
      )
      setCategoryCount(categoryCountMap)
      let cost = checkSummaries.summaries
        ? checkSummaries.summaries
            .filter(
              (eachSummary: any) =>
                eachSummary.categorySpecificSummary?.costOptimizing
                  ?.estimatedMonthlySavings
            )
            .map(
              (eachSummary: any) =>
                eachSummary.categorySpecificSummary?.costOptimizing
                  ?.estimatedMonthlySavings
            )
            .reduce((a: any, b: any) => a + b, 0)
        : 0
      setCostSavings(cost)
    }else if(advisorChecksResponse.statusCode===200  && advisorChecks===null){
      setPageLoader(false)
    }else{
      setPageLoader(false)
      setErrorPopUp(true)
    }
  }

  const getCheckDetails = async (checkId: any) => {
    let Payload: any = {
      secretName: sScope?.secretName,
      tenantId: sScope?.tenantId,
      subscriptionId: sScope?.subscriptionId,
      accountId: sScope?.accountId
    }
    setSummaryLoader(true)
    // setCheckDetail(null);

    let Response = await getAwsAdvisorCheckById(Payload, checkId)
    if(Response.statusCode===200){
      setSummaryLoader(false)
    setCheckDetail(Response.data.result)
    }else{
      setErrorPopUp(true)
    }
  }

  const filterRecommendations = (filterKey: string) => {
    setCategorySelected(filterKey)
    // setCurrentLength(pageLimit);

    switch (providerType) {
      case ProviderType.AZURE:
        setFilteredAzureData(filterAzureRecommendations(filterKey))
        break
      case ProviderType.AWS:
        setFilteredAWSData(filterAwsRecommendations(filterKey))
        break
      default:
        break
    }
  }
  const handleJiraConnectionDataChange = (e: any) => {
    setjiraConnectionPayload({
      ...jiraConnectionPayload,
      [e.target.id]: e.target.value
    })
  }
  const handleSNConnectionDataChange = (e: any) => {
    setSNConnectionPayload({
      ...SNConnectionPayload,
      [e.target.id]: e.target.value
    })
  }

  const filterAzureRecommendations = (filterKey: string) => {
    return filterKey === 'all'
      ? recommendationData
      : recommendationData.filter(each => each.category === filterKey)
  }

  const filterAwsRecommendations = (filterKey: any) => {
    if (recommendationAwsData) {
      return filterKey === 'all'
        ? recommendationAwsData
        : filterKey === 'suppressed'
        ? recommendationAwsData.filter(
            (each: any) => each.resourcesSummary?.resourcesSuppressed
          )
        : recommendationAwsData.filter((each: any) => each.status === filterKey)
    } else {
      return []
    }
  }
  const CancelSelection = async () => {
    SetShowJiraTicketPopup(false)
    SetShowSNTicketPopup(false)
    setSelected([])
    setJiraProjectKeyRequired(false)
    setJiradescriptionRequired(false)
    setJiraissueTypeRequired(false)
    setJirasummaryRequired(false)
    SetSaveLoader(false)
    setjiraConnectionPayload({
      ...jiraConnectionPayload,
      description: '',
      issueType: '',
      summary: '',
      projectKey: [],
      critical:''
    })
    setSNConnectionPayload({
      ...SNConnectionPayload,
      description: '',
      shortDescription: '',
    })
    setDescriptionRequired(false)
    setshortDescriptionRequired(false)
    SetIsSelected('')
    setticketpopup(false)
  }
  const navigatefn = () => {
    if (SNDisable == true && jiraDisable == true) {
      nav('/manageconnectors')
    }
  }
  const Selectjirafn = () => {
    SetIsSelected('jira')
    SelectTicketfn('jira')
  }
  const Selectsnfn = () => {
    SetIsSelected('SN')
    SelectTicketfn('SN')
  }
  const SelectTicketfn = async (type: string) => {
    SetSaveLoader(true)
    if (type === 'jira') {
      const projectRes = await getProjectKeys(sScope?.organizationId)
      if (projectRes?.statusCode == 200) {
        projectRes?.data?.forEach((obj: any, i: any) => {
          options.push({
            label: obj,
            value: obj
          })
        })
        Setoptions(options)
        SetSaveLoader(false)
        SetProjectKeys(projectRes?.data)
        SetSaveLoader(false)
      }
      SetShowJiraTicketPopup(true)
    } else {
      const mailId = await getmailId(sScope?.organizationId, 'ServiceNow')
      SetShowSNTicketPopup(true)
      SetUsermailId(mailId?.data.instanceEmailId)
    }
  }
  const JiraerrorMsg = () => {
    var Errors: any = {
      shortDescription: '',
      issueType: '',
      summary: '',
      projectKey: '',
      critical:''
    }
    if (jiraConnectionPayload.summary == '') {
      Errors.summary = 'Summary is Required'
    } else {
      Errors.summary = ''
    }
    if (jiraConnectionPayload.description == '') {
      Errors.description = ' Description is required'
    } else {
      Errors.description = ''
    }
    if (DefinedFilterValue.length == 0) {
      Errors.projectKey = 'Select atleast one project key'
    } else {
      Errors.projectKey = ''
    }
    if (jiraConnectionPayload.critical == 'on') {
      Errors.critical = ''
    } else {
      Errors.critical = 'Select a critical status'
    }
    setFormErrors(Errors)
    if (
      Errors.projectKey == '' &&
      Errors.description == '' &&
      Errors.summary == ''&&
      Errors.critical == ''
    ) {
      createTicketfn()
    }
  }
  const handleSelect = (selectedOption: any) => {
    setJiraProjectKeyRequired(false)
    setSelected(selectedOption)
    setDefinedFilterValue((prevDefinedFilterValues: any) => {
      const newDefinedFilterValue: any = []
      selectedOption.forEach((obj: any, index: number) => {
        newDefinedFilterValue.push(obj.label)
      })
      return newDefinedFilterValue
    })
  }
  const handleCreateTicket = async (organizationId: any) => {
    const ticketDetailsResponse = await fetchTicketingConnectionDetails(
      organizationId
    )
    if (ticketDetailsResponse.statusCode === 200) {
      if (
        providerType === 'AWS' &&
        (ticketDetailsResponse.data.jiraUrl == 'Connected' ||
          ticketDetailsResponse.data.serviceNowInstanceUrl == 'Connected')
      ) {
        setticketpopup(true)
      }
      if (
        ticketDetailsResponse.data.jiraUrl == 'Connected' &&
        ticketDetailsResponse.data.serviceNowInstanceUrl == 'Connected'
      ) {
        setjiraDisable(false)
        setSNDisable(false)
      } else if (
        ticketDetailsResponse.data.serviceNowInstanceUrl == 'Connected' &&
        ticketDetailsResponse.data.jiraUrl == 'DisConnected'
      ) {
        setjiraDisable(true)
        setSNDisable(false)
      } else if (
        ticketDetailsResponse.data.serviceNowInstanceUrl == 'DisConnected' &&
        ticketDetailsResponse.data.jiraUrl == 'Connected'
      ) {
        setjiraDisable(false)
        setSNDisable(true)
      } else if (
        providerType === 'AWS' &&
        ticketDetailsResponse.data.serviceNowInstanceUrl == 'DisConnected' &&
        ticketDetailsResponse.data.jiraUrl == 'DisConnected'
      ) {
        nav('/manageconnectors')
      } else {
        setjiraDisable(true)
        setSNDisable(true)
      }
    } else {
      setErrorPopUp(true)
    }
  }
  const SNerrorMsg = () => {
    var Errors: any = {
      description: '',
      shortDescription: ''
    }
    if (SNConnectionPayload.description == '') {
      Errors.description = 'Enter description'
    } else {
      Errors.description = ''
    }
    if (SNConnectionPayload.shortDescription == '') {
      Errors.shortDescription = 'Enter short description'
    } else {
      Errors.description = ''
    }
    setFormErrors(Errors)
    if (Errors.shortDescription == '' && Errors.description == '') {
      createTicketfn()
    }
  }
  const createTicketfn = async () => {
    setisJiraSaveLoaderEnabled(true)
    let payload
    if (isSelected === 'jira') {
      payload = {
        issueType: jiraConnectionPayload.issueType,
        summary: jiraConnectionPayload.summary,
        description: jiraConnectionPayload.description,
        projectKey: DefinedFilterValue
      }
    } else {
      payload = {
        mailId: UsermailId,
        category: 'cloud',
        subCategory: providerType,
        description: SNConnectionPayload.description,
        shortDescription: SNConnectionPayload.shortDescription
      }
    }
    const response = await createTicket(
      sScope?.organizationId,
      isSelected,
      payload
    )
    if (response.statusCode == 200) {
      setisJiraSaveLoaderEnabled(false)
      SetIsSelected('')
      setisSuccess(true)
      setmsg(response.data.message)
    }else{
      setErrorPopUp(true)
    }
  }

  return (
    <>
      <div>
        <Header />
        <div className='container-fluid'>
          {/* <!-- cost anomly starts here --> */}
          <div className='row justify-content-center'>
            <div className='col-md-10'>
              <div className='d-lg-flex d-md-block justify-content-between align-items-center my-4'>
                <h5 className='page-header mb-lg-0 mb-3'>Recommendations</h5>
                <div className='d-md-flex d-sm-block'>
                  <div className=' dropdown me-md-4 res-margin-20'>
                    {organziationSelectComponent}
                  </div>
                  <div className='dropdown me-md-4 res-margin-20'>
                    {providerSelectComponent}
                  </div>
                  <div className='input-group ms-3'>{scopeSelectComponent}</div>
                </div>
              </div>
              {providerType == 'Azure' ? (
                <div className='row g-4 mb-4'>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('all')}
                  >
                    <a
                      className={
                        categorySelected === 'all'
                          ? 'card h-100 card1 card-blue text-decoration-none active'
                          : 'card h-100 card1 card-blue text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          All Recommendations
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/recommend-icon.svg'
                              alt='recommendation-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/recommend-icon-white.svg'
                              alt='recommendation-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationData?.length}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('Cost')}
                  >
                    <a
                      className={
                        categorySelected === 'Cost'
                          ? 'card h-100 card1 card-violet text-decoration-none active'
                          : 'card h-100 card1 card-violet text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>Cost</h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/cost-icon.svg'
                              alt='cost-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/cost-icon-white.svg'
                              alt='cost-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationData?.filter(
                              e => e.category === 'Cost'
                            ).length
                              ? recommendationData?.filter(
                                  e => e.category === 'Cost'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('Security')}
                  >
                    <a
                      className={
                        categorySelected === 'Security'
                          ? 'card h-100 card1 card-pink text-decoration-none active'
                          : 'card h-100 card1 card-pink text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>Security</h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/security-icon.svg'
                              alt='security-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/security-icon-white.svg'
                              alt='security-icon-white.svg'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationData?.filter(
                              e => e.category === 'Security'
                            ).length
                              ? recommendationData?.filter(
                                  e => e.category === 'Security'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('HighAvailability')}
                  >
                    <a
                      className={
                        categorySelected === 'HighAvailability'
                          ? 'card h-100 card1 card-green text-decoration-none active'
                          : 'card h-100 card1 card-green text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          Reliability
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/reliability-icon.svg'
                              alt='reliability-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/reliability-icon-white.svg'
                              alt='reliability-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationData?.filter(
                              e => e.category === 'HighAvailability'
                            ).length
                              ? recommendationData?.filter(
                                  e => e.category === 'HighAvailability'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() =>
                      filterRecommendations('OperationalExcellence')
                    }
                  >
                    <a
                      className={
                        categorySelected === 'OperationalExcellence'
                          ? 'card h-100 card1 card-yellow text-decoration-none active'
                          : 'card h-100 card1 card-yellow text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          Operational excellence
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/operational-excell-icon.svg'
                              alt='operational-excell-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/operational-excell-icon-white-version.svg'
                              alt='operational-excell-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationData?.filter(
                              e => e.category === 'OperationalExcellence'
                            ).length
                              ? recommendationData?.filter(
                                  e => e.category === 'OperationalExcellence'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('Performance')}
                  >
                    <a
                      className={
                        categorySelected === 'Performance'
                          ? 'card h-100 card1 card-lightgreen text-decoration-none active'
                          : 'card h-100 card1 card-lightgreen text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3reccom-card-heading'>
                          Performance
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/performance-icon.svg'
                              alt='performance-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/performance-icon-white-version.svg'
                              alt='performance-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationData?.filter(
                              e => e.category === 'Performance'
                            ).length
                              ? recommendationData?.filter(
                                  e => e.category === 'Performance'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ) : (
                <div className='row g-4 mb-4'>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    // onClick={() => filterRecommendations('Cost')}
                  >
                    <div className='card h-100 card-bg-grey text-decoration-none'>
                      <div className='card-body active'>
                        <h6 className='reccom-card-heading mb-3'>
                          Potential Savings
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span>
                            <img
                              src='images/potential-save-icon-white.svg'
                              alt='potential-save-icon-white'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                          {useEncryptStorage.getItem("currency") === "USD" ? "$" : "₹"}{convertCost(parseFloat(roundToDecimalPlaces(parseInt(costSavings),2)),'USD')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('all')}
                  >
                    <a
                      className={
                        categorySelected === 'all'
                          ? 'card h-100 card1 card-blue text-decoration-none active'
                          : 'card h-100 card1 card-blue text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          All Recommendations
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/recommend-icon.svg'
                              alt='recommendation-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/recommend-icon-white.svg'
                              alt='recommendation-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationAwsData?.length}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('error')}
                  >
                    <a
                      className={
                        categorySelected === 'error'
                          ? 'card h-100 card1 card-red text-decoration-none active'
                          : 'card h-100 card1 card-red text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          Action Recommended
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/action-recomm-icon.svg'
                              alt='security-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/action-recomm-icon-white.svg'
                              alt='security-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationAwsData?.filter(
                              (e: any) => e.status === 'error'
                            ).length
                              ? recommendationAwsData?.filter(
                                  (e: any) => e.status === 'error'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('warning')}
                  >
                    <a
                      className={
                        categorySelected === 'warning'
                          ? 'card h-100 card1 card-green text-decoration-none active'
                          : 'card h-100 card1 card-green text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          Investigation suggested
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/investiga-recomm.svg'
                              alt='reliability-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/investiga-recomm-white.svg'
                              alt='reliability-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationAwsData?.filter(
                              (e: any) => e.status === 'warning'
                            ).length
                              ? recommendationAwsData?.filter(
                                  (e: any) => e.status === 'warning'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('ok')}
                  >
                    <a
                      className={
                        categorySelected === 'ok'
                          ? 'card h-100 card1 card-lightgreen text-decoration-none active'
                          : 'card h-100 card1 card-lightgreen text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3'>
                          No Problems Detected
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/no-prob-detect.svg'
                              alt='operational-excell-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/no-prob-detect-white.svg'
                              alt='operational-excell-icon'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {recommendationAwsData?.filter(
                              (e: any) => e.status === 'ok'
                            ).length
                              ? recommendationAwsData?.filter(
                                  (e: any) => e.status === 'ok'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className='col-md-6 col-sm-12 col-lg-2'
                    onClick={() => filterRecommendations('suppressed')}
                  >
                    <a
                      className={
                        categorySelected === 'suppressed'
                          ? 'card h-100 card1 card-grey text-decoration-none active'
                          : 'card h-100 card1 card-grey text-decoration-none'
                      }
                    >
                      <div className='card-body'>
                        <h6 className='reccom-card-heading mb-3reccom-card-heading'>
                          Excluded Items
                        </h6>
                        <div className='d-flex align-items-center'>
                          <span className='hover-img'>
                            <img
                              src='images/check-exclude-icon.svg'
                              alt='check-exclude-icon'
                              className='reccom-card-img me-2'
                            />
                            <img
                              src='images/check-exclude-icon-white.svg'
                              alt='check-exclude-icon-white'
                              className='reccom-card-img me-2'
                            />
                          </span>
                          <span className='reccom-card-count'>
                            {filteredAWSData?.filter(
                              (e: any) => e.status === 'suppressed'
                            ).length
                              ? filteredAWSData?.filter(
                                  (e: any) => e.status === 'suppressed'
                                ).length
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )}
              <div className='row mb-5'>
                {isAws == true &&
                filteredAWSData?.length !== 0 &&
                filteredAWSData !== null &&
                filteredAWSData !== undefined ? (
                  <div className='col-md-12'>
                    <div className='accordion' id='reccommaccordion'>
                      {filteredAWSData.map((eachData: any, i: any) => (
                        <div
                          className='shadow-sm mb-2 accordion-bg-sty'
                          key={i}
                        >
                          <h2
                            className='accordion-header py-3'
                            id={`reccomm-accordion-${i}`}
                          >
                            <button
                              className='accordion-collapse d-flex border-0 ps-3 custom-accordion-btn custom-accordion-heading collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#reccommaccordion_${i}`}
                              aria-expanded='true'
                              aria-controls={`reccommaccordion_${i}`}
                              onClick={() => getCheckDetails(eachData.id)}
                            >
                              <span className='mx-3 mb-0'>
                                <img
                                  src={
                                    eachData.status === 'error'
                                      ? 'images/action-recomm-icon.svg'
                                      : eachData.status === 'warning'
                                      ? 'images/investiga-recomm.svg'
                                      : 'images/no-prob-detect.svg'
                                  }
                                  alt='action-recommend-icon'
                                  className='reccom-acc-icon'
                                />
                              </span>
                              {eachData.description.split('.')[0]}
                            </button>
                          </h2>
                          <div
                            id={`reccommaccordion_${i}`}
                            className='accordion-collapse collapse'
                            aria-labelledby={`reccomm-accordion-${i}`}
                            data-bs-parent='#reccommaccordion'
                          >
                            <div className='accordion-body pt-0 ps-5'>
                              <div className='row'>
                                <div className='para-text mt-2'>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: eachData.description
                                    }}
                                  ></div>
                                </div>
                                <h4 className='headerBodyStyle'></h4>
                                <div className='my-lg-2'>
                                  <button
                                    type='button'
                                    className='btn primary-btn d-lg-block d-none ms-2'
                                    onClick={() => {
                                      handleCreateTicket(
                                        organization.organizationId
                                      )
                                    }}
                                    disabled={
                                      role == 'BillingReader' ? true : false
                                    }
                                  >
                                    <span>
                                      <img
                                        src='images/static-lable-add.svg'
                                        className='me-2'
                                      />
                                    </span>
                                    Create Ticket
                                  </button>
                                </div>
                                <div className='accordion-header mt-1 pb-1 table-responsive'>
                                  <table className='table table-borderless custom-grid mt-4'>
                                    <thead className='custom-table-head'>
                                      <tr>
                                        {eachData.metadata.map(
                                          (eachTh: any, index: any) => (
                                            <th
                                              className='custom-grid-font-weight'
                                              key={index}
                                            >
                                              {eachTh.toUpperCase()}
                                            </th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    {checkDetail?.flaggedResources ? (
                                      <tbody>
                                        {checkDetail?.flaggedResources &&
                                        checkDetail?.flaggedResources?.length &&
                                        summaryLoader ? (
                                          <tr>
                                            <td>
                                              <div className='spinner'>
                                                <div className='custom-loader'></div>
                                              </div>
                                            </td>
                                          </tr>
                                        ) : checkDetail?.flaggedResources &&
                                          checkDetail?.flaggedResources
                                            ?.length ? (
                                          checkDetail?.flaggedResources.map(
                                            (eachDetail: any, index: any) => (
                                              <tr key={index}>
                                                {eachDetail?.metadata.map(
                                                  (eachTd: any, idx: any) => (
                                                    <td key={idx}>{eachTd}</td>
                                                  )
                                                )}
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr className='custom-alternative-line'>
                                          <td
                                            colSpan={6}
                                            className='text-center'
                                          >
                                            <img
                                              src='images/no-rec-found.gif'
                                              alt='no-rec-found'
                                              className='no-data-found-sty mb-4'
                                            />
                                            <p className='para-text'>
                                              No data found
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                  {isAws &&
                  <div className="row">
                  <div className="col-md-12 text-center">
                      <img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-4"/>
                      <p className="para-text">No data found</p>
                  </div>
              </div>}</>
                )}
              </div>
              {!isAws && (
                <div className='custom-accordion-item accordion-item border-0 body-bg'>
                  <div className='accordion-header mt-1 pb-1 table-responsive'>
                    <table className='table table-borderless custom-grid '>
                      <thead className='custom-table-head'>
                        <tr>
                          <th className='custom-grid-font-weight'>
                            DESCRIPTION
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            CATEGORY
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('category', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('category', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='text-center custom-grid-font-weight position-relative'>
                            IMPACT
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('impact', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('impact', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight position-relative'>
                            TYPE
                            <span className='sorting'>
                              <a
                                className='sortup'
                                onClick={() => sort('impactedField', 'Asc')}
                              >
                                <span className='sort-icon sortup-img'></span>
                              </a>
                              <a
                                className='sortdown'
                                onClick={() => sort('impactedField', 'Desc')}
                              >
                                <span className='sort-icon sortdown-img'></span>
                              </a>
                            </span>
                          </th>
                          <th className='custom-grid-font-weight'>RESOURCE</th>
                          <th className='custom-grid-font-weight'>
                            LAST UPDATED
                          </th>
                          <th className='custom-grid-font-weight'>
                            CREATE A TICKET
                          </th>
                        </tr>
                      </thead>
                      {filteredAzureData?.length > 0 ? (
                        <tbody>
                          {filteredAzureData
                            ?.slice(0, currentLength)
                            .map((each: any) => {
                              return (
                                <tr>
                                  <td className='grid-primary-text td-wrap'>
                                    {each?.shortDescription?.problem}
                                  </td>
                                  <td>{each?.category}</td>
                                  <td className='align-items-center text-center'>
                                    <span
                                      className={
                                        each?.impact === 'High'
                                          ? 'legend-sty legend-red'
                                          : each?.impact === 'Medium'
                                          ? 'legend-sty legend-yellow'
                                          : 'legend-sty legend-darkgreen'
                                      }
                                    ></span>
                                  </td>
                                  <td>{each.impactedField}</td>
                                  <td className='td-wrap resource'>
                                    {each.impactedValue}
                                  </td>
                                  <td>
                                    {formatDateWithTime(
                                      new Date(each.lastUpdated)
                                    )}
                                  </td>
                                  <td className='custom-grid-font-weight text-center text-nowrap'>
                                    <a
                                      className={`${
                                        role == 'BillingReader' ||
                                        (jiraDisable === true &&
                                          SNDisable === false)
                                          ? 'disabled action-icons me-2'
                                          : 'action-icons me-2'
                                      }`}
                                      onClick={() => navigatefn()}
                                    >
                                      <img
                                        src='images/Jira_Logo-symbal.svg'
                                        className='custom-icon-position'
                                        onClick={() => Selectjirafn()}
                                      />
                                    </a>
                                    <a
                                      className={`${
                                        role == 'BillingReader' ||
                                        (SNDisable === true &&
                                          jiraDisable === false)
                                          ? 'disabled action-icons'
                                          : 'action-icons'
                                      }`}
                                      onClick={() => navigatefn()}
                                    >
                                      <img
                                        src='images/serviceNowCard.svg'
                                        onClick={() => Selectsnfn()}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      ) : (
                        <>
                          <tbody>
                            <tr className='custom-alternative-line'>
                              <td colSpan={8} className='text-center'>
                                <img
                                  src='images/no-rec-found.gif'
                                  alt='no-rec-found'
                                  className='no-data-found-sty mb-4'
                                />
                                <p className='para-text'>No data found</p>
                              </td>
                            </tr>
                          </tbody>
                        </>
                      )}
                    </table>
                  </div>
                  <p className='mb-3 mt-2 para-text'>
                    # of Records:
                    <span className='primary-color'>
                      {padWithALeadingZero(
                        filteredAzureData?.length < currentLength
                          ? filteredAzureData?.length
                          : currentLength
                      )}{' '}
                      out of {padWithALeadingZero(filteredAzureData?.length)}
                    </span>
                  </p>
                  {filteredAzureData?.length > currentLength && (
                    <div className='row justify-content-center'>
                      <button
                        type='button'
                        className='btn secondary-btn mt-2 mb-5'
                        onClick={handleLoadMore}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              )}
              {/* < !--table ends-- > */}
            </div>
          </div>
        </div>
      </div>
      {/* < !--cost anomly ends here-- > */}
      {ticketPopup && (
        <div
          role='dialog'
          tabIndex={-1}
          className={`create-provider-modal modal show fade ${
            ticketPopup ? 'show-popup' : ''
          }`}
        >
          <div
            className='modal-dialog custom-width-providerpopup'
            id='ticketpopup'
          >
            <div className='modal-content popup-sty border-0 custom-border-radius'>
              <div className='modal-header border-0'>
                <label className='form-label custom-bold my-2 w-100'>
                  Select Ticketing System
                </label>
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  onClick={() => {
                    SetIsSelected('')
                    setticketpopup(false)
                  }}
                ></button>
              </div>
              <div className='modal-body pt-2 px-5'>
                <div className='row mb-4'>
                  <div className='col-12 col-md-6 col-sm-12 col-lg-6 mb-3'>
                    <button
                      type='button'
                      className={` btn-white-recommendation border-info w-100 pop-card shadow-sm py-2 select-ticket-card position-relative btn-lg`}
                      onClick={() => {
                        SetIsSelected('jira')
                      }}
                      disabled={jiraDisable}
                    >
                      <span>
                        <img
                          src='images/Jira_Logo.svg'
                          className='provider-cuts-icon '
                        />
                      </span>
                      {isSelected === 'jira' && jiraDisable === false ? (
                        <span className='position-absolute top-0 start-100 translate-middle badge custom-badge rounded-pill p-2'>
                          <span className='visually-hidden'>
                            unread messages
                          </span>
                        </span>
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                  <div className='col-12 col-md-6 col-sm-12 col-lg-6 mb-3'>
                    <button
                      type='button'
                      className={` btn-white-recommendation border-info w-100 pop-card shadow-sm py-2 select-ticket-card position-relative btn-lg }`}
                      onClick={() => {
                        SetIsSelected('SN')
                      }}
                      disabled={SNDisable}
                    >
                      <span>
                        <img
                          src='images/servicenow.svg'
                          className='provider-cuts-icon-sn'
                        />
                      </span>
                      {isSelected === 'SN' && SNDisable === false ? (
                        <span className='position-absolute top-0 start-100 translate-middle badge custom-badge rounded-pill p-2'>
                          <span className='visually-hidden'>
                            unread messages
                          </span>
                        </span>
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className='modal-footer popup-footer d-flex  pb-4'>
                <button
                  type='button'
                  className='btn secondary-btn me-3'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    SetIsSelected('')
                    setticketpopup(false)
                  }}
                >
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn primary-btn d-lg-block d-none ms-2'
                  data-bs-dismiss={SaveLoader ? '' : 'modal'}
                  onClick={() => {
                    SelectTicketfn(isSelected)
                  }}
                  disabled={isSelected ? false : true}
                >
                  Proceed
                  {SaveLoader ? (
                    <span className='spinner-border spinner-border-sm mr-1'></span>
                  ) : (
                    <></>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        role='dialog'
        tabIndex={-1}
        className={`create-provider-modal modal show fade ${
          showJiraTicketPopup ? 'show-popup' : ''
        }`}
      >
        <div role='document' className='modal-dialog custom-modal-theme'>
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <h5 className='modal-title custom-header-style d-flex align-items-center'>
                <img
                  src='images/Jira_Logo-symbal.svg'
                  className='me-3 provider-icon shadow-sm'
                />
                Jira Ticketing Integration
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => CancelSelection()}
              ></button>
            </div>
            <div className='modal-body pt-2 pb-0'>
              <div className='row mb-4'>
                <div className='col-md-12 mb-3'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='issueType'
                  >
                    Issue type
                    <span className='color-warning'>*</span>
                  </label>
                  <select
                    className='w-100  form-control py-2 px-2 custom-input custom-dropdown-toggle'
                    id='issueType'
                    onChange={e => {
                      handleJiraConnectionDataChange(e)
                    }}
                    onBlur={(e) => { setJiraissueTypeRequired(e.target.value==='') }}
                    
                    value={jiraConnectionPayload.issueType}
                    >
                      <option className='custom-dropdown-brd pb-1' selected>
                       
                      </option>
                      {Issueoptions?.map((eachDetail: any, index: any) => (
                                  <option key={index} value={eachDetail}>{eachDetail}</option>
                                ))}
                                </select>
                  <span
                    className={`text-danger d-block error-span ${
                      jiraissueTypeRequired ? '' : 'invisible'
                    }`}
                  >
                    Select an issue type
                  </span>
                </div>
                <div className='col-md-12 mb-3'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='summary'
                  >
                    Summary
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    id='summary'
                    value={jiraConnectionPayload.summary}
                    onChange={e => {
                      handleJiraConnectionDataChange(e)
                    }}
                    maxLength={300}
                    onBlur={(e) => { setJirasummaryRequired(e.target.value === "") }}
                  />
                  <span
                    className={`text-danger d-block error-span ${
                      jiraSummaryRequired ? '' : 'invisible'
                    }`}
                  >
                    Summary is Required
                  </span>
                </div>
                <div className='col-md-12 mb-3'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='description'
                  >
                    Description
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    // placeholder="Enter username"
                    id='description'
                    value={jiraConnectionPayload.description}
                    onChange={e => {
                      handleJiraConnectionDataChange(e)
                    }}
                    onBlur={(e) => { setJiradescriptionRequired(e.target.value === "") }}
                    maxLength={250}
                  />
                  <span
                    className={`text-danger d-block error-span ${
                      jiradescriptionRequired ? '' : 'invisible'
                    }`}
                  >
                    Description is Required
                  </span>
                </div>
                <div className='col-md-12 mb-3'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='username'
                  >
                    Project Key
                    <span className='color-warning'>*</span>
                  </label>
                  {/* <div className='w-100 py-2 px-2'> */}
                  <Select
                    isMulti
                    options={options}
                    value={selected}
                    onChange={handleSelect}
                    className='custom-input-react-select'
                    onBlur={(e) => { setJiraProjectKeyRequired(selected=='') }}
                  />
                  <span
                    className={`text-danger d-block error-span ${
                      jiraProjectKeyRequired ? '' : 'invisible'
                    }`}
                  >
                    Select atleast one project key
                  </span>
                </div>
                <div className='col-md-12 custom-label'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='critical'
                  >
                    Critical
                    <span className='color-warning'>*</span>
                  </label>
                  {RadioOptions.map((option, index) => (
        <label key={index} className='input-space list-inline-item' >
          <input
          className='list-inline-item'
            type="radio"
            id='critical'
            name='critical'
            value={option}
            checked={ jiraConnectionPayload.critical=== option}
            onChange={handleJiraConnectionDataChange}
          />
          {option}
        </label>
      ))}
                 <span
                    className='text-danger d-block error-span '
                  >
                   {formErrors.critical}
                  </span>
                </div>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-end pb-4 pt-0'>
              <button
                type='button'
                // className='btn-close'
                data-bs-dismiss='modal'
                className='btn secondary-btn'
                onClick={() => CancelSelection()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn primary-btn ms-3'
                data-bs-dismiss='modal'
                onClick={() => {
                  JiraerrorMsg()
                }}
              >
                {isJiraSaveLoaderEnabled && (
                  <span className='spinner-border spinner-border-sm mr-1'></span>
                )}
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        role='dialog'
        tabIndex={-1}
        className={`create-provider-modal modal show fade ${
          showSNTicketPopup ? 'show-popup' : ''
        }`}
      >
        <div role='document' className='modal-dialog custom-modal-theme'>
          <div className='modal-content'>
            <div className='modal-header border-0'>
              <h5 className='modal-title custom-header-style d-flex align-items-center'>
                <img
                  src='images/serviceNowCard.svg'
                  className='me-3 provider-icon shadow-sm'
                />
                ServiceNow Ticketing Integration
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => CancelSelection()}
              ></button>
            </div>
            <div className='modal-body pt-2 pb-0'>
              <div className='row mb-4'>
                <div className='col-md-12'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='issueType'
                  >
                    Mail Id
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    disabled={true}
                    placeholder={SNConnectionPayload.mailId}
                    id='issueType'
                    value={UsermailId}
                  />
                </div>
                <div className='col-md-12'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='category'
                  >
                    Category
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    disabled={true}
                    // placeholder="Enter token"
                    id='category'
                    value='Cloud'
                    onChange={e => {
                      handleJiraConnectionDataChange(e)
                    }}
                    // onBlur={(e) => { setJiraTokenRequired(e.target.value === "") }}
                  />
                </div>
                <div className='col-md-12'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='description'
                  >
                    Sub-Category
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    disabled={true}
                    // placeholder="Enter username"
                    id='subCategory'
                    value={providerType}
                    onChange={e => {
                      handleJiraConnectionDataChange(e)
                    }}
                  />
                </div>
                <div className='col-md-12 custom-label'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='username'
                  >
                    Short Description
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    // placeholder="Enter username"
                    id='shortDescription'
                    value={SNConnectionPayload.shortDescription}
                    onBlur={(e) => { setshortDescriptionRequired(e.target.value === "") }}
                    onChange={e => {
                      handleSNConnectionDataChange(e)
                    }}
                    maxLength={250}
                  />
                   
                  <span
                    className={`text-danger d-block error-span ${
                      shortDescriptionRequired ? '' : 'invisible'
                    }`}
                  >
                    Enter Short Description
                  </span>
                </div>
                <div className='col-md-12'>
                  <label
                    className='w-100 form-label custom-label'
                    htmlFor='username'
                  >
                    Description
                    <span className='color-warning'>*</span>
                  </label>
                  <input
                    className='w-100 custom-input form-control py-2 px-2'
                    type='text'
                    // placeholder="Enter username"
                    id='description'
                    value={SNConnectionPayload.description}
                    onChange={e => {
                      handleSNConnectionDataChange(e)
                    }}
                    maxLength={250}
                    onBlur={(e) => { setDescriptionRequired(e.target.value === "") }}
                  />
                 
                 <span
                    className={`text-danger d-block error-span ${
                      descriptionRequired ? '' : 'invisible'
                    }`}
                  >
                    Enter Description
                  </span>
                </div>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-end pb-4 pt-0'>
              <button
                type='button'
                data-bs-dismiss='modal'
                className='btn secondary-btn'
                onClick={() => CancelSelection()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn primary-btn ms-3'
                data-bs-dismiss='modal'
                onClick={() => {
                  SNerrorMsg()
                }}
              >
                {isJiraSaveLoaderEnabled && (
                  <img
                    src='images/white-loader.gif'
                    alt='white-loader'
                    className='btn-loader'
                  />
                )}
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal show fade ${isSuccess ? 'show-popup' : ''}`}
        tabIndex={-1}
        role='dialog'
      >
        <div className='modal-dialog custom-modal-theme' role='document'>
          <div className='modal-content custom-popup-height'>
            <div className='modal-header border-0'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => setisSuccess(false)}
              />
            </div>
            <div className='modal-body'>
              <div className='text-center mb-1'>
                <img className='mt-1' src='images/Success.svg' alt='Success' />
                <label className='form-label custom-pop-label mt-4 mb-0 w-100'>
                  Ticket Created Successfully
                </label>
              </div>
            </div>
            <div className='modal-footer popup-footer d-flex justify-content-center pb-4'>
              <button
                type='button'
                className='btn primary-btn'
                data-bs-dismiss='modal'
                onClick={() => {
                  gotIt()
                }}
              >
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>
      {pageLoader && (
        <div className='container-fluid'>
          <div
            className='modal-backdrop fade show'
            id='AddOrganization-popup'
            data-bs-keyboard='false'
            tabIndex={-1}
            aria-hidden='true'
          >
            <div className='position-absolute top-50 start-50 translate-middle'>
              <img
                src='images/cloud-load-gif.gif'
                alt='white-loader'
                className='page-loader'
              />
            </div>
          </div>
        </div>
      )}
      				{/* start of error popup */}
				<div className={`modal show fade ${errorPopUp ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setErrorPopUp(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Error.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Error Occured
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setErrorPopUp(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of error popup */}
    </>
  )
}
