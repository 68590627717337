import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement, ReactNode, useRef } from "react";
import React from 'react';
import { IRequestPayload } from '../../interface/IRequestPayload';
import { IOrganizationData, ProviderDetail } from '../../interface/organization';
import { ProviderType } from '../../interface/providerType';
import { Comparator } from '../../interface/comparator';
import { getSecurityGroups, getRegions } from '../../service/environmentService';
import { IScopeSelectRequest } from "../../interface/IScopeSelectRequest";
import { IProviderTypeSelectRequest } from "../../interface/IProviderTypeSelectRequest";
import { ISecurityGroupRequest } from "../../interface/ISecurityGroupRequest";
import { IRegion } from "../../interface/IRegion";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelect, ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { padWithALeadingZero } from "../../utils/numberUtils";
import { Tooltip } from "antd";
import Header from "../../components/Header/Header";
 

export default function SecurityRuleComponent() {
	// PS_SR_01
	const [securityGroupRequest, setSecurityGroupRequest] = useState<ISecurityGroupRequest>();
	const [region, setRegion] = useState<IRegion>();
	const [riskPorts] = useState([22, 25, 53, 80, 110, 143, 389, 445, 1433, 2049, 3306, 3389, 5439, 5432, 1521]);
	const [portMessageMap, setPortMessageMap] = useState(new Map());
	const [scopeSelectRequest, setScopeSelectRequest] = useState("");
	const [portTypeMap, setPortTypeMap] = useState(new Map());
	const [securityGroups, setSecurityGroups] = useState<any>([]);
	const [filteredSecurityGroups, setFilteredSecurityGroups] = useState<any>([]);
	const [filteredRiskRules, setFilteredRiskRules] = useState<any>([]);
	const [searchKey, setSearchKey] = useState("");
	const [pageLoader, setPageLoader] = useState(true);
	const [organization, setOrganization] = useState<IOrganizationData | null>();
	const [provider, setProvider] = useState<ProviderDetail | null>();
	const [providerType, setProviderType] = useState('');
	const [providerTypeSelectRequest, setProviderTypeSelectRequest] = useState<IProviderTypeSelectRequest>();
	const [limit, setLimit] = useState(10);
	const [requestPayload, setRequestPayload] = useState<IRequestPayload>();
	const [selectedProviderDetails, setSelectedProviderDetails] = useState<any>()
	const [InboundRules, setInboundRules] = useState<any>();
	const [OutboundRules, setOutBoundRule] = useState<any>();
	useState<any>();
	
	// PS_SR_02
	const [providerTypeSelectProps, setProviderTypeSelectProps] = useState<ProviderSelectProps>({
		organizationId: 0,
		providerTypes: [],
		onProviderTypeChange: (organizationId, providerType) => { },
	});

	// PS_SR_03
	const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
		organizationId: 0,
		providerType: "",
		onScopeChange: (scope) => { },
	});

	// PS_SR_04
	const pageLimit = 10
	const [currentLength, setCurrentLength] = useState(pageLimit);


	// PS_SR_05
	useEffect(() => {
		setPortTypeMap(new Map<number | string, string>([
			[-1, 'All Traffic'],
			['0 - 65535', 'All TCP'],
			[22, 'SSH'],
			[25, 'SMTP'],
			[53, 'DNS'],
			[80, 'HTTP'],
			[110, 'POP3'],
			[143, 'IMAP'],
			[389, 'LDAP'],
			[443, 'HTTPS'],
			[445, 'SMB'],
			[465, 'SMTPS'],
			[993, 'IMAPS'],
			[995, 'POP3S'],
			[1433, 'MSSQL'],
			[2049, 'NFS'],
			[3306, 'MYSQL/Aurora'],
			[3389, 'RDP'],
			[5439, 'Redshift'],
			[5432, 'PostgreSQL'],
			[1521, 'Oracle - RDS'],
			[5985, 'WinRM - HTTP'],
			[5986, 'WinRM - HTTPS'],
			[2007, 'Elastic Graphics'],
		]));

		setPortMessageMap(new Map<number | string, string>([
			[22, 'If left open, it can lead to unauthorized remote access and potential brute force attacks on SSH services.'],
			[25, 'If open to the public internet, it can be exploited for email spamming or unauthorized relaying'],
			[53, 'If left open and unsecured, it can be targeted for DNS amplification attacks or DNS cache poisoning'],
			[80, 'If open and unencrypted, it can expose web applications to various web-based attacks like cross-site scripting (XSS) and SQL injection'],
			[110, 'If open without proper security measures, it can expose email servers to unauthorized access and email harvesting'],
			[143, 'If open and insecurely configured, it can be targeted for unauthorized access, email retrieval, and potential data leaks.'],
			[389, 'If left open and unsecured, it can lead to unauthorized access, data exposure, or compromise of directory services'],
			[445, 'If open to the public internet, it can be targeted for various SMB-related attacks, such as remote code execution'],
			[1433, 'If open to the public internet without proper security measures, it can be targeted for unauthorized access, SQL injection attacks, or potential data breaches.'],
			[2049, 'If open and unsecured, it can expose UNIX systems to unauthorized file access, data leakage, or potential system compromise.'],
			[3306, 'If open without proper security configurations, it can be targeted for unauthorized database access, SQL injection attacks, or potential data breaches.'],
			[3389, 'If open to the public internet, it can be targeted for unauthorized access, or potential exploitation of vulnerabilities in the Remote Desktop Protocol (RDP).'],
			[5439, 'If left open and unsecured, it can be targeted for unauthorized access, data extraction, or potential compromise of the Redshift database.'],
			[5432, 'If open without proper security measures, it can be targeted for unauthorized database access and SQL injection attacks'],
			[1521, 'If left open and unsecured, it can be targeted for unauthorized access, SQL injection attacks, or potential data breaches']
		]));

		const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		const tooltipList = tooltipTriggerList.map((tooltipTriggerEl: Element) => {
			return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
		});

		return () => {
			// Cleanup code (if needed)
			tooltipList.forEach((tooltip: any) => tooltip.dispose());
		};
	}, []); // Empty dependency array to run the effect only once


	useEffect(() => {
		console.log(filteredRiskRules)
	}, [filteredRiskRules])

	// PS_SR_06
	const handleOrganizationEmit = (organizationEmitted: IOrganizationData) => {
		setPageLoader(true);
		setOrganization(organizationEmitted);

		setProviderTypeSelectRequest({
			list: organizationEmitted.providerType,
		});
	};

	// PS_SR_07
	const onOrganizationChange = async (selectedOrganization: IOrganizationData) => {
		setPageLoader(true);
		setOrganization(selectedOrganization);
		setProviderTypeSelectProps({
			organizationId: selectedOrganization.organizationId,
			onProviderTypeChange: onProviderTypeChange,
			providerTypes: selectedOrganization.providerType,
		})
	}

	// PS_SR_08
	const onProviderTypeChange = async (organizationId: number, selectedProviderType: string) => {
		setPageLoader(true);
		console.log(organization)
		setProviderType(selectedProviderType);
		setScopeSelectProps({
			organizationId: organizationId,
			providerType: selectedProviderType,
			onScopeChange: onScopeChange,
		})
	}
	
	// PS_SR_09
	const onScopeChange = async (selectedScope: ProviderDetail | ProviderDetail[]) => {
		setPageLoader(true);
		selectedScope = selectedScope as ProviderDetail
		console.log(organization)
		console.log(selectedScope)
		setSelectedProviderDetails(selectedScope)
		setProviderType(selectedScope.providerType)
		setCurrentLength(10)

		// PS_SR_10
		if (selectedScope.providerType === 'AWS') {
			const requestPayload = {
				secretName: selectedScope.secretName,
				tenantId: selectedScope.tenantId,
				subscriptionId: selectedScope.subscriptionId,
				accountId: selectedScope.accountId,
			}

			// PS_SR_11
			const regionResponse = await getRegions(requestPayload);

			// PS_SR_13
			const regionData = regionResponse.data;
			setRegion(regionData);

			// PS_SR_14
			if (regionData && regionData.regions?.length > 0) {
				const requestPayload = {
					secretName: selectedScope.secretName,
					tenantId: selectedScope.tenantId,
					subscriptionId: selectedScope.subscriptionId,
					accountId: selectedScope.accountId,
					region: regionData.regions[0]
				}
				// PS_SR_15
				await getSecurityGroupDetails(selectedScope.providerType, requestPayload);
			}
		} else {
			const requestPayload = {
				secretName: selectedScope.secretName,
				tenantId: selectedScope.tenantId,
				subscriptionId: selectedScope.subscriptionId,
				accountId: "",
			}
			// PS_SR_23
			await getSecurityGroupDetails(selectedScope.providerType, requestPayload);
		}

	}

	const getSecurityGroupDetails = async (providerType: any, requestPayload: any) => {
		setPageLoader(true)
		// PS_SR_15
		const regionResponse = await getSecurityGroups(providerType, requestPayload);

		// PS_SR_17
		if (regionResponse.data.securityGroups !== null) {
			setPageLoader(false);
			// PS_SR_18
			if (providerType === "AWS") {
				regionResponse.data.securityGroups.forEach((eachGroup: any) => {
					eachGroup.ipPermissions.forEach((each: any) => {
						each.type = portTypeMap.get(each.toPort) ? portTypeMap.get(each.toPort) : 'Custom';
						if (each.fromPort !== each.toPort) {
							each.toPort = `${each.fromPort} ${each.toPort}`;
						}
						if (each.ipProtocol === '-1') {
							each.toPort = 'All';
							each.ipProtocol = 'All';
							each.type = 'All Traffic';
						}
					});

					// PS_SR_19
					eachGroup.ipPermissionsEgress.forEach((each: any) => {
						if (each.fromPort !== each.toPort) {
							each.toPort = `${each.fromPort} ${each.toPort}`;
						}
						each.type = portTypeMap.get(each.toPort) ? portTypeMap.get(each.toPort) : 'Custom';
						if (each.ipProtocol === '-1') {
							each.toPort = 'All';
							each.ipProtocol = 'All';
							each.type = 'All Traffic';
						}
					});
				});
			} else {
				// PS_SR_20
				getRulesByDirection('Inbound', regionResponse.data.securityGroups)
				getRulesByDirection('Outbound', regionResponse.data.securityGroups)
			}
			// PS_SR_22
			setSecurityGroups(regionResponse.data.securityGroups);
			setFilteredSecurityGroups(regionResponse.data.securityGroups);
			setFilteredRiskRules(getRiskRules(regionResponse.data.securityGroups, searchKey));
		}
		setPageLoader(false);
	}

	const getSecurityGroup = (securityGroupArray: any, searchKey: string): [] => {
		if (!searchKey?.trim()) {
			return securityGroupArray
		}

		let filterKey: string;
		setCurrentLength(limit);

		switch (providerType) {
			case ProviderType.AZURE:
				filterKey = 'name';
				break;
			case ProviderType.AWS:
				filterKey = 'groupName';
				break;
			default:
				break;
		}

		let filteredData = securityGroupArray.filter((each: any) =>
			each[filterKey]?.toLowerCase()?.startsWith(searchKey?.trim()?.toLowerCase())
		);
		return filteredData
	};

	// PS_SR_23
	const getRiskRules = (securityGroups: [], searchKey: string) => {
		let riskRules = [];

		// PS_SR_23
		switch (providerType) {
			// PS_SR_23
			case ProviderType.AZURE:
				riskRules = securityGroups.flatMap((eachGroup: any) =>
					eachGroup.securityRules
						.filter(
							(eachRule: any) =>
								riskPorts.includes(eachRule.properties.destinationPortRange)
						)
						?.map((eachRule: any) => ({
							name: eachGroup.name,
							type: eachRule.properties?.direction,
							ruleName: eachRule.name,
							port: eachRule.properties?.destinationPortRange,
							protocol: eachRule.properties?.protocol,
							source: eachRule.properties?.sourceAddressPrefix,
							destination: eachRule.properties?.destinationAddressPrefix,
							access: eachRule.properties?.access,
						}))
				);
				break;

			// PS_SR_23	
			case ProviderType.AWS:
				riskRules = securityGroups.flatMap((eachGroup: any) => {
					let inboundRules = eachGroup.ipPermissions
						.filter((eachRule: any) => riskPorts.includes(eachRule.toPort))
						?.map((eachRule: any) => ({
							name: eachGroup.groupName,
							type: 'Inbound',
							port: eachRule.toPort,
							protocol: eachRule.ipProtocol,
							source: eachRule.ipRanges?.length ? eachRule.ipRanges[0].cidrIp : '-',
							description: eachRule.userIdGroupPairs.length
								? eachRule.userIdGroupPairs[0].description
								: '-',
						}));

					let outboundRules = eachGroup.ipPermissionsEgress
						.filter((eachRule: any) => riskPorts.includes(eachRule.toPort))
						?.map((eachRule: any) => ({
							name: eachGroup.groupName,
							type: 'Outbound',
							port: eachRule.toPort,
							protocol: eachRule.ipProtocol,
							source: eachRule.ipRanges?.length ? eachRule.ipRanges[0].cidrIp : '-',
							description: eachRule.userIdGroupPairs.length
								? eachRule.userIdGroupPairs[0].description
								: '-',
						}));

					return [...inboundRules, ...outboundRules];
				});
				break;

			default:
				break;
		}
		// PS_SR_23
		return riskRules;
	};

    // PS_SR_21
	const getRulesByDirection = (direction: string, rules: any[]) => {
		return rules?.filter(eachRule => eachRule?.properties?.direction === direction)
	};

	// PS_SR_24
	const sort = (riskRules: boolean, sortKey: string, order: string) => {
		if (riskRules) {
			setFilteredRiskRules((prevRules: any) => [...prevRules].sort((a, b) => Comparator.compare(a[sortKey], b[sortKey], order)));
		} else {
			setFilteredSecurityGroups((prevGroups: any) => [...prevGroups].sort((a, b) => Comparator.compare(a[sortKey], b[sortKey], order)));
		}
	};

	// PS_SR_25
	const search = (e: any) => {
		// PS_SR_26
		const searchKey = e.target.value.trim();

		// PS_SR_27
		if (!searchKey) {
			setFilteredSecurityGroups(getSecurityGroup(securityGroups, ''));
			setFilteredRiskRules(getRiskRules(securityGroups, ''));
		} else {
			const filteredGroup = getSecurityGroup(securityGroups, searchKey);
			setFilteredSecurityGroups(filteredGroup);
			setFilteredRiskRules(getRiskRules(filteredGroup, searchKey));
		}
	};


	// PS_SR_28
	function handleLoadMore() {
		setCurrentLength(currentLength => currentLength + pageLimit);
	}

	// PS_SR_29
	const getRiskPortMessage = (port: number | string) => {
		return portMessageMap.get(port);
	};

	// PS_SR_30
	const handleRegionChange = async (region: string) => {
		// PS_SR_31
		setCurrentLength(10)
		setSecurityGroupRequest((prevRequest: any) => ({
			...prevRequest,
			region: region,
		}));

		// PS_SR_32
		let payload = {
			secretName: selectedProviderDetails.secretName,
			tenantId: selectedProviderDetails.tenantId,
			subscriptionId: selectedProviderDetails.subscriptionId,
			accountId: selectedProviderDetails.accountId,
			region: region
		}
		await getSecurityGroupDetails(providerType, payload);
	};


	// PS_SR_33
	const organziationSelectComponent = useMemo(() => <OrganizationSelect onOrganizationChange={onOrganizationChange} />, []);
	const providerSelectComponent = useMemo(() => <ProviderSelect {...providerTypeSelectProps} />, [providerTypeSelectProps]);
	const scopeSelectComponent = useMemo(() => <ScopeSelect {...scopeSelectProps} />, [scopeSelectProps])

	return (
		<>
			<div className="container-fluid">
				<Header />
				<div className="row justify-content-center">
					<div className="col-md-10">
						<div className="row pt-3">
							<div className="d-md-flex d-block justify-content-between align-items-center my-4">
								<h5 className="page-header me-xl-2 responsive-margin-bottom text-nowrap">Security Rules</h5>
								<div className="d-flex">
								<div className="d-lg-flex d-md-block">
									<div className="dropdown me-md-4 res-margin-20">{organziationSelectComponent}</div>
									<div className="dropdown res-margin-20">{providerSelectComponent}</div>
									<div className="input-group ms-3">{scopeSelectComponent}</div>
								</div>
								{providerType === 'AWS' && (
									<div className="d-lg-flex d-md-block me-lg-3 align-items-center res-margin-20 ms-3">
										<div className="col-lg-auto col-md-12 col-sm-12">
											<label className="form-label mb-lg-0 custom-label me-md-2" htmlFor="Region">
												Region
											</label>
										</div>
										<div className="col-lg-auto col-md-12 col-sm-12">
											<select
												className="custom-input secure-dropdown py-2 w-100 px-3 form-select"
												id="Region"
												onChange={(e) => handleRegionChange(e.target.value)}
											>
												{region?.regions?.map((eachRegion: any) => (
													<option key={eachRegion}>{eachRegion}</option>
												))}
											</select>
										</div>
									</div>
								)}
								<div className="input-group ms-4">
									<input
										type="text"
										className="form-control border-radius font-14 font-medium pe-5 custom-input"
										id="search"
										placeholder="Search"
										onChange={(e) => search(e)}
									/>
									<span className="inputfield-icon-sty">
										<a>
											<img src="images/search.svg" alt="Search icon" className="close-icon" />
										</a>
									</span>
								</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-lg-12">
							<div className="row">
								<ul className="nav nav-pills my-3" id="pills-tab" role="tablist">
									<li className="nav-item" role="presentation">
										<button
											className="nav-link custom-tab-style responsive-show px-2 mx-1 active"
											id="All-tab"
											data-bs-toggle="pill"
											data-bs-target="#All"
											type="button"
											role="tab"
											aria-controls="All"
											aria-selected="true"
										>
											All
										</button>
									</li>
									<li className="nav-item" role="presentation">
										<button
											className="nav-link custom-tab-style responsive-show px-2 mx-1"
											id="Risk-tab"
											data-bs-toggle="pill"
											data-bs-target="#Risk"
											type="button"
											role="tab"
											aria-controls="Risk"
											aria-selected="false"
										>
											Risk
										</button>
									</li>
								</ul>
								<div className="col-md-12 p-0">
									<div className="tab-content" id="pills-tabContent">

										{/* =========================All Rules============================= */}
										<div className="tab-pane fade mt-3 active show" id="All" role="tabpanel" aria-labelledby="All-tab">
											<div className="row">
												<div className="col-md-12">
													<div className="accordion body-bg">
														<div className="custom-accordion-item accordion-item border-0 body-bg">

															{/* =========================AZURE============================= */}
															{providerType === 'Azure' && (
																<div className="accordion-header mt-1 pb-1 table-responsive">
																	<table className="table table-borderless custom-grid">
																		<thead className="custom-table-head">
																			<tr>
																				<th className="">NAME</th>
																				<th className="position-relative">
																					RESOURCE GROUP
																					<span className="sorting">
																						<a className="sortup" onClick={() => sort(false, 'resourceGroup', 'Asc')}>
																							<span className="sort-icon sortup-img"></span>
																						</a>
																						<a className="sortdown" onClick={() => sort(false, 'resourceGroup', 'Desc')}>
																							<span className="sort-icon sortdown-img"></span>
																						</a>
																					</span>
																				</th>
																				<th className="position-relative">
																					LOCATION
																					<span className="sorting">
																						<a className="sortup" onClick={() => sort(false, 'location', 'Asc')}>
																							<span className="sort-icon sortup-img"></span>
																						</a>
																						<a className="sortdown" onClick={() => sort(false, 'location', 'Desc')}>
																							<span className="sort-icon sortdown-img"></span>
																						</a>
																					</span>
																				</th>
																				<th className="text-end">INBOUND RULES</th>
																				<th className="text-end">OUTBOUND RULES</th>
																			</tr>
																		</thead>
																		<tbody>
																			{filteredSecurityGroups?.length !== 0 ? (
																				filteredSecurityGroups?.slice(0, currentLength).map((eachGroup: any, i: any) => (
																					<React.Fragment key={i}>
																						<tr>
																							<td>
																								<span
																									role="button"
																									tabIndex={0}
																									className="accordion-button ps-0 custom-accordion-btn py-0 box-shadow-none collapsed"
																									data-bs-toggle="collapse"
																									data-bs-target={`#panelsStayOpen-InboundOutbound-Rules${i}`}
																									aria-expanded="false"
																								>
																									<span className="ps-2 grid-primary-text td-wrap">{eachGroup.name}</span>
																								</span>
																							</td>
																							<td className="grid-primary-text td-wrap resource">{eachGroup.resourceGroup}</td>
																							<td>{eachGroup.location}</td>
																							<td className="text-end">
																								{getRulesByDirection('Inbound', eachGroup.securityRules)?.length}
																							</td>
																							<td className="text-end">
																								{getRulesByDirection('Outbound', eachGroup.securityRules)?.length}
																							</td>
																						</tr>
																						<tr>
																							<td colSpan={6} id={`panelsStayOpen-InboundOutbound-Rules${i}`} className="accordion-collapse collapse">
																								<div>
																									<div className="accordion-body">
																										<div className="accordion">
																											<h3 className="table-inner-header mb-3">Inbound Rules</h3>
																											<table className="table table-borderless custom-grid">
																												<thead className="custom-table-head">
																													<tr className="border-0">
																														<th>PRIORITY</th>
																														<th className="">RULE NAME</th>
																														<th className="">PORT RANGE</th>
																														<th className="">PROTOCOL</th>
																														<th className="">SOURCE RANGE</th>
																														<th className="">DESTINATION</th>
																														<th className="">ACCESS</th>
																													</tr>
																												</thead>
																												<tbody>
																													{getRulesByDirection('Inbound', eachGroup.securityRules)?.map((each: any, index: any) => (
																														<tr key={index}>
																															<td>{each.properties.priority}</td>
																															<td className="grid-primary-text">{each.name}</td>
																															<td className="grid-primary-text">
																																<Tooltip title={getRiskPortMessage(each.port)}>
																																	<span
																																		className={
																																			`legend-sty me-2 ${riskPorts?.includes(each.destinationPortRange) ? 'legend-red' : 'legend-dark-green'}`
																																		}
																																	>
																																	</span>
																																</Tooltip>
																																{each.destinationPortRange}
																															</td>
																															<td>{each.properties.protocol}</td>
																															<td>{each.properties.sourceAddressPrefix}</td>
																															<td>{each.properties.destinationAddressPrefix}</td>
																															<td>{each.properties.access}</td>
																														</tr>
																													))}
																												</tbody>
																											</table>
																										</div>
																										<div className="accordion mt-4">
																											<h3 className="table-inner-header mb-3">Outbound Rules</h3>
																											<table className="table table-borderless custom-grid">
																												<thead className="custom-table-head">
																													<tr className="border-0">
																														<th>PRIORITY</th>
																														<th className="">RULE NAME</th>
																														<th className="">PORT RANGE</th>
																														<th className="">PROTOCOL</th>
																														<th className="">SOURCE RANGE</th>
																														<th className="">DESTINATION</th>
																														<th className="">ACCESS</th>
																													</tr>
																												</thead>
																												<tbody>
																													{getRulesByDirection('Outbound', eachGroup.securityRules)?.map((each: any, index: any) => (
																														<tr key={index}>
																															<td>{each.properties.priority}</td>
																															<td className="grid-primary-text">{each.name}</td>
																															<td className="grid-primary-text">
																																<Tooltip title={getRiskPortMessage(each.port)}>
																																	<span
																																		className={
																																			`legend-sty me-2 ${riskPorts?.includes(each.destinationPortRange) ? 'legend-red' : 'legend-dark-green'}`
																																		}
																																	>
																																	</span>
																																</Tooltip>
																																{each.destinationPortRange}
																															</td>
																															<td>{each.properties.protocol}</td>
																															<td>{each.properties.sourceAddressPrefix}</td>
																															<td>{each.properties.destinationAddressPrefix}</td>
																															<td>{each.properties.access}</td>
																														</tr>
																													))}
																												</tbody>
																											</table>
																										</div>
																									</div>
																								</div>
																							</td>
																						</tr>
																					</React.Fragment>
																				))
																			) : (
																				<tr>
																					<td colSpan={6} className="text-center">
																						No data available
																					</td>
																				</tr>
																			)}
																		</tbody>
																	</table>
																	<p className="mb-3 mt-2 para-text"># of Records:
																		<span className="primary-color">
																			{padWithALeadingZero(filteredSecurityGroups.length < currentLength ? filteredSecurityGroups.length : currentLength)} out of {padWithALeadingZero(filteredSecurityGroups.length)}
																		</span>
																	</p>
																	{filteredSecurityGroups.length > currentLength &&
																		<div className="row justify-content-center">
																			<button type="button" className="btn secondary-btn mt-2 mb-5" onClick={handleLoadMore}>
																				Load More
																			</button>
																		</div>
																	}
																</div>
															)}

															{/* =========================AWS============================= */}
															{providerType === 'AWS' && (
																<div>
																	<div className="accordion-header mt-1 pb-1 table-responsive">
																		<table className="table table-borderless custom-grid">
																			<thead className="custom-table-head">
																				<tr>
																					<th className="position-relative">
																						NAME
																						<span className="sorting">
																							<a className="sortup" onClick={() => sort(false, 'groupName', 'Asc')}>
																								<span className="sort-icon sortup-img"></span>
																							</a>
																							<a className="sortdown" onClick={() => sort(false, 'groupName', 'Desc')}>
																								<span className="sort-icon sortdown-img"></span>
																							</a>
																						</span>
																					</th>
																					<th className="position-relative">
																						GROUP ID
																						<span className="sorting">
																							<a className="sortup" onClick={() => sort(false, 'groupId', 'Asc')}>
																								<span className="sort-icon sortup-img"></span>
																							</a>
																							<a className="sortdown" onClick={() => sort(false, 'groupId', 'Desc')}>
																								<span className="sort-icon sortdown-img"></span>
																							</a>
																						</span>
																					</th>
																					<th className="position-relative">
																						VPC ID
																						<span className="sorting">
																							<a className="sortup" onClick={() => sort(false, 'vpcId', 'Asc')}>
																								<span className="sort-icon sortup-img"></span>
																							</a>
																							<a className="sortdown" onClick={() => sort(false, 'vpcId', 'Desc')}>
																								<span className="sort-icon sortdown-img"></span>
																							</a>
																						</span>
																					</th>
																					<th className="position-relative">
																						DESCRIPTION
																					</th>
																					<th className="position-relative">
																						INBOUND RULES
																					</th>
																					<th className="position-relative">
																						OUTBOUND RULES
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				{filteredSecurityGroups?.length !== 0 ? (
																					filteredSecurityGroups.slice(0, currentLength).map((eachGroup: any, i: any) => (
																						<React.Fragment key={i}>
																							<tr>
																								<td className="td-wrap">
																									<span
																										role="button"
																										tabIndex={0}
																										className="accordion-button ps-0 custom-accordion-btn py-0 box-shadow-none collapsed"
																										data-bs-toggle="collapse"
																										data-bs-target={`#panelsStayOpen-InboundOutbound-Rules${i}`}
																										aria-expanded="false"
																									>
																										<span className="ps-2 grid-primary-text cus-text-wrap">
																											{eachGroup.groupName}
																										</span>
																									</span>
																								</td>
																								<td className="grid-primary-text">{eachGroup.groupId}</td>
																								<td>{eachGroup.vpcId}</td>
																								<td className="td-wrap resource cus-text-wrap">{eachGroup.description}</td>
																								<td className="text-end">{eachGroup.ipPermissions?.length}</td>
																								<td className="text-end">{eachGroup.ipPermissionsEgress?.length}</td>
																							</tr>
																							<tr>
																								<td colSpan={6} id={`panelsStayOpen-InboundOutbound-Rules${i}`} className="accordion-collapse collapse">
																									<div>
																										<div className="accordion-body">
																											<div className="accordion">
																												<h3 className="table-inner-header mb-3">Inbound Rules</h3>
																												<table className="table table-borderless custom-grid">
																													<thead className="custom-table-head">
																														<tr className="border-0">
																															<th>TYPE</th>
																															<th className="">PORT RANGE</th>
																															<th className="">IP PROTOCOL</th>
																															<th className="">SOURCE RANGE</th>
																															<th className="">DESCRIPTION</th>
																														</tr>
																													</thead>
																													<tbody>
																														{eachGroup.ipPermissions?.map((each: any, index: any) => (
																															<tr key={index}>
																																<td className="grid-primary-text">{each.type}</td>
																																<td className="grid-primary-text">
																																	<Tooltip title={getRiskPortMessage(each.toPort)}>
																																		<span
																																			className={
																																				`legend-sty me-2 ${riskPorts?.includes(each.toPort) ? 'legend-red' : 'legend-dark-green'}`
																																			}
																																		>
																																		</span>
																																	</Tooltip>
																																	{each.toPort}
																																</td>
																																<td className="grid-primary-text">{each.ipProtocol}</td>
																																<td>{each.ipRanges[0]?.cidrIp}</td>
																																<td className="td-wrap resource">{each.userIdGroupPairs[0]?.description}</td>
																															</tr>
																														))}
																													</tbody>
																												</table>
																											</div>
																											<div className="accordion mt-4">
																												<h3 className="table-inner-header mb-3">Outbound Rules</h3>
																												<table className="table table-borderless custom-grid">
																													<thead className="custom-table-head">
																														<tr className="border-0">
																															<th>TYPE </th>
																															<th className="">PORT RANGE</th>
																															<th className="">IP PROTOCOL</th>
																															<th className="">SOURCE RANGE</th>
																															<th className="">DESCRIPTION</th>
																														</tr>
																													</thead>
																													<tbody>
																														{eachGroup.ipPermissionsEgress?.map((each: any, index: any) => (
																															<tr key={index}>
																																<td className="grid-primary-text">{each.type}</td>
																																<td className="grid-primary-text">
																																	<Tooltip title={getRiskPortMessage(each.toPort)}>
																																		<span
																																			className={
																																				`legend-sty me-2 ${riskPorts?.includes(each.toPort) ? 'legend-red' : 'legend-dark-green'}`
																																			}
																																		>
																																		</span>
																																	</Tooltip>
																																	{each.toPort}
																																</td>
																																<td> {each.ipProtocol}</td>
																																<td>{each.ipRanges[0]?.cidrIp}</td>
																																<td>{each.userIdGroupPairs[0]?.description}</td>
																															</tr>
																														))}
																													</tbody>
																												</table>
																											</div>
																										</div>
																									</div>
																								</td>
																							</tr>
																						</React.Fragment>
																					))
																				) : (
																					<tr>
																						<td colSpan={6} className="text-center">
																							<img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-4" />
																							<p className="para-text">No data found</p>
																						</td>
																					</tr>
																				)}
																			</tbody>
																		</table>
																	</div>
																	<p className="mb-3 mt-2 para-text"># of Records:
																		<span className="primary-color">
																			{padWithALeadingZero(filteredSecurityGroups.length < currentLength ? filteredSecurityGroups.length : currentLength)} out of {padWithALeadingZero(filteredSecurityGroups.length)}
																		</span>
																	</p>
																	{filteredSecurityGroups.length > currentLength &&
																		<div className="row justify-content-center">
																			<button type="button" className="btn secondary-btn mt-2 mb-5" onClick={handleLoadMore}>
																				Load More
																			</button>
																		</div>
																	}
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>

										{/* =========================Risk Rules============================= */}
										<div className="tab-pane fade mt-3" id="Risk" role="tabpanel" aria-labelledby="Risk-tab">
											<div className="row">
												<div className="col-md-12">
													{providerType === 'Azure' ? (
														<div className="table-responsive">
															{/* Azure-specific table structure */}
															<table className="table table-borderless custom-grid">
																<thead className="custom-table-head">
																	<tr>
																		<th className="position-relative">
																			NAME
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'name', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'name', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																		<th className="position-relative">
																			TYPE
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'type', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'type', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																		<th className="position-relative">
																			RULE NAME
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'ruleName', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'ruleName', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																		<th className="position-relative">
																			PORT & PROTOCOL
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'port', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'port', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																		<th className="position-relative">
																			SOURCE
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'source', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'source', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																		<th className="position-relative">
																			DESTINATION
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'destination', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'destination', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																		<th className="position-relative">
																			ACCESS
																			<span className="sorting">
																				<a className="sortup" onClick={() => sort(true, 'access', 'Asc')}>
																					<span className="sort-icon sortup-img"></span>
																				</a>
																				<a className="sortdown" onClick={() => sort(true, 'access', 'Desc')}>
																					<span className="sort-icon sortdown-img"></span>
																				</a>
																			</span>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{filteredRiskRules?.length !== 0 ? (
																		filteredRiskRules?.slice(0, currentLength).map((each: any) => (
																			<tr key={each.name}>
																				<td className="grid-primary-text" data-bs-toggle="tooltip" data-bs-placement="top" title="">
																					{each.name}
																				</td>
																				<td className="grid-primary-text">{each.type}</td>
																				<td className="grid-primary-text" data-bs-toggle="tooltip" data-bs-placement="top" title="">
																					{each.ruleName}
																				</td>
																				<td className="grid-primary-text">
																					<Tooltip title={getRiskPortMessage(each.port)}>
																						<span
																							className={
																								`legend-sty me-2 ${riskPorts?.includes(each.port) ? 'legend-red' : 'legend-dark-green'}`
																							}
																						>
																						</span>
																					</Tooltip>
																					{each.port}
																				</td>
																				<td>{each.source}</td>
																				<td>{each.destination}</td>
																				<td>{each.access}</td>
																			</tr>
																		))
																	) : (
																		<tr>
																			<td colSpan={7} className="text-center">
																				<img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-4" />
																				<p className="para-text">No data found</p>
																			</td>
																		</tr>
																	)}
																</tbody>
															</table>
														</div>
													) :
														providerType === 'AWS' ? (
															<div className="table-responsive">
																{/* AWS-specific table structure */}
																<table className="table table-borderless custom-grid">
																	<thead className="custom-table-head">
																		<tr>
																			<th className="position-relative">
																				NAME
																				<span className="sorting">
																					<a className="sortup" onClick={() => sort(true, 'name', 'Asc')}>
																						<span className="sort-icon sortup-img"></span>
																					</a>
																					<a className="sortdown" onClick={() => sort(true, 'name', 'Desc')}>
																						<span className="sort-icon sortdown-img"></span>
																					</a>
																				</span>
																			</th>
																			<th className="position-relative">
																				TYPE
																				<span className="sorting">
																					<a className="sortup" onClick={() => sort(true, 'type', 'Asc')}>
																						<span className="sort-icon sortup-img"></span>
																					</a>
																					<a className="sortdown" onClick={() => sort(true, 'type', 'Desc')}>
																						<span className="sort-icon sortdown-img"></span>
																					</a>
																				</span>
																			</th>
																			<th className="position-relative">
																				PORT & PROTOCOL
																				<span className="sorting">
																					<a className="sortup" onClick={() => sort(true, 'port', 'Asc')}>
																						<span className="sort-icon sortup-img"></span>
																					</a>
																					<a className="sortdown" onClick={() => sort(true, 'port', 'Desc')}>
																						<span className="sort-icon sortdown-img"></span>
																					</a>
																				</span>
																			</th>
																			<th className="position-relative">
																				SOURCE
																				<span className="sorting">
																					<a className="sortup" onClick={() => sort(true, 'source', 'Asc')}>
																						<span className="sort-icon sortup-img"></span>
																					</a>
																					<a className="sortdown" onClick={() => sort(true, 'source', 'Desc')}>
																						<span className="sort-icon sortdown-img"></span>
																					</a>
																				</span>
																			</th>
																			<th className="position-relative">
																				DESCRIPTION
																				<span className="sorting">
																					<a className="sortup" onClick={() => sort(true, 'description', 'Asc')}>
																						<span className="sort-icon sortup-img"></span>
																					</a>
																					<a className="sortdown" onClick={() => sort(true, 'description', 'Desc')}>
																						<span className="sort-icon sortdown-img"></span>
																					</a>
																				</span>
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		{filteredRiskRules?.length !== 0 ? (
																			filteredRiskRules?.slice(0, currentLength).map((each: any, index: number) => (
																				<tr key={`riskRules${index}`}>
																					<td className="grid-primary-text" data-bs-toggle="tooltip" data-bs-placement="top" title="">
																						{each.name}
																					</td>
																					<td className="grid-primary-text">{each.type}</td>
																					<td className="grid-primary-text">

																						<Tooltip title={getRiskPortMessage(each.port)}>
																							<span
																								className={
																									`legend-sty me-2 ${riskPorts?.includes(each.port) ? 'legend-red' : 'legend-dark-green'}`
																								}
																							>
																							</span>
																						</Tooltip>
																						{each.port}
																					</td>
																					<td>{each.source}</td>
																					<td className="td-wrap resource">{each.description}</td>
																				</tr>
																			))
																		) : (
																			<tr>
																				<td colSpan={5} className="text-center">
																					<img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-4" />
																					<p className="para-text">No data found</p>
																				</td>
																			</tr>
																		)}
																	</tbody>
																</table>
															</div>
														) : (
															<React.Fragment>{/* Render for other provider types */}</React.Fragment>
														)}
												</div>
											</div>
											<p className="mb-3 mt-2 para-text"># of Records:
												<span className="primary-color">
													{padWithALeadingZero(filteredRiskRules.length < currentLength ? filteredRiskRules.length : currentLength)} out of {padWithALeadingZero(filteredRiskRules.length)}
												</span>
											</p>
											{filteredRiskRules.length > currentLength &&
												<div className="row justify-content-center">
													<button type="button" className="btn secondary-btn mt-2 mb-5" onClick={handleLoadMore}>
														Load More
													</button>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{pageLoader &&
				<div className="container-fluid" >
					<div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
						<div className="position-absolute top-50 start-50 translate-middle">
							<img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
						</div>
					</div>
				</div >
			}
		</>
	);
}  