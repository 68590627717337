import { client } from "../client/Client";

export const getAllPushNotifications = async() => {
    let response = await client('/notification/push', '', "GET");

    return response
}

export const acknowledgePushNotification = async(notificationId: number, comments: string) => {
    let response = client('/notification/acknowledge', "", "GET")

    return response
}

export const getAllNotifications = async() => {
    let response = await client('/notification', "", "GET")

    return response
}

export const mailAcknowledgement = async(mail_group_id: number, message: string, organizationId: number) => {
    let response = await client(`/notification/organization/${organizationId}/acknowledge/${mail_group_id} `, message, "POST")

    return response
}

export async function getNotification(id: any, organizationId:any, request:any) {
    // PS_NT_09
    let response = await client(`/notification/organization/${organizationId}/provider/${id}`,request, "POST");
    return response;
}
