import { client } from '../client/Client'
import { ProviderType } from '../enum/providerTypeEnum'
export const getResourceAvailability = async (
  providerType: any,
  organizationId: number,
  request: any,
  sort: string,
  order: number,
  skip: number,
  limit: number
) => {
    let url = `/infra/organization/${organizationId}/availability/status/${providerType}?skip=${skip}&limit=${limit}`
    url = sort ? url.concat(`&sort=${sort}`) : url
    url = order ? url.concat(`&order=${order}`) : url
    let result = await client(`${url}`, request, 'POST')
  return result
}
export const getAwsAvailabilitydropdowns = async (
  organizationId: any,
  providerId: any,
  request = {}
) => {
  let url =`/infra/organization/${organizationId}/availability/filters/AWS?providerId=${providerId}`
  let result=await client(`${url}`, request, "GET");
  return result
}
