// ManageEvents.tsx
import { useState, useEffect, useRef, useMemo  } from 'react';
import { getAllEvents } from '../../service/eventService';
import { useSelector} from "react-redux"
import { IFilterRequest } from '../../interface/filter';
import { IManageEvents } from '../../interface/event';
import { IOrganizationData, defaultOrganization, ProviderDetail } from '../../interface/organization';
import { debounce } from 'lodash'
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { ProviderSelect, ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import Header from "../Header/Header"
import { ScopeSelectProp } from '../ResuableComponents/ScopeSelect';



export default function ManageEvents () {

  // PS_ME_01
  const [userEventData, setUserEventData] = useState<IManageEvents[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortOrder, setSortOrder] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [limit, setLimit] = useState<number>(10);
  const [searchKey, setSearchKey] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false)
  const [isPageLoaderActive, setIsPageLoaderActive] = useState(false)
  const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
  const [providerType, setProviderType] = useState("");

  // PS_ME_02
  const filterRequest: IFilterRequest = {
    skip: userEventData.length,
    limit : limit,
    searchText: searchKey,
    sortBy: sortColumn,
    orderBy: sortOrder,
  };

  const selectedOrganization = useSelector((state: any) => state.selectedOrganization);

  // PS_ME_03
  const [providerTypeSelectProps, setProviderTypeSelectProps] =
        useState<ProviderSelectProps>({
            organizationId: 0,
            providerTypes: [],
            onProviderTypeChange: (organizationId, providerType) => { },
        });

    // PS_ME_04
    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        onScopeChange: (scope) => { },
    });

    // PS_ME_05
    const onOrganizationChange = async (
        selectedOrganization: IOrganizationData
    ) => {
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        });
        setOrganization(selectedOrganization);
        getEventsData(selectedOrganization.organizationId, "", "");
    };

    // PS_ME_06
    const onProviderTypeChange = async (
        organizationId: number,
        selectedProviderType: string
    ) => {
        setProviderType(selectedProviderType);
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            onScopeChange: onScopeChange,
        });
        setProviderType(selectedProviderType);
    };

    // PS_ME_07
    const onScopeChange = async (
        selectedScope: ProviderDetail | ProviderDetail[]
    ) => {
        selectedScope = selectedScope as ProviderDetail;
    };

    // PS_ME_08
    const organziationSelectComponent = useMemo(
        () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
        []
    );

  
  const getEventsData = async(organizationID: number,column : string, sortBy: string) => {
    setIsPageLoaderActive(true);
    // PS_ME_09
    const eventsResponse = await getAllEvents(organizationID, {
      skip: 0,
      limit,
      searchText: searchKey.toLowerCase(),
      sortBy: column, // Use the provided sortColumn
      orderBy: sortBy, // Use the provided sortOrder
    });
    // PS_ME_11
    if (eventsResponse.statusCode === 200) {
      setIsPageLoaderActive(false);  
      setUserEventData(eventsResponse.data.events); // Update the userEventData state with the array of events
      setTotalCount(eventsResponse.data.eventCount); // Update the totalCount state with the total count of events
    } else {
      // PS_ME_11
      setErrorPopUp(true)
    }
}

const userEventGridGeneration = () => {
  // PS_ME_12
  return userEventData.map((data, index) => {
    const createdDate: Date = new Date(data.createdDate);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit',
      year: 'numeric', // Change '2-digit' to 'numeric'
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    const formattedDate = createdDate.toLocaleDateString('en-US', options)
                               .replace(',', ''); // Remove comma after year

    return (
      <tr key={index}>
        <td>{data.eventName}</td>
        <td>{formattedDate}</td>
        <td>{data.provider || ' - '}</td>
        <td>{data.scope || ' - '}</td>
        <td>{data.initiatedBy || ' - '}</td>
      </tr>
    );
  });
};

  // PS_ME_13
  const handleSort = (column : string, sortBy: string) => {
    setSortColumn(column)
    setSortOrder(sortBy)
    getEventsData(organization.organizationId, column, sortBy)
  };

// PS_ME_14
const handleSearch = async (value : string) => {
  const searchQuery = value;
  setSearchValue(searchQuery);
  setSearchKey(searchQuery); // Update the searchKey state with the new search query

      setIsPageLoaderActive(true)
      // PS_ME_14
      const eventsResponse = await getAllEvents(organization.organizationId, {
        skip: 0,
        limit,
        searchText: searchQuery.toLowerCase(),
        sortBy: sortColumn,
        orderBy: sortOrder,
      });
      // PS_ME_14
      if (eventsResponse.statusCode === 200) {
        setUserEventData(eventsResponse.data.events);
        setTotalCount(eventsResponse.data.eventCount);
        setIsPageLoaderActive(false)
      }
    }

  
// PS_ME_15
const debouncedSearch = debounce(handleSearch, 1000);

  const handleLoadMore = async () => {
    setIsPageLoaderActive(true);
    const nextPageSkip =  userEventData.length; // Calculate the skip value for the next page
    const eventsResponse = await getAllEvents(organization.organizationId, { ...filterRequest, skip: nextPageSkip });
    
    if (eventsResponse.statusCode === 200) {
      setIsPageLoaderActive(false);
      setUserEventData((prevUserEventData) => [...prevUserEventData, ...eventsResponse.data.events]); 
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
              <h5 className="page-header mb-lg-0 mb-3">Manage Events</h5>
              <div className="d-md-flex d-sm-block">
                <div className="dropdown me-md-4 res-margin-20 app-organization-select">
                  {organziationSelectComponent}
                </div>
                <div className="input-group">
                <input
                   type="text"
                   className="form-control border-radius font-14 font-medium pe-5 custom-input"
                   id="search"
                   placeholder="Search"
                   onChange={(e) => debouncedSearch(e.target.value)} 
                  />
                  <span className="inputfield-icon-sty">
                    <a>
                      <img src="images/search.svg" alt="Search icon" className="close-icon" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-borderless custom-grid">
                    <thead className="custom-table-head">
                      <tr>
                        <th className="custom-grid-font-weight">
                          EVENT NAME
                          <span className="sorting">
                            <a className={`sortup ${sortOrder === 'Asc' ? 'active' : ''}`} onClick={() => handleSort('e.event_name', 'Asc')}>
                              <span className="sort-icon sortup-img"></span>
                            </a>
                            <a className={`sortdown ${sortOrder === 'Desc' ? 'active' : ''}`} onClick={() => handleSort('e.event_name', 'Desc')}>
                              <span className="sort-icon sortdown-img"></span>
                            </a>
                          </span>
                        </th>
                        <th className="custom-grid-font-weight">
                          TIME STAMP
                          <span className="sorting">
                            <a className={`sortup ${sortOrder === 'Asc' ? 'active' : ''}`} onClick={() => handleSort('e.created_on', 'Asc')}>
                              <span className="sort-icon sortup-img"></span>
                            </a>
                            <a className={`sortdown ${sortOrder === 'Desc' ? 'active' : ''}`} onClick={() => handleSort('e.created_on', 'Desc')}>
                              <span className="sort-icon sortdown-img"></span>
                            </a>
                          </span>
                        </th>
                        <th className="custom-grid-font-weight">
                          PROVIDER
                          <span className="sorting">
                            <a className={`sortup ${sortOrder === 'asc' ? 'active' : ''}`} onClick={() => handleSort('p.provider_type', 'Asc')}>
                              <span className="sort-icon sortup-img"></span>
                            </a>
                            <a className={`sortdown ${sortOrder === 'desc' ? 'active' : ''}`} onClick={() => handleSort('p.provider_type', 'Desc')}>
                              <span className="sort-icon sortdown-img"></span>
                            </a>
                          </span>
                        </th>
                        <th className="custom-grid-font-weight">
                          SCOPE
                          <span className="sorting">
                            <a className={`sortup ${sortOrder === 'asc' ? 'active' : ''}`} onClick={() => handleSort('p.connector_name', 'Asc')}>
                              <span className="sort-icon sortup-img"></span>
                            </a>
                            <a className={`sortdown ${sortOrder === 'desc' ? 'active' : ''}`} onClick={() => handleSort('p.connector_name', 'Desc')}>
                              <span className="sort-icon sortdown-img"></span>
                            </a>
                          </span>
                        </th>
                        <th className="custom-grid-font-weight">INITIATED BY</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userEventData.length !== 0 ? (
                        userEventGridGeneration()
                      ): (
                        <tr>
                        <td colSpan={6} className="text-center">
                          <img src="images/no-rec-found.gif" alt="no-rec-found" className="no-data-found-sty mb-4" />
                          <p className="para-text">No data found</p>
                        </td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <p className="mb-3 mt-2 para-text">
                  # of Records:
                  <span className="primary-color">
                    {Math.min(totalCount, userEventData.length).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    {' out of '}
                    {totalCount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </span>
                </p>
                {userEventData.length < totalCount && (
                  <div className="row justify-content-center">
                    <button type="button" className="btn secondary-btn mt-2 mb-5" onClick={handleLoadMore}>
                      Load More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* start of error popup */}
				<div className={`modal show fade ${errorPopUp ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content custom-popup-height">
							<div className="modal-header border-0">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setErrorPopUp(false)} />
							</div>
							<div className="modal-body">
								<div className="text-center mb-1">
									<img className="mt-1" src="images/Error.svg" alt="Success" />
									<label className="form-label custom-pop-label mt-4 mb-0 w-100">
										Error Occured
									</label>
								</div>
							</div>
							<div className="modal-footer popup-footer d-flex justify-content-center pb-4">
								<button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => setErrorPopUp(false)}>Got It</button>
							</div>
						</div>
					</div>
				</div>
				{/* end of error popup */}
        {isPageLoaderActive &&
          <div className="container-fluid">
            <div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
              <div className="position-absolute top-50 start-50 translate-middle">
                <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
              </div>
            </div>
          </div>
        }
        </div>
      </div>
    </>
  );
}  