export class BaseConstant {

    static azureRecCategoryOrder = [
        "Cost", "Security", "HighAvailability",
        "OperationalExcellence", "Performance"
    ];

    static awsRecCategoryOrder = [
        "cost_optimizing", "security", "service_limits",
        "fault_tolerance", "performance"
    ];

        static costExpenseIds = ["monthCost", "forecastedCost", "thresholdLeft", "recommendations"];
    static azureLinkedCharts = ["ResourceLocation", "ServiceName", "ResourceGroup", "ResourceId"];
    static awsLinkedCharts = ["REGION", "SERVICE", "INSTANCE_TYPE", "HIGH_UTILIZATION_REGIONS"]

    static severitySortOrder = ["Low", "Medium", "High"]

}