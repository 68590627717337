import "../../assets/scss/_manageusers.scss";
import "../../assets/scss/_popup-style.scss"
import "../../assets/scss/_appcomponent.scss"
import Header from "../Header/Header";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from 'react-redux'
import {
    IFilterRequest,
    IUserDetailsResponse,
    IUserDetails,
    INewUserDetails,
    IEditUserDetail,
} from "../../interface/manage";
import { createUser, deleteUserData, getUserByEmail, getUsers, updateUser } from "../../service/userService";
import { OrganizationSelect } from "../ResuableComponents/OrganizationSelect";
import { IOrganizationData, ProviderDetail, defaultOrganization } from "../../interface/organization";
import { ProviderSelectProps } from "../ResuableComponents/ProviderSelect";
import { ScopeSelectProp } from "../ResuableComponents/ScopeSelect";
import { debounce } from 'lodash';
import { padWithALeadingZero } from "../../utils/numberUtils";

export default function Users() {
    let filterRequest: IFilterRequest = {
        skip: 0,
        limit: 10,
        searchText: "",
        sortBy: "",
        orderBy: "",
    };

    let userDetailsObj: IUserDetailsResponse = {
        count: 0,
        users: [],
    };

    let editUserObj: IEditUserDetail = {
        "emailId": "",
        "loginId": null,
        "name": "",
        "organizationId": null,
        "organizationName": "",
        "roleId": null,
        "roleName": "",
        "status": "",
    }

    const [userDetails, setUserDetails] = useState<IUserDetailsResponse>(userDetailsObj);
    const [organization, setOrganization] = useState<IOrganizationData>(defaultOrganization);
    const [isNameRequired, setIsNameRequired] = useState<boolean>(false)
    const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false)
    const [emailExistError, setEmailExistError] = useState<boolean>(false)
    const [enterValidMail, setEnterValidMail] = useState<boolean>(false)
    const [enterValidname, setEnterValidname] = useState<boolean>(false)
    const [userAddedSuccessPopup, setUserAddedSuccessPopup] = useState<boolean>(false)
    const [selectedRole, setSelectedRole] = useState<string>("")
    const [confirmDeletePopup, setConfirmDeletePopup] = useState<boolean>(false);
    const [deleteLoginId, setDeleteLoginId] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const pageLimit = 10
    const [currentLength, setCurrentLength] = useState(pageLimit)
    const [deleteUserName, setDeleteUserName] = useState<string>("")
    const [totalCount, setTotalCount] = useState<number>(0)

    let newUserObj: INewUserDetails = {
        emailId: "",
        name: "",
        organizationId: 0,
        organizationName: "",
        roleId: 0,
        status: "Inactive",
    };
    const role = useSelector((state: any) => state.role.role)
    const [newUserDetails, setNewUserDetails] = useState<INewUserDetails>(newUserObj);
    const [editIndexValue, setEditIndexValue] = useState<number | null>(null);
    const [providerType, setProviderType] = useState("");
    const [cloneUserDetails, setCloneUserDetails] = useState<IUserDetailsResponse>(userDetailsObj);
    const [isRoleRequired, setIsRoleRequired] = useState<boolean>(false)

    const onOrganizationChange = async (selectedOrganization: IOrganizationData) => {
        setProviderTypeSelectProps({
            organizationId: selectedOrganization.organizationId,
            onProviderTypeChange: onProviderTypeChange,
            providerTypes: selectedOrganization.providerType,
        })

        setOrganization(selectedOrganization);
        setShowLoader(true)
        const getUsersResponse = await getUsers(filterRequest, selectedOrganization.organizationId);
        setTotalCount(getUsersResponse.data.length)
        if (getUsersResponse.statusCode === 200) {
            setUserDetails(getUsersResponse.data);
            setCloneUserDetails(JSON.parse(JSON.stringify(getUsersResponse.data)));
        }
        setShowLoader(false)
        setNewUserDetails({...newUserDetails,name:'',emailId:'',roleId:0})
        setIsEmailRequired(false)
        setIsNameRequired(false)
        setIsEmailRequired(false)
        setEnterValidMail(false)
        setEnterValidname(false)
    }

    const [providerTypeSelectProps, setProviderTypeSelectProps] = useState<ProviderSelectProps>({
        organizationId: 0,
        providerTypes: [],
        onProviderTypeChange: (organizationId, providerType) => { },
    });

    const [scopeSelectProps, setScopeSelectProps] = useState<ScopeSelectProp>({
        organizationId: 0,
        providerType: "",
        onScopeChange: (scope) => { },
    });

    const onProviderTypeChange = async (organizationId: number, selectedProviderType: string) => {
        setProviderType(selectedProviderType);
        setScopeSelectProps({
            organizationId: organizationId,
            providerType: selectedProviderType,
            onScopeChange: onScopeChange,
        })
    }

    const onScopeChange = async (selectedScope: ProviderDetail | ProviderDetail[]) => {
        selectedScope = selectedScope as ProviderDetail
    }

    const organziationSelectComponent = useMemo(
        () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
        []
    );

    const users = async () => {
        setShowLoader(true)
        const getUsersResponse = await getUsers(filterRequest, organization.organizationId);
        setTotalCount(getUsersResponse.data.length)
        if (getUsersResponse.statusCode === 200) {
            setUserDetails(getUsersResponse.data);
            setCloneUserDetails(JSON.parse(JSON.stringify(getUsersResponse.data)));
        }
        setShowLoader(false)
    };

    const handleNewUserChange = (e: any) => {

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const onlyNameRegex = /^[A-Za-z,' ']+$/;

        if (e.target.id === "name" && e.target.value !== "") {
            setIsNameRequired(false)
            if (onlyNameRegex.test(e.target.value)) {
                setEnterValidname(false)
            }
            else if (!(onlyNameRegex.test(e.target.value))) {
                setEnterValidname(true)
            }
        }
        else if (e.target.id === "name" && e.target.value === "") {
            setIsNameRequired(true)
            setEnterValidname(false)
        }
        

        if (e.target.id === "emailId" && e.target.value !== "") {
            setIsEmailRequired(false)
            if (emailRegex.test(e.target.value)) {
                setEnterValidMail(false)
            }
            else if (!(emailRegex.test(e.target.value))) {
                setEnterValidMail(true)
            }
        }
        else if (e.target.id === "emailId" && e.target.value === "") {
            setIsEmailRequired(true)
            setEnterValidMail(false)
            setEmailExistError(false)
        }

        setNewUserDetails((prevNewUserDetails: any) => {
            const updatedUserData = { ...prevNewUserDetails, [e.target.id]: e.target.value }

            return updatedUserData
        })
    }

    const handleNewUserDropDownChange = (e: any) => {

        setSelectedRole(e.target.value)

        let roleIdvalue = e.target.value

        setNewUserDetails((prevNewUserDetails: any) => {
            const updatedUserEmail = { ...prevNewUserDetails, "roleId": parseInt(roleIdvalue) }

            if(roleIdvalue === "Select Role") {
                setIsRoleRequired(true)
            }
            else {
                setIsRoleRequired(false)
            }
            return updatedUserEmail
        })
    }

    const checkIfUserExist = async (e: any) => {

        if (e.target.value !== "") {
            const userDetails = await getUserByEmail(e.target.value)

            if (userDetails.statusCode === 200) {
                setEmailExistError(true)
            }
            else if(userDetails.statusCode !== 200) {
                setEmailExistError(false)
            }
        }
        else if (e.target.value === "") {
            setEmailExistError(false)
            setIsEmailRequired(true)
        }
    }

    const addUser = async () => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const updatedUserDetails = {
            ...newUserDetails,
            organizationId: organization.organizationId,
            organizationName: organization.organizationName
        };
        
        if (
            updatedUserDetails.emailId &&
            updatedUserDetails.name &&
            updatedUserDetails.roleId !== 0 &&
            updatedUserDetails.roleId !== null &&
            emailRegex.test(updatedUserDetails.emailId)
        ) {
            setNewUserDetails(updatedUserDetails);
            const payload = [updatedUserDetails]
            const addUserResponse = await createUser(payload);

            if (addUserResponse.statusCode === 200) {
                setNewUserDetails(newUserObj)
                setSelectedRole("")
                setUserAddedSuccessPopup(true)
                users();
            }
        }
    };

    const handleDeleteUser = async () => {
        let deletionPayload = {
            loginId: deleteLoginId,
            organizationId: organization.organizationId
        }

        const deleteUserResponse = await deleteUserData(deletionPayload)

        if (deleteUserResponse.statusCode === 200) {
            users()
            setConfirmDeletePopup(false)
        }
    }

    const handleNameChange = (value: string, index: number) => {
        const updatedUsers = [...userDetails.users];
        updatedUsers[index].name = value;
        setUserDetails(prevState => ({
            ...prevState,
            users: updatedUsers
        }));
    };

    const handleRoleChange = (value :any, index : any) => {
        const updatedUsers = [...userDetails.users];
        updatedUsers[index].roleId = parseInt(value);
        updatedUsers[index].roleName = value === '2' ? 'Billing Admin' : 'Billing Reader';
        setUserDetails(prevState => ({
            ...prevState,
            users: updatedUsers
        }));
    };
    
    

    const onRemoveEdit = (index: number) => {
        setEditIndexValue(null)
        const updatedUsers = [...userDetails.users];
        updatedUsers[index].name = cloneUserDetails.users[index].name;
        updatedUsers[index].roleId = cloneUserDetails.users[index].roleId;
        updatedUsers[index].roleName = cloneUserDetails.users[index].roleName;

        // Update the state with the updated users array
        setUserDetails(prevState => ({
            ...prevState,
            users: updatedUsers
        }));
    }

    const editUserDetails = async (index: number) => {

        editUserObj.emailId = userDetails.users[index].emailId
        editUserObj.loginId = userDetails.users[index].loginId
        editUserObj.name = userDetails.users[index].name
        editUserObj.organizationId = organization.organizationId
        editUserObj.organizationName = organization.organizationName
        editUserObj.roleId = userDetails.users[index].roleId
        editUserObj.roleName = userDetails.users[index].roleName
        editUserObj.status = userDetails.users[index].status

        await updateUser(editUserObj)
        users()
    }

    const handleSearch = async (value: string) => {
        filterRequest.searchText = value.toLowerCase();
        setShowLoader(true);
    
        const getUsersResponse = await getUsers(filterRequest, organization.organizationId);
        setUserDetails(getUsersResponse.data);
        setShowLoader(false);
    }
    
    const debouncedSearch = debounce(handleSearch, 1000);

    const handleSort = async(sortBy: string, orderBy: string) => {
        filterRequest.sortBy = sortBy
        filterRequest.orderBy = orderBy

        setShowLoader(true);
    
        const getUsersResponse = await getUsers(filterRequest, organization.organizationId);
        setUserDetails(getUsersResponse.data);
        setShowLoader(false);

    }

    const handleLoadMore = async() => {
        filterRequest.skip = 10
        filterRequest.limit += 10

        setShowLoader(true);
    
        const getUsersResponse = await getUsers(filterRequest, organization.organizationId);
        setUserDetails(getUsersResponse.data);
        setShowLoader(false);
    }

    // const bindUserData = () => {
    //     return userDetails.users.map((eachUsers: IUserDetails, index: number) => (
    //         <tr key={index + 1} className="custom-alternative-line ng-untouched ng-pristine ng-invalid">
    //             <td className="custom-grid-font-weight text-center text-nowrap">
    //                 {index + 1}
    //             </td>
    //             {editIndexValue !== index ? (
    //                 <>
    //                     <td className="custom-grid-font-weight grid-primary-text text-nowrap">
    //                         {eachUsers.name}
    //                     </td>
    //                     <td className="custom-grid-font-weight grid-primary-text text-nowrap">
    //                         {eachUsers.emailId}
    //                     </td>
    //                     <td className="custom-grid-font-weight grid-primary-text text-nowrap">
    //                         {eachUsers.roleName}
    //                     </td>
    //                 </>
    //             ) : (
    //                 <>
    //                     <td>
    //                         <input
    //                             className="custom-input form-control py-2 px-3 ng-pristine ng-valid ng-touched"
    //                             type="text"
    //                             value={eachUsers.name}
    //                             onChange={(e) => handleNameChange(e.target.value, index)}
    //                         />
    //                     </td>
    //                     <td className="custom-grid-font-weight grid-primary-text text-nowrap">
    //                         {eachUsers.emailId}
    //                     </td>
    //                     <td className="custom-grid-font-weight grid-primary-text text-nowrap">
    //                         {/* Assuming roleName is coming from a dropdown */}
    //                         <select className="custom-input py-2 px-3 form-select ng-pristine ng-valid ng-touched"
    //                             value={eachUsers.roleName + "@#$" + eachUsers.roleId}
    //                             onChange={(e) => handleRoleChange(e.target.value, index)}
    //                         >
    //                             <option>Select Role</option>
    //                             {organization.roleName === "Owner" &&
    //                             <option value="Billing Admin@#$2">Billing Admin</option>}
    //                             <option value="Billing Reader@#$3">Billing Reader</option>
    //                             {/* Add more options as needed */}
    //                         </select>
    //                     </td>
    //                 </>
    //             )}
    //             <td className="custom-grid-font-weight text-nowrap text-center">
    //                 <span className={`custom-batch ${eachUsers.status === "Active" ? "batch-green" : "batch-grey"}`}>
    //                     {eachUsers.status}
    //                 </span>
    //             </td>
    //             {editIndexValue !== index && (
    //                 <td className="custom-grid-font-weight text-center text-nowrap">
    //                     {eachUsers.roleName !== "Owner" && 
    //                     <>
    //                         <a onClick={() => setEditIndexValue(index)}>
    //                             <span className="me-3 edit-img" data-bs-toggle="tooltip" title="Edit" data-bs-placement="top"></span>
    //                         </a>
    //                         <a onClick={() => { setConfirmDeletePopup(true); setDeleteLoginId(eachUsers.loginId); setDeleteUserName(eachUsers.name) }}>
    //                             <span className="delete-img" data-bs-toggle="tooltip" title="Delete" data-bs-placement="top"></span>
    //                         </a>
    //                     </>
                        
    //                     }
    //                 </td>
    //             )}
    //             {editIndexValue === index && (
    //                 <td className="custom-grid-font-weight text-center text-nowrap">
    //                     <a>
    //                         <span className="me-3 tick-img" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Save" onClick={() => { editUserDetails(index) }}></span>
    //                     </a>
    //                     <a>
    //                         <span className="wrong-img" onClick={() => onRemoveEdit(index)}></span>
    //                     </a>
    //                 </td>
    //             )}
    //         </tr>
    //     ));
    // };

    const bindUserData = () => {
        return userDetails.users.map((eachUser, index) => (
            <tr key={index + 1} className="custom-alternative-line ng-untouched ng-pristine ng-invalid">
                <td className="custom-grid-font-weight text-center text-nowrap">
                    {index + 1}
                </td>
                {editIndexValue !== index ? (
                    <>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUser.name}
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUser.emailId}
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUser.roleName}
                        </td>
                    </>
                ) : (
                    <>
                        <td>
                            <input
                                className="custom-input form-control py-2 px-3 ng-pristine ng-valid ng-touched"
                                type="text"
                                value={eachUser.name}
                                onChange={(e) => handleNameChange(e.target.value, index)}
                            />
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUser.emailId}
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            <select
                                className="custom-input py-2 px-3 form-select ng-pristine ng-valid ng-touched"
                                value={eachUser.roleId}
                                onChange={(e) => handleRoleChange(e.target.value, index)}
                            >
                            <>
                                <option value={2} >Billing Admin</option>
                                <option value={3} >Billing Reader</option>
                            </>
                            </select>
                        </td>
                    </>
                )}
                <td className="custom-grid-font-weight text-nowrap text-center">
                    <span className={`custom-batch ${eachUser.status === "Active" ? "batch-green" : "batch-grey"}`}>
                        {eachUser.status}
                    </span>
                </td>
                {editIndexValue !== index && (
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        {eachUser.roleName !== "Owner" && 
                        <>
                            <a onClick={() => setEditIndexValue(index)} className={`${
                                        role == 'BillingReader' 
                                          ? 'disabled '
                                          : ''
                                      }`}>
                                <span className="me-3 edit-img" data-bs-toggle="tooltip" title="Edit" data-bs-placement="top"></span>
                            </a>
                            <a onClick={() => { setConfirmDeletePopup(true); setDeleteLoginId(eachUser.loginId); setDeleteUserName(eachUser.name) }} className={`${
                                        role == 'BillingReader' 
                                          ? 'disabled '
                                          : ''
                                      }`}>
                                <span className="delete-img" data-bs-toggle="tooltip" title="Delete" data-bs-placement="top"></span>
                            </a>
                        </>
                        
                        }
                    </td>
                )}
                {editIndexValue === index && (
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        <a>
                            <span className="me-3 tick-img" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Save" onClick={() => { editUserDetails(index) }}></span>
                        </a>
                        <a>
                            <span className="wrong-img" onClick={() => onRemoveEdit(index)}></span>
                        </a>
                    </td>
                )}
            </tr>
        ));
    };
    
    
    

    return (
        <div>
            <Header />
            <div className="container-fluid">
                {/* <!-- Manage Users Starts here --> */}
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center pt-3">
                            <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                                <h5 className="page-header mb-0 responsive-margin-bottom">
                                    Manage Users
                                </h5>
                                <div className="d-md-flex d-sm-block">
                                    <div className=" dropdown me-md-4 res-margin-20 app-organization-select">
                                        {organziationSelectComponent}
                                    </div>
                                    <div className="input-group ">
                                        <input
                                            type="text"
                                            className="form-control text-field border-radius font-14 font-medium pe-5 custom-input"
                                            id="search"
                                            placeholder="Search"
                                            onChange={(e) => debouncedSearch(e.target.value)} />
                                        <span className="inputfield-icon-sty">
                                            <a>
                                                <img
                                                    src="images/search.svg"
                                                    alt="Search icon"
                                                    className="close-icon" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 mb-5">
                                {/* <!--InlineTable section starts here--> */}
                                <div className="table-responsive">
                                    <table className="table table-borderless custom-grid mt-4 mb-4">
                                        <thead className="custom-table-head">
                                            <tr>
                                                <th className="custom-grid-font-weight text-center text-nowrap">
                                                    S.NO
                                                </th>
                                                <th className="custom-grid-font-weight text-nowrap position-relative">
                                                    NAME
                                                    <span className="sorting">
                                                        <a className="sortup" onClick={() => handleSort("l.first_name", "Asc")}>
                                                            <span className="sort-icon sortup-img"></span>
                                                        </a>
                                                        <a className="sortdown" onClick={() => handleSort("l.first_name", "Desc")}>
                                                            <span className="sort-icon sortdown-img"></span>
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="custom-grid-font-weight text-nowrap">
                                                    EMAIL ID
                                                </th>
                                                <th className="custom-grid-font-weight text-nowrap position-relative">
                                                    ROLE
                                                    <span className="sorting">
                                                        <a className="sortup" onClick={() => handleSort("r.role_id", "Asc")}>
                                                            <span className="sort-icon sortup-img"></span>
                                                        </a>
                                                        <a className="sortdown" onClick={() => handleSort("r.role_id", "Desc")}>
                                                            <span className="sort-icon sortdown-img"></span>
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="custom-grid-font-weight text-nowrap text-center position-relative">
                                                    STATUS
                                                    <span className="sorting">
                                                        <a className="sortup" onClick={() => handleSort("l.status", "Asc")}>
                                                            <span className="sort-icon sortup-img"></span>
                                                        </a>
                                                        <a className="sortdown" onClick={() => handleSort("l.status", "Desc")}>
                                                            <span className="sort-icon sortdown-img"></span>
                                                        </a>
                                                    </span>
                                                </th>
                                                <th className="custom-grid-font-weight text-center text-nowrap">
                                                    ACTION
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td className="text-nowrap custom-grid-font-weight">
                                                    <input
                                                        id="name"
                                                        type="text"
                                                        className="custom-input form-control py-2 px-3"
                                                        placeholder="Enter Name"
                                                        value={newUserDetails.name}
                                                        onChange={(e) => handleNewUserChange(e)}
                                                        onBlur={(e) => setIsNameRequired(e.target.value === "")}
                                                        maxLength={33}
                                                        disabled={role == 'BillingReader'}
                                                         />
                                                    {isNameRequired &&
                                                        <span className="text-danger d-block error-span">
                                                            Name is required
                                                        </span>}
                                                    {newUserDetails.name.length>32 &&
                                                        <span className="text-danger d-block error-span">
                                                        Name should not be more than 32 characters.
                                                        </span>
                                                    }
                                                    {enterValidname &&
                                                        <span className="text-danger d-block error-span">
                                                       Enter a valid name
                                                        </span>
                                                    }

                                                </td>
                                                <td className="text-nowrap custom-grid-font-weight">
                                                    <input
                                                        id="emailId"
                                                        type="text"
                                                        className="custom-input form-control py-2 px-3"
                                                        placeholder="name@company.com"
                                                        value={newUserDetails.emailId}
                                                        onChange={(e) => handleNewUserChange(e)}
                                                        onBlur={(e) => { checkIfUserExist(e); }}
                                                        disabled={role == 'BillingReader'} />
                                                    {isEmailRequired &&
                                                        <span className="text-danger d-block error-span">
                                                            Email ID is required
                                                        </span>}
                                                    {emailExistError &&
                                                        <span className="text-danger d-block error-span">
                                                            User is an owner or already present in this organization
                                                        </span>}
                                                    {enterValidMail &&
                                                        <span className="text-danger d-block error-span">
                                                            Enter a valid Email ID
                                                        </span>}
                                                </td>
                                                <td className="text-nowrap custom-grid-font-weight">
                                                    <select
                                                        className="custom-input py-2 px-3 form-select"
                                                        id="Technology-Vertical"
                                                        value={selectedRole}
                                                        onChange={(e) => handleNewUserDropDownChange(e)}
                                                        onBlur={(e) => {setIsRoleRequired(e.target.value === "Select Role")}}
                                                        disabled={role == 'BillingReader'}
                                                    >
                                                        <option>Select Role</option>
                                                        {organization.roleName === "Owner" &&
                                                            <option value="2">Billing Admin</option>}
                                                        <option value="3">Billing Reader</option>
                                                    </select>
                                                    <span className="text-danger d-block error-span">
                                                    {isRoleRequired &&
                                                        "Role is required"
                                                    }</span>
                                                </td>
                                                <td className="custom-grid-font-weight text-nowrap text-center"></td>
                                                <td className="custom-grid-font-weight text-center text-nowrap">
                                                <a onClick={() => addUser()}>
                                                        <span
                                                            className="add-img"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            title=""
                                                            data-bs-original-title="Add"
                                                        ></span>
                                                    </a>
                                                </td>
                                            </tr>
                                            {userDetails.count === 0 && (
                                                <tr className="custom-alternative-line">
                                                    <td colSpan={6} className="text-center">
                                                        <img
                                                            src="images/no-rec-found.gif"
                                                            alt="no-rec-found"
                                                            className="no-data-found-sty mb-4" />
                                                        <p className="para-text">No data found</p>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr></tr>
                                            {bindUserData()}
                                        </tbody>
                                    </table>
                                    <p className="mb-3 mt-2 para-text"># of Records:
                                        <span
                                            className="primary-color">{
                                                padWithALeadingZero(userDetails.users.length < currentLength ? userDetails.users.length : currentLength)} out of {padWithALeadingZero(userDetails.users.length)}
                                        </span>
                                    </p>
                                </div>
                                {/* <!--InlineTable section ends here--> */}
                                {userDetails.count < totalCount && 
                                    <div className="row justify-content-center">
                                        <button type="button" className="btn secondary-btn mt-2 mb-5" onClick={() => handleLoadMore()}>
                                            Load More
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* //   <!-- Manage Users Ends here --> */}
            </div>
            {/* user Success popup */}
            <div role="dialog" tabIndex={-1} className={`modal show fade no-provider-modal ${userAddedSuccessPopup ? 'show-popup' : ''}`}>
                <div role="document" className="custom-width-providerpopup modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img src="images/mail-send.gif" alt="Email" className="mt-2 email-gif-sty" />
                                <label className="form-label custom-pop-label mt-4 mb-0 w-100">Users added successfully!</label>
                                <label className="form-label custom-pop-label mb-0">An invitation has been sent to their email address.</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" data-bs-dismiss="modal" className="btn primary-btn" onClick={() => setUserAddedSuccessPopup(false)}>Got It</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* delete popup */}
            <div role="dialog" tabIndex={-1} className={`modal show fade no-provider-modal ${confirmDeletePopup ? 'show-popup' : ''}`}>
                <div role="document" className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn-close" onClick={() => setConfirmDeletePopup(false)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-1">
                                <img src="images/Delete-Confirmation.svg" alt="delete" className="mt-1" />
                                <label className="form-label custom-bold my-4 w-100">Delete Confirmation</label>
                                <label className="form-label custom-pop-label mb-1">You are about to delete the following user details : <span className="del-user-sty">{deleteUserName}</span>
                                </label>
                                <label className="form-label custom-pop-label mb-1"> Do you want to proceed?</label>
                            </div>
                        </div>
                        <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                            <button type="button" data-bs-dismiss="modal" className="btn secondary-btn me-3" onClick={() => handleDeleteUser()}>Yes</button>
                            <button type="button" className="btn color-warning-btn" onClick={() => setConfirmDeletePopup(false)}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* loader */}
            {showLoader &&
                <div className="container-fluid">
                    <div className="modal-backdrop fade show" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img src="images/cloud-load-gif.gif" alt="white-loader" className="page-loader" />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
