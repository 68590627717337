import { client } from '../client/Client'
import { useEncryptStorage } from '../hooks/useEncryptStorage';

export async function getOrganization() {
    // PS_ORG_1.5
    if(useEncryptStorage.getItem("userName")) {
        let response = await client("/manage/organizations", "", "GET").then(response => {
            response.data.forEach((eachOrganization: any) => {
                eachOrganization.providerType = eachOrganization.providerType
                    ? eachOrganization.providerType.split(",").map((each: string) => each?.trim())
                    : []
            })
            return response;
        });
        // PS_ORG_1.5
        return response;
    }
}

export async function getProviderDetails(organizationId: number, providerPayload: any) {
    let response = await client(`/manage/providers/organization/${organizationId}`, providerPayload, "POST");
    return response;
}

export async function fetchTicketingConnectionDetails(organizationId: any) {
    let response = await client(`/manage/organization/${organizationId}/connection/ticket`, "", "GET")

    return response
}

export async function fetchConnectionDetails(organizationId: number, ticketType: string) {
    let response = await client(`/manage/organization/${organizationId}/ticket?connectorType=${ticketType}`, "", "GET")

    return response
}
export async function fetchProjectKeys(projectKeyPayload: any, organizationId: number) {

    let payload = {
        instanceUrl: projectKeyPayload.url,
        userName: projectKeyPayload.userName
    }
    let response = await client(`/manage/organization/${organizationId}/projectKey`, payload, "POST")

    return response
}

export async function fetchInventoryData(organizationId: number, providerType: string, skip: number, limit: any, sortOrder: number,sort: string, search: string, definedValue: any[], type: string) {
    let response;
    
    let payload = {
        [type]: definedValue,
        search: search
    }

    if(sort !== "") {
        response = await client(`/infra/organization/${organizationId}/inventory/status/${providerType}?skip=${skip}&limit=${limit}&sort=${sort}&order=${sortOrder}`, payload, "POST")
    }
    else {
        response = await client(`/infra/organization/${organizationId}/inventory/status/${providerType}?skip=${skip}&limit=${limit}&order=${sortOrder}`, payload, "POST")
    }

    return response
}


export async function saveInventoryTag(payload: any, organizationId: number, providerType: string) {

    let response = await client(`/infra/organization/${organizationId}/inventory/addTags?providerType=${providerType}`, payload, "POST")

    return response
}


export async function getinventoryFilterData(selectedProviderType: string, organizationId: number) {
    let response = await client(`/infra/organization/${organizationId}/inventory/filters/${selectedProviderType}`, "", "GET")
    return response
}


export async function setSecret(token: string, organizationId: number){
    let payloadData = {
        organizationId: organizationId,
        values: `{\"token\":\"${token}\"}`,
    }

    await client('/manage/token/secret', payloadData, "POST")
}

export async function saveServiceInstance (data: any, toolStatus: string, organizationId: number) {
    let payload = {
        instanceEmailId: data.instanceEmailId
    }

    let response 
    
    if(toolStatus !== "Connected") {
        response = await client(`/manage/organization/${organizationId}/ticket?connectorType=serviceNow`, payload, "POST")
    }
    else {
        response = await client(`/manage/organization/${organizationId}/ticket?connectorType=serviceNow`, payload, "PUT")
    }

    return response
}

export async function saveJiraConnection(data: any, toolStatus: string, organizationId: number) {

    let payload = {
        url: data.url,
        userName: data.userName,
        projectKey : data.projectKey
    }

    let response 
    
    if(toolStatus !== "Connected") {
        response = await client(`/manage/organization/${organizationId}/ticket?connectorType=jira`, payload, "POST")
    }
    else {
        response = await client(`/manage/organization/${organizationId}/ticket?connectorType=jira`, payload, "PUT")
    }

    return response

}

export async function deleteConnection(selectedTool: string, organizationId: number) {
    let response = await client(`/manage/organization/${organizationId}/delete/ticket?connectorType=${selectedTool}`, "", "DELETE")
 
    return response
}

export const checkOrganizationExists = (organizationName: string) => {
    // PS_ORG_1.15
    const result =  client(`/authorize/organization/exists/${organizationName}`, '', 'GET')
    return result
};


export async function addOrganizationDetails(organizationPostData: any) {
    // PS_ORG_1.23
    let response = await client(`/manage/organization`, organizationPostData, "POST");
    return response;
}

export async function fetchTagList(providerType: any, payload: any) {
    const response = await client( `/cost/tags/${providerType}`, payload, "POST")
    return response
}

export async function fetchSubTagsList(providerType: any, payload: any) {

    const response = await client( `/cost/tag/tagKey/${providerType}`, payload, "POST")
    
    return response
}
