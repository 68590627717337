// PS_RP_02
import { useEffect, useState } from 'react';
import { resetPassword } from '../../service/authService';
import {useNavigate} from 'react-router-dom'
import { useSearchParams } from 'react-router-dom';

export default function ResetPassword() {
    const navigate = useNavigate();
    const [viewLink, setViewLink] = useState<string>("")
    // PS_RP_03
    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>("");
    // PS_RP_04
    const [newPasswordRequiredError, setNewPasswordRequiredError] = useState<boolean>(false);
    const [newValidPasswordError, setNewValidPasswordError] = useState<boolean>(false);
    const [newPasswordLengthError, setNewPasswordLengthError] = useState<boolean>(false);
    const [confirmPasswordRequiredError, setConfirmPasswordRequiredError] = useState<boolean>(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [buttonDisable, setButtonDisable] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    // PS_RP_05
    useEffect(() => {
        setViewLink(window.location.pathname)
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map((tooltipTriggerEl: Element) => {
          return new (window as any).bootstrap.Tooltip(tooltipTriggerEl);
        });
        
        return () => {
          // Cleanup code (if needed)
          tooltipList.forEach((tooltip: any) => tooltip.dispose());
        };
      }, []);

    // PS_RP_11  
    const handleNewPasswordInputChange = (e: any) => {
        const lengthRegex = /^.{8,20}$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&*])/;

        
        setPassword(() => {
            const updatedNewPassword = e.target.value

            if (updatedNewPassword === "") {
                setNewPasswordRequiredError(true);
                setNewValidPasswordError(false);
                setNewPasswordLengthError(false);
                setConfirmPasswordError(false);
            }
            else if (!lengthRegex.test(updatedNewPassword)) {
                setNewPasswordLengthError(true);
                setNewValidPasswordError(false);
                setNewPasswordRequiredError(false);
                setConfirmPasswordError(false);
            }
            else if (!passwordRegex.test(updatedNewPassword)) {
                setNewValidPasswordError(true);
                setNewPasswordLengthError(false);
                setNewPasswordRequiredError(false);
                setConfirmPasswordError(false);
            }
            else if (passwordRegex.test(updatedNewPassword)) {
                setNewValidPasswordError(false);
            }
            else if (lengthRegex.test(updatedNewPassword)) {
                setNewPasswordLengthError(false);
            }

            if (updatedNewPassword !== "") {
                setNewPasswordRequiredError(false);
            }

            if (confirmPassword !== "" && lengthRegex.test(updatedNewPassword) && passwordRegex.test(updatedNewPassword)) {
                if (updatedNewPassword !== "" && updatedNewPassword !== confirmPassword) {
                    setConfirmPasswordError(true)
                }
                else if (updatedNewPassword !== "" && updatedNewPassword === confirmPassword) {
                    setConfirmPasswordError(false)
                }
            }

            if (lengthRegex.test(updatedNewPassword)
                && passwordRegex.test(updatedNewPassword)
                && updatedNewPassword !== ""
                && updatedNewPassword === confirmPassword) {
                setButtonDisable(false)
            } else if (!lengthRegex.test(updatedNewPassword)
                || !passwordRegex.test(updatedNewPassword)
                || updatedNewPassword === ""
                || updatedNewPassword !== confirmPassword
            ) {
                setButtonDisable(true)
            }
            return updatedNewPassword
        })
    };

    const [searchParams] = useSearchParams();
    console.log(searchParams)

    
    // PS_RP_12 
    // const handleConfirmPasswordInputChange = (e: any) => {

    //     setConfirmPassword(() => {
    //         const updatedConfirmPassword = e.target.value

    //         if (updatedConfirmPassword === "") {
    //             setConfirmPasswordRequiredError(true);
    //             setConfirmPasswordError(false)
    //             setButtonDisable(true)
    //         }
    //         else if (updatedConfirmPassword !== password) {
    //             setConfirmPasswordError(true);
    //             setConfirmPasswordRequiredError(false);
    //             setButtonDisable(true)
    //         }
    //         else if (updatedConfirmPassword !== "" && updatedConfirmPassword === password) {
    //             setConfirmPasswordError(false);
    //             setConfirmPasswordRequiredError(false);
    //         }
    //         if (updatedConfirmPassword !== "" && updatedConfirmPassword === password) {
    //             setButtonDisable(false)
    //         }
    //         else if (updatedConfirmPassword !== "" && updatedConfirmPassword !== password) {
    //             setButtonDisable(true)
    //         }

    //         return updatedConfirmPassword
    //     })
    // };
    const handleConfirmPasswordInputChange = (e: any) => {
        const updatedConfirmPassword = e.target.value;
    
        setConfirmPassword(() => {
            if (updatedConfirmPassword === "") {
                setConfirmPasswordRequiredError(true);
                setConfirmPasswordError(false);
            }
            else if (updatedConfirmPassword !== password) {
                setConfirmPasswordError(true);
                setConfirmPasswordRequiredError(false);
            }
            else {
                setConfirmPasswordError(false);
                setConfirmPasswordRequiredError(false);
            }
    
            return updatedConfirmPassword;
        });
    
        // Check if both passwords match and pass validation checks before enabling the button
        if (updatedConfirmPassword === password && !newValidPasswordError && !newPasswordLengthError) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    };
    


    const handleSendInstruction = async () => {
        // PS_RP_15
        setLoader(true);

        const resetCode: any = searchParams.get('code');
        const email: any = searchParams.get('email');

        // PS_RP_16
        const sendInstructionResponse = await resetPassword(resetCode, password, email);

        // PS_RP_19
        if (sendInstructionResponse.statusCode === 200) {
            setShowSuccessMessage(true);
            setPassword('');
            setConfirmPassword('');
            setButtonDisable(true);
            setLoader(false);
        } else if (sendInstructionResponse.statusCode !== 200) {
            setErrorMessage(sendInstructionResponse.data.message);
            setShowErrorMessage(true);
            setLoader(false);
        }
    };


    return (
        <>
            <div className="container-fluid">
                {/* <!-- login starts here --> */}
                <div className="row ">
                    {/* <!-- login left part starts here --> */}
                    <div className="col-lg-6 d-lg-block d-none p-0">
                        <div className="bg-gradient-primary bg-height text-center position-relative my-0 rounded-0">
                            <div className="mx-auto">
                                <img src="images/login-image.svg" alt="login-image" className=" login-image-sty mt-5 mb-5" />
                            </div>
                        </div>
                    </div>
                    {/* <!--login form starts here --> */}
                    <div className="form-signup">
                        { viewLink === "/resetPassword" &&
                            <h5 className="page-header mb-3">Reset Password</h5>
                        }
                        { viewLink === "/createPassword" &&
                            <div className="col-md-12 mb-3">
                                <h5 className="page-header mb-3">We Manage Your Cloud Cost </h5>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label custom-labelcustom-label">Create your account password, rest all we
                                        take care</label>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                { viewLink === "/resetPassword" &&
                                  <label htmlFor="Create-Password" className="form-label custom-label">New Password</label>
                                }
                                { viewLink === "/createPassword" &&
                                   <label htmlFor="Create-Password" className="form-label custom-label">Create Password</label>
                                }
                                <span  data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title=''
                                      data-bs-original-title='Password must contain minimum of 8 characters that includes 
                                      at least 1 Lowercase, Uppercase, Number and Special character'>
                                <img className="ms-1" src="images/information-icon.svg" alt="info"/>
                                </span>
                                <input
                                    type="password"
                                    className="form-control custom-input py-2"
                                    id="Create-Password"
                                    placeholder="Enter The Password"
                                    value={password}
                                    // PS_RP_10
                                    onChange={handleNewPasswordInputChange}
                                    onBlur={() => { setNewPasswordRequiredError(password === ""); setShowSuccessMessage(false)}}
                                />
                                {newPasswordRequiredError && (
                                    <span className="signup-errors d-block">Password is required</span>
                                )}
                                {newValidPasswordError && (
                                    <span className="signup-errors d-block">Enter a valid Password</span>
                                )}
                                {newPasswordLengthError && (
                                    <span className="signup-errors d-block">Password field accepts only 8-20 characters.</span>
                                )}
                            </div>
                            <div className="col-md-12 mb-3">
                                { viewLink === "/resetPassword" &&
                                  <label htmlFor="Create-Password" className="form-label custom-label">Confirm Password</label>
                                }
                                { viewLink === "/createPassword" &&
                                   <label htmlFor="confirm-Password" className="form-label custom-label">Re-Enter Password</label>
                                }
                                <input
                                    type="password"
                                    className="form-control custom-input py-2"
                                    id="Create-Password"
                                    placeholder="Enter The Password"
                                    value={confirmPassword}
                                    // PS_RP_10
                                    onChange={handleConfirmPasswordInputChange}
                                    onBlur={() => { setConfirmPasswordRequiredError(confirmPassword === ""); setShowSuccessMessage(false) }}
                                />
                                {confirmPasswordRequiredError && (
                                    <span className="signup-errors d-block">Confirm Password is required</span>
                                )}
                                {confirmPasswordError && (
                                    <span className="signup-errors d-block">
                                        {viewLink === '/resetPassword'
                                            ? "Create Password and Re-Entered Password doesn't match"
                                            : "Password and Confirm Password doesn't match"}
                                    </span>
                                )}
                            </div>
                            <div className="col-md-12 my-3">
                                <button
                                    className="btn primary-btn w-100"
                                    type="button"
                                    disabled={buttonDisable}
                                    // PS_RP_14
                                    onClick={() => handleSendInstruction()}
                                >
                                    {loader && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    {viewLink === '/resetPassword' ? "Reset Password": "Create Password"}
                                </button>
                            </div>
                            <div className="col-md-12 mb-2">
                                {showSuccessMessage &&
                                    <div className="alert alert-success py-2" role="alert">
                                        <strong className="font-12">Password reset was successfull. Login with your new password</strong>
                                    </div>
                                }
                                {showErrorMessage &&
                                    <div className="alert alert-warning py-2" role="alert">
                                        <strong className="font-12">{errorMessage}</strong>
                                    </div>
                                }
                            </div>
                            <div className="col-md-12 mb-2">
					            {/* PS_FP_27, 28*/}
					            <p className="login-link-text">Redirect to
						          <span onClick={() => navigate('/login')}>
						            <a className='text-decoration-none link-sty ms-2'>Sign In</a>
						          </span>
					            </p>
					       </div>
                        </div>
                    </div>
                </div>
                {/* <!-- login form ends here --> */}
            </div>
            {/* <!-- login ends here --> */}

        </>
    );
}