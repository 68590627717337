import { client } from "../client/Client";
import { ICostRequest, IDashboard, IDefaultDashboard, IDimensionCostRequest } from "../interface/dashboard";

export async function getDefaultDashboard(organizationId: number) {
    let response = await client(`/cost/organization/${organizationId}/defaultdashboard`, "", "GET");
    return response;
}

export async function getMonthlyExpenses(payload: any, selectedProviderType: string) {
    let response = await client(`/cost/monthExpense/${selectedProviderType}`, payload, "POST");
    return response;
}

export async function getForecastedCost(payload: any, selectedProviderType: string) {
    let response = await client(`/cost/forecastedCost/${selectedProviderType}`, payload, "POST");
    return response;
}

export async function getCostAnalysis(payload: ICostRequest[], dateRange: string, providerType: string,organizationId:any) {
    let response = await client(`/cost/organization/${organizationId}/costAnalysis/${providerType}/${dateRange}`, payload, "POST");
    return response;
}
export async function getCostByDimension(payload: IDimensionCostRequest[], providerType: string, organizationId: number) {
    let response = await client(`/cost/organization/${organizationId}/dimension/${providerType}`, payload, "POST");
    return response;
}

export async function getCostByDimensions(payload: IDimensionCostRequest[], providerType: string) {
    let response = await client(`/cost/dimension/${providerType}`, payload, "POST");
    return response;
}

export async function saveDashboardData(dashboard: IDefaultDashboard, providerType: string, organizationId: number) {
    let response = await client(`/cost/organization/${organizationId}/dashboard/default?providerType=${providerType}`, dashboard, "POST")

    return response
}

export async function getMonthlyExpense(payload: any, selectedProviderType: string) {
    let response = await client(`/cost/monthExpense/${selectedProviderType}`, payload, "POST");
    return response;
}

export async function getDimensionList(providerType: string) {
    let response = await client(`/cost/dimensions/${providerType}`, "", "GET")

    return response
}

export async function getTagsList(payload: ICostRequest[], providerType: string) {
    let response = client(`/cost/tags/${providerType}`, payload, "POST")

    return response
}
export async function getAllDashboards(organizationId: number, isPrivate: boolean, providerType: string) {
    let payload = { providerType }

    let response = await client(`/cost/organization/${organizationId}/dashboards?private=${isPrivate}`, payload, "POST")

    return response
}

export async function saveCustomDashboardS(organizationId: number, dashboard: any, isDefault: boolean) {
    
    let url = `/cost/organization/${organizationId}/dashboard`;
    let response: any

    const formData = new FormData();

    const previewUrlBlob = new Blob([dashboard.previewUrl]);
    formData.append('previewUrl', previewUrlBlob);

    // It is needed to create a json file to send the data
    // const previewUrl = dashboard.previewUrl;
    dashboard.previewUrl = null;
    const dashboardJson = new Blob([JSON.stringify(dashboard)], {
        type: 'application/json',
    });
    formData.append('dashboard', dashboardJson);

    if (!dashboard.dashboardId) {
        url = isDefault ? url.concat(`?isDefault=true`) : url;
        response = await client(url, formData, "POST")

        return response
    } else {
        response = client (`${url}/${dashboard.dashboardId}`, formData, "PUT")
    }
}

export async function deleteDashhbord(organizationId: number, dashboardId: string) {
    let response = client(`/cost/organization/${organizationId}/dashboard/${dashboardId}`, "", "DELETE")

    return response
}

export async function setDefaultDashboard(organizationId: number, dashboardId: string) {
    let response = client(`/cost/organization/${organizationId}/dashboard/default/${dashboardId}`, "", "PUT")

    return response
}

export async function getDashboardById(organizationId: any, dashboardId: any) {
    let response = client(`/cost/organization/${organizationId}/dashboard/${dashboardId}`, "", "GET")

    return response
}

export async function getCostByTags(payload: IDimensionCostRequest[], providerType: string) {
    let response = client(`/cost/tag/${providerType}`, payload, "POST")

    return response
}
