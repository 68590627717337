import React, { useMemo, useState,useEffect } from 'react'
import '../../assets/scss/_inventory.scss'
import {
  fetchInventoryData,
  getProviderDetails,
  getinventoryFilterData,
  saveInventoryTag
} from '../../service/managementService'
import {
  IOrganizationData,
  ProviderDetail,
  defaultOrganization
} from '../../interface/organization'
import {
  ProviderSelect,
  ProviderSelectProps
} from '../ResuableComponents/ProviderSelect'
import { ScopeSelect, ScopeSelectProp } from '../ResuableComponents/ScopeSelect'
import { OrganizationSelect } from '../ResuableComponents/OrganizationSelect'
import Header from '../Header/Header'
import { debounce } from 'lodash'
import { Select, Tooltip } from 'antd'
import { MultiSelect } from 'react-multi-select-component'
import $ from 'jquery'
import { useSelector } from 'react-redux'
import { padWithALeadingZero } from '../../utils/numberUtils'
var XLSX = require('xlsx')

interface Workbook {
  Sheets: { [sheetName: string]: any }
  SheetNames: string[]
}

interface ITagsDetail {
    tagKey: "",
    tagValue: ""
}

export default function Inventory () {
  const AWStagsNotAllowedTypes = [
    'SimpleEmailService',
    'Amplify',
    'GaurdDuty',
    'SecurityHub'
  ]
  const AzuretagsNotAllowedtypes = [
    'ActionGroup',
    'Solution',
    'ApplicationGroup',
    'SQLDb',
    'SharedDashBoard',
    'WafPolicy',
    'RouteTable'
  ]

  let queryParams = {
    skip: 0,
    limit: 10,
    order: 1,
    sort: '',
    search: ''
  }

  let tagsDetail: ITagsDetail = {
    tagKey: '',
    tagValue: ''
  }
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    const tooltipList = tooltipTriggerList?.map((tooltipTriggerEl: Element) => {
      return new (window as any).bootstrap.Tooltip(tooltipTriggerEl)
    })

    return () => {
      tooltipList.forEach((tooltip: any) => tooltip.dispose())
    }
  }, [])

  const [organization, setOrganization] =
    useState<IOrganizationData>(defaultOrganization)
  const [providerType, setProviderType] = useState('')

  const [inventoryData, setInventoryData] = useState<any>({})
  const [selectedProviderDetails, setSelectedProviderDetails] = useState<any>(
    {}
  )
  const [dataRetrivalLoader, setDataRetrivalLoader] = useState<boolean>(false)
  const [showPageLoader, setShowPageLoader] = useState<boolean>(true)
  const [isCheck, setIsCheck] = useState<any>([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [selectedTags, setSelectedTags] = useState<any>({})
  const [keyTags, setKeyTags] = useState<ITagsDetail>(tagsDetail)
  const [tags, setTags] = useState<any[]>([])
  const [nameRequired, setNameRequired] = useState<boolean>(false)
  const [valueRequired, setValueRequired] = useState<boolean>(false)
  const [addSecretButton, setAddSecretButton] = useState<boolean>(true)
  const [isSaveButton, setIsSaveButton] = useState<boolean>(true)
  const [inventoryFilterData, setInventoryFilterData] = useState<any>([])
  const [filterTypeValue, setFilterTypeValue] = useState<string>('')
  const [filterValues, setFilterValues] = useState<any>([])
  const [selectedValue, setselectedValue] = useState<any>([])
  const [definedFilterValue, setDefinedFilterValue] = useState<any>([])
  const role = useSelector((state: any) => state.role.role)

  const [providerTypeSelectProps, setProviderTypeSelectProps] =
    useState<ProviderSelectProps>({
      organizationId: 0,
      providerTypes: [],
      onProviderTypeChange: (organizationId, providerType) => {}
    })

  const onOrganizationChange = async (
    selectedOrganization: IOrganizationData
  ) => {
    setProviderTypeSelectProps({
      organizationId: selectedOrganization.organizationId,
      onProviderTypeChange: onProviderTypeChange,
      providerTypes: selectedOrganization.providerType
    })
    setOrganization(selectedOrganization)
  }

  const onProviderTypeChange = async (
    organizationId: number,
    selectedProviderType: string
  ) => {
    setProviderType(selectedProviderType)

    let providerPayload = {
      page: 1000,
      searchText: '',
      sortBy: '',
      orderBy: '',
      providerType: selectedProviderType
    }

    setShowPageLoader(true)

    const providerDetailsResponse = await getProviderDetails(
      organizationId,
      providerPayload
    )
    if (providerDetailsResponse.statusCode === 200) {
      let providerId =
        providerDetailsResponse.data.providerdetails[0].providerId
      setSelectedProviderDetails(providerDetailsResponse.data)

            const inventoryFilterResponse = await getinventoryFilterData(
                selectedProviderType, organizationId)

      if (inventoryFilterResponse.statusCode === 200) {
        setInventoryFilterData(inventoryFilterResponse.data)
      }

            await getInventoryData(organizationId, selectedProviderType, 0, 10, 1, "", "",[],"")
        }

    setIsCheck([])
    setSelectedTags({})
    setIsCheckAll(false)
  }

  const organziationSelectComponent = useMemo(
    () => <OrganizationSelect onOrganizationChange={onOrganizationChange} />,
    []
  )

    const providerSelectComponent = useMemo(
        () => <ProviderSelect {...providerTypeSelectProps} />,
        [providerTypeSelectProps]
    );

    const getInventoryData = async (organizationId: number, providerType: string, skip: number, limit: number, sortOrder: number, sort: string, search: string, definedFilterValue : [], filterTypeValue : string ) => {
        setDataRetrivalLoader(true)
        setShowPageLoader(true)

        const inventoryDataResponse = await fetchInventoryData(organizationId, providerType, skip, limit, queryParams.order, queryParams.sort, queryParams.search, definedFilterValue, filterTypeValue)

    if (inventoryDataResponse.statusCode === 200) {
      setInventoryData(inventoryDataResponse.data)
    }

    setShowPageLoader(false)
    setDataRetrivalLoader(false)
  }

  const loadMore = async () => {
    setShowPageLoader(true)
    queryParams.skip = inventoryData?.inventoryData.length

        const inventoryDataResponse = await fetchInventoryData(organization.organizationId, providerType, queryParams.skip, queryParams.limit, queryParams.order, queryParams.sort, queryParams.search, definedFilterValue, filterTypeValue)

    if (inventoryDataResponse.statusCode === 200) {
      const newData = [...(inventoryData?.inventoryData || [])]
      inventoryDataResponse.data.inventoryData.forEach((item: any) => {
        newData.push(item)
      })
      setInventoryData((prevState: any) => ({
        ...prevState,
        inventoryData: newData
      }))
    }
    setShowPageLoader(false)
  }

    const handleDownload = async () => {
        const inventoryDataResponse = await fetchInventoryData(organization.organizationId, providerType, 0, 0, -1, "", "", [], filterTypeValue);
        const excelData = inventoryDataResponse.data.inventoryData;

    const filteredData = excelData?.filter((item: any) => {
      return Object.values(item).some(value => value !== null && value !== '')
    })

    const cleanedData = filteredData.map((item: any) => {
      Object.entries(item).forEach(([key, value]) => {
        if (value === '' || value === null) {
          delete item[key]
        }
      })
      return item
    })

    const groupedData = groupByType(cleanedData)

    let fileName = `${providerType} Inventory-Info.xlsx`
    let workbook: Workbook = { Sheets: {}, SheetNames: [] }

    for (const someType in groupedData) {
      if (groupedData.hasOwnProperty(someType)) {
        const sheet = XLSX.utils.json_to_sheet(groupedData[someType])
        workbook.Sheets[someType] = sheet
        workbook.SheetNames.push(someType)
      }
    }

    XLSX.writeFile(workbook, fileName)
  }

  const groupByType = (data: any) => {
    return data.reduce((result: any, item: any) => {
      const key = item.type
      result[key] = result[key] || []
      result[key].push(item)
      return result
    }, {})
  }

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll)
    if (!isCheckAll) {
      const allIndices = Array.from(
        { length: inventoryData.inventoryData.length },
        (_, index) => index
      ).filter(
        index =>
          !AzuretagsNotAllowedtypes.includes(
            inventoryData.inventoryData[index].type
          )
      )
      setIsCheck(allIndices)
      setSelectedTags(
        inventoryData.inventoryData.reduce(
          (acc: { [x: string]: any }, _: any, index: string | number) => {
            if (
              !AzuretagsNotAllowedtypes.includes(
                inventoryData.inventoryData[index].type
              )
            ) {
              acc[index] = inventoryData.inventoryData[index]
            }
            return acc
          },
          {}
        )
      )
      if (allIndices.length === 0) {
        setIsCheckAll(false)
      }
    } else {
      setIsCheck([])
      setSelectedTags({})
    }
  }

  const handleSelect = (index: number) => {
    if (isCheck.includes(index)) {
      setIsCheck(isCheck.filter((item: any) => item !== index))
      setSelectedTags((prevTags: any) => {
        const updatedTags = { ...prevTags }
        delete updatedTags[index]
        return updatedTags
      })
    } else {
      setIsCheck([...isCheck, index])
      setSelectedTags((prevTags: any) => ({
        ...prevTags,
        [index]: inventoryData.inventoryData[index]
      }))
    }
  }

  const handleTagValueChange = (id: string, value: string) => {
    setKeyTags((prevData: any) => {
      const updatedKeys = { ...prevData, [id]: value }

            if (id === "tagKey") {
                if (value === "") {
                    setNameRequired(true)
                }
                else if (value !== "") {
                    setNameRequired(false)
                }
            }

            if (id === "tagValue") {
                if (value === "") {
                    setValueRequired(true)
                }
                else if (value !== "") {
                    setValueRequired(false)
                }
            }

            if (updatedKeys.tagKey !== "" && updatedKeys.tagValue !== "") {
                setAddSecretButton(false)
            }
            else {
                setAddSecretButton(true)
            }

      return updatedKeys
    })
  }

  const handleAddTagValues = () => {
    setTags((prevTags: any[]) => [...prevTags, keyTags])

    setAddSecretButton(true)
    setIsSaveButton(false)

        setKeyTags(
            {
                tagKey: "",
                tagValue: ""
            }
        )

    };

    const handleCancelButton = () => {
        setKeyTags({
            tagKey: "",
            tagValue: ""
        })

    setNameRequired(false)
    setValueRequired(false)
    setTags([])
    setIsCheck([])
    setSelectedTags({})
    setIsCheckAll(!isCheckAll)
  }

    const handleSaveTags = async () => {
        setShowPageLoader(true)

       
        const dataArray = Object.values(selectedTags);    

        
        let payload = {
            accountId: selectedProviderDetails?.providerdetails[0].accountId,
           
            data: dataArray,
            secretName: selectedProviderDetails?.providerdetails[0].secretName,
            subscriptionId: selectedProviderDetails?.providerdetails[0].subscriptionId,
            tags: tags,
            tenantId: selectedProviderDetails?.providerdetails[0].tenantId
        }

    const saveTagResponse = await saveInventoryTag(
      payload,
      organization.organizationId,
      providerType
    )

    if (saveTagResponse.statusCode === 200) {
    }

        setKeyTags({
            tagKey: "",
            tagValue: ""
        })

    setTags([])
    setIsCheck([])
    setSelectedTags({})
    setIsCheckAll(!isCheckAll)

    setShowPageLoader(false)
  }

  const handleSort = (sort: string, order: string) => {
    queryParams.sort = sort
    queryParams.order = parseInt(order)

        getInventoryData(organization.organizationId, providerType, queryParams.skip, queryParams.limit, queryParams.order, queryParams.sort, queryParams.search, [], "")
    }

  const handleSearch = (value: string) => {
    queryParams.search = value

        getInventoryData(organization.organizationId, providerType, queryParams.skip, queryParams.limit, queryParams.order, queryParams.sort, queryParams.search,[],"")
    }

  const debouncedSearch = debounce(handleSearch, 1000)

    const handleFilterValue = (value: string) => {
        setDefinedFilterValue([])
        setFilterValues([])
        setselectedValue([])
        
        setFilterTypeValue(value)
        if(value === "name") {
            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                inventoryFilterData?.name?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
        else if (value === "type") {
            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                inventoryFilterData?.type?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
        else if (value === "location") {
            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                inventoryFilterData?.location?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
        else if (value === "accountId") {
            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                inventoryFilterData?.accountId?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
        else if (value === "resourceGroup") {
            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                inventoryFilterData?.resourceGroup?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
        else if (value === "subscriptionId") {
            setFilterValues((prevFilterValues: any) => {
                const newFilterValues: any = [];
                inventoryFilterData?.subscriptionId?.forEach((obj: any) => {
                    newFilterValues.push({
                        label: obj,
                        value: obj,
                    });
                });
                return newFilterValues;
            });
        }
    }

  const handletagsDelete = (keyToRemove: string) => {
    setTags((prevTags: any) => {
      const updatedTags = { ...prevTags }
      delete updatedTags[keyToRemove]
      return updatedTags
    })
  }

    const handleOptionSelect = (value: string[]) => {
        setselectedValue(value)

        setDefinedFilterValue(value)
    }

    const handleApply = () => {
        getInventoryData(organization.organizationId, providerType, queryParams.skip, queryParams.limit, queryParams.order, queryParams.sort, queryParams.search, definedFilterValue, filterTypeValue)
    }

    const handleFilterCancel = () => {
        getInventoryData(organization.organizationId, providerType, queryParams.skip, queryParams.limit, queryParams.order, queryParams.sort, queryParams.search, [],"")
        setDefinedFilterValue([])
        setFilterValues([])
        setselectedValue([])
        setFilterTypeValue("null")
    }

  const bindAwsInventory = () => {
    return inventoryData?.inventoryData?.map(
      (eachInventory: any, index: number) => (
        <tr key={index}>
          <td className=' check-cust-tbl-sty'>
            <span className='form-check tbl-check'>
              <input
                disabled={AWStagsNotAllowedTypes.includes(eachInventory.type)|| role === 'BillingReader'}
                className='form-check-input custom-checked box-shadow-none tbl-check-sty'
                type='checkbox'
                checked={isCheck.includes(index)}
                onClick={e => {
                  handleSelect(index)
                }}
              />
            </span>
          </td>
          <td className='grid-primary-text'>{eachInventory?.name}</td>
          <td>{eachInventory?.type}</td>
          <td>
            {' '}
            <span>{eachInventory?.location}</span>
          </td>
          
            <td>
            <Tooltip title={eachInventory?.accountId}><span>{eachInventory?.accountId?.slice(0, 10)}</span>
          </Tooltip></td>
        </tr>
      )
    )
  }

    const bindAzureInventory = () => {
        return inventoryData?.inventoryData?.map((eachInventory: any, index: number) => (
            <tr key={index}>
                <td className=" check-cust-tbl-sty">
                    <span className="form-check tbl-check">
                        <input
                            className="form-check-input custom-checked box-shadow-none tbl-check-sty"
                            type="checkbox"
                            checked={isCheck.includes(index)}
                            onClick={(e) => { handleSelect(index) }} 
                            disabled={AzuretagsNotAllowedtypes.includes(eachInventory.type) || role === 'BillingReader'} />
                    </span>
                </td>
                <td className="grid-primary-text">
                    {eachInventory?.name}
                </td>
                <td>{eachInventory?.type}</td>
                <td> 
                    <Tooltip title = {eachInventory?.resourceGroup}>
                        {eachInventory?.resourceGroup?.slice(0,10)}
                    </Tooltip>
                </td>
                <td>{eachInventory?.location}</td>
                <td>
                    <Tooltip title = {eachInventory?.subscriptionId}>
                        {eachInventory?.subscriptionId?.slice(0,10)}
                    </Tooltip>
                </td>
            </tr>
        ))
    }

  const bindAwsAddTag = () => {
    return Object.keys(selectedTags).map(key => {
      const eachData = selectedTags[key]
      return (
        <tr key={key}>
          <td className='grid-primary-text'>{eachData.name}</td>
          <td>{eachData.type}</td>
          <td>{eachData.location}</td>
          <td>{eachData.accountId}</td>
        </tr>
      )
    })
  }

  const bindAzureAddTag = () => {
    return Object.keys(selectedTags).map(key => {
      const eachData = selectedTags[key]
      return (
        <tr key={key}>
          <td className='grid-primary-text'>{eachData.name}</td>
          <td>{eachData.type}</td>
          <td>{eachData.location}</td>
          <td>{eachData.accountId}</td>
        </tr>
      )
    })
  }

    const bindAddedTags = () => {
        if (Object.keys(tags).length > 0) {
            return Object.keys(tags).map((eachTags: any, index: any) => (
                <React.Fragment key = {index}>
                    <span className=" rounded-2 multi-select-bubble me-2 mb-2" key={index}>
                        {`${tags[eachTags].tagKey} : ${tags[eachTags].tagValue}`}
                        <a onClick={() => {handletagsDelete(index)}}>
                            <span className="ms-2 batch-close-img">
                            </span>
                        </a>
                    </span>
                </React.Fragment>
            ));
        } else {
            return null;
        }
    };

    return (
        <div>
            <Header />
            <div className="container-fluid">
                {/* <!-- Inventory starts here --> */}
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="d-lg-flex d-md-block justify-content-between align-items-center my-4">
                            <h5 className="page-header mb-lg-0 res-margin-20">Cloud Inventory</h5>
                            <div className="d-md-flex d-sm-block">
                                <div className="dropdown res-margin-20 me-md-4 app-organization-select">
                                    {organziationSelectComponent}
                                </div>
                                {providerSelectComponent}
                                {/* <!--Assign Tag Popup starts here--> */}
                                <div>
                                    {/* <!-- Assign Tags button modal starts here --> */}
                                    <button type="button"
                                        className={`px-3 db-custom-input shadow-none me-4 ms-3 ${Object.keys(selectedTags).length === 0 ? 'assign-tag-disabled' : ''}`}
                                        data-bs-toggle="modal"
                                        data-bs-target="#AssignTags-popup"
                                        disabled={(Object.keys(selectedTags).length === 0) || (role === "BillingReader")}>
                                        <span className="d-flex align-items-center">
                                            <span className="me-2 tag-icon"></span>
                                            <span className="d-none d-lg-inline-block">Assign Tags</span>
                                        </span>
                                    </button>
                                    {/* <!-- Assign Tags button modal ends here --> */}
                                    {/* <!--Assign Tags Modal Starts here--> */}
                                    <div className="modal fade" id="AssignTags-popup" data-bs-backdrop="static" tabIndex={-1}
                                        aria-hidden="true">
                                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                            <div className="modal-content popup-sty px-3 border-0 custom-border-radius">
                                                <div className="modal-header border-0">
                                                    <h5 className="modal-title custom-header-style">Assign Tags</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body pt-0">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/* <!--Table section starts here--> */}
                                                            {providerType === "AWS" ? (
                                                                <div className="table-responsive assign-tag-scrl mb-4">
                                                                    <table className="table table-borderless custom-grid my-0">
                                                                        <thead className="custom-table-head sticky-top">
                                                                            <tr>
                                                                                <th className="custom-grid-font-weight">NAME</th>
                                                                                <th className="custom-grid-font-weight">TYPE</th>
                                                                                <th className="custom-grid-font-weight">LOCATION</th>
                                                                                <th className="custom-grid-font-weight">ACCOUNT ID</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {bindAwsAddTag()}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ) : (
                                                                <div className="table-responsive assign-tag-scrl mb-4">
                                                                    <table className="table table-borderless custom-grid my-0">
                                                                        <thead className="custom-table-head sticky-top">
                                                                            <tr>
                                                                                <th className="custom-grid-font-weight">NAME</th>
                                                                                <th className="custom-grid-font-weight">TYPE</th>
                                                                                <th className="custom-grid-font-weight">RESOURCE GROUP</th>
                                                                                <th className="custom-grid-font-weight">LOCATION</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {bindAzureAddTag()}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )}
                                                            {/* <!--InlineTable section ends here--> */}
                                                        </div>
                                                        <div className="col-md-12">
                                                            <h5 className="modal-title custom-header-style mb-2">Tags</h5>
                                                            <label className="custom-pop-label mb-3">Tags are name/Value pairs that
                                                                enables you to categorize resources and view consolidated
                                                                billing by applying the same tag to
                                                                multiple resources and resource groups</label>
                                                        </div>
                                                        <div className="col-md-5 mb-3 pe-5">
                                                            <label className="w-100 form-label custom-label" htmlFor="Name"> Name
                                                            <span
                                                            data-bs-toggle='tooltip'
                                                            data-bs-placement='right'
                                                            title=''
                                                            data-bs-original-title='Tag Names are case sensitive and are limited to 512 characters'
                                                            >
                                                              <img
                                                              className='ms-1'
                                                              src='images/information-icon.svg'
                                                              alt='info'
                                                              data-bs-toggle='tooltip'
                                                              data-bs-placement='top'  
                                                              />
                                                            </span>
                                                            </label>
                                                            <input className="w-100 custom-input form-control py-2 px-2" type="text"
                                                                placeholder="Enter Name" id="tagKey"
                                                                value={keyTags.tagKey}
                                                                maxLength={513}
                                                                onChange={(e) => { handleTagValueChange(e.target.id, e.target.value) }}
                                                                onBlur={(e) => { setNameRequired(e.target.value === "") }} />
                                                            {nameRequired &&
                                                                <span className="text-danger d-block error-span">
                                                                    Name is required
                                                                </span>
                                                            }
                                                            {keyTags.tagKey.length>512 &&
                                                                <span className="text-danger d-block error-span">
                                                                    Tag Name should not be more than 512 characters
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-md-5 mb-3 ps-4" >
                                                            <label className="w-100 form-label custom-label" htmlFor="Value">Value <span
                                                            data-bs-toggle='tooltip'
                                                            data-bs-placement='right'
                                                            title=''
                                                            data-bs-original-title='Tag Values are case sensitive and are limited to 256 characters'
                                                            >
                                                              <img
                                                              className='ms-1'
                                                              src='images/information-icon.svg'
                                                              alt='info'
                                                              data-bs-toggle='tooltip'
                                                              data-bs-placement='top'  
                                                              />
                                                            </span></label>
                                                            <input className="w-100 custom-input form-control py-2 px-2" type="text"
                                                                placeholder="Enter Value" id="tagValue"
                                                                value={keyTags.tagValue}
                                                                maxLength={257}
                                                                onChange={(e) => { handleTagValueChange(e.target.id, e.target.value) }}
                                                                onBlur={(e) => { setValueRequired(e.target.value === "") }} />
                                                            {valueRequired &&
                                                                <span className="text-danger d-block error-span">
                                                                    Value is required
                                                                </span>
                                                            }
                                                            {keyTags.tagValue.length>256 &&
                                                                <span className="text-danger d-block error-span">
                                                                   Tag Value should not be more than 256 characters
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-md-2 px-md-0 d-flex justify-content-center pt-2">
                                                            <button type="button" className="btn primary-btn mt-4" disabled={addSecretButton} onClick={() => { handleAddTagValues() }}>Add</button>
                                                        </div>
                                                        <div className="col-md-12">
                                                            {bindAddedTags()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer border-0 pb-4 pt-3">
                                                    <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" onClick={() => { handleCancelButton() }}>Cancel</button>
                                                    <button type="button" className="btn primary-btn ms-3" data-bs-dismiss="modal" disabled={isSaveButton} onClick={() => { handleSaveTags() }}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--Assign Tags Modal ends here--> */}
                                </div>
                                <div className="d-md-flex align-items-center res-margin-20">
                                    <div className="input-group me-3">
                                        <input type="text"
                                            className="form-control text-field border-radius font-14 font-medium pe-5 custom-input"
                                            id="search" placeholder="Search" onChange={(e) => { debouncedSearch(e.target.value) }} />
                                        <span className="inputfield-icon-sty"><a href="#"><img src="images/search.svg"
                                            alt="Search icon" className="close-icon" /></a></span>
                                    </div>
                                </div>
                                <div className="dropdown res-margin-20 d-lg-block d-none">
                                    <button
                                        className="btn secondary-btn filter-icon-sty db-filter-btn px-2 d-flex align-items-center"
                                        data-bs-toggle="dropdown" aria-expanded="false" id="inventoryFilter">
                                        <span className="filter-img"></span>
                                    </button>
                                    <div className="dropdown-menu filter-dropdown p-3">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="table-inner-header mb-3">Advanced Filter</h6>
                                            <button id="inventoryFilter" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {$('#inventoryFilter').trigger('click')}}>
                                            </button>
                                        </div>
                                        <div className="row" onClick={(e) => e.stopPropagation()}>
                                            <div className="col-md-12 col-sm-12 mb-3">
                                                <label htmlFor="Filter" className="form-label custom-label">Filter</label>
                                                {providerType === "AWS" ? (
                                                    <>
                                                        <select className="custom-input py-2 w-100 px-3 form-select" id="Filter" value = {filterTypeValue} onChange={(e) => {handleFilterValue(e.target.value)}}>
                                                            <option value ="null">Choose Filter</option>
                                                            <option value ="name">NAME</option>
                                                            <option value ="type">TYPE</option>
                                                            <option value ="location">LOCATION</option>
                                                            <option value ="accountId">ACCOUNT ID</option>
                                                        </select>
                                                        <span className="text-danger d-block error-span">
                                                            {""}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <select className="custom-input py-2 w-100 px-3 form-select" id="Filter" value = {filterTypeValue} onChange={(e) => {handleFilterValue(e.target.value)}}>
                                                            <option value= "null">Choose Filter</option>
                                                            <option value = "name">NAME</option>
                                                            <option value = "type">TYPE</option>
                                                            <option value = "location">LOCATION</option>
                                                            <option value = "resourceGroup">RESOURCE GROUP</option>
                                                            <option value = "subscriptionId">SUBSCRIPTION ID</option>
                                                        </select>
                                                        <span className="text-danger d-block error-span">
                                                            {""}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-md-12 col-sm-12 mb-3">
                                                <label htmlFor="FilterData" className="form-label custom-label">Values</label>
                                                <div className='w-100 ' onClick={(e) => {e.stopPropagation()}}>
                                                <Select
                                                        className='multiselect-dropdown'
                                                        options={filterValues}
                                                        value={selectedValue}
                                                        onChange={handleOptionSelect}
                                                        allowClear
                                                        mode="multiple"
                                                        placeholder="Select Values"
                                                        showSearch={false}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-end mt-4">
                                                    <button type="button" className="btn me-3 secondary-btn" onClick={() => {handleFilterCancel(); $('#inventoryFilter').trigger('click')}}>Cancel</button>
                                                    <button type="button" onClick={() => {handleApply(); $('#inventoryFilter').trigger('click')}}
                                                        className="btn primary-btn">Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn secondary-btn mb-3" onClick={() => handleDownload()}>
                                <span className="d-flex align-items-center">
                                    <span className="me-1 dwn-icon"></span>
                                    <span className="d-none d-lg-inline-block">Download CSV</span>
                                </span>
                            </button>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <div className="accordion body-bg">
                                    {/* <!-- first solution starts --> */}
                                    <div className="custom-accordion-item accordion-item border-0 body-bg">
                                        {providerType === "Azure" ? (
                                            <div className="accordion-header mt-1 pb-1 table-responsive">
                                                <table className="table table-borderless custom-grid ">
                                                    <thead className="custom-table-head">
                                                        <tr>
                                                            <th
                                                                className="custom-grid-font-weight position-relative check-cust-tbl-sty">
                                                                <span className="form-check tbl-check">
                                                                    <input
                                                                        className="form-check-input custom-checked box-shadow-none tbl-check-sty"
                                                                        type="checkbox"
                                                                        checked={isCheckAll}
                                                                        disabled={role === 'BillingReader'}
                                                                        onChange={() => handleSelectAll()} />
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">NAME </th>
                                                            <th className="custom-grid-font-weight position-relative">TYPE
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("service_type", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("service_type", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">RESOURCE GROUP
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("data.resourceGroup", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("data.resourceGroup", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">LOCATION
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("data.location", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("data.location", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">SUBSCRIPTION
                                                                ID
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("data.subscriptionId", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("data.subscriptionId", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bindAzureInventory()}
                                                    </tbody>
                                                    {inventoryData.count === 0 &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={8} className="text-center">
                                                                    <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                                        className="no-data-found-sty mb-4" />
                                                                    <p className="para-text">No data found</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="accordion-header mt-1 pb-1 table-responsive">
                                                <table className="table table-borderless custom-grid ">
                                                    <thead className="custom-table-head">
                                                        <tr>
                                                            <th
                                                                className="custom-grid-font-weight position-relative check-cust-tbl-sty">
                                                                <span className="form-check tbl-check">
                                                                    <input
                                                                        className="form-check-input custom-checked box-shadow-none tbl-check-sty"
                                                                        type="checkbox"
                                                                        checked={isCheckAll}
                                                                        disabled={role === 'BillingReader'}
                                                                        onChange={() => handleSelectAll()} />
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">NAME</th>
                                                            <th className="custom-grid-font-weight position-relative">TYPE
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("service_type", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("service_type", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">LOCATION
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("data.location", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("data.location", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                            <th className="custom-grid-font-weight position-relative">ACCOUNT ID
                                                                <span className="sorting">
                                                                    <a className="sortup" onClick={() => { handleSort("data.accountId", "1") }}><span
                                                                        className="sort-icon sortup-img"></span></a>
                                                                    <a className="sortdown" onClick={() => { handleSort("data.accountId", "-1") }}><span
                                                                        className="sort-icon sortdown-img"></span></a>
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bindAwsInventory()}
                                                    </tbody>
                                                    {inventoryData?.count === 0 &&
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={8} className="text-center">
                                                                    <img src="images/no-rec-found.gif" alt="no-rec-found"
                                                                        className="no-data-found-sty mb-4" />
                                                                    <p className="para-text">No data found</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }
                                                </table>
                                            </div>
                                        )}
                                            <p className="mb-3 mt-2 para-text"># of Records: <span className="primary-color">{
                                                padWithALeadingZero(inventoryData?.inventoryData?.length)} out of {padWithALeadingZero(inventoryData?.count)}</span>
                                            </p>
                                        {!(inventoryData?.inventoryData?.length < 9 || (inventoryData.inventoryData?.length === inventoryData.count)) &&
                                            <div className="row justify-content-center" onClick={() => { loadMore() }}>
                                                <button type="button" className="btn secondary-btn mt-2 mb-5">Load
                                                    More</button>
                                            </div>
                                        }
                                    </div>
                                    {/* <!-- table ends  --> */}
                                </div>
                            </div>
                        </div>
                        {dataRetrivalLoader &&
                            <div className="text-center mt-5">
                                <img src="gif/CCM-Loader.gif" alt="white-loader" className="cloud-page-loader" />
                                <h3 className="sub-header">"Data retrieval is in progress, and it may take a little while. We appreciate
                                    your patience!"</h3>
                            </div>
                        }
                        {showPageLoader && (
                            <div className="container-fluid">
                                <div
                                    className="modal-backdrop fade show"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-hidden="true"
                                >
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <img
                                            src="images/cloud-load-gif.gif"
                                            alt="white-loader"
                                            className="page-loader"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* <!-- inventory ends here --> */}
            </div>
        </div>
    )
};