import { useEffect, useState } from "react";
import Header from "../Header/Header";
import { useEncryptStorage } from "../../hooks/useEncryptStorage";
import { getOrganization } from "../../service/managementService";
import { Role } from "../../enum/Role";
import { IOrganizationData } from "../../interface/organization";
import { useNavigate } from "react-router-dom";
import { IAWSSecret, IAwsProviderPayload, IAzureProviderDetails, IAzureSecret, IEditUserDetail, IFilterRequest, INewUserDetails, IProviderPayload, IProviderResponse, ISecretKey, ISecretKeyResponse, IUserDetails, IUserDetailsResponse } from "../../interface/manage";
import { createUser, deleteUserData, getUserByEmail, getUsers, updateUser } from "../../service/userService";
import { fetchProviders, fetchTestConnection, updateProviderDetails } from "../../service/providerService";
import { fetchSecretKeyDetails, postSecretKeyDetails } from "../../service/secretservice";

export default function Check() {

    let userDetailsObj: IUserDetailsResponse = {
        count: 0,
        users: [],
    };

    let editUserObj: IEditUserDetail = {
        "emailId": "",
        "loginId": null,
        "name": "",
        "organizationId": null,
        "organizationName": "",
        "roleId": null,
        "roleName": "",
        "status": "",
    }

    let azureProviderPayload: IAzureProviderDetails = {
        connectorName: "",
        description: "",
        organizationId: 0,
        provider: "",
        secretId: 0,
        secretName: "",
        subscriptionId: "",
        tenantId: "",
        providerId: 0
    };

    let secretKeyResponseObj: ISecretKeyResponse = {
        secretcount: 0,
        secretdetails: []
    }

    let providerResponse: IProviderResponse = {
        providercount: 0,
        providerdetails: [],
    };

    let azureAddProviderObject: IAzureSecret = {
        organizationId: 0,
        providerType: "",
        secretId: 0,
        secretName: "",
        values: {
            clientid: "",
            clientSecret: ""
        }
    }

    let secretKeyObj: ISecretKey = {
        organizationId: null,
        page: null,
        providerType: "",
        searchText: ""
    }

    
    let organizationData: IOrganizationData = {
        logoUrl: "",
        organizationId: 0,
        organizationName: "",
        parentOrganizationId: 0,
        providerType: [],
        roleId: 0,
        roleName: ""
    }

    let awsProviderPayload: IAwsProviderPayload = {
        accountId: "",
        connectorName: "",
        description: "",
        isLinkedAccount: false,
        organizationId: 0,
        provider: "",
        secretId: 0,
        secretName: "",
        providerId: 0
    }

    let awsAddProviderObject: IAWSSecret = {
        secretId: 0,
        secretName: "",
        providerType: "",
        organizationId: 0,
        values: {
            accessKey: "",
            secretKey: ""
        }
    }
    
    const [organization, setOrganization] = useState<IOrganizationData>(organizationData)
    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [userDetails, setUserDetails] = useState<IUserDetailsResponse>(userDetailsObj);
    const [isNameRequired, setIsNameRequired] = useState<boolean>(false)
    const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false)
    const [emailExistError, setEmailExistError] = useState<boolean>(false)
    const [enterValidMail, setEnterValidMail] = useState<boolean>(false)
    const [selectedRole, setSelectedRole] = useState<string>("")
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const pageLimit = 10
    const [totalCount, setTotalCount] = useState<number>(0)
    const [isRoleRequired, setIsRoleRequired] = useState<boolean>(false)
    const [editIndexValue, setEditIndexValue] = useState<number | null>(null);
    const [cloneUserDetails, setCloneUserDetails] = useState<IUserDetailsResponse>(userDetailsObj);
    const [showOptionPopup, setShowOptionPopup] = useState<boolean>(true)
    const [showProviderSelectPopup, setShowProviderSelectPopup] = useState<boolean>(false)
    const [selectedProvider, setSelectedProvider] = useState<string>("")
    const [saveDetailsLoader, setSaveDetailsLoader] = useState<boolean>(false)

    const [showAzurePopup, setShowAzurePopup] = useState<boolean>(false)
    const [azureProviderDetails, setAzureProviderDetails] = useState<IAzureProviderDetails>(azureProviderPayload)
    const [azureConnectorNameRequired, setAzureConnectorNameRequired] = useState<boolean>(false)
    const [azureTenantIdRequired, setAzureTenantAccountIdRequired] = useState<boolean>(false)
    const [azureSubscriptionIdRequired, setAzureSubscriptionIdRequired] = useState<boolean>(false)
    const [azureSecretKeyRequired, setAzureSecretKeyRequired] = useState<boolean>(false)
    const [azureInValidTenantIdError, setAzureInValidTenantAccountIdError] = useState<boolean>(false)
    const [azureInValidSubscriptionIdError, setAzureInValidSubscriptionIdRequired] = useState<boolean>(false)
    const [isAzureTestConnectionButtonDiabled, setIsAzureTestConnectionButtonDiabled] = useState<boolean>(true)
    const [azureTestConnectionLoaderActive, setAzureTestConnectionLoaderActive] = useState<boolean>(false)
    const [isAzureSaveButtonDiabled, setIsAzureSaveButtonDiabled] = useState<boolean>(true)
    const [testConnectionSuccessPopup, setTestConnectionSuccessPopup] = useState<boolean>(false)
    const [showNotAddedErrorPopup, setShowNotAddedErrorPopup] = useState<boolean>(false)
    const [secretKeys, setSecretKeys] = useState<ISecretKeyResponse>(secretKeyResponseObj)
    const [showAzureAddSecretPopup, setShowAzureAddSecretPopup] = useState<boolean>(false)
    const [azureSaveLoaderActive, setAzureSaveLoaderActive] = useState<boolean>(false)
    const [showPageLoader, setShowPageLoader] = useState<boolean>(false); 
    const [providerDetails, setProviderDetail] = useState<IProviderResponse>(providerResponse); 
    const [azureAddSecretPayload, setAzureAddSecretPayload] = useState<IAzureSecret>(azureAddProviderObject)
    const [azureSecretNameRequired, setAzureSecretNameRequired] = useState<boolean>(false)
    const [azureClientIdRequired, setAzureClientIdRequired] = useState<boolean>(false)
    const [azureClientSecretKeyRequired, setAzureClientSecretKeyRequired] = useState<boolean>(false)
    const [azureAddProvderSaveLoader, setAzureAddProvderSaveLoader] = useState<boolean>(false)
    const [azureAddProvderSaveButton, setAzureAddProvderSaveButton] = useState<boolean>(true)

    const [showAWSPopup, setShowAWSPopup] = useState<boolean>(false)
    const [awsProviderDetails, setAwsProviderDetails] = useState<IAwsProviderPayload>(awsProviderPayload)
    const [awsConnectorNameRequired, setAwsConnectorNameRequires] = useState<boolean>(false)
    const [awsAccountIdRequired, setAwsAccountIdRequired] = useState<boolean>(false)
    const [awsValidAccountIdRequired, setAwsValidAccountIdRequired] = useState<boolean>(false)
    const [awsSecretKeyRequired, setAwsSecretKeyRequired] = useState<boolean>(false)
    const [isAwsTestConnectionButtonDiabled, setIsAwsTestConnectionButtonDiabled] = useState<boolean>(true)
    const [awsTestConnectionLoaderActive, setAwsTestConnectionLoaderActive] = useState<boolean>(false)
    const [isAwsSaveButtonDiabled, setIsAwsSaveButtonDiabled] = useState<boolean>(true)
    const [awsSaveLoaderActive, setAwsSaveLoaderActive] = useState<boolean>(false)
    const [awsAddSecretPayload, setAwsAddSecretPayload] = useState<IAWSSecret>(awsAddProviderObject)
    const [awsSecretNameRequired, setAwsSecretNameRequired] = useState<boolean>(false)
    const [awsAccessRequired, setAwsAccessRequired] = useState<boolean>(false)
    const [awsNewSecretKeyRequired, setAwsNewSecretKeyRequired] = useState<boolean>(false)
    const [awsAddProvderSaveLoader, setAwsAddProvderSaveLoader] = useState<boolean>(false)
    const [awsAddProvderSaveButton, setAwsAddProvderSaveButton] = useState<boolean>(true)
    const [showAWSAddSecretPopup, setShowAWSAddSecretPopup] = useState<boolean>(false)
    const [addedProviderSuccessPopup, setAddedProviderSuccessPopup] = useState<boolean>(false)
    const [userAddedSuccessPopup, setUserAddedSuccessPopup] = useState<boolean>(false)
    const [showAddProvider, setShowAddProvider] = useState<boolean>(false)


    let providerPayload: IProviderPayload = {
        page: 10,
        searchText: "",
        sortBy: "desc",
        orderBy: "",
        providerType: "",
    };

    let newUserObj: INewUserDetails = {
        emailId: "",
        name: "",
        organizationId: 0,
        organizationName: "",
        roleId: 0,
        status: "Inactive",
    };

    const [newUserDetails, setNewUserDetails] = useState<INewUserDetails>(newUserObj);

    let navigate = useNavigate()

    let filterRequest: IFilterRequest = {
        skip: 0,
        limit: 10,
        searchText: "",
        sortBy: "",
        orderBy: "",
    };

    

    useEffect(() => {
        checkProviderExist()
    }, [])

    async function checkProviderExist() {
        let organizationsResponse = await getOrganization();

        if(organizationsResponse){
            setOrganization(organizationsResponse.data[0])
    
            let userRoleId;
    
            userRoleId = organizationsResponse.data.find((eachOrganization: any) => eachOrganization.roleId === Role.BILLING_OWNER) ? Role.BILLING_OWNER
                : organizationsResponse.data.find((eachOrganization: any) => eachOrganization.roleId === Role.BILLING_ADMIN) ? Role.BILLING_ADMIN
                    : Role.BILLING_READER
    
            let organizationWithProviders = organizationsResponse.data.find((eachOrganization: any) => eachOrganization.providerType.length > 0);
    
            if (!organizationWithProviders) {
                setShowAddProvider(true)
                setOrganization(organizationsResponse.data.find((eachOrganization: any) => eachOrganization.providerType.length === 0))
    
                if (organization.roleId === Role.BILLING_OWNER || organization.roleId === Role.BILLING_ADMIN) {
                    setShowOptionPopup(true)
                }
            }
            else {
                navigate('/dashboard')
            }
        }
    }

    const handleNewUserChange = (e: any) => {

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        if (e.target.id === "name") {
            if (e.target.value === "") {
                setIsNameRequired(true)
            }
            else if (e.target.value !== "") {
                setIsNameRequired(false)
            }
        }

        if (e.target.id === "emailId" && e.target.value !== "") {
            setIsEmailRequired(false)
            if (emailRegex.test(e.target.value)) {
                setEnterValidMail(false)
            }
            else if (!(emailRegex.test(e.target.value))) {
                setEnterValidMail(true)
            }
        }
        else if (e.target.id === "emailId" && e.target.value === "") {
            setIsEmailRequired(true)
            setEnterValidMail(false)
            setEmailExistError(false)
        }

        setNewUserDetails((prevNewUserDetails: any) => {
            const updatedUserData = { ...prevNewUserDetails, [e.target.id]: e.target.value }

            return updatedUserData
        })
    }

    const checkIfUserExist = async (e: any) => {

        if (e.target.value !== "") {
            const userDetails = await getUserByEmail(e.target.value)

            if (userDetails.statusCode === 200) {
                setEmailExistError(true)
            }
            else if (userDetails.statusCode !== 200) {
                setEmailExistError(false)
            }
        }
        else if (e.target.value === "") {
            setEmailExistError(false)
            setIsEmailRequired(true)
        }
    }

    const handleNewUserDropDownChange = (e: any) => {

        setSelectedRole(e.target.value)

        let roleIdvalue = e.target.value

        setNewUserDetails((prevNewUserDetails: any) => {
            const updatedUserEmail = { ...prevNewUserDetails, "roleId": parseInt(roleIdvalue) }

            if (roleIdvalue === "Select Role") {
                setIsRoleRequired(true)
            }
            else {
                setIsRoleRequired(false)
            }
            return updatedUserEmail
        })
    }

    const handleNameChange = (value: string, index: number) => {
        const updatedUsers = [...userDetails.users];
        updatedUsers[index].name = value;
        setUserDetails(prevState => ({
            ...prevState,
            users: updatedUsers
        }));
    };

    const handleRoleChange = (value: string, index: number) => {
        const updatedUsers = [...userDetails.users];
        updatedUsers[index].roleName = value.split("@#$")[0];
        updatedUsers[index].roleId = parseInt(value.split("@#$")[1]);
        setUserDetails(prevState => ({
            ...prevState,
            users: updatedUsers

        }));

    };

    const editUserDetails = async (index: number) => {

        editUserObj.emailId = userDetails.users[index].emailId
        editUserObj.loginId = userDetails.users[index].loginId
        editUserObj.name = userDetails.users[index].name
        editUserObj.organizationId = organization.organizationId
        editUserObj.organizationName = organization.organizationName
        editUserObj.roleId = userDetails.users[index].roleId
        editUserObj.roleName = userDetails.users[index].roleName
        editUserObj.status = userDetails.users[index].status

        await updateUser(editUserObj)
        window.location.reload()
    }

    const onRemoveEdit = (index: number) => {
        setEditIndexValue(null)
        const updatedUsers = [...userDetails.users];
        updatedUsers[index].name = cloneUserDetails.users[index].name;
        updatedUsers[index].roleId = cloneUserDetails.users[index].roleId;
        updatedUsers[index].roleName = cloneUserDetails.users[index].roleName;

        // Update the state with the updated users array
        setUserDetails(prevState => ({
            ...prevState,
            users: updatedUsers
        }));
    }

    const addUser = async () => {
        const updatedUserDetails = {
            ...newUserDetails,
            organizationId: organization.parentOrganizationId,
            organizationName: organization.organizationName
        };

        if (updatedUserDetails.emailId && updatedUserDetails.name && updatedUserDetails.status) {
            setNewUserDetails(updatedUserDetails);
            const payload = [updatedUserDetails]
            const addUserResponse = await createUser(payload);

            if (addUserResponse.statusCode === 200) {
                setShowAddModal(false)
                setNewUserDetails(newUserObj)
                setSelectedRole("")
                users();
                setUserAddedSuccessPopup(true)
            }
        }
    };

    const users = async () => {
        setShowLoader(true)
        const getUsersResponse = await getUsers(filterRequest, organization.organizationId);
        setTotalCount(getUsersResponse.data.length)
        if (getUsersResponse.statusCode === 200) {
            setUserDetails(getUsersResponse.data);
            setCloneUserDetails(JSON.parse(JSON.stringify(getUsersResponse.data)));
        }
        setShowLoader(false)
    };

    const handleDeleteUser = async (deleteLoginId: number) => {
        let deletionPayload = {
            loginId: deleteLoginId,
            organizationId: organization.parentOrganizationId
        }

        const deleteUserResponse = await deleteUserData(deletionPayload)

        if (deleteUserResponse.statusCode === 200) {
            users()
        }
    }

    const handleProviderTypeSelect = () => {
        if(selectedProvider === "AWS") {
            setShowAWSPopup(true)
            setShowProviderSelectPopup(false)
            getSecretKeyDetails("AWS")
        }
        else if(selectedProvider === "Azure") {
            setShowAzurePopup(true)
            setShowProviderSelectPopup(false)
            getSecretKeyDetails("Azure")
        }
    }

    const handleSaveDetails = async() => {
        const getUsersResponse = await getUsers(filterRequest, organization.organizationId);

        if(getUsersResponse.statusCode === 200) {
            if(getUsersResponse.data.count > 0) {
                navigate('/dashboard')
            }
        }
    }

    const handleAzurePopupClose = () => {
        setShowAzurePopup(false)
        setShowProviderSelectPopup(true)
        setAzureProviderDetails(azureProviderPayload)
        setAzureConnectorNameRequired(false)
        setAzureInValidSubscriptionIdRequired(false)
        setAzureInValidTenantAccountIdError(false)
        setAzureSubscriptionIdRequired(false)
        setAzureTenantAccountIdRequired(false)
        setAzureSecretKeyRequired(false)
    }

    const handleAzureAddSecretClose = () => {

        let azureAddProviderObject: IAzureSecret = {
            secretId: 0,
            secretName: "",
            providerType: "",
            organizationId: 0,
            values: {
                clientid: "",
                clientSecret: ""
            }
        }

        setShowAzureAddSecretPopup(false);
        setShowAzurePopup(true)
        setAzureAddSecretPayload(azureAddProviderObject)
    }

    const handleAzureSecretAdd = async () => {
        setAzureAddProvderSaveLoader(true)
        let postSecretPayload = {
            organizationId: organization.organizationId,
            providerType: "Azure",
            secretId: 0,
            secretName: azureAddSecretPayload.secretName,
            values: JSON.stringify({
                clientid: azureAddSecretPayload.values.clientid,
                clientSecret: azureAddSecretPayload.values.clientSecret
            })
        }
        const updateSecretKeyResponse = await postSecretKeyDetails(postSecretPayload)

        if (updateSecretKeyResponse.statusCode === 200) {
            await getSecretKeyDetails("Azure")

            let azureAddProviderObject: IAzureSecret = {
                secretId: 0,
                secretName: "",
                providerType: "",
                organizationId: 0,
                values: {
                    clientid: "",
                    clientSecret: ""
                }
            }

            setAzureAddSecretPayload(azureAddProviderObject)
            setShowAzureAddSecretPopup(false)
            setShowAzurePopup(true)
        }

        setAzureAddProvderSaveLoader(true)
    }

    const getSecretKeyDetails = async (providerType: string) => {
        secretKeyObj.organizationId = organization.organizationId
        secretKeyObj.page = 1000
        secretKeyObj.providerType = providerType

        const secretKeyResponse = await fetchSecretKeyDetails(secretKeyObj)

        if (secretKeyResponse.statusCode === 200) {
            setSecretKeys(secretKeyResponse.data)
        }
    }

    const handleAzureProviderDetailsChange = (id: string, value: any) => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
        setAzureProviderDetails((prevData: any) => {
            let updatedAzureProviderDetails;
            if(id !== "secretId") {
                updatedAzureProviderDetails = { ...prevData, [id]: value }

                if(id === "connectorName") {
                    if(value === "") {
                        setAzureConnectorNameRequired(true)
                    }
                    else if (value !== "") {
                        setAzureConnectorNameRequired(false)
                    }
                }

                if(id === "tenantId") {
                    if(value === "") {
                        setAzureTenantAccountIdRequired(true)
                        setAzureInValidTenantAccountIdError(false)
                    }
                    else if (value !== "") {
                        setAzureTenantAccountIdRequired(false)
                        if (!(uuidRegex.test(value))) {
                            setAzureInValidTenantAccountIdError(true)
                        }
                        else if ((uuidRegex.test(value))) {
                            setAzureInValidTenantAccountIdError(false)
                        }
                    }
                }

                if(id === "subscriptionId") {
                    if(value === "") {
                        setAzureSubscriptionIdRequired(true)
                        setAzureInValidSubscriptionIdRequired(false)
                    }
                    else if (value !== "") {
                        setAzureSubscriptionIdRequired(false)
                        if (!(uuidRegex.test(value))) {
                            setAzureInValidSubscriptionIdRequired(true)
                        }
                        else if ((uuidRegex.test(value))) {
                            setAzureInValidSubscriptionIdRequired(false)
                        }
                    }
                }

            }
            else if (id === "secretId") {
                if (value !== "") {
                    setAzureSecretKeyRequired(false)
                    const secrets = value.split("#$%");
                    updatedAzureProviderDetails = {
                        ...prevData,
                        secretId: parseInt(secrets[0]),
                        secretName: secrets[1]
                    };
                }
                else if (value === "") {
                    updatedAzureProviderDetails = {
                        ...prevData,
                        secretId: 0,
                        secretName: ""
                    };
                    setAzureSecretKeyRequired(true)
                }
            }

            if (
                updatedAzureProviderDetails.connectorName !== "" &&
                updatedAzureProviderDetails.subscriptionId !== 0 &&
                updatedAzureProviderDetails.secretId !== 0 &&
                updatedAzureProviderDetails.tenantId !== 0 &&
                (uuidRegex.test(updatedAzureProviderDetails.tenantId)) &&
                (uuidRegex.test(updatedAzureProviderDetails.subscriptionId))
            ) {
                setIsAzureTestConnectionButtonDiabled(false)
                setIsAzureSaveButtonDiabled(false)
            }
            else {
                setIsAzureTestConnectionButtonDiabled(true)
                setIsAzureSaveButtonDiabled(true)
            }

            return updatedAzureProviderDetails
        })
    }

    const azureTestConnection = async () => {
        setAzureTestConnectionLoaderActive(true)
        azureProviderDetails.organizationId = organization.organizationId
        azureProviderDetails.provider = "Azure"

        const testConnectionResponse = await fetchTestConnection(azureProviderDetails)

        if (testConnectionResponse.statusCode === 200) {
            setTestConnectionSuccessPopup(true)
            // setShowAzurePopup(false)
            // setShowAddProvider(false)
        }
        else if (testConnectionResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAzurePopup(false)
        }
        setAzureTestConnectionLoaderActive(false)
    }

    const azureAddProvider = async () => {
        setAzureSaveLoaderActive(true)
        azureProviderDetails.organizationId = organization.organizationId
        azureProviderDetails.provider = "Azure"
        const updateProviderResponse = await updateProviderDetails(azureProviderDetails, 0)

        if (updateProviderResponse.statusCode === 200) {
            handleAzurePopupClose()
            getProviderGridDetails(organization.organizationId, "Azure")
            navigate('/dashboard')
        }
        else if (updateProviderResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAzurePopup(false)
        }
        setAzureSaveLoaderActive(false)
    }

    const getProviderGridDetails = async (
        organizationId: number,
        selectedProviderType: string
    ) => {
        setShowPageLoader(true);
        providerPayload.providerType = selectedProviderType;
        const providerDetailsResponse = await fetchProviders(
            providerPayload,
            organizationId
        );

        if (providerDetailsResponse.statusCode === 200) {
            setProviderDetail(providerDetailsResponse.data);
        }

        setShowPageLoader(false);
    };

    const handleAwsSecretAdd = async () => {
        setAwsAddProvderSaveLoader(true)
        let postSecretPayload = {
            organizationId: organization.organizationId,
            providerType: "AWS",
            secretId: 0,
            secretName: awsAddSecretPayload.secretName,
            values: JSON.stringify({
                accessKey: awsAddSecretPayload.values.accessKey,
                secretKey: awsAddSecretPayload.values.secretKey
            })
        }
        const updateSecretKeyResponse = await postSecretKeyDetails(postSecretPayload)

        if (updateSecretKeyResponse.statusCode === 200) {
            await getSecretKeyDetails("AWS")

            let awsAddProviderObject: IAWSSecret = {
                secretId: 0,
                secretName: "",
                providerType: "",
                organizationId: 0,
                values: {
                    accessKey: "",
                    secretKey: ""
                }
            }

            setAwsAddSecretPayload(awsAddProviderObject)
            setShowAWSAddSecretPopup(false)
            setShowAWSPopup(true)
        }

        setAwsAddProvderSaveLoader(false)
    }

    const handleAzureAddSecretDataChange = (id: string, value: string) => {
        setAzureAddSecretPayload((prevData: any) => {
            let updatedAzureSecret = { ...prevData }
            if (id === "secretName") {
                updatedAzureSecret = { ...prevData, [id]: value }
            }
            else if (id === "clientid") {
                updatedAzureSecret.values.clientid = value
            }
            else if (id === "clientSecret") {
                updatedAzureSecret.values.clientSecret = value
            }

            if (id === "secretName" && value === "") {
                setAzureSecretNameRequired(true)
            }
            else if (id === "secretName" && value !== "") {
                setAzureSecretNameRequired(false)
            }

            if (id === "clientid" && value === "") {
                setAzureClientIdRequired(true)
            }
            else if (id === "clientid" && value !== "") {
                setAzureClientIdRequired(false)
            }

            if (id === "clientSecret" && value === "") {
                setAzureClientSecretKeyRequired(true)
            }
            else if (id === "clientSecret" && value !== "") {
                setAzureClientSecretKeyRequired(false)
            }

            if (
                updatedAzureSecret.secretName !== "" &&
                updatedAzureSecret.values.clientid !== "" &&
                updatedAzureSecret.values.clientSecret !== ""
            ) {
                setAzureAddProvderSaveButton(false)
            }
            else {
                setAzureAddProvderSaveButton(true)
            }
            return updatedAzureSecret
        })
    }

    const handleAwsPopupClose = () => {
        setShowAWSPopup(false)
        setAwsProviderDetails(awsProviderPayload)
        setShowProviderSelectPopup(true)
    }

    const handleAwsProviderDetailsChange = (id: string, value: any) => {
        const accountIdRegex = /^[0-9]{12}$/;
        setAwsProviderDetails((prevData: any) => {
            let updatedAwsProviderDetails = { ...prevData }
            if (id !== "secretId") {
                updatedAwsProviderDetails = { ...prevData, [id]: value }

                //validations
                if (id === "connectorName" && value === "") {
                    setAwsConnectorNameRequires(true)
                }
                else if (id === "connectorName" && value !== "") {
                    setAwsConnectorNameRequires(false)
                }

                if (id === "accountId" && value === "") {
                    setAwsAccountIdRequired(true)
                    setAwsValidAccountIdRequired(false)
                }
                else if (id === "accountId" && value !== "") {
                    setAwsAccountIdRequired(false)
                    if (accountIdRegex.test(value)) {
                        setAwsValidAccountIdRequired(false)
                    }
                    else if (!(accountIdRegex.test(value))) {
                        setAwsValidAccountIdRequired(true)
                    }
                }
            }
            else if (id === "secretId") {
                if (value !== "Select") {
                    setAwsSecretKeyRequired(false)
                    const secrets = value.split("#$%");

                    updatedAwsProviderDetails = {
                        ...prevData,
                        secretId: parseInt(secrets[0]),
                        secretName: secrets[1]
                    };
                }
                else if (value === "Select") {
                    updatedAwsProviderDetails = {
                        ...prevData,
                        secretId: 0,
                        secretName: ""
                    };
                    setAwsSecretKeyRequired(true)
                }
            }

            if (
                updatedAwsProviderDetails.connectorName !== "" &&
                updatedAwsProviderDetails.accountId !== "" &&
                updatedAwsProviderDetails.secretId !== 0 &&
                (accountIdRegex.test(updatedAwsProviderDetails.accountId))
            ) {
                setIsAwsTestConnectionButtonDiabled(false)
                setIsAwsSaveButtonDiabled(false)
            }
            else {
                setIsAwsTestConnectionButtonDiabled(true)
                setIsAwsSaveButtonDiabled(true)
            }
            return updatedAwsProviderDetails;
        })
    }

    const awsTestConnection = async () => {
        setAwsTestConnectionLoaderActive(true)
        awsProviderDetails.organizationId = organization.organizationId
        awsProviderDetails.provider = "AWS"

        const testConnectionResponse = await fetchTestConnection(awsProviderDetails)

        if (testConnectionResponse.statusCode === 200) {
            setTestConnectionSuccessPopup(true)
            setShowAWSPopup(false)
        }
        else if (testConnectionResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAWSPopup(false)
        }
        setAwsTestConnectionLoaderActive(false)
    }

    
    const awsAddProvider = async () => {
        setAwsSaveLoaderActive(true)
        awsProviderDetails.organizationId = organization.organizationId
        awsProviderDetails.provider = "AWS"
        const updateProviderResponse = await updateProviderDetails(awsProviderDetails, 0)

        if (updateProviderResponse.statusCode === 200) {
            setAddedProviderSuccessPopup(true)
        }
        else if (updateProviderResponse.statusCode === 500) {
            setShowNotAddedErrorPopup(true)
            setShowAWSPopup(false)
        }
        setAwsSaveLoaderActive(false)
    }

    const handleAwsAddSecretClose = () => {

        let awsAddProviderObject: IAWSSecret = {
            secretId: 0,
            secretName: "",
            providerType: "",
            organizationId: 0,
            values: {
                accessKey: "",
                secretKey: ""
            }
        }

        setShowAWSAddSecretPopup(false);
        setShowAWSPopup(true)
        setAwsAddSecretPayload(awsAddProviderObject)
        setAwsSaveLoaderActive(false)
    }

    const handleAwsAddSecretDataChange = (id: string, value: string) => {
        setAwsAddSecretPayload((prevData: any) => {
            let updatedAwsSecret = { ...prevData }
            if (id === "secretName") {
                updatedAwsSecret = { ...prevData, [id]: value }
            }
            else if (id === "accessKey") {
                updatedAwsSecret.values.accessKey = value
            }
            else if (id === "secretKey") {
                updatedAwsSecret.values.secretKey = value
            }

            if (id === "secretName" && value === "") {
                setAwsSecretNameRequired(true)
            }
            else if (id === "secretName" && value !== "") {
                setAwsSecretNameRequired(false)
            }

            if (id === "accessKey" && value === "") {
                setAwsAccessRequired(true)
            }
            else if (id === "accessKey" && value !== "") {
                setAwsAccessRequired(false)
            }

            if (id === "secretKey" && value === "") {
                setAwsNewSecretKeyRequired(true)
            }
            else if (id === "secretKey" && value !== "") {
                setAwsNewSecretKeyRequired(false)
            }

            if (
                updatedAwsSecret.secretName !== "" &&
                updatedAwsSecret.values.accessKey !== "" &&
                updatedAwsSecret.values.secretKey !== ""
            ) {
                setAwsAddProvderSaveButton(false)
            }
            else {
                setAwsAddProvderSaveButton(true)
            }
            return updatedAwsSecret
        })
    }

    const bindUserData = () => {
        return userDetails.users.map((eachUsers: IUserDetails, index: number) => (
            <tr key={index + 1} className="custom-alternative-line ng-untouched ng-pristine ng-invalid">
                <td className="custom-grid-font-weight text-center text-nowrap">
                    {index + 1}
                </td>
                {editIndexValue !== index ? (
                    <>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUsers.name}
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUsers.emailId}
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUsers.roleName}
                        </td>editIndexValueeditIndexValue
                    </>
                ) : (
                    <>
                        <td>
                            <input
                                className="custom-input form-control py-2 px-3 ng-pristine ng-valid ng-touched"
                                type="text"
                                value={eachUsers.name}
                                onChange={(e) => handleNameChange(e.target.value, index)}
                            />
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {eachUsers.emailId}
                        </td>
                        <td className="custom-grid-font-weight grid-primary-text text-nowrap">
                            {/* Assuming roleName is coming from a dropdown */}
                            <select className="custom-input py-2 px-3 form-select ng-pristine ng-valid ng-touched"
                                value={eachUsers.roleName + "@#$" + eachUsers.roleId}
                                onChange={(e) => handleRoleChange(e.target.value, index)}
                            >
                                <option>Select Role</option>
                                {organization.roleName === "Owner" &&
                                    <option value="Billing Admin@#$2">Billing Admin</option>}
                                <option value="Billing Reader@#$3">Billing Reader</option>
                                {/* Add more options as needed */}
                            </select>
                        </td>
                    </>
                )}
                <td className="custom-grid-font-weight text-nowrap text-center">
                    <span className={`custom-batch ${eachUsers.status === "Active" ? "batch-green" : "batch-grey"}`}>
                        {eachUsers.status}
                    </span>
                </td>
                {editIndexValue !== index && (
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        {eachUsers.roleName !== "Owner" &&
                            <>
                                <a onClick={() => setEditIndexValue(index)}>
                                    <span className="me-3 edit-img" data-bs-toggle="tooltip" title="Edit" data-bs-placement="top"></span>
                                </a>
                                <a onClick={() => { handleDeleteUser(eachUsers.loginId) }}>
                                    <span className="delete-img" data-bs-toggle="tooltip" title="Delete" data-bs-placement="top"></span>
                                </a>
                            </>

                        }
                    </td>
                )}
                {editIndexValue === index && (
                    <td className="custom-grid-font-weight text-center text-nowrap">
                        <a>
                            <span className="me-3 tick-img" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Save" onClick={() => { editUserDetails(index) }}></span>
                        </a>
                        <a>
                            <span className="wrong-img" onClick={() => onRemoveEdit(index)}></span>
                        </a>
                    </td>
                )}
            </tr>
        ));
    };

    const bindSecretKeys = () => {
        return secretKeys.secretdetails.map((eachSecretKey: any, index: number) => (
            <option key={index} value={`${eachSecretKey.secretId + "#$%" + eachSecretKey.secretName}`}>{eachSecretKey.secretName}</option>
        ))
    }

    return (
        <>
        {showAddProvider &&     
            <>
                <Header />
                <div role="dialog" id="landing-popup" tabIndex={-1} className={`modal ${showOptionPopup ? 'show-popup' : ''}`}>
                    <div className="modal-dialog custom-width-providerpopup">
                        <div className="modal-content popup-sty border-0 custom-border-radius">
                            <div className="modal-body">
                                <div className="text-center mb-4">
                                    <img className="mt-5" src="images/Provider-Icon.svg" alt="Provider Image" />
                                    <label className="form-label custom-pop-label mt-5 mb-0">Looks like you haven't added any providers yet.</label>
                                    <label className="form-label custom-pop-label">To view the dashboard, please add a Provider or a User.</label>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn secondary-btn responsive-style responsive-full-width" onClick={() => { setShowProviderSelectPopup(true); setShowOptionPopup(false); }}>Add Provider</button>
                                <label className="custom-pop-label mx-md-3">or</label>
                                <button type="button" className="btn primary-btn responsive-style responsive-full-width" onClick={() => { setShowAddModal(true); setShowOptionPopup(false); users() }}>Add User</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div role="dialog" tabIndex={-1} className={`modal show fade ${showAddModal ? 'show-popup' : ''}`}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content popup-sty px-3 border-0 custom-border-radius">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style">Add Users</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"onClick={() => { setShowAddModal(false); setShowOptionPopup(true) }}></button>
                            </div>
                            <div className="modal-body pt-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="custom-pop-label">Add new users to the organization and assign roles. An invitation will be
                                    sent to each user via email. At least one billing admin user is required.</label>
                                        {/* <!--InlineTable section starts here--> */}
                                        <div className="table-responsive">
                                            <table className="table table-borderless custom-grid mt-4 mb-4">
                                                <thead className="custom-table-head">
                                                    <tr>
                                                        <th className="custom-grid-font-weight text-center text-nowrap">
                                                            S.NO
                                                        </th>
                                                        <th className="custom-grid-font-weight text-nowrap position-relative">
                                                            NAME
                                                        </th>
                                                        <th className="custom-grid-font-weight text-nowrap">
                                                            EMAIL ID
                                                        </th>
                                                        <th className="custom-grid-font-weight text-nowrap position-relative">
                                                            ROLE
                                                        </th>
                                                        <th className="custom-grid-font-weight text-nowrap text-center position-relative">
                                                            STATUS
                                                        </th>
                                                        <th className="custom-grid-font-weight text-center text-nowrap">
                                                            ACTION
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td className="text-nowrap custom-grid-font-weight">
                                                            <input
                                                                id="name"
                                                                type="text"
                                                                className="custom-input form-control py-2 px-3"
                                                                placeholder="Enter the name"
                                                                value={newUserDetails.name}
                                                                onChange={(e) => handleNewUserChange(e)}
                                                                onBlur={(e) => setIsNameRequired(e.target.value === "")} />
                                                            {isNameRequired &&
                                                                <span className="text-danger d-block">
                                                                    Name is required
                                                                </span>}
                                                        </td>
                                                        <td className="text-nowrap custom-grid-font-weight">
                                                            <input
                                                                id="emailId"
                                                                type="text"
                                                                className="custom-input form-control py-2 px-3"
                                                                placeholder="Enter Email ID"
                                                                value={newUserDetails.emailId}
                                                                onChange={(e) => handleNewUserChange(e)}
                                                                onBlur={(e) => { checkIfUserExist(e); }} />
                                                            {isEmailRequired &&
                                                                <span className="text-danger d-block">
                                                                    Email ID is required
                                                                </span>}
                                                            {emailExistError &&
                                                                <span className="text-danger d-block">
                                                                    User is an owner or already present in this organization
                                                                </span>}
                                                            {enterValidMail &&
                                                                <span className="text-danger d-block">
                                                                    Enter a valid Email ID
                                                                </span>}
                                                        </td>
                                                        <td className="text-nowrap custom-grid-font-weight">
                                                            <select
                                                                className="custom-input py-2 px-3 form-select"
                                                                id="Technology-Vertical"
                                                                value={selectedRole}
                                                                onChange={(e) => handleNewUserDropDownChange(e)}
                                                                onBlur={(e) => { setIsRoleRequired(e.target.value === "Select Role") }}
                                                            >
                                                                <option>Select Role</option>
                                                                <option value="2">Billing Admin</option>
                                                                <option value="3">Billing Reader</option>
                                                            </select>
                                                            <span className="text-danger d-block">
                                                                {isRoleRequired &&
                                                                    "Role is required"
                                                                }</span>
                                                        </td>
                                                        <td className="custom-grid-font-weight text-nowrap text-center"></td>
                                                        <td className="custom-grid-font-weight text-center text-nowrap">
                                                            <a onClick={() => addUser()}>
                                                                <span
                                                                    className="add-img"
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="top"
                                                                    title=""
                                                                    data-bs-original-title="Add"
                                                                ></span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    {userDetails.count === 0 && (
                                                        <tr className="custom-alternative-line">
                                                            <td colSpan={6} className="text-center">
                                                                <img
                                                                    src="images/no-rec-found.gif"
                                                                    alt="no-rec-found"
                                                                    className="no-data-found-sty mb-4" />
                                                                <p className="para-text">No data found</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr></tr>
                                                    {bindUserData()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="modal-footer border-0 pb-4">
                                            <button type="button" className="btn secondary-btn" data-bs-dismiss="modal" onClick={() => { setShowAddModal(false); setShowOptionPopup(true) }}>Cancel</button>
                                            <button type="button" className="btn primary-btn ms-3" onClick={() => { setSaveDetailsLoader(true); handleSaveDetails() }}>
                                                {saveDetailsLoader &&
                                                    <img src="images/white-loader.gif" alt="white-loader" className="btn-loader"/>
                                                }
                                                Save Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                    </div>
                </div>

                <div role="dialog" tabIndex={-1} className={`modal show fade ${showProviderSelectPopup ? 'show-popup' : ''}`}>
                    <div className="modal-dialog modal-xl choose-provider-modal custom-width-option">
                        <div className="modal-content popup-sty border-0 px-3 py-2 custom-border-radius">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style">Add New Providers</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setShowOptionPopup(true); setShowProviderSelectPopup(false); setSelectedProvider("")}}
                                ></button>
                            </div>
                            <div className="modal-body pt-2">
                                <h5 className="custom-subheading-style mb-3">Cloud Providers</h5>
                                <div className="row mb-4">
                                    <div className="col-6 col-md-3 col-sm-6 col-lg-3 mb-3">
                                        <button type="button" className="btn btn-white shadow-sm pop-card position-relative" onClick={() => {setSelectedProvider("AWS")}}
                                        >
                                            <span className="icons-custom-width aws-img"></span>
                                            {selectedProvider === "AWS" &&
                                                <span className="position-absolute top-0 start-100 translate-middle badge custom-badge rounded-pill p-2">
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            }
                                        </button>
                                    </div>

                                    <div className="col-6 col-md-3 col-sm-6 col-lg-3 mb-3">
                                        <button type="button" className="btn btn-white shadow-sm pop-card position-relative" onClick={() => {setSelectedProvider("Azure")}}
                                        >
                                            <img className="icons-custom-width" src="images/azure-logo.svg" alt="azure" />
                                            {selectedProvider === "Azure" &&
                                                <span className="position-absolute top-0 start-100 translate-middle badge custom-badge rounded-pill p-2">
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            }
                                        </button>
                                    </div>

                                    <div className="col-6 col-md-3 col-sm-6 col-lg-3 mb-3">
                                        <button type="button" className="btn btn-white shadow-sm pop-card position-relative color-disabled" disabled>
                                            <img className="icons-custom-width" src="images/Googlecloud-Logo.svg" alt="Googlecloud" />
                                            Coming soon
                                        </button>
                                    </div>
                                </div>

                                <h5 className="custom-subheading-style mb-3">Workspace Providers</h5>
                                <div className="col-md-12  mt-2">
                                    <div className="row">
                                        <div className="col-6 col-md-3 col-sm-6 col-lg-3 mb-3">
                                            <button type="button" className="btn btn-white shadow-sm pop-card position-relative color-disabled" disabled>
                                                <img className="icons-custom-width" src="images/MS-Logo.svg" alt="MS" />
                                                Coming soon
                                            </button>
                                        </div>
                                        <div className="col-6 col-md-3 col-sm-6 col-lg-3 mb-3">
                                            <button type="button" className="btn btn-white shadow-sm pop-card position-relative color-disabled" disabled>
                                                <img className="icons-custom-width" src="images/Gsuite-Logo.svg" alt="Gsuite" />
                                                Coming soon
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-4">
                                <button type="button" className="btn primary-btn ms-3" onClick={() => {handleProviderTypeSelect()}} disabled = {selectedProvider === ""}
                                >Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of Azure add provider popup */}
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAzurePopup ? 'show-popup' : ''}`}>
                    <div role="document" className="custom-width-connect-style modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style d-flex align-items-center">
                                    <span className="me-3 provider-icon azure-bg-img shadow-sm"></span>
                                    Add Azure Connect
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {handleAzurePopupClose()}}
                                ></button>
                            </div>
                            <div className="modal-body pt-2 pb-0">
                                <div className="row mb-4">
                                    <div className="col-md-6 mb-3">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="connectorName"
                                        >
                                            Connector Name
                                        </label>
                                        <input
                                            className="w-100 custom-input form-control py-2 px-2"
                                            type="text"
                                            placeholder="Enter Connector Name"
                                            id="connectorName"
                                            value = {azureProviderDetails.connectorName}
                                            onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAzureConnectorNameRequired(e.target.value === "") }}
                                        />
                                        {azureConnectorNameRequired &&
                                            <   span className="text-danger d-block error-span">Connector Name is required</span>
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="tenantId"
                                        >
                                            Tenant ID
                                        </label>
                                        <input
                                            className="w-100 custom-input form-control py-2 px-2"
                                            type="text"
                                            placeholder="Enter Tenant ID"
                                            id="tenantId"
                                            value = {azureProviderDetails.tenantId}
                                            onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAzureTenantAccountIdRequired(e.target.value === "") }}
                                        />
                                        {azureTenantIdRequired &&
                                            <   span className="text-danger d-block error-span">Tenant ID is required</span>
                                        }
                                        {azureInValidTenantIdError &&
                                            <   span className="text-danger d-block error-span">Enter a valid Tenant ID</span>
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="subscriptionId"
                                        >
                                            Subscription ID
                                        </label>
                                        <input
                                            className="w-100 custom-input form-control py-2 px-2"
                                            type="text"
                                            placeholder="Enter Subscription ID"
                                            id="subscriptionId"
                                            value = {azureProviderDetails.subscriptionId}
                                            onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAzureSubscriptionIdRequired(e.target.value === "") }}
                                        />
                                        {azureSubscriptionIdRequired &&
                                            <   span className="text-danger d-block error-span">Subscription ID is required</span>
                                        }
                                        {azureInValidSubscriptionIdError &&
                                            <   span className="text-danger d-block error-span">Enter a valid Subscription ID</span>
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <label
                                                    className="w-100 form-label custom-label"
                                                    htmlFor="secretId"
                                                >
                                                    Secret Key
                                                </label>
                                                <select
                                                    className="custom-input py-2 px-3 form-select"
                                                    id="secretId"
                                                    value={`${azureProviderDetails.secretId + "#$%" + azureProviderDetails.secretName}`}
                                                    onChange={(e) => {
                                                        handleAzureProviderDetailsChange(e.target.id, e.target.value);
                                                    }}
                                                    onBlur={(e) => { setAzureSecretKeyRequired(e.target.value === "") }}
                                                >
                                                    <option value="">Select</option>
                                                    {bindSecretKeys()}
                                                </select>
                                            </div>
                                            <div className="col-md-3 px-md-0 d-flex align-items-end">
                                                <button type="button" className="btn primary-btn mt-3" onClick={() => { setShowAzureAddSecretPopup(true); setShowAzurePopup(false) }}>
                                                    Add
                                                </button>
                                            </div>
                                            {azureSecretKeyRequired &&
                                                <span className="text-danger d-block error-span">Secret Key is required</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="description"
                                        >
                                            Description (optional)
                                        </label>
                                        <textarea
                                            className="form-control custom-input custom-resize"
                                            rows={3}
                                            placeholder="Enter Description"
                                            id="description"
                                            value = {azureProviderDetails.description}
                                            onChange={(e) => { handleAzureProviderDetailsChange(e.target.id, e.target.value) }}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-5 responsive-padding-none">
                                <button type="button" className="btn ternary-btn" onClick={() => {handleAzurePopupClose()}}>
                                    Cancel
                                </button>
                                <button type="button" className="btn secondary-btn ms-3" disabled = {isAzureTestConnectionButtonDiabled} onClick={() => azureTestConnection()}>
                                    {azureTestConnectionLoaderActive &&
                                        <img
                                            src="images/white-loader.gif"
                                            alt="white-loader"
                                            className="btn-loader"
                                        />
                                    }
                                    Test
                                    <span className="responsive-display-none">Connection</span>
                                </button>
                                <button type="button" className="btn primary-btn ms-3" disabled = {isAzureSaveButtonDiabled} onClick={() => azureAddProvider()}>
                                    {azureSaveLoaderActive &&
                                        <img
                                            src="images/white-loader.gif"
                                            alt="white-loader"
                                            className="btn-loader"
                                        />
                                    }
                                    Save
                                    <span className="responsive-display-none">Details</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of azure add secret popUp */}
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAzureAddSecretPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style d-flex align-items-center"><span
                                    className="me-3 provider-icon azure-bg-img shadow-sm"></span>Add new secret key</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { handleAzureAddSecretClose() }}
                                ></button>
                            </div>
                            <div className="modal-body pt-2 pb-0">
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="SecretName">Secret Name
                                            <span className="color-warning">*</span>
                                            <span>
                                                <img className="ms-2" src="images/information-icon.svg" alt="info" />
                                            </span>
                                        </label>
                                        <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Name"
                                            id="secretName"
                                            value={azureAddSecretPayload.secretName}
                                            onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAzureSecretNameRequired(e.target.value === "") }}/>
                                        <span className={`text-danger d-block error-span ${azureSecretNameRequired ? "" : "invisible"}`}>
                                            Secret Name is required
                                        </span>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="clientid">Client ID<span
                                            className="color-warning">*</span></label>
                                        <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Client ID"
                                            id="clientid"
                                            value={azureAddSecretPayload.values.clientid}
                                            onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAzureClientIdRequired(e.target.value === "") }}/>
                                        <span className={`text-danger d-block error-span ${azureClientIdRequired ? "" : "invisible"}`}>
                                            Client ID is required
                                        </span>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="clientSecret">Client Secret<span
                                            className="color-warning">*</span></label>
                                        <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Client Secret"
                                            id="clientSecret"
                                            value={azureAddSecretPayload.values.clientSecret}
                                            onChange={(e) => { handleAzureAddSecretDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAzureClientSecretKeyRequired(e.target.value === "") }}/>
                                        <span className={`text-danger d-block error-span ${azureClientSecretKeyRequired ? "" : "invisible"}`}>
                                            Client Secret is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                                <button type="button" className="btn secondary-btn" onClick={() => { handleAzureAddSecretClose() }}
                                >Cancel</button>
                                <button type="button" className="btn primary-btn ms-3"
                                disabled={azureAddProvderSaveButton}
                                onClick={() => handleAzureSecretAdd()}
                                >
                                    {azureAddProvderSaveLoader &&
                                        <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                    }
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of Aws add provider popup */}
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAWSPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="custom-width-connect-style modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style d-flex align-items-center">
                                    <span className="me-3 provider-icon aws-bg-img shadow-sm"></span>Add
                                    AWS Connect
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => { handleAwsPopupClose() }}
                                ></button>
                            </div>
                            <div className="modal-body pt-2 pb-0">
                                <div className="row mb-4">
                                    <div className="col-md-6 mb-3">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="connectorName"
                                        >
                                            Connector Name
                                        </label>
                                        <input
                                            className="w-100 custom-input form-control py-2 px-2"
                                            type="text"
                                            placeholder="Enter Connector Name"
                                            id="connectorName"
                                            value={awsProviderDetails.connectorName}
                                            onChange={(e) => {
                                                handleAwsProviderDetailsChange(e.target.id, e.target.value);
                                            }}
                                            onBlur={(e) => { setAwsConnectorNameRequires(e.target.value === "") }}
                                        />
                                        {awsConnectorNameRequired &&
                                            <span className="text-danger d-block error-span">Connector Name is required</span>
                                        }
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="accountId"
                                        >
                                            Account ID
                                        </label>
                                        <input
                                            className="w-100 custom-input form-control py-2 px-2"
                                            type="text"
                                            placeholder="Enter Account ID"
                                            id="accountId"
                                            value={awsProviderDetails.accountId}
                                            onChange={(e) => {
                                                handleAwsProviderDetailsChange(e.target.id, e.target.value);
                                            }}
                                            onBlur={(e) => { setAwsAccountIdRequired(e.target.value === "") }}
                                        />
                                        {awsAccountIdRequired &&
                                            <span className="text-danger d-block error-span">Account ID is required</span>
                                        }
                                        {awsValidAccountIdRequired &&
                                            <span className="text-danger d-block error-span">Enter a valid Account ID</span>
                                        }
                                        <div className="form-check mt-2">
                                            <input
                                                className="form-check-input custom-checked"
                                                type="checkbox"
                                                value=""
                                                id="isLinkedAccount"
                                                checked={awsProviderDetails.isLinkedAccount}
                                                onChange={(e) => {
                                                    handleAwsProviderDetailsChange(e.target.id, e.target.checked);
                                                }}
                                            />
                                            <label
                                                className="form-check-label custom-label"
                                                htmlFor="isLinkedAccount"
                                            >
                                                Is this linked account ?
                                            </label>{" "}
                                            <span>
                                                <img
                                                    className="ms-1"
                                                    src="images/information-icon.svg"
                                                    alt="info"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title = "Enable this, if you are using consolidated billing in AWS"
                                                    data-bs-original-title="Edit"                
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <label
                                                    className="w-100 form-label custom-label"
                                                    htmlFor="secretId"
                                                >
                                                    Secret Key
                                                </label>
                                                <select
                                                    className="custom-input py-2 px-3 form-select"
                                                    id="secretId"
                                                    value={`${awsProviderDetails.secretId + "#$%" + awsProviderDetails.secretName}`}
                                                    onChange={(e) => {
                                                        handleAwsProviderDetailsChange(e.target.id, e.target.value);
                                                    }}
                                                    onBlur={(e) => { setAwsSecretKeyRequired(e.target.value === "") }}
                                                >
                                                    <option value="">
                                                        Select
                                                    </option>
                                                    {bindSecretKeys()}
                                                </select>
                                            </div>
                                            <div className="col-md-3 px-md-0 d-flex align-items-end">
                                                <button type="button" className="btn primary-btn mt-3" onClick={() => { setShowAWSAddSecretPopup(true); setShowAWSPopup(false) }}>
                                                    Add
                                                </button>
                                            </div>
                                            {awsSecretKeyRequired &&
                                                <span className="text-danger d-block error-span">Secret Key is required</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label
                                            className="w-100 form-label custom-label"
                                            htmlFor="Description"
                                        >
                                            Description (optional)
                                        </label>
                                        <textarea
                                            className="form-control custom-input custom-resize"
                                            rows={3}
                                            placeholder="Enter Description"
                                            id="description"
                                            onChange={(e) => { handleAwsProviderDetailsChange(e.target.id, e.target.value) }}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-5 responsive-padding-none">
                                <button type="button" className="btn ternary-btn" onClick={() => { handleAwsPopupClose() }}>
                                    Cancel
                                </button>
                                <button type="button"
                                    className="btn secondary-btn ms-3"
                                    disabled={isAwsTestConnectionButtonDiabled}
                                    onClick={() => { awsTestConnection() }}>
                                    {awsTestConnectionLoaderActive &&
                                        <img
                                            src="images/white-loader.gif"
                                            alt="white-loader"
                                            className="btn-loader"
                                        />
                                    }
                                    Test <span className="responsive-display-none">Connection</span>
                                </button>
                                <button type="button"
                                    className="btn primary-btn ms-3"
                                    disabled={isAwsSaveButtonDiabled}
                                    onClick={() => { awsAddProvider() }}>
                                    {awsSaveLoaderActive &&
                                        <img
                                            src="images/white-loader.gif"
                                            alt="white-loader"
                                            className="btn-loader"
                                        />
                                    }
                                    Save <span className="responsive-display-none">Details</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of aws add secret popup */}
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showAWSAddSecretPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title custom-header-style d-flex align-items-center"><span
                                    className="me-3 provider-icon aws-bg-img shadow-sm"></span>Add new secret key</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => { handleAwsAddSecretClose() }}>
                                </button>
                            </div>
                            <div className="modal-body pt-2 pb-0">
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="secretName">Secret Name
                                            <span className="color-warning">*</span>
                                            <span>
                                                <img className="ms-2" src="images/information-icon.svg" alt="info" />
                                            </span></label>
                                        <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Name"
                                            value={awsAddSecretPayload.secretName}
                                            id="secretName"
                                            onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAwsSecretNameRequired(e.target.value === "") }} />
                                        <span className={`text-danger d-block error-span ${awsSecretNameRequired ? "" : "invisible"}`}>
                                            Secret Name is required
                                        </span>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="accessKey">
                                            Access Key
                                            <span className="color-warning">*</span>
                                        </label>
                                        <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Access Key"
                                            value={awsAddSecretPayload.values.accessKey}
                                            id="accessKey"
                                            onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAwsAccessRequired(e.target.value === "") }} />
                                        <span className={`text-danger d-block error-span ${awsAccessRequired ? "" : "invisible"}`}>
                                            Access Key is required
                                        </span>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="w-100 form-label custom-label" htmlFor="secretKey">Secret Key<span
                                            className="color-warning">*</span></label>
                                        <input className="w-100 custom-input form-control py-2 px-2" type="text" placeholder="Enter Secret Key"
                                            id="secretKey"
                                            onChange={(e) => { handleAwsAddSecretDataChange(e.target.id, e.target.value) }}
                                            onBlur={(e) => { setAwsNewSecretKeyRequired(e.target.value === "") }}
                                            value={awsAddSecretPayload.values.secretKey} />
                                        <span className={`text-danger d-block error-span ${awsNewSecretKeyRequired ? "" : "invisible"}`}>
                                            Secret Key is required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-end pb-4 pt-0">
                                <button type="button" className="btn secondary-btn"
                                    onClick={() => { handleAwsAddSecretClose() }}
                                >Cancel</button>
                                <button type="button"
                                    className="btn primary-btn ms-3"
                                    disabled={awsAddProvderSaveButton}
                                    onClick={() => handleAwsSecretAdd()}
                                >
                                    {awsAddProvderSaveLoader &&
                                        <img src="images/white-loader.gif" alt="white-loader" className="btn-loader" />
                                    }
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of added provider success popup */}
                <div className={`modal show fade ${addedProviderSuccessPopup ? 'show-popup' : ''}`} tabIndex={-1} role="dialog">
                    <div className="custom-modal-theme modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAddedProviderSuccessPopup(false)} />
                            </div>
                            <div className="modal-body">
                                <div className="text-center mb-1">
                                    <img className="mt-1" src="images/Success.svg" alt="Success" />
                                    <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                                        {`Connector ${selectedProvider} connectivity has been added successfully`}
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => navigate('/dashboard')}>Got It</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of test connection success popup */}
                <div tabIndex={-1} role="dialog" className={`modal show fade ${testConnectionSuccessPopup ? 'show-popup' : ''}`}>
                    <div className="custom-modal-theme modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {setTestConnectionSuccessPopup(false); setShowAWSPopup(true)}} />
                            </div>
                            <div className="modal-body">
                                <div className="text-center mb-1">
                                    <img className="mt-1" src="images/Success.svg" alt="Success" />
                                    <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                                        {`The ${selectedProvider} connectivity has been verified`}
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn primary-btn" data-bs-dismiss="modal" onClick={() => {setTestConnectionSuccessPopup(false); setShowAWSPopup(true)}}>Got It</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* start of provider not able to add error popup */}
                <div role="dialog" tabIndex={-1} className={`create-provider-modal modal show fade ${showNotAddedErrorPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="custom-modal-theme modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <button type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="btn-close"
                                    onClick={() => { setShowNotAddedErrorPopup(false); setShowAWSPopup(true) }}></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-center mb-1">
                                    <img alt="Error" src="images/Error.svg" />
                                    <label className="form-label custom-pop-label mt-4 mb-0 w-100">
                                    Oops! Looks like there's an error.
                                        {/* {`Failed to connect ${selectedProvider === "AWS" ? awsProviderDetails.connectorName : azureProviderDetails.connectorName}. Please try again later.Error: validation failed`} */}
                                    </label>
                                    <label className="form-label custom-pop-label mb-0 w-100">Please check the details and try again</label>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" className="btn primary-btn" onClick={() => { setShowNotAddedErrorPopup(false); setShowAWSPopup(true) }}>
                                    Got It
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* user Success popup */}
                <div role="dialog" tabIndex={-1} className={`modal show fade no-provider-modal ${userAddedSuccessPopup ? 'show-popup' : ''}`}>
                    <div role="document" className="custom-width-providerpopup modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="text-center mb-1">
                                    <img src="images/mail-send.gif" alt="Email" className="mt-2 email-gif-sty" />
                                    <label className="form-label custom-pop-label mt-4 mb-0 w-100">Users added successfully!</label>
                                    <label className="form-label custom-pop-label mb-0">An invitation has been sent to their email address.</label>
                                </div>
                            </div>
                            <div className="modal-footer popup-footer d-flex justify-content-center pb-4">
                                <button type="button" data-bs-dismiss="modal" className="btn primary-btn" onClick={() => setUserAddedSuccessPopup(false)}>Got It</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        </>
    )

};