import { IDashboardData } from "../interface/dashboard";

export class CostExpensesLayout {
    static customLayout: IDashboardData[] = [
        {
            chartDimension: 'monthCost',
            chartType: '',
            title: 'Monthly Expenses till Date',
            layout: {
                x: 0,
                y: 0,
                w: 3,
                h: 20,
                minW: 3,
                minH: 20,
                id: 'monthCost',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'forecastedCost',
            chartType: '',
            title: 'Forecasted Cost',
            layout: {
                x: 3,
                y: 0,
                w: 3,
                h: 20,
                minW: 3,
                minH: 20,
                id: 'forecastedCost',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'thresholdLeft',
            chartType: '',
            title: 'Amount left for Threshold',
            layout: {
                x: 6,
                y: 0,
                w: 3,
                h: 20,
                minW: 3,
                minH: 20,
                id: 'thresholdLeft',
                noResize: true,
                noMove: true,
                locked: true
            }
        },
        {
            chartDimension: 'recommendations',
            chartType: '',
            title: 'Recommendations',
            layout: {
                x: 9,
                y: 0,
                w: 3,
                h: 20,
                minW: 3,
                minH: 20,
                id: 'recommendations',
                noResize: true,
                noMove: true,
                locked: true
            }
        }
    ]
}
