import '../src/assets/scss/_styles.scss'
import '../src/assets/scss/_popup-style.scss'
import '../src/assets/scss/_environment.scss'
import '../src/assets/scss/_footer.scss'
import '../src/assets/scss/_dashboard.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/DashBoard'
import UserProfile from './components/UserProfile/UserProfile';
import Users from './components/Manage/Users'
import Check from './components/AddUserProviderCheck/Check'
import { Provider } from 'react-redux';
import store from './redux/store'
import Providers from './components/Manage/Provider'
import Connectors from './components/Manage/Connectors'
import Secrets from './components/Manage/Secrets'
import Inventory from './components/Inventory/Inventory'
import UserRegistration from './components/Login/UserRegistration'
import ForgetPassword from './components/Login/ForgetPassword'
import ResetPassword from './components/Login/ResetPassword'
import SecurityRuleComponent from './components/SecurityRules/SecurityRules'
import ManageEvents from './components/Events/ManageEvents'
import ManageOrganization from './components/Organization/ManageOrganization'
import CostAnomalyDetection from './components/CostAnomalyDetection/CostAnomalyDetection';
import CriticalEvent from './components/CriticalEvents/CriticalEvents'
import Notification from './components/Notification/Notifications'
import { ManageTreshold } from './components/Threshold/Threshold'
import { AddTreshold } from './components/Threshold/AddThreshold'
import { Recommendations } from './components/Recommendations/Recommendations'
import { Invoices } from './components/Invoices/Invoices'
import { ResourceAvailability } from './components/ResourceAvailability/ResourceAvailability'

function App() {
	return (
		<Provider store={store} >
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/login' element={<Login />} />
					<Route path='/userprofile' element={<UserProfile />} />
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/login' element={<Login />} />
					<Route path='/userprofile' element={<UserProfile />} />
					<Route path='/manageuser' element={<Users />} />
					<Route path='/check' element={<Check />} />
					<Route path='/manageproviders' element={<Providers />} />
					<Route path='/manageconnectors' element={<Connectors />} />
					<Route path='/managesecrets' element={<Secrets />} />
					<Route path='/inventory' element={<Inventory />} />
					<Route path='/signup' element={<UserRegistration />} />
					<Route path='/forgetpassword' element={<ForgetPassword />} />
					<Route path='/resetPassword' element={<ResetPassword />} />
					<Route path='/createPassword' element={<ResetPassword />} />
					<Route path='/securityrules' element={<SecurityRuleComponent />} />
					<Route path='/manageevents' element={<ManageEvents />} />
					<Route path='/manageorganization' element={<ManageOrganization />} />
					<Route path='/criticalEvents' element={<CriticalEvent />} />
					<Route path='/costAnomaly' element={<CostAnomalyDetection />} />
					<Route path='/notifications' element={<Notification />} />
					<Route path='/threshold' element={<ManageTreshold/>}/>
					<Route path='/addthreshold' element={<AddTreshold/>}/>
					<Route path='/editthreshold' element={<AddTreshold/>}/>
					<Route path='/recommendation' element={<Recommendations/>}/>
					<Route path='/invoice' element={<Invoices/>}/>
					<Route path='/resourceavailability' element={<ResourceAvailability/>}/>
				</Routes>	
			</BrowserRouter>
		</Provider>
	);
}  

export default App;
